import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";
import { UserService } from "../user.service";
@Component({
  selector: "app-exercise-list",
  templateUrl: "./exercise-list.component.html",
  styleUrls: ["./exercise-list.component.css"],
})
export class ExerciseListComponent implements OnInit {
  id: any;
  api_url;
  questionindex: any;
  exerciselist;
  fiorettourl;
  flagIsWatch = false;
  flagFoundWatched = false;
  FLAGFOREQUALDATE = true;
  video;
  constructor(
    private route: ActivatedRoute,
    private UserService: UserService,
    private router: Router
  ) {
    this.route.queryParams.subscribe((param) => {
      this.id = param["id"];
      this.questionindex = param["questionindex"];
    });
  }

  GetFormattedDate(date: any) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    var today = new Date(date);
    var dd = String(today.getDate()).padStart(2,"0");
    var mm = String(today.getMonth()+1).padStart(2,"0"); //January is 0!
    var yyyy = today.getFullYear();

    return dd + "." + mm + "." + yyyy;
  }



  ngOnInit() {
    this.api_url = environment.API_URL;
    $("#loaderouterid").css("display", "block");
    let data = { starttreatment_id: this.id };
    this.UserService.getexercisebytherapy(data).subscribe((result: any) => {
      this.exerciselist = result.data;
      if (this.exerciselist.length > 0) {
        let i = 0;
        this.exerciselist.forEach((element) => {
          let phasestareflag=true;
          let end_date = new Date(element.end_date);
          let start_date = new Date(element.start_date);
          let currentdate = new Date();

          let k = 0;
          element.exercise.forEach((exercise_id) => {
            let watch = exercise_id.watch;

            if (watch == "1") {
              this.flagIsWatch = true;
              this.flagFoundWatched = true;
            }

            if (this.flagFoundWatched && watch == "0") {
              watch = "2";

              if (end_date.getTime() > currentdate.getTime()) {
                this.flagFoundWatched = false;
              } else if (start_date.getTime() >= currentdate.getTime()) {
                this.flagFoundWatched = false;
              }
            }

            if (end_date.getTime() < currentdate.getTime() && watch != "1") {
              watch = "2";
            }
            // if (end_date.getTime()>currentdate.getTime() && watch != "1") {
            //   watch = "2"
            // }
            if (start_date.getTime() > currentdate.getTime()) {
              watch = "0";
            }

            if (start_date.getTime() == currentdate.getTime()) {
              if (this.FLAGFOREQUALDATE) {
                if(watch!='1')
                {
                watch = "2";
                this.FLAGFOREQUALDATE = false;
                }
              }
            }
            if ((start_date.getTime() < currentdate.getTime()) && phasestareflag && watch == "0") {
              watch = "2";
            }

            phasestareflag=false;

            console.log("datat" + watch);

            this.exerciselist[i].exercise[k].watch = watch;
            k++;
          });

          i++;
        });

        if (!this.flagIsWatch) {
          this.exerciselist[0].exercise[0].watch = "2";
          // if self.model.count > 0 {
          //     self.model[0][0].watch = "2"
          // }
        }
      }

      $("#loaderouterid").css("display", "none");
      console.log(result);
    });
  }

  getdescription(description) {
    return description.slice(0, 50);
  }

  repeatvideo(exercise_id,index)
  {


    Swal.fire({
      title: "Möchten Sie sich diese Übung wirklich noch einmal ansehen?",
      showCancelButton: true,
      confirmButtonText: "Ja",
      cancelButtonText: "Nein"
    }).then(result => {
      if (result.value) {
      $("#loaderouterid").css("display", "block");
      let data11 = {
        exercise_id: exercise_id,
        index: String(index),
        starttreatment_id:this.id
      };
      this.UserService.repeatvideo(data11).subscribe((success11:any)=>{
        this.navigateexercisevideo(exercise_id,index,"repeat");
         $("#loaderouterid").css("display", "none");
        })
      }
    });
    

    
  

  }

  navigateexercisevideo(exercise_id,index,type) {
    this.router.navigate(["/exercisevideo"], {
      queryParams: {
        id: this.id,
        index:index,
        exercise_id: exercise_id,
        type: type,
        questionindex:this.questionindex,
      },
    });
  }
}
