import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

import { UserService } from "../user.service";

@Component({
  selector: 'app-therapist-product',
  templateUrl: './therapist-product.component.html',
  styleUrls: ['./therapist-product.component.css']
})
export class TherapistProductComponent implements OnInit {



  queryID:any;
  routeParams: any;
  constructor(private userService: UserService,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    ) {
      this.activatedRoute.queryParams.subscribe(params => {
        this.queryID = params['name'];
       
        // this.statusCustomer();
        // this.userService.statusCustomer({ "id": this.pagedItems[0]._id })
      });



     }
    title = this.userService.getDecodedAccessToken(sessionStorage.getItem("Token"))
    .title;
    currentActiveRole = sessionStorage.getItem('currentActiveRole');
  lastname = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).lastname;
  firstname = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).firstname;
  COMPANYNAME = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).companyname;

  // loginRole = this.userService.getDecodedAccessToken(localStorage.getItem('token')).roles;
  loginRole = localStorage.getItem("currentActiveRole");
  ngOnInit() {
    let data = $("#goinsidediv").val();
    console.log("dddddddddddddddddddddddddddd" + data);
    this.routeParams = this.activatedRoute.snapshot.routeConfig.path;
  }
 

  add_therapist_product() {
    this.router.navigate(['/add-theraphist-product']);
    // this.queryID = this.selectedUser.customerno;
    // this.ngOnInit()
  }
  therapist_product_management()
  {
    this.router.navigate(['/theraphist-product-management']);
  }
 
}
