import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-online-shop',
  templateUrl: './online-shop.component.html',
  styleUrls: ['./online-shop.component.css']
})
export class OnlineShopComponent implements OnInit {
headername;
  constructor() { }

  ngOnInit() {
    this.headername="Online Shop";
  }

}
