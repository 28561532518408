import { Component, OnInit, ViewChild } from "@angular/core";

import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "../user.service";
import { first } from "rxjs/operators";
import { AgmMap } from "@agm/core";
// import {} from "googlemaps";
import { PagerService } from "../pager.service";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";

@Component({
  selector: 'app-my-company',
  templateUrl: './my-company.component.html',
  styleUrls: ['./my-company.component.css']
})
export class MyCompanyComponent implements OnInit {
  defaultCenter = { lat: 55.5815245, lng: 36.8251383 };
  currentCenter = Object.assign({}, this.defaultCenter);
  zoom = 3;
  api_url;
  editid: any = "";
  popupData: any;
  exerciselist: any = [];
  @ViewChild(AgmMap, null) agmMap;
  showCustomer = false;
  showmekFinanz = false;
  showFiorettoImmob = false;
  showBirema = false;
  showFiorettoMedia = false;
  showAirmage = false;
  showHoraiDetektei = false;
  showVarioImport = false;
  showSterbVorsoge = false;
  showCheckntrack = false;
  customerDocList: any = [];
  latitude: any;
  longitude: any;
  monthnew: any = "";
  month: any = "";
  yearnew: any = "";
  year: any = "";
  token: any;
  origin = {};
  destination = {};
  pager: any = {};
  pagerGDOC: any = {};
  pagedItems: any[] =[];
  pagedItemsGDOC: any[] =[];
  startRecord: any;
  endRecord: any;
  length:any =[];
  startRecordGDOC: any = 0;
  endRecordGDOC: any = 0;
  ImgUrl: string = "";
  href: string = "";
  ShowDiv: boolean = false;
  TimeLineData: [] = [];
  queryID;
  localData: any=[];
  selectedUser = {
    id: "",
  };
  routeParams: any;
  title = this.userService.getDecodedAccessToken(sessionStorage.getItem("Token"))
    .title;
  lastname = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).lastname;
  firstname = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).firstname;
  id = this.userService.getDecodedAccessToken(sessionStorage.getItem("Token")).id;
  COMPANYNAME = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).companyname;
  // localData = JSON.parse(sessionStorage.getItem("currentUser"));
  loginRole = sessionStorage.getItem("currentActiveRole");
  documents: any = [];
  tags: any[] = [];
  previewid: string;
  constructor(
    
    private router: Router,
    private userService: UserService,
    private pagerService: PagerService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.queryID = params["id"];
      console.log(this.queryID);
    });
  }
  navigateWithb2bID() {
    console.log(this.selectedUser);

    this.router.navigate(["/coursula-b2b-dashboard"], {
      queryParams: { id: this.selectedUser.id },
    });

    // this.queryID = this.selectedUser.customerno;
    // this.ngOnInit()
  }

  getmonthname(month) {
    month = Number(month - 1).toString();
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return months[month];
  }

  getcommissionbill(month, year, data=[]) {
    let document_unique_id: any = "";
    if(data.length > 0){
    for (var i = 0; i < data.length; i++) {
      if (data[i].month == month && data[i].year == year) {
        document_unique_id = data[i].document_unique_id;
      }
    }

  }
    return document_unique_id;
    // })
  }

  commissiondata(data) {
    let newarray = [];

    for (let i = 0; i < data.length; i++) {
      // console.log(data[i])
      if (i == 0) {
        this.month = String(
          new Date(data[i].createdAt).getMonth() + 1
        ).padStart(2, "0");
        this.year = String(new Date(data[i].createdAt).getFullYear());
        this.monthnew = this.month;
        this.yearnew = this.year;
      }
      // console.log("monthnew"+i+this.monthnew+"year"+this.yearnew);
      // console.log("monthnew"+i+this.month+"year"+this.year);

      if (this.month == this.monthnew) {
        if (this.year == this.yearnew) {
          newarray.push(data[i]);
        } else {
          // let datanew=data[i];
          // datanew.coursename="Test";
          // console.log("Data1111111gg"+JSON.stringify(data[i])+i)
          // newarray.push(datanew);
          let url: any = this.getcommissionbill(
            this.monthnew,
            this.yearnew,
            data[i - 1].documentnew
          );
          console.log("3urlnew" + url);
          if (url != "") {
            newarray.push({
              coursename: "checkcommissionbill",
              Text:
                "Provisionsrechnung für " +
                this.getmonthname(this.monthnew) +
                " " +
                this.yearnew +
                "",
              url: url,
            });
          }

          // console.log("monthnew11111111111111111111111ffffffffff"+this.monthnew+"year11111111111111111111111ffffffffffff"+this.yearnew+"index"+i);
          // console.log("Data1111111gg"+JSON.stringify(data[i])+i)
          newarray.push(data[i]);
          let year = this.yearnew;
          let month = this.monthnew;
        }

        
      

        if (i != 0) {
          if (i == data.length - 1) {
            //   let datanew=data[i];
            // datanew.coursename="Test";
            // newarray.push(datanew);
            console.log("1urlnew");
            let url: any = this.getcommissionbill(
              this.month,
              this.year,
              data[i - 1].documentnew
            );
       
            if (url != "") {
              newarray.push({
                coursename: "checkcommissionbill",
                Text:
                  "Provisionsrechnung für " +
                  this.getmonthname(this.month) +
                  " " +
                  this.year +
                  "",
                url: url,
              });
            }
            let year = this.year;
            let month = this.month;
            // console.log("monthnew11111111111111111111111"+this.month+"year11111111111111111111111"+this.year+"index"+i);
          }
          this.monthnew = this.month;
          this.yearnew = this.year;
        }
      } else {
        // console.log("Data1111111"+JSON.stringify(data[i])+i)
        console.log("2urlnew");
        let url: any = this.getcommissionbill(
          this.monthnew,
          this.yearnew,
          data[i - 1].documentnew
        );
     
        if (url != "") {
          newarray.push({
            coursename: "checkcommissionbill",
            Text:
              "Provisionsrechnung für " +
              this.getmonthname(this.monthnew) +
              " " +
              this.yearnew +
              " ",
            url: url,
          });
        }
        // console.log("Data1111111"+JSON.stringify(data[i])+i)
        newarray.push(data[i]);
        let year = this.monthnew;
        let month = this.yearnew;

        // console.log("monthnewn"+this.monthnew+"yearn"+this.yearnew+"index"+i);
        if (i != 0) {
          this.monthnew = this.month;
          this.yearnew = this.year;
        }
      }

      if (i == data.length - 1) {
      } else {
        let i1 = i + 1;
        this.month = String(
          new Date(data[i1].createdAt).getMonth() + 1
        ).padStart(2, "0");
        this.year = String(new Date(data[i1].createdAt).getFullYear());
      }
    }

    return newarray;
    // console.log("newarray"+JSON.stringify(newarray));
  }

  ngOnInit() {
    this.api_url = environment.API_URL;
    this.routeParams = this.activatedRoute.snapshot.routeConfig.path;

    this.selectedUser.id = this.id;
    console.log(this.id);
    var company = JSON.parse(
      sessionStorage.getItem("currentUser")
    ).companies_with_roles;

    this.userService
      .getEditUser(this.queryID)
      .pipe(first())
      .subscribe((user: any) => {
        this.localData = user;
      });

    this.userService
      .getcommissionlisybyb2b({ id: this.queryID })
      .pipe(first())
      .subscribe(
        (result) => {
          // this.documents = result;
          this.documents = this.commissiondata(result);
          this.setPage(1, true);
        },
        (error) => {
          console.log(error);
        }
      );
    var datanew = { id: "" };
    this.userService
      .getpurchasemembershipplan(datanew)
      .pipe(first())
      .subscribe(
        (data) => {
          this.customerDocList = data;

          this.setPage(1);
        },
        (error) => {
          console.log(error);
        }
      );

    let strno = this.localData.strno;
    let strassa = this.localData.strassa;
    let city = this.localData.city;
    let current_country = this.localData.current_country;
    let plz = this.localData.plz;
   
  }
  MetaDataLooping() {
    for (let i = 0; i < this.documents.length; i++) {
      console.log("forloop");
      // let date_of_uploadnew = this.documents[i].element.createdAt;
      let date_of_uploadnew = this.documents[i].element.createdAt;
      let metadata = this.documents[i].element.tags[0].split(",");
      var d = new Date(date_of_uploadnew).toLocaleDateString("en-IN", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      var date_of_upload = d.replace(/[/]/g, ".");

      if (typeof metadata[2] != "undefined") {
        let dateofdocument = Number(metadata[2]);
        var date = new Date(dateofdocument).toLocaleDateString("en-IN", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });

        var date_of_document = date.replace(/[/]/g, ".");
      } else {
        var date_of_document = "";
      }
      var filetype = "";
      if (typeof metadata[1] != "undefined") {
        filetype = metadata[1];
      } else {
        filetype = "";
      }
      this.documents[i].size = metadata[0];
      this.documents[i].filetype = filetype;
      this.documents[i].date_of_document = date_of_document;
      this.documents[i].date_of_upload = date_of_upload;
    }
  }

  MetaDataLoopingDocList() {
    for (let i = 0; i < this.customerDocList.length; i++) {
      console.log("forloop");
      let SOC = this.customerDocList[i].element.Start_of_contract;
      let ContributionDate = this.customerDocList[i].element.Contribution;
      console.log("SOC" + SOC);
      console.log("ContributionDate" + ContributionDate);
      let metadata = this.customerDocList[i].element.tags[0].split(",");
      var d1 = new Date(SOC);
      var d2 = new Date(ContributionDate);
      var Start_of_contract =
        d1.getDate() + "." + (d1.getMonth() + 1) + "." + d1.getFullYear();
      var Contribution =
        d2.getDate() + "." + (d2.getMonth() + 1) + "." + d2.getFullYear();

      if (typeof metadata[2] != "undefined") {
        let dateofdocument = Number(metadata[2]);
        var date = new Date(dateofdocument).toLocaleDateString("en-IN", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });

        var date_of_document = date.replace(/[/]/g, ".");
      } else {
        var date_of_document = "";
      }
      var filetype = "";
      if (typeof metadata[1] != "undefined") {
        filetype = metadata[1];
      } else {
        filetype = "";
      }
      this.customerDocList[i].size = metadata[0];
      this.customerDocList[i].filetype = filetype;
      this.customerDocList[i].Start_of_contract = Start_of_contract;
      this.customerDocList[i].Contribution = Contribution;
    }
  }

  preview(
    url,
    tags,
    imagename,
    companycode,
    brand,
    document_name,
    date_of_uploadnew,
    id,
    created_byname,
    ticket_no
  ) {
    this.previewid = id;
    console.log(id);
    let element: HTMLElement = document.getElementById(
      "click" + this.previewid
    ) as HTMLElement;
    if (element.innerHTML == "Schließen") {
      element.innerHTML = "Öffnen";
      // $("#imagediv").removeClass("col-md-7");
      // $("#imagediv").addClass("col-md-12");
      // $("#preview" + id).html("");
      $("#preview" + id).html("");
    } else {
      $(".openclass").html("Öffnen");
      element.innerHTML = "Schließen";
      $(".previewclass").html("");
    }

    if (element.innerHTML == "Schließen") {
      // $("#imagediv").removeClass("col-md-12");
      // $("#imagediv").addClass("col-md-7");
      console.log("tags" + JSON.stringify(tags));
      const removepreview = () => {
        let elementnew: HTMLElement = document.getElementById(
          "click" + this.previewid
        ) as HTMLElement;
        elementnew.innerHTML = "Öffnen";

        // $("#imagediv").removeClass("col-md-7");
        // $("#imagediv").addClass("col-md-12");
        $("#preview" + id).html("");

        console.log("sadasda");
      };

      const result1 = this.getFileExtension(imagename);
      let metadata = tags[0].split(",");
      var d = new Date(date_of_uploadnew).toLocaleDateString("en-IN", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      var date_of_upload = d.replace(/[/]/g, ".");
      if (typeof metadata[2] != "undefined") {
        let dateofdocument = Number(metadata[2]);
        var date = new Date(dateofdocument).toLocaleDateString("en-IN", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });

        var date_of_document = date.replace(/[/]/g, ".");
      } else {
        var date_of_document = "";
      }
      var filetype = "";
      if (typeof metadata[1] != "undefined") {
        filetype = metadata[1];
      } else {
        filetype = "";
      }

      // if (
      //   result1 == "pdf" ||
      //   result1 == "pdfx" ||
      //   result1 == "txt" ||
      //   result1 == "TXT"  ||
      //   result1 == "mp4"  ||
      //   result1 == "MP4"  ||
      //   result1 == "m4v" ||
      //   result1 == "M4V"
      // ) {
      $("#preview" + id).html(
        '<div style="padding: 33px;border:1px solid;margin-bottom: 15px;border-radius: 20px"><div class="col-md-6"  style="display: inline-block;vertical-align: top;"><div class="line-heights"><h3>Dokumentenname: ' +
          document_name +
          "</h3><h3>Dateigröße: " +
          metadata[0] +
          " Kb</h3><h3>Vorgangs Nr.: " +
          ticket_no +
          "</h3><h3>Datum des Dokuments: " +
          date_of_document +
          "</h3><h3>Datum des Uploads: " +
          date_of_upload +
          "</h3><h3>Hochgeladen von: " +
          created_byname +
          "</h3><h3>Dateityp: " +
          filetype +
          "</h3><h3>Stichworte: " +
          companycode +
          "," +
          brand +
          "," +
          ticket_no +
          '</h3></div><div class="col-md-12"> </div></div><div class="col-md-6" style="display: inline-block;"><span class="side-icons"><i class="fa fa-times" aria-hidden="true" style="position:relative;float:right;" aria-hidden="true"  id="previewimg" ></i></span><embed  type="' +
          filetype +
          '" src="' +
          url +
          '" style=" width: 100%; height:850px;object-fit: contain; "/><a href="' +
          environment.API_URL +
          "document/downloaddocument/" +
          imagename +
          '" ><span class="side-icons" ><i class="fa fa-download" style="position:relative;float:right;" aria-hidden="true"  ></i></span></a></div> </div>'
      );

      const someInput = document.getElementById("previewimg");
      someInput.addEventListener(
        "click",
        function () {
          removepreview();
        },
        false
      );

      //   // $('#loaderouterid').css("display","none");
    }
  }
  getFileExtension(filename) {
    // get file extension
    const extension =
      filename.substring(filename.lastIndexOf(".") + 1, filename.length) ||
      filename;
    return extension;
  }

  //Check for Displaying Companies for which the Customer is Registered
  cusCompany() {
    var company = JSON.parse(
      localStorage.getItem("currentUser")
    ).companies_with_roles;
    this.setCompanyCondition(company);

    return;
  }

  // pagination

  setPage(page: number, Double?) {
    // get pager object from service
    //this.getdivoutside();
    if (Double) {
      this.pagerGDOC = this.pagerService.getPager(this.documents.length, page);
      // get current page of items
      this.pagedItemsGDOC = this.documents.slice(
        this.pagerGDOC.startIndex,
        this.pagerGDOC.endIndex + 1
      );
      console.log("AAAAAAAAA" + JSON.stringify(this.pagedItems));
      if (this.documents.length > 0) {
        this.startRecordGDOC =
          this.pagerGDOC.currentPage * this.pagerService.getDefaultPageSize() -
          this.pagerService.getDefaultPageSize() +
          1;
        this.endRecordGDOC =
          this.pagerGDOC.currentPage * this.pagerService.getDefaultPageSize() >
          this.documents.length
            ? this.documents.length
            : this.pagerGDOC.currentPage *
              this.pagerService.getDefaultPageSize();
      } else {
        this.startRecordGDOC = 0;
        this.endRecordGDOC = 0;
      }
      console.log("pageitem" + this.pagedItemsGDOC);
      console.log("startIndex" + this.pagerGDOC.startIndex);
      console.log("endIndex" + this.pagerGDOC.endIndex);
      console.log("startRecord" + this.endRecordGDOC);
      console.log("endRecord" + this.endRecordGDOC);
      console.log("pager" + this.pagerGDOC);
    } else {
      this.pager = this.pagerService.getPager(
        this.customerDocList.length,
        page
      );
      // get current page of items
      this.pagedItems = this.customerDocList.slice(
        this.pager.startIndex,
        this.pager.endIndex + 1
      );
      console.log("AAAAAAAAA" + JSON.stringify(this.pagedItems));
      if (this.customerDocList.length > 0) {
        this.startRecord =
          this.pager.currentPage * this.pagerService.getDefaultPageSize() -
          this.pagerService.getDefaultPageSize() +
          1;
        this.endRecord =
          this.pager.currentPage * this.pagerService.getDefaultPageSize() >
          this.customerDocList.length
            ? this.customerDocList.length
            : this.pager.currentPage * this.pagerService.getDefaultPageSize();
      } else {
        this.startRecord = 0;
        this.endRecord = 0;
      }
      console.log("pageitem" + this.pagedItems);
      console.log("startIndex" + this.pager.startIndex);
      console.log("endIndex" + this.pager.endIndex);
      console.log("startRecord" + this.startRecord);
      console.log("endRecord" + this.endRecord);
      console.log("pager" + this.pager);
    }
  }
  getstatus(start_date, duration) {
    let enddate = new Date(start_date);
    enddate = this.addMonthsUTC(enddate, parseInt(duration));
    let currentdate = new Date();
    if (enddate.getTime() < currentdate.getTime()) {
      return 1;
    } else {
      return 0;
    }
  }
  addMonthsUTC(date, count) {
    if (date && count) {
      var m,
        d = (date = new Date(+date)).getUTCDate();

      date.setUTCMonth(date.getUTCMonth() + count, 1);
      m = date.getUTCMonth();
      date.setUTCDate(d);
      if (date.getUTCMonth() !== m) date.setUTCDate(0);
    }
    return date;
  }

  editRecordofsequence(id, accordianId, cl) {
    $("#loaderouterid").css("display", "block");
    this.exerciselist = [];

    let element: HTMLElement = document.getElementById(
      "clicksequence" + id
    ) as HTMLElement;
    accordianId = "cardbodyid";

    this.popupData = cl;
    let element1: HTMLElement = document.getElementById(
      "ulsequence" + id
    ) as HTMLElement;
    let element1new: HTMLElement = document.getElementById(
      "cardbodysequence"
    ) as HTMLElement;

    let accordian: HTMLElement = document.getElementById(accordianId);

    if (element.innerHTML == "Schließen") {
      $("#cardbodyid").css("display", "none");
      element1new.after(accordian);
      element.innerHTML = "Öffnen";
      $("#loaderouterid").css("display", "none");
      this.getdivoutside();
    } else {
      if (this.editid != "") {
        let elementnew1: HTMLElement = document.getElementById(
          "clicksequence" + this.editid
        ) as HTMLElement;
        if (elementnew1) {
          elementnew1.innerHTML = "Öffnen";
        }
      }
      element1.after(accordian);
      $("#cardbodyid").css("display", "block");
      element.innerHTML = "Schließen";
      this.editid = id;
      this.getcurrentexercisesequence(this.editid);
    }
  }
  getdivoutside() {
    // let accordianId = "collapse";
    // let accordian: HTMLElement = document.getElementById(accordianId);
    // let element1new: HTMLElement = document.getElementById(
    //   "cardbodyid"
    // ) as HTMLElement;
    // element1new.after(accordian);
    // accordian.classList.add("collapse");
    // // accordian.classList.add("collapse");
    // accordian.classList.remove("collapse-show");
  }
  getpricetotal(discount, price, premiumamount, premium, deductamount) {
    deductamount = deductamount.replace(",", ".");

    if (premium == "yes") {
      let price1 = price.replace(",", ".");
      let netprice = ((price1 / 100) * (100 - discount) - deductamount).toFixed(
        2
      );

      console.log(deductamount);

      return (parseFloat(netprice) + parseFloat(premiumamount))
        .toFixed(2)
        .replace(".", ",");
    } else {
      console.log(deductamount);
      let price1 = price.replace(",", ".");
      let netprice = ((price1 / 100) * (100 - discount) - deductamount).toFixed(
        2
      );
      return parseFloat(netprice).toFixed(2).replace(".", ",");
    }
  }
  getcurrentexercisesequence(id) {
    var datanew = { upgradeplan_id: id };
    const data = this.userService
      .getpurchasemembershipplanbyupgradeplan_id(datanew)
      .subscribe(
        (success: any) => {
          $("#loaderouterid").css("display", "none");
          // if success and error give response
          if (success.status == "error") {
            Swal.fire("Therapiepläneliste nicht gefunden", "", "error");
          } else {
            this.exerciselist = success;
            $("#loaderouterid").css("display", "none");
          }
        },
        (rejected) => {
          console.log(rejected);
        }
      );
  }

  end_date(start_date, duration) {
    let startdate = new Date(start_date);
    return this.addMonthsUTC(startdate, parseInt(duration));
  }

  upgradeplan(id, plantype) {
    console.log("upgrade called");
    console.log(plantype);
    if (plantype == "Therapist") {
      console.log("inside if");
      this.router.navigate(["/upgrade-plan"], { queryParams: { id: id,b2bid:this.queryID } });
    } else {
      this.router.navigate(["/upgrade-plan-doctor"], {
        queryParams: { id: id,b2bid:this.queryID },
      });
    }
  }
  renewplan(id, plantype) {
    if (plantype == "Therapist") {
      this.router.navigate(["/renew-plan"], { queryParams: { id: id } });
    } else {
      this.router.navigate(["/renew-plan-doctor"], { queryParams: { id: id } });
    }
  }
  totalcommission() {
    let amount: number = 0;
    this.pagedItemsGDOC.forEach((pagedItemsGDOC1) => {
      console.log("pagedItemsGDOC1" + pagedItemsGDOC1.commission);
if(pagedItemsGDOC1.hasOwnProperty('commission'))
{
      amount =
        amount +
        parseFloat(
          this.getcommissionamount1(
            pagedItemsGDOC1.commission,
            pagedItemsGDOC1.discount,
            pagedItemsGDOC1.coupen_code,
            pagedItemsGDOC1.price
          )
        );
          }
      console.log("pagedItemsGDOC1" + amount);
    });
    return amount.toFixed(2).replace(".", ",");
  }
  getcommissionamount1(commission, discount, coupen_code, amount) {
    console.log(amount);
    console.log(discount);
    console.log(((amount / 100) * (100 - discount)) / 100);
    amount = amount.replace(",", ".");
    if (coupen_code != "") {
      return ((((amount / 100) * (100 - discount)) / 100) * commission).toFixed(
        2
      );
    } else {
      return ((amount / 100) * commission).toFixed(2);
    }
  }

  getcommissionamount(commission, discount, coupen_code, amount) {
    console.log(amount);
    console.log(discount);
    console.log(((amount / 100) * (100 - discount)) / 100);
    amount = amount.replace(",", ".");
    if (coupen_code != "") {
      return ((((amount / 100) * (100 - discount)) / 100) * commission)
        .toFixed(2)
        .replace(".", ",");
    } else {
      return ((amount / 100) * commission).toFixed(2).replace(".", ",");
    }
  }
  getdiscount(discount, coupen_code) {
    if (coupen_code != "") {
      return discount + " %";
    } else {
      return " ";
    }
  }

  setCompanyCondition(company) {
    console.log("adsasdsadasdasdas");
    if (company.includes("cefima_customer")) {
      console.log("compaaaanyyyyy");
      this.showmekFinanz = true;
    }

    if (company.includes("fiorettoimmob_customer")) {
      this.showFiorettoImmob = true;
    }

    if (company.includes("birema_customer")) {
      this.showBirema = true;
    }

    if (company.includes("fiorettomedia_customer")) {
      this.showFiorettoMedia = true;
    }

    if (company.includes("airmage_customer")) {
      this.showAirmage = true;
    }

    if (company.includes("horaidetektei_customer")) {
      this.showHoraiDetektei = true;
    }

    if (company.includes("varioimport_customer")) {
      this.showVarioImport = true;
    }

    if (company.includes("sterbvorsoge_customer")) {
      this.showSterbVorsoge = true;
    }

    if (company.includes("checkntrack_customer" || "checkntrack_user")) {
      this.showCheckntrack = true;
    }
  }

  //Kunde
  Cus() {
    if (
      this.userService.getDecodedAccessToken(localStorage.getItem("token"))
        .roles == "customer"
    ) {
      this.showCustomer = false;
    }
  }

  //User Logout
  logout() {
    localStorage.removeItem("token");
    this.router.navigate(["./"]);
  }

  //OpenPreview function
  OpenPreview(ID, INDEX, DATA) {
    console.log(ID);
    console.log(INDEX);
    console.log(DATA);
    this.ShowDiv = true;
    this.ImgUrl = DATA;
    this.href = DATA;
    setTimeout(() => {
      console.log("dsfsdfsdafafdasda");
      if (DATA.search("docx") !== -1) {
        $("#showdivdoc").attr("src", this.ImgUrl);
        $("#showdivdoc").css("display", "block");
        $("#showdivpdf").css("display", "none");
        $("#showdivimg").css("display", "none");
      } else if (DATA.search("pdf") !== -1) {
        $("#showdivpdf").attr("src", this.href);
        $("#showdivpdf").css("display", "block");
        $("#showdivimg").css("display", "none");
        $("#showdivdoc").css("display", "none");
      } else {
        $("#showdivimg").attr("src", this.href);
        $("#showdivimg").css("display", "block");
        $("#showdivpdf").css("display", "none");
        $("#showdivdoc").css("display", "none");
      }
    }, 100);
  }

  
  
}
