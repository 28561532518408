


import {
  Component,
  OnInit,
  AfterViewChecked,
  AfterViewInit,
  ElementRef,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
  CheckboxControlValueAccessor,
} from "@angular/forms";
import { UserService } from "../user.service";
import { first } from "rxjs/operators";
import { error } from "util";
import { Router } from "@angular/router";
// import { SearchCountryField, CountryISO } from "ngx-intl-tel-input";
import { ReturnStatement } from "@angular/compiler";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ActivatedRoute, Params } from "@angular/router";
// import { CompanySelectDialogComponent } from "../company-select-dialog/company-select-dialog.component";
import { MatDialog } from "@angular/material";
import * as $ from "jquery";
import intlTelInput from "intl-tel-input";
// import { ShowcompanyCodeDialogComponent } from "../showcompany-code-dialog/showcompany-code-dialog.component";

@Component({
  selector: 'app-add-patient',
  templateUrl: './add-patient.component.html',
  styleUrls: ['./add-patient.component.css']
})
export class AddPatientComponent implements OnInit {
  myControl = new FormControl();
  activemembership:any;
  optionsValue: string[] = ['Afghanisch',
    'Ägyptisch',
    'Albanisch',
    'Algerisch',
    'Andorranisch',
    'Angolanisch',
    'Antiguanisch',
    'Äquatorialguineisch',
    'Argentinisch',
    'Armenisch',
    'Aserbaidschanisch',
    'Äthiopisch',
    'Australisch',
    'Bahamaisch',
    'Bahrainisch',
    'Bangladeschisch',
    'Barbadisch',
    'Belgisch',
    'Belizisch',
    'Beninisch',
    'Bhutanisch',
    'Bolivianisch',
    'Bosnisch herzegowinisch',
    'Botsuanisch',
    'Brasilianisch',
    'Bruneiisch',
    'Bulgarisch',
    'Burkinisch',
    'Burundisch',
    'Chilenisch',
    'Chinesisch',
    'Costa-ricanisch',
    'Dänisch',
    'Kongolesisch',
    'Deutsch',
    'Dominikanisch',
    'Dschibutisch',
    'Ecuadorianisch',
    'Salvadorianisch',
    'Ivorisch',
    'Englisch',
    'Eritreisch',
    'Estnisch',
    'Fidschianisch',
    'Finnisch',
    'Französisch',
    'Gabunisch',
    'Gambisch',
    'Georgisch',
    'Ghanaisch',
    'Grenadisch',
    'Griechisch',
    'Grönländisch',
    'Britisch',
    'Guatemaltekisch',
    'Guineisch',
    'Guinea-bissauisch',
    'Guyanisch',
    'Haitianisch',
    'Honduranisch',
    'Indisch',
    'Indonesisch',
    'Irakisch',
    'Iranisch',
    'Irisch',
    'Isländisch',
    'Israelisch',
    'Italienisch',
    'Jamaikanisch',
    'Japanisch',
    'Jemenitisch',
    'Jordanisch',
    'Kambodschanisch',
    'Kamerunisch',
    'Kanadisch',
    'Kapverdisch',
    'Kasachisch',
    'Katarisch',
    'Kenianisch',
    'Kirgisisch',
    'Kiribatisch',
    'Kolumbianisch',
    'Komorisch',
    'Kongolesisch',
    'Kosovarisch',
    'Kroatisch',
    'Kubanisch',
    'Kuwaitisch',
    'Laotisch',
    'Lesothisch',
    'Lettisch',
    'Libanesisch',
    'Liberianisch',
    'Libysch',
    'Liechtensteinisch',
    'Litauisch',
    'Luxemburgisch',
    'Madagassisch',
    'Malawisch',
    'Malaysisch',
    'Maledivisch',
    'Malisch',
    'Maltesisch',
    'Marokkanisch',
    'Marshallisch',
    'Mauretanisch',
    'Mauritisch',
    'Mazedonisch',
    'Mexikanisch',
    'Mikronesisch',
    'Moldauisch',
    'Monegassisch',
    'Mongolisch',
    'Montenegrinisch',
    'Mosambikanisch',
    'Myanmarisch',
    'Namibisch',
    'Nauruisch',
    'Nepalesisch',
    'Neuseeländisch',
    'Nicaraguanisch',
    'Niederländisch',
    'Nigrisch',
    'Nigerianisch',
    'Nordkoreanisch',
    'Norwegisch',
    'Ömanisch',
    'Österreichisch',
    'Pakistanisch',
    'Palästinensisch',
    'Palauisch',
    'Panamaisch',
    'Papua-neuguineisch',
    'Paraguayisch',
    'Peruanisch',
    'Philippinisch',
    'Polnisch',
    'Portugiesisch',
    'Puerto-ricanisch',
    'Kongolesisch',
    'Ruandisch',
    'Rumänisch',
    'Russisch',
    'Salomonisch',
    'Sambisch',
    'Samoanisch',
    'San-marinesisch',
    'Saudi-arabisch',
    'Schottisch',
    'Schwedisch',
    'Schweizerisch',
    'Senegalesisch',
    'Serbisch',
    'Seychellisch',
    'Sierra-leonisch',
    'Simbabwisch',
    'Singapurisch',
    'Slowenisch',
    'Somalisch',
    'Spanisch',
    'Sri-lankisch',
    'Lucianisch',
    'Vincentisch',
    'Südafrikanisch',
    'Sudanesisch',
    'Südkoreanisch',
    'Surinamisch',
    'Swasiländisch',
    'Syrisch',
    'São-toméisch',
    'Tadschikisch',
    'Taiwanisch',
    'Tansanisch',
    'Thailändisch',
    'Tibetisch',
    'Timoresisch',
    'Togoisch',
    'Tongaisch',
    'Tschadisch',
    'Tschechisch',
    'Tunesisch',
    'Türkisch',
    'Turkmenisch',
    'Tuvaluisch',
    'Ugandisch',
    'Ukrainisch',
    'Ungarisch',
    'Uruguayisch',
    'Amerikanisch',
    'Üsbekisch',
    'Vanuatuisch',
    'Vatikanisch',
    'Venezolanisch',
    'Vietnamesisch',
    'Walisisch',
    'Weißrussisch',
    'Zentralafrikanisch'];

  filteredOptions: Observable<string[]>;

  mekFinanz_customer = false;

  phone_number: '';
  customerno: '';
  token: any;
  countryName: any;
  localityName = '';
  password = '';
  title = this.userService.getDecodedAccessToken(sessionStorage.getItem('Token')).title;
  lastname = this.userService.getDecodedAccessToken(sessionStorage.getItem('Token')).lastname;
  firstname = this.userService.getDecodedAccessToken(sessionStorage.getItem('Token')).firstname;
COMPANYNAME = this.userService.getDecodedAccessToken(sessionStorage.getItem('Token')).companyname;
  // loginRole = this.userService.getDecodedAccessToken(localStorage.getItem('token')).roles;
  //customerNo = this.genFiorettoGmbHCustomerNo();
  loginRole = sessionStorage.getItem('currentActiveRole')
  isLinear = false;
  hide = true;
  // roleFormGroup: FormGroup;
  companyFormGroup: FormGroup;
  personalInfoFormGroup: FormGroup;
  addressFormGroup: FormGroup;
  moreinfoFormGroup: FormGroup;
  options: any;
  registerDone = false;
  registraionError = false;
  realcustomer = false;
  //DatePicker min and max date
  minDate = new Date(1900, 0, 1);
  maxDate = new Date(2020, 0, 1);
  companyArr = [];
  customerFunction = this.nextCompanies();
  customerNo: string;
  autoGeneratedCustomerNumber = this.getLastUser();
  selected: string;
  public country: any;
  routeParams: any;
  companyTitle = false;
  roleCompanies = {
    employee: [],
    admin: [],
    customer: ["cefima_customer"],
    b2b: []
  }

  email_exists:any = false;

  constructor(
    private _formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog
  ) { }

  telInputObject(obj) {
    console.log(obj);
    obj.setCountry('de');
  }
  ngAfterViewInit()
  {
    
    const input = document.querySelector("#phone");
    console.log("querySelector"+input);
    intlTelInput(input, {
      initialCountry: "de",
      geoIpLookup: function(callback) {
        $.get("http://ipinfo.io", function() {}, "jsonp").always(function(resp) {
          var countryCode = (resp && resp.country) ? resp.country : "de";
          callback(countryCode);
          console.log("countryCode"+countryCode)
        });
      },
 
      });
      // $('.firstlast').parent().css('overflow', 'none');
      // $(".firstlast").find('.mat-form-field-infix').css('width',"100%");
      $('.firstlast').parent("div").find(".mat-form-field-infix").css('width',"100%");
      $('.stepnewclass').parent("div").find(".mat-stepper-horizontal-line").css('min-width',"0px");
      $('.stepnewclass').parent("div").find(".mat-stepper-horizontal-line").css('margin',"0px");
  }
  setnationality() {
    console.log(this.myControl.value)
    console.log("sadsad");
    this.personalInfoFormGroup.controls.nationality.setValue(this.myControl.value);
    // this.personalInfoFormGroup.nationality=this.myControl.value
  }


  ngOnInit() {

    var datanew = { id: "" };
    const data = this.userService.getpurchasemembershipplan(datanew).subscribe(
      (success: any) => {
        $("#loaderouterid").css("display", "none");
        // if success and error give response
        if (success.status == "error") {
          // Swal.fire("Übungsliste nicht gefunden", "", "error");
        } else {
this.activemembership=success;
console.log(success);

          // this.customerList = success;
          // this.setPage(1);
          // this.recordCount = success.length;
          // console.log(this.customerList);
        }
      },
      (rejected) => {
        console.log(rejected);
      }
    );
    this.loginRole = this.loginRole;
    this.routeParams = this.activatedRoute.snapshot.routeConfig.path;
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    this.autoPassword();
    this.nextCompanies();

    //Mat Stepper Control
    // this.roleFormGroup = this._formBuilder.group({
    //   roles: this._formBuilder.array([], Validators.required)
    // });

    this.companyFormGroup = this._formBuilder.group({
      companies: this._formBuilder.array([], Validators.required)
    });

    this.personalInfoFormGroup = this._formBuilder.group({
      title: ['', Validators.required],
      companyName: [' ', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      dob: ['', Validators.required],
      nationality: ['', Validators.required],
      birthPlace: ['', Validators.required],
      status: ['', Validators.required]
    });

    this.addressFormGroup = this._formBuilder.group({
      street: ['', Validators.required],
      streetNumber: ['', Validators.required],
      postCode: ['', Validators.required],
      city: ['', Validators.required],
      additionalReference: [''],
      countryOfResidence: ['', Validators.required]
    });

    this.moreinfoFormGroup = this._formBuilder.group({
      password: ['', Validators.required],
      phone_number: ['', Validators.required],
      customerno: ['']
    });
    this.personalInfoFormGroup.patchValue({
      status:"Kunde"
    })
    if (this.personalInfoFormGroup.get('status').value == 'Kunde (Interessent)') {
      console.log("fffff");
      this.realcustomer = false;
    }
    else{
      this.realcustomer = true;
    }
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.optionsValue.filter(option => option.toLowerCase().includes(filterValue));
  }
  get personalInfoForm() {
    return this.personalInfoFormGroup.controls;
  }

  get addressInfoForm() {
    return this.addressFormGroup.controls;
  }

  get moreinfoForm() {
    return this.moreinfoFormGroup.controls;
  }

  //Register New User
  register(emailsendornot) {
    //console.log('in register user function');
    //console.log("next", this.companyFormGroup.value);
    //console.log(this.roleFormGroup.value);
    //console.log(this.companyFormGroup.value);
    //console.log(this.personalInfoFormGroup.value);
    //console.log(this.addressFormGroup.value);
    //console.log(this.moreinfoFormGroup.value);
    // if (
    //   this.roleFormGroup.invalid ||
    //   this.companyFormGroup.invalid ||
    //   this.personalInfoFormGroup.invalid ||
    //   this.addressFormGroup.invalid
    // ) {
    //   //console.log('some error in validation for form groups!');
    //   return;
    // }

//let statusbrandarraynew=['Coursula']
let statusbrandarraynew=['Ontrain']
let statusarraynew=[this.personalInfoForm.status.value]
//let brokerbrandarraynew = ['Coursula'];
let brokerbrandarraynew = ['Ontrain'];
let brokerarraynew = [this.userService.getDecodedAccessToken(sessionStorage.getItem('Token')).customerno];
    let roles = ['customer'];
    // let companies = ['coursula'];
    // let rolesCompaniesArray = ['coursula_customer']
    let companies = ['ontrain'];
    let rolesCompaniesArray = ['ontrain_customer']

    let data = {
      // Roles & Companies
      roles: roles,
      companies: companies,
      companies_with_roles: rolesCompaniesArray,
      //companies: this.companyFormGroup.value.companies,

      // Personal Information
      title: this.personalInfoForm.title.value,
      companyname: this.personalInfoForm.companyName.value,
      firstname: this.personalInfoForm.firstName.value,
      lastname: this.personalInfoForm.lastName.value,
      email: this.personalInfoForm.email.value,
      dateofbirth: this.personalInfoForm.dob.value,
      nationality: this.personalInfoForm.nationality.value,
      birth_place: this.personalInfoForm.birthPlace.value,
      customer_status: this.personalInfoForm.status.value,
      // Address Information
      strassa: this.addressInfoForm.street.value,
      strno: this.addressInfoForm.streetNumber.value,
      plz: this.addressInfoForm.postCode.value,
      city: this.addressInfoForm.city.value,
      additionalReference: this.addressInfoForm.additionalReference.value,
      current_country: this.addressInfoForm.countryOfResidence.value,

      //More Information
      password: this.moreinfoForm.password.value,
      contactno: this.moreinfoForm.phone_number.value,
      customerno: this.moreinfoForm.customerno.value,
      emailsendornot: emailsendornot,
      status: '1',
      //companycode: ['c42150_customer'],
      companycode: ['c42130_customer'],
           
      statusbrandarray: statusbrandarraynew,
      statusarray:statusarraynew,
      brokerbrandarray: brokerbrandarraynew,
      brokerarray:brokerarraynew,

      broker: this.userService.getDecodedAccessToken(sessionStorage.getItem('Token')).customerno,
     plantype:this.activemembership[0].plantype


    }
    // console.log(data, "user data");
    this.userService.registerUsercoursula_user(data)
      .pipe(first())
      .subscribe(
        data => {
          console.log("POST Request is successful ", data);
          this.registerDone = true;
        },
        error => {
          this.registraionError = true;
          //console.log("Error", error['error']);
        });
  }

  //File Upload
  onFileSelected(event) {
    //console.log('in on File Select Function');
    //console.log(event);
  }



  checkemailnew1()
  {
    
    $('#emailiderrorfirst').html("")
    $('#emailiderrorfirst').css("background-color","transparent");
    $('#emailiderrorfirst').css("padding","0px");
    let datanew={email:this.personalInfoFormGroup.controls.email.value}
    this.userService
    .checkemail(datanew)
    .pipe(first())
    .subscribe((data11) => {
if(data11['status']=='200')
{
  $('#email_error').css('display','none');
  $('#emailiderrorfirst').html("")
  $('#emailiderrorfirst').css("background-color","transparent");
  $('#emailiderrorfirst').css("padding","0px");

  this.email_exists = false;
  
}
else{
  $('#email_error').css('display','flex');
  if(data11['msg'].indexOf('Email Id is already registered') != -1){
    $('#emailiderrorfirst').html("Bitte geben Sie eine andere E-Mail ID ein.");
  }
  // $('#emailiderrorfirst').html(data11['msg'])
  // $('#emailiderrorfirst').css("background-color","white");
  //$('#emailiderrorfirst').css("padding","10px");
  this.email_exists = true;
}
    });
  }


  //Generate Customer Number
  /*genFiorettoGmbHCustomerNo(){
    //console.log('pp');
     this.companyArr = this.companyArr; 
    //console.log('in Generate Customer Number Function',this.companyArr);
    const fiorettoGroupGmbH = '-20-';
    const fiorettoMediaGmbH = '-30-';
    const dfgfinanzProfiGmbH = '-40-';
    const biremaGmbH = '-50';

    var nums = [10001];
    var j;
    for (j in nums){
      //console.log(nums);
    }
    var cusNo = nums.toString().concat(fiorettoGroupGmbH);
    //console.log(cusNo);
    return cusNo;
  }*/

  nextCompanies() {
    console.log('in Generate Customer Number Function', this.companyArr, this.roleCompanies);
    let company_array = [];
    for(let i in this.roleCompanies){
      company_array = company_array.concat(this.roleCompanies[i])
    }
    let temp_set = new Set(company_array);
    company_array = Array.from(temp_set);
    this.userService.getLastUser().pipe(first()).subscribe(data => {
      this.autoGeneratedCustomerNumber = data.toString();
      var nums = [this.autoGeneratedCustomerNumber];
      var tmp = company_array//this.companyArr;
      // if (tmp) {
      //   tmp.forEach(function (i, k) {
      //     var cusNo = i.substring(0, 2);
      //     nums.push(cusNo);
      //   });
      //   var cusNo = nums.join('-');
      //   this.customerNo = cusNo;
      //   this.moreinfoFormGroup.patchValue({ customerno: this.customerNo });
      //   return this.customerNo;
      // }


      // this.customerNo=this.autoGeneratedCustomerNumber+"-60-co";

      this.customerNo=this.autoGeneratedCustomerNumber+"-30-ot";
      this.moreinfoFormGroup.patchValue({ customerno: this.customerNo });
      return this.customerNo;
    });
  }

  getLastUser() {
    this.userService.getLastUser().pipe(first()).subscribe(data => {
      this.autoGeneratedCustomerNumber = data.toString();
      //return this.autoGeneratedCustomerNumber;
    });
  }

  //Get AutoGenerated Password
  autoPassword() {
    //console.log('in autopassword function');
    this.userService.getPassword()
      .pipe(first())
      .subscribe(data => {
        this.password = data.toString();
        this.moreinfoFormGroup.patchValue({ password: this.password });
        //console.log('password ====', data);
        return data;
      },
        error => {
          //console.error(error.message);
          //console.log("Error", error['error']);
        });
  }

  handleAddressChange(data) {
    const splitArr = data.address_components;
    this.getCountry(splitArr);
  }
  handleAddressChangeland(data) {
    const splitArr = data.address_components;
    this.getCountryland(splitArr);
  }








  getCountryland(data): any {
    let that = this;
    const splitArr = data;
    // console.log(splitArr);
    splitArr.forEach(function (i, k) {
      let content: any = i.types;
      //// console.log(content);
      if (content.length > 1) {
        const countryArr = content;
        for (let index = 0; index < content.length; index++) {
          if (countryArr[index] === 'country') {
            that.countryName = i.long_name;
          }
        
          let localityCountry = that.countryName;
          that.addressFormGroup.patchValue({ countryOfResidence: localityCountry });
        }
      }
    });
  }

  handleAllFields(data) {
    //console.log("original data = ", data);
    const splitArr = data.address_components;
    this.getAllFields(splitArr);
  }

  getCountry(data): any {
    let that = this;
    const splitArr = data;
    //console.log(splitArr);
    splitArr.forEach(function (i, k) {
      let content: any = i.types;
      ////console.log(content);
      if (content.length > 1) {
        const countryArr = content;
        for (let index = 0; index < content.length; index++) {
          if (countryArr[index] === 'country') {
            that.countryName = i.long_name;
            //that.countryName = i.short_name;
            ////console.log("country",i.short_name);
            //that.personalInfoFormGroup.patchValue({birthPlace: i.short_name});
          }
          if (countryArr[index] === 'locality') {
            that.localityName = i.short_name;
            ////console.log("locality",i.short_name);
          }
          let localityCountry = that.localityName + "," + that.countryName;
          that.personalInfoFormGroup.patchValue({ birthPlace: localityCountry });
          //that.personalInfoFormGroup.patchValue({nationality: that.countryName});
          /*if(countryArr[index] === 'country'){
            that.personalInfoFormGroup.patchValue({nationality: i.short_name});
          }*/
        }
      }
    });
  }

  getAllFields(data): any {
    let that = this;
    const splitArr = data;
    let str = "";
    //console.log("data", splitArr);
    splitArr.forEach(function (i, k) {
      let content: any = i.types;
      const found = content.find(element => element = 'street_number');
      if (found === 'street_number') {
        that.addressFormGroup.patchValue({ streetNumber: i.short_name });
      } else if (found === 'postal_code') {
        that.addressFormGroup.patchValue({ postCode: i.short_name });
      } else if (found === 'route') {
        str = i.short_name;
      }
      if (content.length > 1) {
        const countryArr = content;
        ////console.log(countryArr);
        for (let index = 0; index < content.length; index++) {
          if (countryArr[index] === 'country') {
            //console.log("country", i);
            that.addressFormGroup.patchValue({ countryOfResidence: i.long_name });
          }
          if (countryArr[index] === 'locality') {
            that.addressFormGroup.patchValue({ city: i.short_name });
          }
        }
      }
    });
    if (str.length > 0) {
      that.addressFormGroup.patchValue({ street: str });
    } else {
      that.addressFormGroup.patchValue({ street: "" });
    }
  }
  //Logout Function
  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['./']);
  }

  titleChange($event) {
    this.companyTitle = false;
    if (this.personalInfoFormGroup.get('title').value == 'Firma') {
      this.companyTitle = true;
      this.personalInfoFormGroup.patchValue({ companyName: '' });
    } else {
      this.personalInfoFormGroup.patchValue({ companyName: ' ' });
    }
  }


  changestatus($event) {
    this.realcustomer = false;
  



    if (this.personalInfoFormGroup.get('status').value == 'Kunde (Interessent)') {
      console.log("fffff");
      this.realcustomer = false;
      this.personalInfoFormGroup.get('email').clearValidators();
      this.personalInfoFormGroup.get('email').updateValueAndValidity();
      this.personalInfoFormGroup.get('dob').clearValidators();
      this.personalInfoFormGroup.get('dob').updateValueAndValidity();
      this.personalInfoFormGroup.get('nationality').clearValidators();
      this.personalInfoFormGroup.get('nationality').updateValueAndValidity();
      this.personalInfoFormGroup.get('birthPlace').clearValidators();
      this.personalInfoFormGroup.get('birthPlace').updateValueAndValidity();
      this.addressFormGroup.get('street').clearValidators();
      this.addressFormGroup.get('street').updateValueAndValidity();
      this.addressFormGroup.get('streetNumber').clearValidators();
      this.addressFormGroup.get('streetNumber').updateValueAndValidity();
      this.addressFormGroup.get('postCode').clearValidators();
      this.addressFormGroup.get('postCode').updateValueAndValidity();
      this.addressFormGroup.get('city').clearValidators();
      this.addressFormGroup.get('city').updateValueAndValidity();
  
      this.addressFormGroup.get('countryOfResidence').clearValidators();
      this.addressFormGroup.get('countryOfResidence').updateValueAndValidity();
      this.moreinfoFormGroup.get('password').clearValidators();
      this.moreinfoFormGroup.get('password').updateValueAndValidity();
      this.moreinfoFormGroup.get('phone_number').clearValidators();
      this.moreinfoFormGroup.get('phone_number').updateValueAndValidity();
      this.moreinfoFormGroup.get('customerno').clearValidators();
      this.moreinfoFormGroup.get('customerno').updateValueAndValidity();
    } else {
      this.personalInfoFormGroup.get('email').setValidators([Validators.required]);
      this.personalInfoFormGroup.get('email').updateValueAndValidity();
      this.personalInfoFormGroup.get('dob').setValidators([Validators.required]);
      this.personalInfoFormGroup.get('dob').updateValueAndValidity();

      this.personalInfoFormGroup.get('nationality').setValidators([Validators.required]);
      this.personalInfoFormGroup.get('nationality').updateValueAndValidity();

      this.personalInfoFormGroup.get('birthPlace').setValidators([Validators.required]);
      this.personalInfoFormGroup.get('birthPlace').updateValueAndValidity();

      this.addressFormGroup.get('street').setValidators([Validators.required]);
      this.addressFormGroup.get('street').updateValueAndValidity();
      this.addressFormGroup.get('streetNumber').setValidators([Validators.required]);
      this.addressFormGroup.get('streetNumber').updateValueAndValidity();
      this.addressFormGroup.get('postCode').setValidators([Validators.required]);
      this.addressFormGroup.get('postCode').updateValueAndValidity();
      this.addressFormGroup.get('city').setValidators([Validators.required]);
      this.addressFormGroup.get('city').updateValueAndValidity();
    
      this.addressFormGroup.get('countryOfResidence').setValidators([Validators.required]);
      this.addressFormGroup.get('countryOfResidence').updateValueAndValidity();
      this.moreinfoFormGroup.get('password').setValidators([Validators.required]);
      this.moreinfoFormGroup.get('password').updateValueAndValidity();
      this.moreinfoFormGroup.get('phone_number').setValidators([Validators.required]);
      this.moreinfoFormGroup.get('phone_number').updateValueAndValidity();
      this.moreinfoFormGroup.get('customerno').setValidators([Validators.required]);
      this.moreinfoFormGroup.get('customerno').updateValueAndValidity();
      this.realcustomer = true;
  
    }
  }


}

