import { Component, OnInit, AfterViewInit } from "@angular/core";
import {
  CdkDragDrop,
  moveItemInArray,
  copyArrayItem,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
  CheckboxControlValueAccessor,
} from "@angular/forms";
import { UserService } from "../user.service";

import { first } from "rxjs/operators";
import { error } from "util";
import { Router } from "@angular/router";
import { PagerService } from "../pager.service";
import { MatChipInputEvent } from "@angular/material/chips";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ActivatedRoute, Params } from "@angular/router";
import * as $ from "jquery";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { DomSanitizer } from "@angular/platform-browser";
import Swal from "sweetalert2";
import { HttpEvent, HttpEventType } from "@angular/common/http";
type unit = "bytes" | "KB" | "MB" | "GB" | "TB" | "PB";
type unitPrecisionMap = {
  [u in unit]: number;
};

const defaultPrecisionMap: unitPrecisionMap = {
  bytes: 0,
  KB: 0,
  MB: 1,
  GB: 1,
  TB: 2,
  PB: 2,
};

// import * as AWS from 'aws-sdk';
export interface Fruit {
  name: string;
}
export interface Fruitnew {
  name: string;
}
@Component({
  selector: 'app-therapy-plan-list',
  templateUrl: './therapy-plan-list.component.html',
  styleUrls: ['./therapy-plan-list.component.css']
})
export class TherapyPlanListComponent implements OnInit {
  routeParams: string;
  personalInfoFormGroup: FormGroup;
  exerciseformgroup: FormGroup;

  typesOptionsArray: any[] = [];
  typesOptionsArrayedit: any[] = [];
  oldtypesOptionsArray: any[] = [];
  customerList: any;
  recordCount: any;
  pagedItems: any;
  startRecord: number;
  progress = [];
  endRecord: any;
  l = 0;
  popupData: any;
  editid: any = "";
  editidnew: any = "";
  currentUserData: any;
  filearray: any[] = [];
  currentopendata: any;
  ticket_no: any;
  exercisedata: any = [];
  filearraynew: any[] = [];

  exercisesequencelistsearch: any = [];
  searchby = "";
  lengthfile: any = 0;
  uploadlength: any = 0;
  documentid: any = [];
  exerciselist: any = [];
  exercisesequencelist: any[][] = [];
  phase: any = [];
  exerciseoption: Observable<any[]>;
  searchlist = [];
  exercise_controls = new FormControl();
  values: any;
  constructor(
    private _formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
  
    private pagerService: PagerService,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {}
  loginRole = sessionStorage.getItem("currentActiveRole");
  title = this.userService.getDecodedAccessToken(sessionStorage.getItem("Token"))
    .title;
  loginid = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).id;
  lastname = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).lastname;
  firstname = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).firstname;
  COMPANYNAME = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).companyname;
  private readonly units: unit[] = ["bytes", "KB", "MB", "GB", "TB", "PB"];
  pager: any = {};
  selecteditem: string[] = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  itemList: string[] = [
    "Matte",
    "Bett",
    "Spiegel",
    "Strohhalm",
    "Eiswürfel",
    "Hocker",
    "Widerstandsband",
    "Pezziball",
    "Stab",
    "Kettlebell",
    "Faszienrolle",
    "Seil",
    "Hantel (klein)",
    "Handtuch",
    "Tape",
    "Tennisball",
    "Murmeln",
    "Zopfgummi",
    "Tisch",
    "Knete",
  ];
  selecteditemList = this.itemList;

  onKey1(value) {
    this.selecteditemList = this.search(value);
  }
  search(value: string) {
    let filter = value.toLowerCase();
    return this.itemList.filter((option) =>
      option.toLowerCase().startsWith(filter)
    );
  }

  getdatanew(value) {
    this.selecteditem.push(value);
    console.log("asdsaddsadasdA" + value);
    $("#inputtools").val("");
    this.onKey("");
  }

  ngOnInit() {
    this.loginRole = this.loginRole;
    this.routeParams = this.activatedRoute.snapshot.routeConfig.path;

    this.personalInfoFormGroup = this._formBuilder.group({
      exercise_name: ["", Validators.required],
      exercise_name1: ["", Validators.required],
      comment1: [""],
      comment: ["", Validators.required],
      manual_tags: ["", Validators.required],
      tools: ["", Validators.required],
      difficully_level: ["", Validators.required],

      upload_file: ["", Validators.required],
      explain: ["", Validators.required],
      implementation: ["", Validators.required],
      exercise_category: ["", Validators.required],
    });

    this.exerciseformgroup = this._formBuilder.group({
      edit_id: [""],
      secondstep: this._formBuilder.array([]),
      therapyplan_name: ["", Validators.required],
      duration: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
    });

    $("#loaderouterid").css("display", "block");
    // this.getallexerciesequence();
    this.getallexerciesequence();
    this.exerciseoption = this.exercise_controls.valueChanges.pipe(
      startWith(""),
      map((value) => (typeof value == "string" ? value : value.name)),
      map((name) => this._filterexercisedata(name))
    );
    this.typesOptionsArray.push();
    this.typesOptionsArrayedit.push();
    this.exercisesequencelist.push([]);
  }
  exerciselength11(index) {
    let acc = 0;
    for (let i = 0; i < index; i++) {
      acc += this.exercisesequencelistsearch[i].length;
    }
    return acc;
  }
  drop(event: CdkDragDrop<any[]>) {
    let lengthtotal = this.exerciselength(this.exercisesequencelist.length) - 1;
    let indexnewcurrent: any = lengthtotal - event.currentIndex;
    let indexnewprevious: any = lengthtotal - event.previousIndex;

if (event.previousContainer === event.container) {
  let key1;
  let count = 0;
  $.each(this.exercisesequencelist, function (key, element) {
    let coutexercise = element.length;
    count = count + coutexercise;
    if (indexnewcurrent < count) {
      key1 = key;
      return false;
    }
  });

  let key1previous;
  let countprevious = 0;
  $.each(
    this.exercisesequencelist,
    function (keyprevious, elementprevious) {
      let coutexerciseprevious = elementprevious.length;
      countprevious = countprevious + coutexerciseprevious;
      if (indexnewprevious < countprevious) {
        key1previous = keyprevious;
        return false;
      }
    }
  );

  if (this.exercisesequencelist[key1previous].length > 1) {
    console.log("key1previous" + key1previous);
    console.log("key1previous" + key1);
    if (key1previous == key1) {
      let totalpreviouscount = 0;
      if (key1 >= 0) {
        for (let k = 0; k < key1; k++) {
          totalpreviouscount =
            totalpreviouscount + this.exercisesequencelist[k].length;
        }
      }

  
      let movedata = {
        id: event.previousContainer.data[key1][
          indexnewprevious - totalpreviouscount
        ].id,
        value:
          event.previousContainer.data[key1][
            indexnewprevious - totalpreviouscount
          ].value,
        img: event.previousContainer.data[key1][
          indexnewprevious - totalpreviouscount
        ].img,
        diff: event.previousContainer.data[key1][
          indexnewprevious - totalpreviouscount
        ].diff,
        tags: event.previousContainer.data[key1][
          indexnewprevious - totalpreviouscount
        ].tags,
      };

      if (
        indexnewcurrent - totalpreviouscount >
        indexnewprevious - totalpreviouscount
      ) {
        for (
          let i = (indexnewprevious - totalpreviouscount) + 1;
          i <= indexnewcurrent - totalpreviouscount;
          i++
        ) {
          let movedata1 = {
            id: event.previousContainer.data[key1][i].id,
            value: event.previousContainer.data[key1][i].value,
            img: event.previousContainer.data[key1][i].img,
            diff: event.previousContainer.data[key1][i].diff,
            tags: event.previousContainer.data[key1][i].tags,
          };

          this.exercisesequencelist[key1][i - 1] = movedata1;
        }

        this.exercisesequencelist[key1][
          (indexnewcurrent - totalpreviouscount)
        ] = movedata;
      } else {
        for (
          let i = (indexnewprevious - totalpreviouscount) - 1;
          i >= indexnewcurrent - totalpreviouscount;
          i--
        ) {
          let movedata1 = {
            id: event.previousContainer.data[key1][i].id,
            value: event.previousContainer.data[key1][i].value,
            img: event.previousContainer.data[key1][i].img,
            diff: event.previousContainer.data[key1][i].diff,
            tags: event.previousContainer.data[key1][i].tags,
          };

          this.exercisesequencelist[key1][i + 1] = movedata1;
        }

        this.exercisesequencelist[key1][
          (indexnewcurrent - totalpreviouscount)
        ] = movedata;
      }
    } else {
      let totalpreviouscountnewprevious = 0;
      if (key1previous > 0) {
        for (let k = 0; k < key1previous; k++) {
          totalpreviouscountnewprevious =
            totalpreviouscountnewprevious +
            this.exercisesequencelist[k].length;
        }
      }
      console.log(
        "totalpreviouscountnewprevious" +
        totalpreviouscountnewprevious +
          "previousIndex" +
          event.previousIndex +
          "indexnewprevious" +
          indexnewprevious +
          "currentIndex" +
          event.currentIndex +
          "indexnewcurrent" +
          indexnewcurrent
      );

      let movedata = {
        id: event.previousContainer.data[key1previous][
          indexnewprevious - totalpreviouscountnewprevious
        ].id,
        value:
          event.previousContainer.data[key1previous][
            indexnewprevious - totalpreviouscountnewprevious
          ].value,
        img: event.previousContainer.data[key1previous][
          indexnewprevious - totalpreviouscountnewprevious
        ].img,
        diff: event.previousContainer.data[key1previous][
          indexnewprevious - totalpreviouscountnewprevious
        ].diff,
        tags: event.previousContainer.data[key1previous][
          (indexnewprevious - totalpreviouscountnewprevious)
        ].tags,
      };

      if (
        (indexnewprevious - totalpreviouscountnewprevious) + 1 ==
        this.exercisesequencelist[key1previous].length
      ) {
        this.exercisesequencelist[key1previous].splice(
          indexnewprevious - totalpreviouscountnewprevious,
          1
        );
      } else {
        for (
          let i = indexnewprevious - totalpreviouscountnewprevious + 1;
          i < this.exercisesequencelist[key1previous].length;
          i++
        ) {
          let movedata1 = {
            id: event.previousContainer.data[key1previous][i].id,
            value: event.previousContainer.data[key1previous][i].value,
            img: event.previousContainer.data[key1previous][i].img,
            diff: event.previousContainer.data[key1previous][i].diff,
            tags: event.previousContainer.data[key1previous][i].tags,
          };

          this.exercisesequencelist[key1previous][i - 1] = movedata1;
        }
        this.exercisesequencelist[key1previous].splice(
          this.exercisesequencelist[key1previous].length - 1,
          1
        );
      }

      let totalpreviouscount = 0;
      if (key1 > 0) {
        for (let k = 0; k < key1; k++) {
          totalpreviouscount =
            totalpreviouscount + this.exercisesequencelist[k].length;
        }
      }
      console.log(
        "totalpreviouscount" +
        totalpreviouscount 
          
      );
      let lastarraystartindex;
      if (indexnewcurrent > (totalpreviouscount)) {
        lastarraystartindex = indexnewcurrent - (totalpreviouscount);
      } else {
        lastarraystartindex = totalpreviouscount - indexnewcurrent;
      }
   
      let currentarraylength = this.exercisesequencelist[key1].length;

      if (lastarraystartindex <= currentarraylength - 1) {
       
        for (
          let m = currentarraylength - 1;
          m >= lastarraystartindex;
          m--
        ) {
          let movedata1 = {
            id: event.previousContainer.data[key1][m].id,
            value: event.previousContainer.data[key1][m].value,
            img: event.previousContainer.data[key1][m].img,
            diff: event.previousContainer.data[key1][m].diff,
            tags: event.previousContainer.data[key1][m].tags,
          };
          if (m == currentarraylength) {
            this.exercisesequencelist[key1].push(movedata1);
          } else {
            this.exercisesequencelist[key1][m + 1] = movedata1;
          }
        }
      }
      this.exercisesequencelist[key1][lastarraystartindex] = movedata;
    }
  } else {
    Swal.fire(
      "In dieser Phase ist nur noch 1 Übung übrig. Also nicht bewegen",
      "",
      "error"
    );
  }

}
    else {

     
      var datanew = {
        id: event.previousContainer.data[event.previousIndex].id,
      };
      let key1;
      let count = 0;
      $.each(this.exercisesequencelist, function (key, element) {
        let coutexercise = element.length;
        count = count + coutexercise;
        if (indexnewcurrent < count) {
          key1 = key;
          return false;
        }
      });

  
      let add = 0;
      let indexnew = 0;
      if(this.exerciseformgroup.controls.secondstep.value.length>0)
      {
        if(this.exerciseformgroup.controls.secondstep.value[this.exerciseformgroup.controls.secondstep.value.length-1].duration!='')  
        {
          if(isNaN(this.exerciseformgroup.controls.secondstep.value[this.exerciseformgroup.controls.secondstep.value.length-1].duration)){
            Swal.fire("Bitte nur numerischen Wert in Dauer eingeben in","","error")
         }else{
          add=1;
          indexnew=this.exerciseformgroup.controls.secondstep.value.length;
          this.phase[this.exerciseformgroup.controls.secondstep.value.length]=this.exerciseformgroup.controls.secondstep.value[this.exerciseformgroup.controls.secondstep.value.length-1].duration;
         }  
        }
        else{
          add=0;
        } 
      }
      else
      {
      if(this.exerciseformgroup.controls.duration.value!='')  
      {
        if(isNaN(this.exerciseformgroup.controls.duration.value)){
          Swal.fire("Bitte nur numerischen Wert in Dauer eingeben in","","error")
       }else{
        add=1;
        indexnew=0;
        this.phase[0]=this.exerciseformgroup.controls.duration.value;
       }
       
      }
      else{
        add=0;
      } 
      }
      
      
      let totalpreviouscountnewprevious11111111 = 0;
      if (key1 > 0) {
        for (let k = 0; k < key1; k++) {
          totalpreviouscountnewprevious11111111 =
            totalpreviouscountnewprevious11111111 +
            this.exercisesequencelist[k].length;
        }
      }
      if (add == 1) {
        if (this.searchby == "yes") {
          if (this.exercisesequencelist[indexnew].length > 0) {
            let currentindex = indexnewcurrent + 1 - totalpreviouscountnewprevious11111111;
          
            console.log(
              "currentindex" +
                currentindex +
                "currentindex" +
                event.currentIndex +
                "indexnewcurrent" +
                indexnewcurrent +
                "totalpreviouscountnewprevious11111111" +
                totalpreviouscountnewprevious11111111
            );

            if (key1 == undefined) {
              this.exercisesequencelist[indexnew].push({
                id: event.previousContainer.data[event.previousIndex].id,
                value:
                  event.previousContainer.data[event.previousIndex].value,
                img: event.previousContainer.data[event.previousIndex].img,
                diff: event.previousContainer.data[event.previousIndex].diff,
                tags: event.previousContainer.data[event.previousIndex].tags,
              });
            } else {
              if (this.exercisesequencelist[key1].length > currentindex) {
                let currentmovedata = {
                  id: this.exercisesequencelist[key1][currentindex].id,
                  value: this.exercisesequencelist[key1][currentindex].value,
                  img: this.exercisesequencelist[key1][currentindex].img,
                  diff: this.exercisesequencelist[key1][currentindex].diff,
                  tags: this.exercisesequencelist[key1][currentindex].tags,
                };
                this.exercisesequencelist[key1][currentindex] = {
                  id: event.previousContainer.data[event.previousIndex].id,
                  value:
                    event.previousContainer.data[event.previousIndex].value,
                  img: event.previousContainer.data[event.previousIndex].img,
                  diff: event.previousContainer.data[event.previousIndex]
                    .diff,
                  tags: event.previousContainer.data[event.previousIndex]
                    .tags,
                };

                // this.exercisesequencelist[key1].push([]);

                for (
                  var i = this.exercisesequencelist[key1].length - 1;
                  i > currentindex;
                  i--
                ) {
                  this.exercisesequencelist[key1][i + 1] = {
                    id: this.exercisesequencelist[key1][i].id,
                    value: this.exercisesequencelist[key1][i].value,
                    img: this.exercisesequencelist[key1][i].img,
                    diff: this.exercisesequencelist[key1][i].diff,
                    tags: this.exercisesequencelist[key1][i].tags,
                  };
                }

                this.exercisesequencelist[key1][currentindex + 1] =
                  currentmovedata;
              } else {
                this.exercisesequencelist[key1].push({
                  id: event.previousContainer.data[event.previousIndex].id,
                  value:
                    event.previousContainer.data[event.previousIndex].value,
                  img: event.previousContainer.data[event.previousIndex].img,
                  diff: event.previousContainer.data[event.previousIndex]
                    .diff,
                  tags: event.previousContainer.data[event.previousIndex]
                    .tags,
                });
              }
            }
          } else {
            this.exercisesequencelist[indexnew].push({
              id: event.previousContainer.data[event.previousIndex].id,
              value: event.previousContainer.data[event.previousIndex].value,
              img: event.previousContainer.data[event.previousIndex].img,
              diff: event.previousContainer.data[event.previousIndex].diff,
              tags: event.previousContainer.data[event.previousIndex].tags,
            });
          }
        } else {
          $("#loaderouterid").css("display", "block");
          $("#loadertext").html("Please wait for extract therapy Plan..");
          $("#loadertext").css("width", "320px");
          $("#loadertext").css("margin-left", "-160px");
          let exerciselistdata = [];
          const data = this.userService
            .getexercisesequence(datanew)
            .subscribe(
              (success: any) => {
                if (success.status == "error") {
                } else {
                  exerciselistdata = success;
                  for (let i = 0; i < exerciselistdata.length; i++) {
                    if (this.exercisesequencelist[indexnew].length > 0) {
                      let currentindex =
                        indexnewcurrent +
                        1 -
                        totalpreviouscountnewprevious11111111;
                      if (key1 == undefined) {
                        this.exercisesequencelist[indexnew].push({
                          id: event.previousContainer.data[
                            event.previousIndex
                          ].id,
                          value:
                            event.previousContainer.data[event.previousIndex]
                              .value,
                          img: event.previousContainer.data[
                            event.previousIndex
                          ].img,
                          diff: event.previousContainer.data[
                            event.previousIndex
                          ].diff,
                          tags: event.previousContainer.data[
                            event.previousIndex
                          ].tags,
                        });
                      } else {
                        if (
                          this.exercisesequencelist[key1].length >
                          currentindex
                        ) {
                          let currentmovedata = {
                            id: this.exercisesequencelist[key1][currentindex]
                              .id,
                            value:
                              this.exercisesequencelist[key1][currentindex]
                                .value,
                            img: this.exercisesequencelist[key1][currentindex]
                              .img,
                            diff: this.exercisesequencelist[key1][
                              currentindex
                            ].diff,
                            tags: this.exercisesequencelist[key1][
                              currentindex
                            ].tags,
                          };
                          this.exercisesequencelist[key1][currentindex] = {
                            id: event.previousContainer.data[
                              event.previousIndex
                            ].id,
                            value:
                              event.previousContainer.data[
                                event.previousIndex
                              ].value,
                            img: event.previousContainer.data[
                              event.previousIndex
                            ].img,
                            diff: event.previousContainer.data[
                              event.previousIndex
                            ].diff,
                            tags: event.previousContainer.data[
                              event.previousIndex
                            ].tags,
                          };

                          // this.exercisesequencelist[key1].push([]);

                          for (
                            var j =
                              this.exercisesequencelist[key1].length - 1;
                            j > currentindex;
                            j--
                          ) {
                            this.exercisesequencelist[key1][j + 1] = {
                              id: this.exercisesequencelist[key1][j].id,
                              value: this.exercisesequencelist[key1][j].value,
                              img: this.exercisesequencelist[key1][j].img,
                              diff: this.exercisesequencelist[key1][j].diff,
                              tags: this.exercisesequencelist[key1][j].tags,
                            };
                          }

                          this.exercisesequencelist[key1][currentindex + 1] =
                            currentmovedata;
                        } else {
                          this.exercisesequencelist[key1].push({
                            id: event.previousContainer.data[
                              event.previousIndex
                            ].id,
                            value:
                              event.previousContainer.data[
                                event.previousIndex
                              ].value,
                            img: event.previousContainer.data[
                              event.previousIndex
                            ].img,
                            diff: event.previousContainer.data[
                              event.previousIndex
                            ].diff,
                            tags: event.previousContainer.data[
                              event.previousIndex
                            ].tags,
                          });
                        }
                      }
                    } else {
                      this.exercisesequencelist[indexnew].push({
                        id: exerciselistdata[i][event.previousIndex]._id,
                        value:
                          exerciselistdata[i][event.previousIndex]
                            .exercise_name1,
                        img: exerciselistdata[i][1].image,
                        diff: exerciselistdata[i][event.previousIndex]
                          .difficully_level,
                        tags: exerciselistdata[i][event.previousIndex].tags,
                      });
                    }
                  }
                  $("#loadertext").html("");
                  $("#loadertext").css("width", "100px");
                  $("#loaderouterid").css("display", "none");
                  $("#loadertext").css("margin-left", "-50px");
                }
              },
              (rejected) => {
                console.log(rejected);
              }
            );
        }
      } else {
        Swal.fire(
          "Bitte geben Sie die Dauer als numerischen Wert ein",
          "",
          "error"
        );
      }


    }
 
  }

 

  _filterexercisedata(value: string): string[] {
    let searchlist = this.exercisedata;
    let searchlistnewlist = [];
    console.log("kundenew11" + JSON.stringify(searchlist));
    for (let i = 0; i < this.typesOptionsArrayedit.length; i++) {
      const filterValue = this.typesOptionsArrayedit[i].toLowerCase();
      searchlist = searchlist.filter(function (itm) {
        return (
          JSON.stringify(itm.name).toLowerCase().indexOf(filterValue) > -1 ||
          JSON.stringify(itm.tags).toLowerCase().indexOf(filterValue) > -1
        );
      });
    }

    const filterValue11 = value.toLowerCase();
    searchlistnewlist = this.exercisedata.filter(function (itm) {
      return (
        JSON.stringify(itm.name).toLowerCase().indexOf(filterValue11) > -1 ||
        JSON.stringify(itm.tags).toLowerCase().indexOf(filterValue11) > -1
      );
    });
    searchlist = searchlist.filter(function (itm) {
      return (
        JSON.stringify(itm.name).toLowerCase().indexOf(filterValue11) > -1 ||
        JSON.stringify(itm.tags).toLowerCase().indexOf(filterValue11) > -1
      );
    });

    console.log("kundenew11" + JSON.stringify(searchlist));
    this.searchlist = searchlist;
    return searchlistnewlist;
  }
  removesequence(index,i) {
    console.log(i);
    this.exercisesequencelist[index].splice(i, 1);
    if(this.exercisesequencelist[index].length>0)
    {

    }
    else{
      this.phase.splice(index,1);
    }
  }
  getexercisenew() {
    // this.getexercise(
    //   this.exercise_controls.value.id,
    //   this.exercise_controls.value.name,
    //   this.exercise_controls.value.img,
    //   this.exercise_controls.value.diff
    // );

    if (this.typesOptionsArrayedit.length > 19) {
      Swal.fire("Begrenzen Sie 20 Tags", "", "error");
    } else {
      console.log("ffffffffffff" + JSON.stringify(this.searchlist));
      if ((this.exercise_controls.value.name || "").trim()) {
        this.typesOptionsArrayedit.push(this.exercise_controls.value.name);
        this.setsearchlist();
        this.exercise_controls.setValue(" ");
        $("#datanew").trigger("click");
      }
      // Reset the input value
    }
  }
  openinformation(id, index, tags) {
    $("#loaderouterid").css("display", "block");
    this.getcurrentUser(id);
   
  }
  getexercise(id, name, img, diff, tags, data11, index) {
    

    let add = 0;
    let indexnew = 0;
    console.log(
      "phase" + this.exerciseformgroup.controls.secondstep.value.length
    );

    if (this.exerciseformgroup.controls.secondstep.value.length > 0) {
      console.log(
        "phaseif" +
          JSON.stringify(
            this.exerciseformgroup.controls.secondstep.value.length
          )
      );
      console.log(
        "phaseif" +
          JSON.stringify(this.exerciseformgroup.controls.secondstep.value)
      );

      console.log(
        "phaseif" +
          JSON.stringify(
            this.exerciseformgroup.controls.secondstep.value[
              this.exerciseformgroup.controls.secondstep.value.length - 1
            ].duration
          )
      );
      if (
        this.exerciseformgroup.controls.secondstep.value[
          this.exerciseformgroup.controls.secondstep.value.length - 1
        ].duration != ""
      ) {
        if (
          isNaN(
            this.exerciseformgroup.controls.secondstep.value[
              this.exerciseformgroup.controls.secondstep.value.length - 1
            ].duration
          )
        ) {
          Swal.fire(
            "Bitte nur numerischen Wert in Dauer eingeben in",
            "",
            "error"
          );
        } else {
          add = 1;
          indexnew = this.exerciseformgroup.controls.secondstep.value.length;
          this.phase[this.exerciseformgroup.controls.secondstep.value.length] =
            this.exerciseformgroup.controls.secondstep.value[
              this.exerciseformgroup.controls.secondstep.value.length - 1
            ].duration;
        }
      } else {
        add = 0;
      }
    } else {
      console.log("phaseif" + this.personalInfoForm.duration.value);
      if (this.personalInfoForm.duration.value != "") {
        if (isNaN(this.personalInfoForm.duration.value)) {
          Swal.fire(
            "Bitte nur numerischen Wert in Dauer eingeben in",
            "",
            "error"
          );
        } else {
          add = 1;
          indexnew = 0;
          this.phase[0] = this.personalInfoForm.duration.value;
        }
      } else {
        add = 0;
      }
    }

    if (add == 1) {
      if (this.searchby == "yes") {
        let l = "b";
        console.log("exercisesequencelist" + this.exercisesequencelist.length);
        this.exercisesequencelist[indexnew].push({
          id: id,
          value: name,
          img: img,
          diff: diff,
          tags: tags,
          data: data11,
        });
        console.log("exercisesequencelist" + this.exercisesequencelist.length);
      } else {
        this.exercisesequencelist[indexnew].push({
          id: id,
          value: name,
          img: img,
          diff: diff,
          tags: tags,
          data: data11,
        });

        let lastindex = this.exercisesequencelist[indexnew].length;

        $("#loaderouterid").css("display", "block");
        $("#loadertext").html("Please wait for extract exercise sequence..");

        let exerciselistdata = [];

        var datanew = { id: id };
        const data = this.userService.getexercisesequence(datanew).subscribe(
          (success: any) => {
            if (success.status == "error") {
            } else {
              exerciselistdata = success;
              console.log(
                "ddddddddddddddddddd" + JSON.stringify(exerciselistdata)
              );

              for (let i = 0; i < exerciselistdata.length; i++) {
                console.log(
                  "ddddddddddddddddddd" +
                    JSON.stringify(exerciselistdata[i][0]._id)
                );
                console.log(
                  "ddddddddddddddddddd" +
                    JSON.stringify(exerciselistdata[i][1].image)
                );
                this.exercisesequencelist[indexnew].push({
                  id: exerciselistdata[i][0]._id,
                  value: exerciselistdata[i][0].exercise_name1,
                  img: exerciselistdata[i][1].image,
                  diff: exerciselistdata[i][0].difficully_level,
                  tags: exerciselistdata[i][0].tags,
                });
              }

              this.exercisesequencelist[indexnew].splice(lastindex - 1, 1);

              $("#loadertext").html("");
              // setTimeout(() => {
              //   $('.openinformationclass').html('<i class="fa fa-eye"></i>');
              // }, 1000);
              $("#loaderouterid").css("display", "none");
            }
          },
          (rejected) => {
            console.log(rejected);
          }
        );
      }
    } else {
      Swal.fire(
        "Bitte geben Sie die Dauer als numerischen Wert ein",
        "",
        "error"
      );
    }

    // }
  }

  getKeyByDinner(obj, searchid) {
    let returnKey: any = -1;

    console.log("kundenew1111111111111111111" + JSON.stringify(obj));
    $.each(obj, function (key, info) {
      console.log("kundenew1111111111111111111" + JSON.stringify(info));

      if (info[0]._id == searchid) {
        returnKey = key;
        return false;
      }
    });

    return returnKey;
  }

 

  addOptedit(event: MatChipInputEvent, index: number): void {
    const input = event.input;
    const value = event.value;
    // Add our fruit
    if (this.typesOptionsArrayedit.length > 19) {
      Swal.fire("Begrenzen Sie 20 Tags", "", "error");
    } else {
      console.log("ffffffffffff" + JSON.stringify(this.searchlist));
      if ((value || "").trim()) {
        this.typesOptionsArrayedit.push(value.trim());
        this.setsearchlist();
        this.exercise_controls.setValue(" ");
        $("#datanew").trigger("click");
      }
      // Reset the input value
      if (input) {
        input.value = "";
      }
    }
  }
  setsearchlist() {
    if (this.searchby == "yes") {
      this.exercisesequencelistsearch = [];
      if (this.typesOptionsArrayedit.length > 0) {
        for (let i = 0; i < this.searchlist.length; i++) {
          console.log("ffffffffffff" + this.searchlist[i]);
          this.exercisesequencelistsearch.push({
            id: this.searchlist[i].id,
            value: this.searchlist[i].name,
            img: this.searchlist[i].img,
            diff: this.searchlist[i].diff,
            tags: this.searchlist[i].tags,
          });
        }
      }
    } else {
      this.exercisesequencelistsearch = [];
      if (this.typesOptionsArrayedit.length > 0) {
        for (let i = 0; i < this.searchlist.length; i++) {
          console.log("ffffffffffff" + this.searchlist[i]);
          this.exercisesequencelistsearch.push({
            id: this.searchlist[i].id,
            value: this.searchlist[i].name,
            img: this.searchlist[i].img,
            diff: this.searchlist[i].diff,
            tags: "",
          });
        }
      }
    }
  }

  removeOptedit(opt: string, index: number): void {
    console.log("removeOpt");
    const optIndex = this.typesOptionsArrayedit.indexOf(opt);
    if (optIndex >= 0) {
      this.typesOptionsArrayedit.splice(optIndex, 1);
    }
    this.exercise_controls.setValue(" ");
  }
  choosecategory(value) {
    console.log("dddddddddddd" + value);
    this.personalInfoFormGroup.patchValue({
      exercise_category: value,
    });

    $("#exercise_category").val(value);
    if (value == "Therapie") {
      $("#Therapie").css("background-color", "gray");
      $("#Prävention").css("background-color", "white");
    } else {
      $("#Prävention").css("background-color", "gray");
      $("#Therapie").css("background-color", "white");
    }
  }

  choosecategoryedit(searchby, value?) {
    this.exercisesequencelistsearch = [];
    this.typesOptionsArrayedit = [];
    this.searchby = searchby;
    this.getallexerciselist(value);
    setTimeout(() => {
      this.exercise_controls.setValue(" ");
    }, 500);
  }

  getallexerciselist(therapydata) {
    let exercise_category = "Therapie";
    if (this.searchby == "yes") {
      console.log("sddd" + exercise_category);
      this.userService
        .getsearchexerciseinsequence(exercise_category)
        .pipe(first())
        .subscribe((data: any) => {
          this.exercisedata = this.loopingexerciselist(data, therapydata);
          // setTimeout(() => {
          //   $('.openinformationclass').html('<i class="fa fa-eye"></i>');
          // }, 1000);
          console.log("exercisedata" + this.exercisedata);
        });
    } else {
      this.userService
        .getSearchexercisesequence(exercise_category)
        .pipe(first())
        .subscribe((data: any) => {
          this.exercisedata = this.loopingexerciselist(data, therapydata);
          // setTimeout(() => {
          //   $('.openinformationclass').html('<i class="fa fa-eye"></i>');
          // }, 1000);
          console.log("exercisedata" + this.exercisedata);
        });
    }
  }

  loopingexerciselist(userData: string | any[], therapydata) {
    let exerciselist = [];

    // this.getcurrentexercisesequence(this.editid);

    var datanew = { id: this.editid };
    const data = this.userService.gettherapyplan(datanew).subscribe(
      (success: any) => {
        // if success and error give response
        if (success.status == "error") {
        } else {
          // for(let j=0;j<therapydata[0].exercise_id[therapydata[0].exercise_id.length-1].phase;j++)
          // {
          // this.phase[]=therapydata[0].exercise_id[therapydata[0].exercise_id.length-1].days;
          // }

          for (let i = 0; i < therapydata[0].exercise_id.length; i++) {
            let exercise_new_id = therapydata[0].exercise_id[i].exercise;

            let key = this.getKeyByDinner(success, exercise_new_id);

            if (key != "-1") {
              let index = therapydata[0].exercise_id[i].phase - 1;
              this.phase[index] = therapydata[0].exercise_id[i].days;

              console.log("phase" + JSON.stringify(this.exercisesequencelist));
              console.log("phase" + index);
              this.exercisesequencelist[index].push({
                id: success[key][0]._id,
                value: success[key][0].exercise_name1,
                img: success[key][1].image,
                diff: success[key][0].difficully_level,
                tags: success[key][0].tags,
              });
            }
          }
          $("#loaderouterid").css("display", "none");
        }
      },
      (rejected) => {
        console.log(rejected);
      }
    );

    if (this.searchby == "yes") {
     
      for (let i = 0; i < userData.length; i++) {
        // console.log(userData[i].roles.includes('b2b') , userData[i].roles)
      
        exerciselist.push({
          name: userData[i].element.exercise_name1,
          id: userData[i].element._id,
          tags: userData[i].element.tags,
          img: userData[i].img,
          diff: userData[i].element.difficully_level,
        });
      }
    } else {
      console.log("kundenew" + JSON.stringify(userData));
      for (let i = 0; i < userData.length; i++) {
        // console.log(userData[i].roles.includes('b2b') , userData[i].roles)

        exerciselist.push({
          name: userData[i].exercisesequence_name,
          id: userData[i]._id,
          tags: "",
          img: "",
          diff: userData[i].exercise_id.length,
        });
      }
    }
    console.log("broooker!!!!!!!!!!!!!!!!!!!!!" + JSON.stringify(exerciselist));

    return exerciselist;
  }

  onKey(event) {
    console.log();
    // this.values = event.target.value;
    var value = event.keyCode;
    this.values = event.target.value;
    console.log(value);
    if (value == "13") {
      // without type info
      // this.customerList = [];
      const that = this;
      console.log(that.customerList);

      // this.values = event.target.value;
      if (event.target.value != "") {
        console.log(event.target.value);
        // var datanew={currentrole:this.loginRole,id:this.loginid}
        this.userService
          .getSearchtherapyplan(event.target.value)
          .subscribe(function (data1) {
            that.customerList = data1;

            that.setPage(1);

            that.recordCount = that.customerList.length;
            //this.customerList.push(data1);
            //return this.customerList.push(data1);
          });
      } else {
        this.getallexerciesequence();
      }
    }
  }
  onSubmit() {
    const that = this;
    console.log();

    // this.values = event.target.value;
    if (this.values != "") {
      // var datanew={currentrole:this.loginRole,id:this.loginid}
      this.userService
        .getSearchtherapyplan(this.values)
        .subscribe(function (data1) {
          that.customerList = data1;

          that.setPage(1);

          that.recordCount = that.customerList.length;
          //this.customerList.push(data1);
          //return this.customerList.push(data1);
        });
    } else {
      this.getallexerciesequence();
    }
  }
  editRecordofsequenceedit(id, accordianId, cl) {
    // $("#loaderouterid").css("display", "block");
    this.exercisesequencelist = [];

    let element: HTMLElement = document.getElementById(
      "clicksequenceedit" + id
    ) as HTMLElement;
    accordianId = "cardbodyidedit";

    this.popupData = cl;
    let element1: HTMLElement = document.getElementById(
      "ulsequence" + id
    ) as HTMLElement;
    let element1new: HTMLElement = document.getElementById(
      "cardbodysequence"
    ) as HTMLElement;
    let elementnew111: HTMLElement = document.getElementById(
      "clicksequence" + id
    ) as HTMLElement;

    let accordian: HTMLElement = document.getElementById(accordianId);
    let accordian111: HTMLElement = document.getElementById("cardbodyid");
    
    if (element.innerHTML == "Schließen") {
      $("#cardbodyidedit").css("display", "none");
      element1new.after(accordian);
      element.innerHTML = "Therapiepläne bearbeiten";
      $("#loaderouterid").css("display", "none");
      this.getdivoutside();
    } else {
      if (this.editid != "") {
        let elementnew1: HTMLElement = document.getElementById(
          "clicksequenceedit" + this.editid
        ) as HTMLElement;
        if (elementnew1) {
          elementnew1.innerHTML = "Therapiepläne bearbeiten";
        }
      }
      element1.after(accordian);
      $("#cardbodyidedit").css("display", "block");
      element.innerHTML = "Schließen";
      this.editid = id;
      this.getcurrentexercisesequenceedit(this.editid);
    }
  }

  editRecordofsequence(id, accordianId, cl) {
    $("#loaderouterid").css("display", "block");
    this.exerciselist = [];

    let element: HTMLElement = document.getElementById(
      "clicksequence" + id
    ) as HTMLElement;
    accordianId = "cardbodyid";

    this.popupData = cl;
    let element1: HTMLElement = document.getElementById(
      "ulsequence" + id
    ) as HTMLElement;
    let element1new: HTMLElement = document.getElementById(
      "cardbodysequence"
    ) as HTMLElement;

    let accordian: HTMLElement = document.getElementById(accordianId);

    let elementedit: HTMLElement = document.getElementById(
      "clicksequenceedit" + id
    ) as HTMLElement;
    let accordiannewedit: HTMLElement =
      document.getElementById("cardbodyidedit");

    if (elementedit.innerHTML == "Schließen") {
      $("#cardbodyidedit").css("display", "none");
      element1new.after(accordiannewedit);
      elementedit.innerHTML = "Öffnen";
      this.getdivoutside();
    }

    if (element.innerHTML == "Übungsliste ausblenden") {
      $("#cardbodyid").css("display", "none");
      element1new.after(accordian);
      element.innerHTML = "Übungsliste anzeigen";
      $("#loaderouterid").css("display", "none");
      this.getdivoutside();
    } else {
      if (this.editid != "") {
        let elementnew1: HTMLElement = document.getElementById(
          "clicksequence" + this.editid
        ) as HTMLElement;
        if (elementnew1) {
          elementnew1.innerHTML = "Übungsliste anzeigen";
        }
      }
      element1.after(accordian);
      $("#cardbodyid").css("display", "block");
      element.innerHTML = "Übungsliste ausblenden";
      this.editid = id;
      this.getcurrentexercisesequence(this.editid);
    }
  }

  getcurrentexercisesequence(id) {
    var datanew = { id: id };
    const data = this.userService.gettherapyplan(datanew).subscribe(
      (success: any) => {
        $("#loaderouterid").css("display", "none");
        // if success and error give response
        if (success.status == "error") {
          Swal.fire("Therapiepläneliste nicht gefunden", "", "error");
        } else {
          this.exerciselist = success;
          $("#loaderouterid").css("display", "none");
        }
      },
      (rejected) => {
        console.log(rejected);
      }
    );
  }
  exerciselength(index) {
    let acc = 0;
    for (let i = 0; i < index; i++) {
      acc += this.exercisesequencelist[i].length;
    }
    return acc;
  }
  calculatelength() {
    let count = 0;
    for (let i = 0; i < this.phase.length; i++) {
      for (let j = 0; j < this.exercisesequencelist[i].length; j++) {
        count++;
      }
    }
    return count;
  }

 
  patchValue1(days) {
    console.log('patchValue1'+JSON.stringify(days))
    var data = {
      secondstep: days
    }
 
    this.exerciseformgroup.patchValue(data);
}



  getcurrentexercisesequenceedit(id) {
    $("#loaderouterid").css("display", "block");
    var datanew = { id: id };
    const data = this.userService.gettherapyplanbyid(datanew).subscribe(
      (success: any) => {
        // if success and error give response
        if (success.status == "error") {
          Swal.fire("Therapiepläneliste nicht gefunden", "", "error");
        } else {
          this.choosecategoryedit("yes", success);
          this.exerciseformgroup.patchValue({
            therapyplan_name: success[0].therapyplan_name,
            edit_id: id,
          });

          let lastphase=success[0].exercise_id[success[0].exercise_id.length-1].phase - 1;


          let newindex=success[0].exercise_id.length - 1;
          for (
            let j = 0;
            j < success[0].exercise_id[newindex].phase;
            j++
          ) {
            console.log("length"+this.quantities().length);
            if(lastphase>this.phase.length)
            {
            this.phase.push([]);
            }
            // if(lastphase>this.phase.length)
            // {
            this.exercisesequencelist.push([]);
            // }
            if (j != 0) {
              if(lastphase>this.quantities().length)
              {
              this.quantities().push(this.newQuantity());
              }
            }
          }
          let days=[];
          let newindex1=[];
          for (let j = 0; j < success[0].exercise_id.length; j++) {
         
            let index = success[0].exercise_id[j].phase - 1;
            console.log("index" + index);
            if (index == 0) {
              this.exerciseformgroup.patchValue({
                duration: success[0].exercise_id[j].days,
              });
            } else {
if(newindex1.includes(index))
{

}
else
{
  days.push({duration:success[0].exercise_id[j].days})
  newindex1.push(index);
}
              
              // this.patchValue1(success[0].exercise_id[j].days);
              // this.exerciseformgroup.controls['secondstep'].setValue(success[0].exercise_id[j].days)
              // this.quantities().setValue(
              //   success[0].exercise_id[j].days
              // );
            }
           
          }
          this.patchValue1(days);
          let therapyplan_namecount: any;

          let therapyplan_name =
            this.exerciseformgroup.controls.therapyplan_name.value.length;
          therapyplan_namecount = 35 - therapyplan_name;

          $("#therapyplan_namecount").html(therapyplan_namecount);

          // this.exerciselist = success;
        }
      },
      (rejected) => {
        console.log(rejected);
      }
    );
  }
  quantities(): FormArray {
    return this.exerciseformgroup.get("secondstep") as FormArray;
  }

  newQuantity(): FormGroup {
    return this._formBuilder.group({
      duration: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
    });
  }
  addmorefield() {
    this.quantities().push(this.newQuantity());
    this.exercisesequencelist.push([]);
  }

  checkphase() {
    if (this.phase.length > 0) {
      if (
        this.phase.length - 1 ==
        this.exerciseformgroup.controls.secondstep.value.length
      ) {
        return 1;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }
  removeQuantity(i: number, index: any) {
    this.quantities().removeAt(i);
    this.phase.splice(index, 1);
    this.exercisesequencelist[index].splice(
      0,
      this.exercisesequencelist[index].length
    );
    // this.uploadedFiles.splice(index, 1);
    // this.imgURL.splice(index, 1);
  }
  setdays(index) {
    console.log("setdays" + index);
    if (this.phase.length > 0) {
      if (index == "0") {
        this.phase[index] = this.personalInfoForm.duration.value;
      } else {
        if (
          this.exerciseformgroup.controls.secondstep.value[index].duration != ""
        ) {
          this.phase[index] =
            this.exerciseformgroup.controls.secondstep.value[index].duration;
        }
      }
    }
  }

  update_exercise_sequence() {
    $("#loaderouterid").css("display", "block");

    let companycodenew = "";

    companycodenew = "60-co-";

        this.ticket_no = companycodenew + "" + new Date().valueOf();

        let dataarray = [];

        for (let j = 0; j < this.phase.length; j++) {
          for (let i = 0; i < this.exercisesequencelist[j].length; i++) {
            // let datannn={'exercise_id1':this.exercisesequencelist[i].id}
            dataarray.push({
              phase: j + 1,
              days: this.phase[j],
              exercise: this.exercisesequencelist[j][i].id,
            });
          }
        }

        let datanew = {
          therapyplan_name:
            this.exerciseformgroup.controls.therapyplan_name.value,
          edit_id: this.exerciseformgroup.controls.edit_id.value,
          exercise_id: dataarray,
        };

        this.userService
          .updatetherapyplan(datanew)
          .pipe(first())
          .subscribe(
            (data) => {
              Swal.fire(
                "Therapiepläne erfolgreich aktualisiert.",
                "",
                "success"
              );
              this.ticket_no = "";
              // $('#clicksequenceedit'+this.exerciseformgroup.controls.edit_id.value).trigger('click');
              $("#loaderouterid").css("display", "none");

              setTimeout(() => {
                Swal.close();
                this.getallexerciesequence(this.editid);
                this.exercisesequencelist = [];
                this.phase = [];

                //  this.exercise_management();
              }, 1000);
            },
            (error) => {
              Swal.fire("Error" + error["error"] + "", "", "error");

              $("#loaderouterid").css("display", "none");
              setTimeout(() => {
                Swal.close();
                this.getallexerciesequence(this.editid);
                this.exercisesequencelist = [];
                this.phase = [];
              }, 1000);
              // $("html, body").animate(
              //   { scrollTop: "0" }, 3000);
            }
          );
      
  }

  editRecord(id, accordianId, cl) {
    this.progress[0] = 0;
    this.progress[1] = 0;
    this.progress[2] = 0;
    let idnew = id;
    id = id + "" + accordianId;
    accordianId = "collapse";
    $("#loaderouterid").css("display", "block");
    this.popupData = cl;
    let element1: HTMLElement = document.getElementById(
      "ul" + id
    ) as HTMLElement;
    let element1new: HTMLElement = document.getElementById(
      "cardbodyid"
    ) as HTMLElement;
    let element: HTMLElement = document.getElementById(
      "click" + id
    ) as HTMLElement;
    let accordian: HTMLElement = document.getElementById(accordianId);
    console.log("accordian" + accordian);
    if (element.innerHTML == "Schließen") {
      element1new.after(accordian);
      accordian.classList.add("collapse");
      accordian.classList.remove("collapse-show");
      element.innerHTML = "Öffnen";
      $("#loaderouterid").css("display", "none");
    } else {
      if (this.editid != "") {
        let elementnew1: HTMLElement = document.getElementById(
          "click" + this.editidnew
        ) as HTMLElement;
        if (elementnew1) {
          elementnew1.innerHTML = "Öffnen";
        }
      }

      element1.after(accordian);
      accordian.classList.remove("collapse");
      accordian.classList.add("collapse-show");
      element.innerHTML = "Schließen";
      this.editid = idnew;
      this.editidnew = id;
      this.getcurrentUser(this.editid);
    }
  }

  addOpt(event: MatChipInputEvent, index: number): void {
    const input = event.input;
    const value = event.value;
    console.log("dsdsad" + index);
    console.log("input" + input);
    console.log("value" + value);
    console.log("valuenew" + value.trim());
    console.log("valuenew" + this.typesOptionsArray);
    // Add our fruit
    if (this.typesOptionsArray.length > 19) {
      Swal.fire("Begrenzen Sie 20 Tags", "", "error");
    } else {
      if ((value || "").trim()) {
        this.typesOptionsArray.push(value.trim());
      }
      // Reset the input value
      if (input) {
        input.value = "";
      }
      let tagscount: any = 20 - this.typesOptionsArray.length;
      $("#manual_tagscount").html(tagscount);
    }
  }

  removeOpt(opt: string, index: number): void {
    const optIndex = this.typesOptionsArray.indexOf(opt);
    if (optIndex >= 0) {
      this.typesOptionsArray.splice(optIndex, 1);
    }
  }

  getcurrentUser(openid?, currentData?) {
    const that = this;
    if (currentData && !openid) {
      setData(currentData);
    } else {
      // console.log("call api")
      this.userService.getEditexercise(openid).subscribe(function (data1) {
        that.currentUserData = data1;
        console.log(data1);

        setData(data1);
      });
    }

    function setData(data1) {
      that.currentopendata = data1;

      console.log("dddddddddddd" + JSON.stringify(data1));
      console.log("dddddddddddd" + data1.element.exercise_category);
      that.choosecategory(data1.element.exercise_category);

      that.calcount("commentcount", "comment");
      let commentlength: any = 500 - data1.element.comment.length;
      $("#commentcount").html(commentlength);
      let commentlength1: any = 500 - data1.element.comment1.length;
      $("#comment1count").html(commentlength1);
      let exercise_name: any = 35 - data1.element.exercise_name.length;
      $("#exercise_namecount").html(exercise_name);
      let exercise_name1: any = 35 - data1.element.exercise_name1.length;
      $("#exercise_name1count").html(exercise_name1);
      let tags: any = 20 - data1.element.tags.length;
      $("#manual_tagscount").html(tags);

      that.personalInfoFormGroup.patchValue({
        exercise_name: data1.element.exercise_name,
        exercise_name1: data1.element.exercise_name1,
        difficully_level: data1.element.difficully_level,
        tools: data1.element.tools,
        comment: data1.element.comment,
        comment1: data1.element.comment1,
      });
      that.oldtypesOptionsArray = data1.element.tags;
      console.log("dddddddddddd" + that.oldtypesOptionsArray);
      $("#logopreview0").html(
        "<img src='" + data1.image1 + "' style='width: 100%;height: 300px'>"
      );
      $("#logopreview1").html(
        "<video  style='width: 100%;height: 300px' controls><source  src='" +
          data1.image2 +
          "' type='video/mp4'></video>"
      );
      $("#logopreview2").html(
        "<video  style='width: 100%;height: 300px' controls><source  src='" +
          data1.image3 +
          "' type='video/mp4'></video>"
      );
      $("#loaderouterid").css("display", "none");
    }

    // this.choosecategory();
    // $('#loaderouterid').css("display","none");
  }
  calcountedit(id, type) {
    console.log("dddddddddddddddd");
    let therapyplan_namecount: any;
    if (type == "therapyplan_name") {
      let therapyplan_name =
        this.exerciseformgroup.controls.therapyplan_name.value.length;

      therapyplan_namecount = 35 - therapyplan_name;
    }
    $("#" + id).html(therapyplan_namecount);
  }
  calcount(id, type) {
    let commentcount: any;
    if (type == "comment") {
      let comment = this.personalInfoForm.comment.value.length;

      commentcount = 500 - comment;
    }
    if (type == "comment1") {
      let comment = this.personalInfoForm.comment1.value.length;

      commentcount = 500 - comment;
    }
    if (type == "exercise_name") {
      let comment = this.personalInfoForm.exercise_name.value.length;

      commentcount = 35 - comment;
    }
    if (type == "exercise_name1") {
      let comment = this.personalInfoForm.exercise_name1.value.length;

      commentcount = 35 - comment;
    }
    $("#" + id).html(commentcount);
  }

  dataconvert(
    bytes: number = 0,
    precision: number | unitPrecisionMap = defaultPrecisionMap
  ): string {
    if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) return "?";

    let unitIndex = 0;

    while (bytes >= 1024) {
      bytes /= 1024;
      unitIndex++;
    }

    const unit = this.units[unitIndex];

    if (typeof precision === "number") {
      return `${bytes.toFixed(+precision)} ${unit}`;
    }
    return `${bytes.toFixed(precision[unit])} ${unit}`;
  }

  handleImageChange(event: any, index) {
    $("#result").html("");
    console.log(this.loginid);
    event.preventDefault();
    const removeData = (j, indexw) => {
      console.log("sdddd" + this.filearraynew);
      console.log("sdddd" + j);
      delete this.filearraynew[j];
      console.log("sdddd" + this.filearraynew);
      let newfilearray = this.filearraynew.filter(function () {
        return true;
      });
      if (newfilearray.length > 0) {
      } else {
        // this.showButton = false;
      }

      this.filearray = newfilearray;
      console.log("sdddd" + this.filearray);
      $("#logopreview" + indexw).html(
        '<p style="text-align: center;padding: 105px 27px 106px 38px;border: 1px solid;"> Vorschau</p>'
      );
    };

    var files = event.target.files; //FileList object
    // var output = document.getElementById("result");

    var filesLength = files.length;

    for (let i = 0; i < filesLength; i++) {
      let f = files[i];
      let newsize = this.l;
      this.l = this.l + 1;
      // this.filearraynew.splice(index, 0, f);

      this.filearraynew[index] = f;
      this.filearray = this.filearraynew;
      let Size1 = f.size;
      let Size = this.dataconvert(Size1);
      var fileReader = new FileReader();
      //var target:EventTarget;
      fileReader.onload = function (e) {
        //var file = e.target;

        let extension = f.name.substr(f.name.lastIndexOf(".") + 1);

        let ImageName;

        ImageName = (e.target as any).result;

        let typeofimage = f.type;

        let dateofdocument = f.lastModified;
        // var d = new Date(dateofdocument);
        // var date =
        //   d.getDate() + "." + (d.getMonth() + 1) + "." + d.getFullYear();

        var date1 = new Date(dateofdocument).toLocaleDateString("en-IN", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });

        var date = date1.replace(/[/]/g, ".");

        // let Size = Math.round(f.size / 1024);
        $("#logopreview" + index).html(
          '<div class="pip" style="width: 290px;display: inline-block;margin: 8px;" "id=\'pipremove' +
            newsize +
            "'>" +
            '<div class="removepreview" id="removepreviewid' +
            newsize +
            '" style="background: #184297;border-radius: 50%;width:30px;height:30px;font-size: 14px; text-align: center; padding: 6px;color: white;position: absolute;margin-left: 257px;margin-top: 2px;margin-right: 0 !important;cursor: pointer;">X</div>' +
            "" +
            '<img class="imageThumb" style="width: 100%;height:210px" src="' +
            ImageName +
            '" title="' +
            f.name +
            '"/>' +
            "<div> <b>Dokumentenname: " +
            f.name +
            "</b> </div><div> <b>Dateigröße: " +
            Size +
            "</b>  </div><div> <b>Dateityp: " +
            typeofimage +
            "</b> </div><div> <b>Datum des Dokuments: " +
            date +
            "</b> </div></div>"
        );

        // $(`<div> <b>  ${Math.round((f.size / 1024))} </b> KB </div>`).insertAfter(".pip")
        $("#removepreviewid" + newsize).click(function () {
          removeData(newsize, index);
          // $("#pipremove" + i).remove();
          $(this).parent(".pip").remove();
        });
      };
      fileReader.readAsDataURL(f);
    }
  }

  handleImageChangeexplain(event: any, index) {
    $("#result").html("");
    console.log(this.loginid);
    event.preventDefault();
    const removeData = (j) => {
      console.log("sdddd" + this.filearraynew);
      console.log("sdddd" + j);
      delete this.filearraynew[j];
      console.log("sdddd" + this.filearraynew);
      let newfilearray = this.filearraynew.filter(function () {
        return true;
      });
      if (newfilearray.length > 0) {
      } else {
        // this.showButton = false;
      }

      this.filearray = newfilearray;
      console.log("sdddd" + this.filearray);
    };

    var files = event.target.files; //FileList object
    // var output = document.getElementById("result");

    var filesLength = files.length;

    for (let i = 0; i < filesLength; i++) {
      let f = files[i];
      let newsize = this.l;
      this.l = this.l + 1;
      // this.filearraynew.splice(index, 0, f);

      this.filearraynew[index] = f;
      this.filearray = this.filearraynew;
      let Size1 = f.size;
      let Size = this.dataconvert(Size1);
      var fileReader = new FileReader();
      //var target:EventTarget;
      fileReader.onload = function (e) {
        //var file = e.target;

        let extension = f.name.substr(f.name.lastIndexOf(".") + 1);

        let ImageName;

        ImageName = (e.target as any).result;

        let typeofimage = f.type;

        let dateofdocument = f.lastModified;
        // var d = new Date(dateofdocument);
        // var date =
        //   d.getDate() + "." + (d.getMonth() + 1) + "." + d.getFullYear();

        var date1 = new Date(dateofdocument).toLocaleDateString("en-IN", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });

        var date = date1.replace(/[/]/g, ".");

        // let Size = Math.round(f.size / 1024);
        $("#logopreview" + index).html(
          '<div class="pip" style="width: 290px;display: inline-block;margin: 8px;" "id=\'pipremove' +
            newsize +
            "'>" +
            '<div class="removepreview" id="removepreviewid' +
            newsize +
            '" style="background: #184297;border-radius: 50%;width:30px;height:30px;font-size: 14px; text-align: center; padding: 6px;color: white;position: absolute;margin-left: 257px;margin-top: -30px;margin-right: 0 !important;cursor: pointer;">X</div>' +
            "" +
            '<video  style="width: 100%;height:210px"  controls><source src="' +
            (e.target as any).result +
            '" type="video/mp4"></video>' +
            "<div> <b>Dokumentenname: " +
            f.name +
            "</b> </div><div> <b>Dateigröße: " +
            Size +
            "</b>  </div><div> <b>Dateityp: " +
            typeofimage +
            "</b> </div><div> <b>Datum des Dokuments: " +
            date +
            "</b> </div></div>"
        );

        // $(`<div> <b>  ${Math.round((f.size / 1024))} </b> KB </div>`).insertAfter(".pip")
        $("#removepreviewid" + newsize).click(function () {
          removeData(newsize);
          // $("#pipremove" + i).remove();
          $(this).parent(".pip").remove();
        });
      };
      fileReader.readAsDataURL(f);
    }
  }

  handleImageChangeimplementation(event: any, index) {
    $("#result").html("");
    console.log(this.loginid);
    event.preventDefault();
    const removeData = (j) => {
      console.log("sdddd" + this.filearraynew);
      console.log("sdddd" + j);
      delete this.filearraynew[j];
      console.log("sdddd" + this.filearraynew);
      let newfilearray = this.filearraynew.filter(function () {
        return true;
      });
      if (newfilearray.length > 0) {
      } else {
        // this.showButton = false;
      }

      this.filearray = newfilearray;
      console.log("sdddd" + this.filearray);
    };

    var files = event.target.files; //FileList object
    // var output = document.getElementById("result");

    var filesLength = files.length;

    for (let i = 0; i < filesLength; i++) {
      let f = files[i];
      let newsize = this.l;
      this.l = this.l + 1;
      // this.filearraynew.splice(index, 0, f);

      this.filearraynew[index] = f;
      this.filearray = this.filearraynew;
      let Size1 = f.size;
      let Size = this.dataconvert(Size1);
      var fileReader = new FileReader();
      //var target:EventTarget;
      fileReader.onload = function (e) {
        //var file = e.target;

        let extension = f.name.substr(f.name.lastIndexOf(".") + 1);

        let ImageName;

        ImageName = (e.target as any).result;

        let typeofimage = f.type;

        let dateofdocument = f.lastModified;
        // var d = new Date(dateofdocument);
        // var date =
        //   d.getDate() + "." + (d.getMonth() + 1) + "." + d.getFullYear();

        var date1 = new Date(dateofdocument).toLocaleDateString("en-IN", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });

        var date = date1.replace(/[/]/g, ".");

        // let Size = Math.round(f.size / 1024);
        $("#logopreview" + index).html(
          '<div class="pip" style="width: 290px;display: inline-block;margin: 8px;" "id=\'pipremove' +
            newsize +
            "'>" +
            '<div class="removepreview" id="removepreviewid' +
            newsize +
            '" style="background: #184297;border-radius: 50%;width:30px;height:30px;font-size: 14px; text-align: center; padding: 6px;color: white;position: absolute;margin-left: 257px;margin-top: -30px;margin-right: 0 !important;cursor: pointer;">X</div>' +
            "" +
            '<video  style="width: 100%;height:210px"  controls><source src="' +
            (e.target as any).result +
            '" type="video/mp4"></video>' +
            "<div> <b>Dokumentenname: " +
            f.name +
            "</b> </div><div> <b>Dateigröße: " +
            Size +
            "</b>  </div><div> <b>Dateityp: " +
            typeofimage +
            "</b> </div><div> <b>Datum des Dokuments: " +
            date +
            "</b> </div></div>"
        );

        // $(`<div> <b>  ${Math.round((f.size / 1024))} </b> KB </div>`).insertAfter(".pip")
        $("#removepreviewid" + newsize).click(function () {
          removeData(newsize);
          // $("#pipremove" + i).remove();
          $(this).parent(".pip").remove();
        });
      };
      fileReader.readAsDataURL(f);
    }
  }





  get personalInfoForm() {
    return this.personalInfoFormGroup.controls;
  }

  getallexerciesequence(id = "") {
    if (id != "") {
      let element: HTMLElement = document.getElementById(
        "clicksequenceedit" + id
      ) as HTMLElement;
      let accordianId = "cardbodyidedit";
      let accordian: HTMLElement = document.getElementById(accordianId);
      let element1new: HTMLElement = document.getElementById(
        "cardbodysequence"
      ) as HTMLElement;
      if (element.innerHTML == "Schließen") {
        $("#cardbodyidedit").css("display", "none");
        element1new.after(accordian);
        element.innerHTML = "Öffnen";
        this.getdivoutside();
      }
    }
    var datanew = { id: "" };
    const data = this.userService.gettherapyplan(datanew).subscribe(
      (success: any) => {
        $("#loaderouterid").css("display", "none");
        // if success and error give response
        if (success.status == "error") {
          Swal.fire("Übungsliste nicht gefunden", "", "error");
        } else {
          this.customerList = success;
          this.setPage(1);
          this.recordCount = success.length;
          console.log(this.customerList);
        }
      },
      (rejected) => {
        console.log(rejected);
      }
    );
    this.filearray = [];
  }

  updateexercise() {
    console.log("dddddddddddddd" + this.lengthfile);
    console.log("dddddddddddddd" + this.uploadlength);
    if (this.lengthfile == this.uploadlength) {
      let product_type_new = [];
      for (var i = 0; i < this.typesOptionsArray.length; i++) {
        product_type_new[i] = this.typesOptionsArray[i];
      }
      if (this.oldtypesOptionsArray) {
        for (var l = 0; l < this.oldtypesOptionsArray.length; l++) {
          product_type_new[i] = this.oldtypesOptionsArray[l];
          i++;
        }
      }
      let data = {
        comment: this.personalInfoForm.comment.value,
        comment1: this.personalInfoForm.comment1.value,
        exercise_name: this.personalInfoForm.exercise_name.value,
        exercise_name1: this.personalInfoForm.exercise_name1.value,
        difficully_level: this.personalInfoForm.difficully_level.value,
        tools: this.personalInfoForm.tools.value,
        exercise_category: this.personalInfoForm.exercise_category.value,
        tags: product_type_new,
        document: this.documentid,
        _id: this.currentopendata.element._id,
      };

      this.userService
        .updateexercise(data)
        .pipe(first())
        .subscribe(
          (data) => {
            console.log("POST Request is successful ", data);
            $("#loaderouterid").css("display", "none");
            Swal.fire("Übung erfolgreich aktualisiert", "", "success");

            setTimeout(() => {
              Swal.close();
              this.getallexerciesequence();
            }, 1000);
          },
          (error) => {
            console.log("Error", error["error"]);
            $("#loaderouterid").css("display", "none");
            Swal.fire("Übung aktualisiert fehlgeschlagen", "", "error");
            // setTimeout(() => {
            Swal.close();
            // window.location.reload();
            // location.href="http://localhost:4200/#/finance/product-partner-list";
            this.getallexerciesequence();
            // }, 1000);
            // $("html, body").animate(
            //   { scrollTop: "0" }, 3000);
          }
        );
    }
  }

  
  logout() {
    localStorage.removeItem("token");
    this.router.navigate(["./"]);
  }
  exercise_management() {
    this.router.navigate(["/therapy-plan-list"], {});
  }
  getdivoutside() {
    // let accordianId = "collapse";
    // let accordian: HTMLElement = document.getElementById(accordianId);
    // let element1new: HTMLElement = document.getElementById(
    //   "cardbodyid"
    // ) as HTMLElement;
    // element1new.after(accordian);
    // accordian.classList.add("collapse");
    // // accordian.classList.add("collapse");
    // accordian.classList.remove("collapse-show");
  }

  setPage(page: number) {
    this.getdivoutside();
    // get pager object from service
    this.pager = this.pagerService.getPager(this.customerList.length, page);

    // get current page of items
    this.pagedItems = this.customerList.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );
    if (this.customerList.length > 0) {
      this.startRecord =
        this.pager.currentPage * this.pagerService.getDefaultPageSize() -
        this.pagerService.getDefaultPageSize() +
        1;
      this.endRecord =
        this.pager.currentPage * this.pagerService.getDefaultPageSize() >
        this.customerList.length
          ? this.customerList.length
          : this.pager.currentPage * this.pagerService.getDefaultPageSize();
    } else {
      this.startRecord = 0;
      this.endRecord = 0;
    }
  }
}

