import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UserService } from '../user.service';
import { OwlOptions } from "ngx-owl-carousel-o";
import Swal from 'sweetalert2';
@Component({
  selector: 'app-patient-product',
  templateUrl: './patient-product.component.html',
  styleUrls: ['./patient-product.component.css']
})
export class PatientProductComponent implements OnInit {
headername;
therapyplan;
api_url;
netprice;
constructor( private service:UserService,private router:Router,private route:ActivatedRoute) { this.api_url=environment.API_URL;}
customOptions: OwlOptions = {
  items: 5,
  loop: false,
  navText: [
    '<i class="fa fa-chevron-left"></i>',
    '<i class="fa fa-chevron-right"></i>',
  ],
  margin: 10,

  // nav:true,

  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 5,
    },
  },
};

  ngOnInit() {
this.headername="Präventionskurs";

$("#loaderouterid").css("display", "block");
this.service.getpatientplan('').subscribe((data:any) =>  {
if(data.length>0)
{
 this.therapyplan = data;
 let price=data[0].price.replace(",",".");
 this.netprice= (
   (price / 100) *
   (100 - data[0].discount)
 ).toFixed(2).replace(".",",")
  }
 $("#loaderouterid").css("display", "none");
   console.log(this.therapyplan);
 });


  }


  navigateWithb2bID(id) {


    // Swal.fire({
    //   title: "Sind Sie sicher, diesen Präventionskurs zu kaufen?",
    //   showCancelButton: true,
    //   confirmButtonText: "Speichern",
    //   cancelButtonText: "Verwerfen"
    // }).then(result => {
    //   if (result.value) {
        this.router.navigate(["/purchase-patient-prevention-course"], {
          queryParams: { id: id },
        });
    //   }
    //   else{
    //     $("#loaderouterid").css("display", "none");
    //   }
    // });



    // let id = $("#therapistid").val();

    // this.queryID = this.selectedUser.customerno;
    // this.ngOnInit()
  }

}
