import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from "../environments/environment";
import * as jwtDecode from "jwt-decode";
import * as jwt_decode from "jwt-decode";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
@Injectable()
export class UserService {
  API_URL = environment.API_URL;

  constructor(private http: HttpClient,private jwthelper:JwtHelperService) {}
  checkVerificationCode(verificationData){
    return this.http.post(`${this.API_URL}checkVerificationCode`,{"otp":verificationData.otp,"requestId":verificationData.requestId,'userId':verificationData.userId});
  }


  getTherapy(data) 
  {
    return this.http.post(`${this.API_URL}starttreatment/patienttherapyplan`, data);
  }


  getpurchasepreventioncourse(data) 
  {
    return this.http.post(`${this.API_URL}patientproduct/getpurchasepreventioncourse`, data);
  }


  viewdocument(data) 
  {
    return this.http.get(`${this.API_URL}document/view_document/`+data);
  }


  getcommissionlisybyb2b(data)
  {
    console.log("data====");
    return this.http.post(`${this.API_URL}patientproduct/getcommissionlisybyb2b`, data);
  }
  
  getpurchasemembershipplanbyupgradeplan_id(data) {
    console.log("data====");
    return this.http.post(`${this.API_URL}membershipplan/getpurchasemembershipplanbyupgradeplan_id`, data);
  }


  
getCustomerscoursula(company, role?): any {
  if (role) {
    return this.http.post(`${this.API_URL}users/getCustomerscoursula`, {
      company: company,
      role: "b2b",
    });
  } else {
    return this.http.post(`${this.API_URL}users/getCustomerscoursula`, {
      company: company,
    });
  }
}
getCustomerssearch(searchtext, company, role?): any {
  if (role) {
    return this.http.post(`${this.API_URL}users/getCustomerssearch`, {
      searchText: searchtext,
      company: company,
      role: "b2b",
    });
  } else {
    return this.http.post(`${this.API_URL}users/getCustomerssearch`, {
      searchText: searchtext,
      company: company,
    });
  }
}



getSearch(searchText, CustomerNumbernew = "") {
  return this.http.post(`${this.API_URL}users/getSearch`, {
    searchText: searchText,
    CustomerNumbernew: CustomerNumbernew,
  });
}

getSearchcoursula(searchText, CustomerNumbernew = "") {
  return this.http.post(`${this.API_URL}users/getSearchcoursula`, {
    searchText: searchText,
    CustomerNumbernew: CustomerNumbernew,
  });
}

getpurchasemembershipplanwithstatus(data) {
  console.log("data====");
  return this.http.post(`${this.API_URL}membershipplan/getpurchasemembershipplanwithstatus`, data);
}

getDecodedAccessToken(token: string): any {
  console.log("in decode token function");
  try {
    let decodedToken = jwt_decode(token);
    console.log(decodedToken);
    return decodedToken;
  } catch (Error) {
    return null;
  }
}



  gettherapistplan(data) {
    // this.http.post(this.API_URL+"membershipplan",``)
    return this.http.post(`${this.API_URL}membershipplan/list`, data);
  }
  getstarttreatmentbypatientid(data) {
    console.log("data====");
    return this.http.post(`${this.API_URL}starttreatment/getbypatientid`, data);
  }
  getsearchexerciseinsequence(searchText) {
    let datanew = { searchText: searchText };
    console.log("data====");
    return this.http.post(
      `${this.API_URL}exercise/getsearchexercise1`,
      datanew
    );
  }
  getsearchtherapyinsequence(searchText) {
    let datanew = { searchText: searchText };
    console.log("data====");
    return this.http.post(
      `${this.API_URL}therapyplan/getSearchtherapyplan`,
      datanew
    );
  }
  getSearchexercisesequence(searchText) {
    let datanew = { searchText: searchText };
    console.log("data====");
    return this.http.post(
      `${this.API_URL}exercisesequence/getSearchexercisesequence`,
      datanew
    );
  }
  updatestarttreatmentstatus(data) {
    console.log("data====", data);
    return this.http.post(`${this.API_URL}starttreatment/updatestatus`, data);
  }
  getpatientproduct(data) {
    console.log("data====");
    return this.http.post(`${this.API_URL}patientproduct/list`, data);
  }
  getUsers() {
    console.log("get all users function");

    return this.http.get(`${this.API_URL}users/allusers`);
  }


  savequestion(data)
  {
    return this.http.post(`${this.API_URL}therapyprogress`, data);
  }

  getquestion(data)
  {
    return this.http.post(`${this.API_URL}starttreatment/medicalquestion`, data);
  }
  
  checkbrokerassign(data)
  {
    return this.http.post(`${this.API_URL}users/checkbrokerassign`, data);
  }
  
  sendLinkForgotPassword(data)
  {
    return this.http.post(`${this.API_URL}checkEmail/appforgotpassword`, data);
  }
  
  


  gettoolsbytherapy(data)
  {
    return this.http.post(`${this.API_URL}starttreatment/gettoolsbytherapy`, data);
  }


  gettoolsbypreventioncourse(data)
  {
    return this.http.post(`${this.API_URL}patientproduct/gettoolsbypreventioncourse`, data);
  }

  appuserdatabyid(data)
  {
    return this.http.post(`${this.API_URL}users/appuserdatabyid`, data);
  }
  

  getEditUser(id): any {
    return this.http.post(`${this.API_URL}users/editUser`, { id: id });
    /*return this.http.post(`${this.API_URL}users/editUser`,id).subscribe((data:any) => {
        return data;
    }); */
  }

  editUserwithoutlogin(id): any {
    return this.http.post(`${this.API_URL}users/editUserwithoutlogin`, { id: id });
    /*return this.http.post(`${this.API_URL}users/editUser`,id).subscribe((data:any) => {
        return data;
    }); */
  }



  

  getexercisebytherapy(data)
  {
    return this.http.post(`${this.API_URL}starttreatment/getexercisebytherapy`, data);
  }

  getvideobytherapy(data)
  {
    return this.http.post(`${this.API_URL}starttreatment/getvideobytherapy`, data);
  }

  getvideobyprevention(data)
  {
    return this.http.post(`${this.API_URL}patientproduct/getvideobyprevention`, data);
  }

  editexercise(data)
  {
    return this.http.post(`${this.API_URL}exercise/editexercise`, data);
  }

  savevideo(data)
  {
    return this.http.post(`${this.API_URL}therapyprogress/savevideo`, data);
  }
  savevideoinprevention(data)
  {
    return this.http.post(`${this.API_URL}patientproduct/savevideoinprevention`, data);
  }

  repeatvideo(data)
  {
    return this.http.post(`${this.API_URL}therapyprogress/repeatvideo`, data);
  }
  patientproductrepeatvideo(data)
  {
    return this.http.post(`${this.API_URL}patientproduct/repeatvideo`, data);
  }


  getmembershipplanbynoofpatient(data)
  {
    return this.http.post(`${this.API_URL}membershipplan/getmembershipplanbynoofpatient`, data);
  }

  getmembershipplanbynoofpatientandduration(data)
  {
    return this.http.post(`${this.API_URL}membershipplan/getmembershipplanbynoofpatientandduration`, data);
  }

getLastUser():any {
  return this.http.post(`${this.API_URL}users/getLastUser`,{});
}

callApiMultipartnew11(data): Observable<any> {
  var headers = {};
  headers["Content-Type"] = "multipart/form-data";
  var token = localStorage.getItem("token");
  if (token !== "") {
    headers["Authorization"] = token;
  }
  return this.http
    .post(`${this.API_URL}document/uploaddocumentnew`, data, {
      reportProgress: true,
      observe: "events",
    })
    .pipe(catchError(this.errorMgmt));
}
errorMgmt(error: HttpErrorResponse) {
  let errorMessage = "";
  if (error.error instanceof ErrorEvent) {
    // Get client-side error
    errorMessage = error.error.message;
  } else {
    // Get server-side error
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  }
  console.log(errorMessage);
  return throwError(errorMessage);
}
registerexercise(data) {
  console.log("data====", data);
  return this.http.post(`${this.API_URL}exercise`, data);
}
getSearchexercise(searchText) {
  let datanew = { searchText: searchText };
  console.log("data====");
  return this.http.post(`${this.API_URL}exercise/getsearchexercise`, datanew);
}
getexercise(data) {
  console.log("data====");
  return this.http.post(`${this.API_URL}exercise/list`, data);
}
registerexercisesequence(data) {
  console.log("data====", data);
  return this.http.post(`${this.API_URL}exercisesequence`, data);
}
getexercisesequencebyid(data) {
  console.log("data====");
  return this.http.post(`${this.API_URL}exercisesequence/getbyid`, data);
}
updateerexercisesequence(data) {
  console.log("data====", data);
  return this.http.post(`${this.API_URL}exercisesequence/update`, data);
}
registerpreventioncourse(data) {
  console.log("data====", data);
  return this.http.post(`${this.API_URL}preventioncourse`, data);
}
getSearchpreventioncourse(searchText) {
  let datanew = { searchText: searchText };
  console.log("data====");
  return this.http.post(
    `${this.API_URL}preventioncourse/getSearchpreventioncourse`,
    datanew
  );
}
getpreventioncourse(data) {
  console.log("data====");
  return this.http.post(`${this.API_URL}preventioncourse/list`, data);
}

getpreventioncoursebyid(data) {
  console.log("data====");
  return this.http.post(`${this.API_URL}preventioncourse/getbyid`, data);
}
updatepreventioncourse(data) {
  console.log("data====", data);
  return this.http.post(`${this.API_URL}preventioncourse/update`, data);
}
adddoctormembershipplan(data) {
  console.log("data====", data);
  return this.http.post(`${this.API_URL}doctormembershipplan`, data);
}
getSearchdoctormembershipplan(searchText) {
  let datanew = { searchText: searchText };
  console.log("data====");
  return this.http.post(
    `${this.API_URL}doctormembershipplan/getSearchdoctormembershipplan`,
    datanew
  );
}
getdoctormembershipplan(data) {
  console.log("data====");
  return this.http.post(`${this.API_URL}doctormembershipplan/list`, data);
}
getEditdoctormembership(id): any {
  return this.http.post(
    `${this.API_URL}doctormembershipplan/editdoctormembershipplan`,
    { id: id }
  );
 }
 uploadmembershipproductdocument(data): Observable<any> {
  var headers = {};
  headers["Content-Type"] = "multipart/form-data";
  var token = localStorage.getItem("token");
  if (token !== "") {
    headers["Authorization"] = token;
  }
  return this.http
    .post(`${this.API_URL}document/uploadmembershipproductdocument`, data, {
      reportProgress: true,
      observe: "events",
    })
    .pipe(catchError(this.errorMgmt));
}
updatedoctormembershipplan(data) {
  console.log("data====", data);
  return this.http.post(
    `${this.API_URL}doctormembershipplan/updatedoctormembershipplan`,
    data
  );
}
addmembershipplan(data) {
  console.log("data====", data);
  return this.http.post(`${this.API_URL}membershipplan`, data);
}
getSearchmembershipplan(searchText) {
  let datanew = { searchText: searchText };
  console.log("data====");
  return this.http.post(
    `${this.API_URL}membershipplan/getSearchmembershipplan`,
    datanew
  );
}
getmembershipplan(data) {
  console.log("data====");
  return this.http.post(`${this.API_URL}membershipplan/list`, data);
}
getEditmembership(id): any {
  return this.http.post(`${this.API_URL}membershipplan/editmembershipplan`, {
    id: id,
  });
  
}
updatemembershipplan(data) {
  console.log("data====", data);
  return this.http.post(
    `${this.API_URL}membershipplan/updatemembershipplan`,
    data
  );
}
getBroker(company, role?): any {
  if (role) {
    return this.http.post(`${this.API_URL}users/getBroker`, {
      company: company,
      role: "b2b",
    });
  } else {
    return this.http.post(`${this.API_URL}users/getBroker`, {
      company: company,
    });
  }
}
addpatientproduct(data) {
  console.log("data====", data);
  return this.http.post(`${this.API_URL}patientproduct`, data);
}
getSearchpatientproduct(searchText) {
  let datanew = { searchText: searchText };
  console.log("data====");
  return this.http.post(
    `${this.API_URL}patientproduct/getSearchpatientproduct`,
    datanew
  );
}
getEditpatientproduct(id): any {
  return this.http.post(`${this.API_URL}patientproduct/editpatientproduct`, {
    id: id,
  });
  /*return this.http.post(`${this.API_URL}users/editUser`,id).subscribe((data:any) => {
      return data;
  }); */
}
updatepatientproduct(data) {
  console.log("data====", data);
  return this.http.post(
    `${this.API_URL}patientproduct/updatepatientproduct`,
    data
  );
}


//Product Partner Registration  
coursula_b2b_register(data) {
  console.log('data====', data);
  return this.http.post(`${this.API_URL}membershipplan/coursula_b2b_register`, data)
}

coursula_customer_register(data) {
  console.log('data====', data);
  return this.http.post(`${this.API_URL}patientproduct/coursula_customer_register`, data)
}

callApiMultipartnew(data): any {
  var headers = {};
  headers["Content-Type"] = "multipart/form-data";
  var token = sessionStorage.getItem("Token");
  if (token !== "") {
    headers["Authorization"] = token;
  }

  return this.http.post(`${this.API_URL}document/uploaddocumentnew`, data);
}

uploaddocumentwithoutticketno(data): Observable<any> {
  var headers = {};
  headers["Content-Type"] = "multipart/form-data";
  var token = localStorage.getItem("token");
  if (token !== "") {
    headers["Authorization"] = token;
  }
  return this.http
    .post(`${this.API_URL}document/uploaddocumentwithoutticketno`, data, {
      reportProgress: true,
      observe: "events",
    })
    .pipe(catchError(this.errorMgmt));
}
uploaddocumentnewinpreventioncourse(data): any {
  var headers = {};
  headers["Content-Type"] = "multipart/form-data";
  var token = sessionStorage.getItem("Token");
  if (token !== "") {
    headers["Authorization"] = token;
  }

  return this.http.post(`${this.API_URL}document/uploaddocumentnewinpreventioncourse`, data);
}




callApiMultipartnewwithoutlogininpreventioncourse(data): any {
  var headers = {};
  headers["Content-Type"] = "multipart/form-data";
  var token = sessionStorage.getItem("Token");
  if (token !== "") {
    headers["Authorization"] = token;
  }

  return this.http.post(`${this.API_URL}document/callApiMultipartnewwithoutlogininpreventioncourse`, data);
}


callApiMultipartnewwithoutlogininprevention(data): any {
  var headers = {};
  headers["Content-Type"] = "multipart/form-data";
  var token = sessionStorage.getItem("Token");
  if (token !== "") {
    headers["Authorization"] = token;
  }

  return this.http.post(`${this.API_URL}document/callApiMultipartnewwithoutlogininprevention`, data);
}






callApiMultipartnewwithoutlogin(data): any {
  var headers = {};
  headers["Content-Type"] = "multipart/form-data";
  var token = sessionStorage.getItem("Token");
  if (token !== "") {
    headers["Authorization"] = token;
  }

  return this.http.post(`${this.API_URL}document/uploaddocumentnewwithoutlogin`, data);
}

getexercisebypreventioncourse(data) {
  console.log('data====', data);
  return this.http.post(`${this.API_URL}patientproduct/getexercisebypreventioncourse`, data)
}
updatedocument_unique_id(data) {
  console.log('data====', data);
  return this.http.post(`${this.API_URL}patientproduct/updatedocument_unique_id`, data)
}

updatemembershipdocument_unique_id(data) {
  console.log('data====', data);
  return this.http.post(`${this.API_URL}membershipplan/updatedocument_unique_id`, data)
}


checkcoupencode(data) {
  console.log('data====', data);
  return this.http.post(`${this.API_URL}patientproduct/checkcoupencode`, data)
}




checkemail(data) {
  console.log('data====', data);
  return this.http.post(`${this.API_URL}membershipplan/checkemail`, data)
}

  getbrokerlist(data) {
    return this.http.post(`${this.API_URL}users/getBrokerbyzipcode`, data);
  }
  getbrokerlistwithoutlogin(data) {
    return this.http.post(`${this.API_URL}users/getBrokerbyzipcodewithoutlogin`, data);
  }




  getdoctorplan(data) {
    // this.http.post(this.API_URL+"membershipplan",``)
    return this.http.post(`${this.API_URL}doctormembershipplan/list`, data);
  }



  getpatientplan(data) {
    // this.http.post(this.API_URL+"membershipplan",``)
    return this.http.post(`${this.API_URL}patientproduct/list`, data);
  }



  getdoctorplanbynoofpatient(data)
  {
    return this.http.post(`${this.API_URL}doctormembershipplan/getdoctorplanbynoofpatient`, data);
  }
  getdoctorplanbynoofpatientandduration(data)
  {
    return this.http.post(`${this.API_URL}doctormembershipplan/getdoctorplanbynoofpatientandduration`, data);
  }
  


  getdoctorplandetails(data)
  {
    return this.http.post(`${this.API_URL}doctormembershipplan/getbyid`, data);
  }

  gettherapistplandetails(data)
  {
    return this.http.post(`${this.API_URL}membershipplan/getbyid`, data);
  }



  getpatientproductplandetails(data)
  {
    return this.http.post(`${this.API_URL}patientproduct/getbyid`, data);
  }


  getlastinvoice_no(data)
  {
    return this.http.post(`${this.API_URL}document/getlastinvoice_no`, data);
  }

  getLastdocument(data)
  {
    return this.http.post(`${this.API_URL}document/getLastdocument`, data);
  }
  getLastdocumentnew(): any {
    return this.http.post(`${this.API_URL}document/getLastdocument`, {});
    /*this.http.post(`${this.API_URL}users/getLastUser`,{}).subscribe((data:any) => {
        //console.log("getLLLAT",data);
        return data;
    }); */
  }

  patienttherapyplan(data)
  {
    return this.http.post(`${this.API_URL}starttreatment/patienttherapyplan`, data);
  }


  getUser(id){
    return this.http.post(`${this.API_URL}getUser`,{"id":id});
 
  }
  sendVerificationCode(phoneData){
    return this.http.post(`${this.API_URL}sendVerificationCode`,{"phoneNumber":phoneData.phoneNumber});
  }
  updatePassword(data):any{
    return this.http.post(`${this.API_URL}updatePassword`,{id:data.id,password:data.password}).subscribe((data:any) => {
        return data;
    }); 
    //return this.http.post(`${this.API_URL}updatePassword`,data);
    //return this.http.post(`${this.API_URL}updatePassword`,{ id:data.id, password:data.password });
  }
  login(username,password)
  {
    return this.http.post(`${this.API_URL}login/websitelogin`, {email:username,password:password});
  }

  apploginwithoutpass(username,password)
  {
    return this.http.post(`${this.API_URL}login/apploginwithoutpass`, {email:username,password:password});
  }



  getpurchasemembershipplan(data) {
    console.log("data====");
    return this.http.post(`${this.API_URL}membershipplan/getpurchasemembershipplan`, data);
  }
  gettherapyplan(data) {
    console.log("data====");
    return this.http.post(`${this.API_URL}therapyplan/list`, data);
  }
  gettherapyplannew(data) {
    console.log("data====");
    return this.http.post(`${this.API_URL}therapyplan/listnew`, data);
  }
  getEditexercise(id): any {
    return this.http.post(`${this.API_URL}exercise/editexercise`, { id: id });
  }
  start_treatment(data) {
    console.log("data====", data);
    return this.http.post(`${this.API_URL}starttreatment`, data);
  }
  gettreatementplannew(data) {
    console.log("data====");
    return this.http.post(`${this.API_URL}treatementplan/listnew`, data);
  }
  getexercisesequence(data) {
    console.log("data====");
    return this.http.post(`${this.API_URL}exercisesequence/list`, data);
  }
  getsearchtreatementinsequence(searchText) {
    let datanew = { searchText: searchText };
    console.log("data====");
    return this.http.post(
      `${this.API_URL}treatementplan/getSearchtreatementplan`,
      datanew
    );
  }
  getCustomers(company, role?): any {
    if (role) {
      return this.http.post(`${this.API_URL}users/getCustomers`, {
        company: company,
        role: "b2b",
      });
    } else {
      return this.http.post(`${this.API_URL}users/getCustomers`, {
        company: company,
      });
    }
  }
  getdoctorplanbynoofpatientnotequalduration(data)
  {
    return this.http.post(`${this.API_URL}doctormembershipplan/getdoctorplanbynoofpatientnotequalduration`, data);
  }
  upgradeplan(data) {
    console.log('data====', data);
    return this.http.post(`${this.API_URL}membershipplan/upgradeplan`, data)
  }
  getmembershipplanbynoofpatientnotequalduration(data)
  {
    return this.http.post(`${this.API_URL}membershipplan/getmembershipplanbynoofpatientnotequalduration`, data);
  }
  registerUsercoursula_user(data) {
    console.log("data====", data);
    return this.http.post(`${this.API_URL}users/coursula_user`, data);
  }
  getPassword() {
    console.log("in get password function");
    return this.http.get(`${this.API_URL}users/autopassword`);
  }
  
  
  checkLinkExpire(id){
    console.log(id);
    return this.http.post(`${this.API_URL}checkLinkExpire`,{"id":id});
  }

  checkLinkExpirenew(id) {
    return this.http.post(`${this.API_URL}checkLinkExpirenew`, { id: id });
  }


  getGeneratePassword()
  {
    return this.http.get(`${this.API_URL}generatePassword`)
  }
  getdecode(token:String)
  {
    try {
      console.log("dd"+token);
      let decodedToken = jwtDecode(token);
      console.log("ddjjjjjjjjj"+decodedToken);
      return decodedToken;
    } catch (Error) {
      console.log("ddjjjjjjjjj");
      return null;
    }
  }
  public isAuthenticated(): boolean {
    const token = sessionStorage.getItem('Token');
    return !this.jwthelper.isTokenExpired(token);
  }





  getUserRolesAndRouteArray() {
    console.log("DATA");
    let roles = [];
    if (sessionStorage.getItem("Token")) {
      let currentRoles = this.getDecodedAccessToken(
        sessionStorage.getItem("Token")
      ).roles;
      console.log(currentRoles);
      for (let i = 0; i < currentRoles.length; i++) {
        let role: any = {};
        // if (currentRoles[i] == "admin") {
        //   role.name = "Admin";
        //   role.english_name = "admin";
        //   role.route = "./admin-dashboard";
        //   roles.push(role);
        // } else
        
        if (currentRoles[i] == "customer") {
          role.name = "Patient";
          role.english_name = "customer";
          role.route = "./dashboard";
          roles.push(role);
        } else if (currentRoles[i] == "b2b") {
          role.name = "B2B";
          role.english_name = "b2b";
          role.route = "./b2b-dashboard";
          roles.push(role);
        } 
        
        // else if (currentRoles[i] == "employee") {
        //   role.name = "Mitarbeiter";
        //   role.english_name = "employee";
        //   role.route = "./mitarbeiter-home";
        // } 
        
        // else if (currentRoles[i] == "Superadmin") {
        //   role.name = "Superadmin";
        //   role.english_name = "superadmin";
        //   role.route = "./superadmin-home";
        // }
      
      }
    }
    console.log(roles);
    return roles;
  }

  updatestarttreatment(data) {
    console.log("data====", data);
    return this.http.post(`${this.API_URL}starttreatment/update`, data);
  }

  getLastdocument1(): any {
    return this.http.post(`${this.API_URL}document/getLastdocument`, {});
    /*this.http.post(`${this.API_URL}users/getLastUser`,{}).subscribe((data:any) => {
        //console.log("getLLLAT",data);
        return data;
    }); */
  }


  sendreferallink(data) {
    console.log("data====");
    return this.http.post(`${this.API_URL}patientproduct/sendreferallink`, data);
  }


  updateUser(data) {
    console.log(data);
    return this.http.post(`${this.API_URL}users/updateUser`, data);
  }




  registertherapyplan(data) {
    console.log("data====", data);
    return this.http.post(`${this.API_URL}therapyplan`, data);
  }
  getSearchtherapyplan(searchText) {
    let datanew = { searchText: searchText };
    console.log("data====");
    return this.http.post(
      `${this.API_URL}therapyplan/getSearchtherapyplan`,
      datanew
    );
  }
  gettherapyplanbyid(data) {
    console.log("data====");
    return this.http.post(`${this.API_URL}therapyplan/getbyid`, data);
  }
  updatetherapyplan(data) {
    console.log("data====", data);
    return this.http.post(`${this.API_URL}therapyplan/update`, data);
  }
  updateexercise(data) {
    console.log("data====", data);
    return this.http.post(`${this.API_URL}exercise/updateexercise`, data);
  }



  getexercisebytherapywithoutstatus(data) {
    console.log("data====");
    return this.http.post(
      `${this.API_URL}starttreatment/getexercisebytherapywithoutstatus`,
      data
    );
  }
  getquestionbystarttretmentid(data) {
    console.log("data====");
    return this.http.post(
      `${this.API_URL}starttreatment/getquestionbystarttretmentid`,
      data
    );
  }
  getquestionbystarttretmentidwithoutstatus(data) {
    console.log("data====");
    return this.http.post(
      `${this.API_URL}starttreatment/getquestionbystarttretmentidwithoutstatus`,
      data
    );
  }
  getstarttreatmentbypatientid1(data) {
    console.log("data====");
    return this.http.post(`${this.API_URL}starttreatment/getbypatientid1`, data);
  }

  registertreatementplan(data) {
    console.log("data====", data);
    return this.http.post(`${this.API_URL}treatementplan`, data);
  }
  gettreatementplan(data) {
    console.log("data====");
    return this.http.post(`${this.API_URL}treatementplan/list`, data);
  }
  getSearchtreatementplan(searchText) {
    let datanew = { searchText: searchText };
    console.log("data====");
    return this.http.post(
      `${this.API_URL}treatementplan/getSearchtreatementplan`,
      datanew
    );
  }
  gettreatementplanbyid(data) {
    console.log("data====");
    return this.http.post(`${this.API_URL}treatementplan/getbyid`, data);
  }
  updatetreatementplan(data) {
    console.log("data====", data);
    return this.http.post(`${this.API_URL}treatementplan/update`, data);
  }
}
