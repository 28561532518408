import { Component, OnInit ,Injectable} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserService } from '../user.service';

declare var window: any;
@Component({
  selector: 'app-therapist-list',
  templateUrl: './therapist-list.component.html',
  styleUrls: ['./therapist-list.component.css']
})
@Injectable()
export class TherapistListComponent implements OnInit {
  headername;
  searchvalue:any;
  api_url:any;
  b2blist:any=[];

  geocoder;

  lat:any;
  color:any="#2cbfc6";
iconurl:any;
  long:any;
  token = this.UserService.getdecode(sessionStorage.getItem('Token'));
  zoom=20;
  label:any;
  constructor( private route:ActivatedRoute,private UserService:UserService) { 
this.route.queryParams.subscribe((param)=>{
this.searchvalue=param['searchvalue'];
this.ngOnInit();

})




  }

  callback(result,name)
  {
this.lat=result.geometry.location.lat();
 this.long=result.geometry.location.lng();
 console.log("vvvvvvvvvvvvvvv"+name);
 this.label=name;
  }

  getLatitudeLongitude(strno,strassa,additionalReference,city,current_country,plz,title,firstname,lastname,logo) {
  let  that=this;
    // If adress is not supplied, use default value 'Ferrol, Galicia, Spain'
   let address = strno+" "+strassa+" "+additionalReference+" "+city+" "+current_country+" "+plz;
   let name=title+" "+firstname.charAt(0).toUpperCase() + firstname.slice(1)+" "+lastname.charAt(0).toUpperCase() + lastname.slice(1);
   
//    that.iconurl=  {
//     url: that.api_url+"document/downloaddocument/"+logo,
//     scaledSize: {
//       width: 50,
//       height: 50,
//       radius:10
//     }
//     // url: that.api_url+"document/downloaddocument/"+logo,
//     // scaledSize: {
//     //   width: "100px",
//     //   height: "100px"
//     // }
// }
   
   // Initialize the Geocoder
    that.geocoder = new google.maps.Geocoder();
    if (that.geocoder) {
      that.geocoder.geocode({
            'address': address
        }, function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                that.callback(results[0],name);
                console.log("ddddddddddddddd"+address);
                console.log("ddddddddddddddd"+results[0]);
// this.lat=results[0].geometry.location.lat();
// this.long=results[0].geometry.location.lng();
                console.log("ddddddddddddddd"+results[0].geometry.location.lat());
            }
        });
    }
}

  ngOnInit() {
    
    this.api_url=environment.API_URL
    if(this.searchvalue=='Your Therapist')
    {
    this.headername="Ihr zugewiesener Therapeut"
    }
    else{
      this.headername="Therapeuten suchen nach '"+this.searchvalue+"'"
    }
    let data={zipcode:this.searchvalue}
    $("#loaderouterid").css("display", "block");

    if(this.token!=null)
    {
this.UserService.getbrokerlist(data).subscribe((result)=>
{
this.b2blist=result['usersData'];
$("#loaderouterid").css("display", "none");
})
    }
    else{
      this.UserService.getbrokerlistwithoutlogin(data).subscribe((result)=>
      {
      this.b2blist=result['usersData'];
      $("#loaderouterid").css("display", "none");
      })  
    }


// $(window).ready(()=>{
//   let height:any = $(window).height();
//   let containerheight:any = $("#containerheight").height();
 
//   let newy = parseInt("206")+parseInt("117");
//   let newdata:any=height-newy;
//   console.log(newdata+" "+containerheight);
// if(parseFloat(containerheight)<parseFloat(newdata))
// {
 
//   $("#containerheight").css("height",newdata);
// }
// else{
//   $("#containerheight").css("height","100%");
// }
// })



  }

}
