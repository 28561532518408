import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { UserService } from '../user.service';
@Component({
  selector: 'app-exercisevideo',
  templateUrl: './exercisevideo.component.html',
  styleUrls: ['./exercisevideo.component.css']
})
export class ExercisevideoComponent implements OnInit {
  id: any;
  api_url;
  index: any;
  exerciselist;
  fiorettourl;
  exercise_id;
  type;
  exercisenewdata;
  questionindex;
  video;
  constructor(
    private route: ActivatedRoute,
    private UserService: UserService,
    private router: Router
  ) {
    this.route.queryParams.subscribe((param) => {
      this.id = param["id"];
      this.index = param["index"];
      this.exercise_id = param["exercise_id"];
      this.type = param["type"];
      this.questionindex=param['questionindex'];
    });
  }
  ngOnInit() {
    $("#loaderouterid").css("display", "block");
    this.fiorettourl=environment.fiorettourl
    let data = { starttreatment_id: this.id,exercise_id: this.exercise_id,index: this.index };
this.getvedio(data);


  }

getvedio(data)
{
  this.UserService.getvideobytherapy(data).subscribe((result: any) => {
    this.exerciselist = result;
    console.log("dddddddddddddddddd"+JSON.stringify(this.exerciselist))
    $("#loaderouterid").css("display", "none");
  });

}

stopVideo()
{ 
  
         this.video.pause();
         this.video.currentTime = 0;
    }
    gotonext()
    {
      // this.video = document.getElementById("myVideoPlayer");
      // this.stopVideo();
      $('#video1').trigger('pause');
      $('#video2').trigger('play');
    }
    playvideoback()
    {
      $('#video1').trigger('play');
      $('#video2').trigger('pause');
    }


  save_exercise()
  {
    $("#loaderouterid").css("display", "block");
    $('#video1').trigger('pause');
    $('#video2').trigger('pause');
    let data11 = {
      exercise_id: this.exercise_id,
      phase: this.exerciselist.phase,
      start_date: this.exerciselist.start_date,
      end_date: this.exerciselist.end_date,
      index: this.index,
      starttreatment_id:this.id
    };

    this.UserService.savevideo(data11).subscribe((success11:any)=>{

      // let data = { starttreatment_id: this.id,exercise_id: success11.nextid,index: parseInt(this.index)+1 };
      // this.getvedio(data);
      if(success11.questionindex=='0')
      {
        if(success11.nextid=='')
        {
        Swal.fire("Ihr Therapieplan-Vedio wird bereits per App angezeigt","","success")
        this.router.navigate(["/exercise_list"], {
          queryParams: {
            id: this.id,
            questionindex:success11.questionindex,
            },
        });
        }
else if(success11.nextid=='0')
{
Swal.fire("Ihr Therapieplan ist abgeschlossen","","success")
this.router.navigate(["/dashboard"]);
}
else if(success11.nextid=='1')
{

  Swal.fire("Deine Phase ist abgeschlossen","","success")
  this.router.navigate(["/exercise_list"], {
    queryParams: {
      id: this.id,
      questionindex:success11.questionindex,
      },
  });
}
else
    {
      this.navigateexercisevideo(success11.nextid,parseInt(this.index)+1,'new');
    }
  }
  else{
    this.router.navigate(["/questionlist"],  { queryParams: { id: this.id,questionindex:success11.questionindex } } );
  }
       $("#loaderouterid").css("display", "none");
      })


  }
  Navigateubungsliste() {
    
    this.router.navigate(["/exercise_list"],  { queryParams: { id: this.id,questionindex:this.questionindex } } );
  
  }
  navigateexercisevideo(exercise_id,index,type) {
    $("#loaderouterid").css("display", "block");


  //   this.router.routeReuseStrategy.shouldReuseRoute = function () {
  //     return false;
  // }
  // this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(["/exercisevideo"], {
      queryParams: {
        id: this.id,
        index:index,
        exercise_id: exercise_id,
        type:type,
        questionindex:this.questionindex,
      },
    });
$('#resetstepper').trigger('click');
let data = { starttreatment_id: this.id,exercise_id: this.exercise_id,index: index };
this.getvedio(data);
$('#video1').trigger('pause');
$('#video2').trigger('pause');
let video:any = document.getElementById('video2');
video.currentTime = 0;
let video1:any = document.getElementById('video1');
video1.currentTime = 0;
// location.href="/#/exercisevideo?id="+this.id+"&index="+index+"&exercise_id="+exercise_id+"&type="+type+"&questionindex="+this.questionindex+"";
  
// window.location.reload();
}
}
