import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { PlansComponent } from "./plans/plans.component";
import { PlansB2bComponent } from "./plans-b2b/plans-b2b.component";
import { PatientProductComponent } from "./patient-product/patient-product.component";

import { OnlineShopComponent } from "./online-shop/online-shop.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { RegisterUserComponent } from "./register-user/register-user.component";
import { AlreadyRegisteredComponent } from './already-registered/already-registered.component';
import { PurchaseB2bPlanComponent } from "./purchase-b2b-plan/purchase-b2b-plan.component";
import { TherapistListComponent } from "./therapist-list/therapist-list.component";
import { PurchaseDoctorPlanComponent } from "./purchase-doctor-plan/purchase-doctor-plan.component";
import { CookiePolicyComponent } from "./cookie-policy/cookie-policy.component";
import { TherapyPlanComponent } from './therapy-plan/therapy-plan.component';
import { AuthGaurdService } from "./auth-gaurd.service";
import {QuestionlistComponent } from "./questionlist/questionlist.component"
import { ToolslistComponent } from './toolslist/toolslist.component';
import { ExerciseListComponent } from './exercise-list/exercise-list.component';
import { ExercisevideoComponent } from './exercisevideo/exercisevideo.component';
import { PurchasePatientPreventionCourseComponent } from './purchase-patient-prevention-course/purchase-patient-prevention-course.component';
import { PreventionCourseComponent } from './prevention-course/prevention-course.component';
import { PreventionCourseExerciseListComponent } from './prevention-course-exercise-list/prevention-course-exercise-list.component';
import { MaindatenComponent } from './maindaten/maindaten.component';
import { PreventionexercisevideoComponent } from './preventionexercisevideo/preventionexercisevideo.component';
import { PreventioncoursetoolslistComponent } from './preventioncoursetoolslist/preventioncoursetoolslist.component';
import { MyProgressComponent } from './my-progress/my-progress.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';


import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { HowitworkComponent } from './howitwork/howitwork.component';


import { B2bDashboardComponent } from './b2b-dashboard/b2b-dashboard.component';
import { StartTreatmentComponent } from './start-treatment/start-treatment.component';
import { AddPatientComponent } from './add-patient/add-patient.component';
import { TherapyplanComponent } from './therapyplan/therapyplan.component';
import { PatientManagementComponent } from './patient-management/patient-management.component';
import { MyCompanyComponent } from './my-company/my-company.component';
import { AddTherapyPlanComponent } from './add-therapy-plan/add-therapy-plan.component';
import { TherapyPlanListComponent } from './therapy-plan-list/therapy-plan-list.component';

 import { TreatementplanComponent } from './treatementplan/treatementplan.component';
 import { PatientDashboardComponent } from './patient-dashboard/patient-dashboard.component';
 import { AddTreatmentPlanComponent } from './add-treatment-plan/add-treatment-plan.component';
import { TreatmentPlanListComponent } from './treatment-plan-list/treatment-plan-list.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { ExerciseComponent } from './exercise/exercise.component';
import { ExerciseSequenceComponent } from './exercise-sequence/exercise-sequence.component';
import { PreventionCoursesComponent } from './prevention-courses/prevention-courses.component';
import { OnlineShopAdminComponent } from './online-shop-admin/online-shop-admin.component';
import { AddExerciseComponent } from './add-exercise/add-exercise.component';
import { ExerciseListsComponent } from './exercise-lists/exercise-lists.component';
import { AddExerciseSequenceComponent } from './add-exercise-sequence/add-exercise-sequence.component';
import { ExerciseSequenceListComponent } from './exercise-sequence-list/exercise-sequence-list.component';
import { AddPreventionCourseComponent } from './add-prevention-course/add-prevention-course.component';
import { PreventionCourseListComponent } from './prevention-course-list/prevention-course-list.component';
import { DoctorMembershipPlanComponent } from './doctor-membership-plan/doctor-membership-plan.component';
import { TherapistProductComponent } from './therapist-product/therapist-product.component';
import { PatientProductPlanComponent } from './patient-product-plan/patient-product-plan.component';
import { AddDoctorMembershipplanComponent } from './add-doctor-membershipplan/add-doctor-membershipplan.component';
import { DoctorMembershipPlanManagementComponent } from './doctor-membership-plan-management/doctor-membership-plan-management.component';
import { AddTheraphistProductComponent } from './add-theraphist-product/add-theraphist-product.component';
import { TheraphistProductManagementComponent } from './theraphist-product-management/theraphist-product-management.component';
import { AddPatientProductComponent } from './add-patient-product/add-patient-product.component';
import { PatientProductManagementComponent } from './patient-product-management/patient-product-management.component';
const routes: Routes = [
  {
    path: "",
    component: LoginComponent,
  },
  {
    path: "add-therapy-plan",
    component: AddTherapyPlanComponent,
    canActivate:[AuthGaurdService]
  },
  {
    path: "therapy-plan-list",
    component: TherapyPlanListComponent,
    canActivate:[AuthGaurdService]
  },

  {
path:"therapy-plan",
component:TherapyPlanComponent,
canActivate:[AuthGaurdService]
  },
  {
    path:"admin-dashboard",
    component:AdminDashboardComponent,
    canActivate:[AuthGaurdService]
      },
  {
    path: "patient-dashboard",
    component:PatientDashboardComponent ,
    canActivate:[AuthGaurdService]
  },
  {
path:"treatementplan",
component:TreatementplanComponent,
canActivate:[AuthGaurdService]
  },
  {
    path:"add-treatment-plan",
    component:AddTreatmentPlanComponent,
    canActivate:[AuthGaurdService]
      },
      {
        path:"treatment-plan-list",
        component:TreatmentPlanListComponent,
        canActivate:[AuthGaurdService]
          },
  {
    path:"b2b-dashboard",
    component:B2bDashboardComponent,
    canActivate:[AuthGaurdService]
      },
      {
        path:"my-company",
        component:MyCompanyComponent,
        canActivate:[AuthGaurdService]
          },
      {
        path:"patient-management",
        component:PatientManagementComponent,
        canActivate:[AuthGaurdService]
          },
          // {
          //   path:"my-company",
          //   component:MyCompanyComponent,
          //   canActivate:[AuthGaurdService]
          //     },  
              {
                path:"start-treatment",
                component:StartTreatmentComponent,
                canActivate:[AuthGaurdService]
                  }, 
                {  
                path:"add-patient",
                component:AddPatientComponent,
                canActivate:[AuthGaurdService]
                  },  
                  {  
                    path:"therapyplan",
                    component:TherapyplanComponent,
                    canActivate:[AuthGaurdService]
                      },
  {
    path:"reset-password/:id",
    component:ResetPasswordComponent
  },
  {
    path:"preventioncoursetoolslist",
    component:PreventioncoursetoolslistComponent,
    canActivate:[AuthGaurdService]
      },
      {
        path:"my-progress",
        component:MyProgressComponent,
        canActivate:[AuthGaurdService]
          },
  {
    path:"maindaten",
    component:MaindatenComponent,
    canActivate:[AuthGaurdService]
      },
      {
        path:"preventionexercisevideo",
        component:PreventionexercisevideoComponent,
        canActivate:[AuthGaurdService]
          },
  {
    path:"prevention-course",
    component:PreventionCourseComponent,
    canActivate:[AuthGaurdService]
      },

      {
        path:"prevention-course-exercise-list",
        component:PreventionCourseExerciseListComponent,
        canActivate:[AuthGaurdService]
      },
  {
    path:"exercisevideo",
    component:ExercisevideoComponent,
    canActivate:[AuthGaurdService]
  },
  {
    path: "plans",
    component: PlansComponent,
  },
 {
    path: "howitswork",
    component: HowitworkComponent,
  },

  {
    path:"exercise_list",
    component:ExerciseListComponent,
    canActivate:[AuthGaurdService]
  },
  
  {
    path:"questionlist",
    component:QuestionlistComponent,
    canActivate:[AuthGaurdService]
  },
  {
    path: "register-user/:id",
    component: RegisterUserComponent,
  },
  {
    path: "plans-b2b",
    component: PlansB2bComponent,
  },
  {
path:"already-registered",
component:AlreadyRegisteredComponent,
  },
  {
    path: "data-policy",
    component: CookiePolicyComponent,
  },
  // {
  //   path: "privacy-policy",
  //   component: PrivacyPolicyComponent,
  // },
  
  {
path:"purchase-b2b-plan",
component:PurchaseB2bPlanComponent
  },
  {
    path:"purchase-patient-prevention-course",
    component:PurchasePatientPreventionCourseComponent
      },
    

  {
    path:"purchase-doctor-plan",
    component:PurchaseDoctorPlanComponent
      },
      
  {
path:"therapist-list",
component:TherapistListComponent
  },
  {
    path: "patient-product",
    component: PatientProductComponent,
  },

  {
    path: "online-shop",
    component: OnlineShopComponent,
  },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate:[AuthGaurdService]
  },
  {
    path: "toolslist",
    component: ToolslistComponent,
    canActivate:[AuthGaurdService]
  },
  {
    path: "exercise",
    component: ExerciseComponent,
    canActivate:[AuthGaurdService]
  },
  {
    path: "exercise-sequence",
    component: ExerciseSequenceComponent,
    canActivate:[AuthGaurdService]
  },
  {
    path: "prevention-courses",
    component: PreventionCoursesComponent,
    canActivate:[AuthGaurdService]
  },
  {
    path: "online-shop-admin",
    component: OnlineShopAdminComponent,
    canActivate:[AuthGaurdService]
  },
  {
    path: "add-exercise",
    component: AddExerciseComponent,
    canActivate:[AuthGaurdService]
  },
  {
    path: "exercise-lists",
    component: ExerciseListsComponent,
    canActivate:[AuthGaurdService]
  },
  {
    path: "add-exercise-sequence",
    component: AddExerciseSequenceComponent,
    canActivate:[AuthGaurdService]
  },
  {
    path: "exercise-sequence-list",
    component: ExerciseSequenceListComponent,
    canActivate:[AuthGaurdService]
  },
  {
    path: "add-prevention-course",
    component: AddPreventionCourseComponent,
    canActivate:[AuthGaurdService]
  },
  {
    path: "prevention-course-list",
    component: PreventionCourseListComponent,
    canActivate:[AuthGaurdService]
  },
  {
    path: "doctor-membership-plan",
    component: DoctorMembershipPlanComponent,
    canActivate:[AuthGaurdService]
  },
  {
    path: "theraphist-product",
    component: TherapistProductComponent,
    canActivate:[AuthGaurdService]
  },
  {
    path: "patient-product-plan",
    component: PatientProductPlanComponent,
    canActivate:[AuthGaurdService]
  },
  {
    path: "add-doctor-membershipplan",
    component: AddDoctorMembershipplanComponent,
    canActivate:[AuthGaurdService]
  },
  {
    path: "doctor-membership-plan-management",
    component: DoctorMembershipPlanManagementComponent,
    canActivate:[AuthGaurdService]
  },
  {
    path: "add-theraphist-product",
    component: AddTheraphistProductComponent,
    canActivate:[AuthGaurdService]
  },
  {
    path: "theraphist-product-management",
    component:TheraphistProductManagementComponent ,
    canActivate:[AuthGaurdService]
  },
  {
    path: "add-patient-product",
    component:AddPatientProductComponent ,
    canActivate:[AuthGaurdService]
  },
  {
    path: "patient-product-management",
    component:PatientProductManagementComponent ,
    canActivate:[AuthGaurdService]
  },
  {
    path: "**",
    redirectTo: "/",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true , scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
