import { Component, OnInit } from '@angular/core';
import {  FormGroup,FormBuilder, Validators, ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import { first, switchAll } from 'rxjs/operators';
import { UserService } from '../user.service';
import intlTelInput from "intl-tel-input";
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  // route: any;
verifyuserid;
checkLinkExpire;
userData;
data = [];
phoneData=[];
otpSuccess;
verificationData=[];
otpError
otp: any=false
registerPasswordDone: any=false
registerDone: any =false
  constructor(private formbuilder:FormBuilder,private userservice:UserService,private router:Router,private route:ActivatedRoute) { }
  registerForm:FormGroup;
  verifyForm:FormGroup;
  password: any;


  functionOnWhichRedirectShouldHappen() {
    console.log("ddddd");
    this.router.navigate(["/already-registered"]);
  }
  getUser(id) {
    const user = this.userservice.getUser(id);
    return user;
  }
  telInputObject(obj) {
    console.log(obj);
    obj.setCountry("de");
  }

  ngAfterViewInit() {
    const input = document.querySelector("#phone");
    console.log("querySelector" + input);
    intlTelInput(input, {
      //utilsScript: "build/js/utils.js",
    });
  }


  public verify() {
    this.phoneData["phoneNumber"] = this.verifyForm.value.phone_number;
    this.userservice.sendVerificationCode(this.phoneData).subscribe(
      (success: any) => {
        // if success and error give response
        if (success) {
          this.otp = true;
          sessionStorage.setItem("key", JSON.stringify(success));
          return true;
        } else {
          this.otp = false;
          return false;
        }
      },
      (rejected) => {
        console.log(rejected);
      }
    );
  }



  public verifyOtp() {
    this.otpSuccess = false;
    this.otpError = false;

    this.otp = this.verifyForm.value.otp;
    this.verificationData["otp"] = this.otp;
    this.verificationData["requestId"] = JSON.parse(
      sessionStorage.getItem("key")
    );
    this.verificationData["userId"] = this.verifyuserid;
    this.userservice.checkVerificationCode(this.verificationData).subscribe(
      (success: any) => {
        // if success and error give response
        if (success == 0) {
       
            this.otpSuccess = true;
$('#nextbuttonid').trigger("click");

            return true;
          
        } else {
          this.otpError = true;
          return true;
        }
      },
      (rejected) => {
        console.log(rejected);
      }
    );
  }
  
  redirect()
  {
    this.router.navigate(["./"]);
  }


  ngOnInit() {
    
    setTimeout(() => {
      const input = document.querySelector("#phone");
      console.log("querySelector" + JSON.stringify(input));
      intlTelInput(input, {});
    }, 1000);
    this.route.paramMap.subscribe((params) => {
      this.verifyuserid = params.get("id");
      this.checkLinkExpire = this.userservice.checkLinkExpirenew(this.verifyuserid);
      this.checkLinkExpire.subscribe((checkLink: any) => {
        console.log("checkLink", checkLink);
        if (checkLink.expired) {
          this.functionOnWhichRedirectShouldHappen();
        }
      });
      this.userData = this.getUser(this.verifyuserid);
      this.userData.subscribe((data: any) => {
if(!data)
{
  console.log("dddddddddddddddddddddddddddd");
  Swal.fire({
    title: "Ihr Konto wurde von superadmin gelöscht. Verwenden Sie diesen Link also nicht",
    showCloseButton: true,
    allowOutsideClick: false,
    icon: "error",
  }).then((result) => {
    console.log(result);
    if (result["isDismissed"]) {
      this.redirect();
    } else {
   
      this.redirect();
    }
  });
}
else{
  let contactno = data.contactno;
        this.registerForm.patchValue({ username: data.email });
        this.verifyForm.patchValue({ phone_number: contactno });
}







      
      });
    });




this.registerForm=this.formbuilder.group({
  username:["",Validators.required],
  password:["",  [
    Validators.required,
    Validators.minLength(10),
    patternValidator(/\d/, { hasNumber: true }),
    patternValidator(/[A-Z]/, { hasCapitalCase: true }),
    patternValidator(/[a-z]/, { hasSmallCase: true }),
    patternValidator(/[!@#$%^&*(),._?":{}|<>]/, {
      hasSpecialCharacters: true,
    }),
  ],],
  confirm_password:["",
    Validators.required,
  ]
},{
  validator: MustMatch("password", "confirm_password"),
})


this.verifyForm=this.formbuilder.group({
  phone_number:["",Validators.required],
  otp:["",Validators.required]
})

  }
  backToLogin() {
    //this.router.navigate(['/login']);
    this.userservice
      .login(this.registerForm.controls.username.value, this.registerForm.controls.password.value)
      .subscribe(
        (dataUSER: any) => {
        
          sessionStorage.setItem("Token",dataUSER.token);
          sessionStorage.setItem("currentUser", JSON.stringify(dataUSER.user));
          setTimeout(() => {
            $('#closemodal').trigger('click');
            let decodedData = this.userservice.getDecodedAccessToken(sessionStorage.getItem('Token'))
            // if (decodedData.roles && decodedData.roles.includes('Superadmin')) {
            //   sessionStorage.setItem('currentActiveRole', "superadmin");
            //   this.router.navigate(['./superadmin-home']);
            // }
            // else if (decodedData.roles && decodedData.roles.includes('admin')) {
            //   sessionStorage.setItem('currentActiveRole', "admin");
            //   this.router.navigate(['./admin-home']);
            // }
            // else if (decodedData.roles && decodedData.roles.includes('employee')) {
            //   sessionStorage.setItem('currentActiveRole', "employee");
            //   this.router.navigate(['./mitarbeiter-home']);
            // } else 
            if (decodedData.roles && decodedData.roles.includes('admin')) {
              sessionStorage.setItem('currentActiveRole', "admin");
              this.router.navigate(['./admin-dashboard']);
            }
            else  if (decodedData.roles && decodedData.roles.includes('b2b')) {
              sessionStorage.setItem('currentActiveRole', "b2b");
              this.router.navigate(['./b2b-dashboard']);
            }
            else {
              sessionStorage.setItem('currentActiveRole', "customer");
              this.router.navigate(['./dashboard']);
            }
            
            
          }, 100);
           
         
        },

        (error) => {
          Swal.fire("  Ungültiger Benutzername und oder Passwort","","error")
          this.router.navigate([""]);
        }
      );
  }
 
  public confirm() {
    console.log("submitted");
    // this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }
    this.password = this.registerForm.controls.password.value;
    this.data["id"] = this.verifyuserid;
    this.data["password"] = this.password;
    const update = this.userservice.updatePassword(this.data);
    console.log("update", update);
    this.registerPasswordDone = true;
    this.registerDone = true;
    //console.log("update",update);
  }
  public confirmNext() {
    // this.registerDone = true;
  }
  autoPassword()
  {
    console.log("in autopassword function");
    this.userservice
      .getGeneratePassword()
      .pipe(first())
      .subscribe(
        (data) => {
          this.password = data.toString();
          this.registerForm.patchValue({ password: this.password });
        },
        (error) => {
          console.error(error.message);
          console.log("Error", error["error"]);
        }
      );
  }

}





export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}




export function patternValidator(
  regex: RegExp,
  error: ValidationErrors
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control.value) {
      // if control is empty return no error
      return null;
    }

    // test the value of the control against the regexp supplied
    const valid = regex.test(control.value);

    // if true, return no error (no error), else return error passed in the second parameter
    return valid ? null : error;
  };
}


