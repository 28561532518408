import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { UserService } from '../user.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  @Input() searchvaluesend:any;
  constructor(
    private router: Router,
    private userservice: UserService,
    private formbuilder: FormBuilder
  ) {}
  captchaError: boolean;
  enableCaptcha= true;
  loginForm: FormGroup;
  forgotForm: FormGroup;
  showPassword: boolean = false;
  captcha = "";
  year;
  token= this.userservice.getdecode(sessionStorage.getItem('Token'));
  search;
fetchvalue
  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.captcha = captchaResponse;
  }

  grecaptcha: any;
  ngOnInit() {
this.year=new Date().getFullYear();

console.log("hhh"+this.searchvaluesend);
    this.loginForm = this.formbuilder.group({
      username: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });
    this.forgotForm = this.formbuilder.group({
      username: ["", [Validators.required, Validators.email]],
    });

    if(window){
      if(window.location.host.includes("localhost")){
          this.enableCaptcha = false
      }
  }
  }
  onSubmit(event)
  {
    console.log("ddddddd");
this.search=event.search
if(this.search!='')
{
  console.log("dddddddjjjj"+this.search);
  setTimeout(() => {
    $('#serarchclosemodalfooter').trigger('click');
    this.router.navigate(["./therapist-list"],{queryParams:{searchvalue:this.search}});
  }, 100);


}

  }

  login() {
    // const response = grecaptcha.getResponse();
    // console.log("ddddddddddd" + this.captcha);
    // if (this.captcha == "" || this.captcha == "null" ) {
    //   console.log("ddddddddddd" + this.captcha);
    //   this.captchaError = true;
    //   return;
    // }
    if (this.loginForm.invalid) {
      return false;
    }
    this.userservice
      .login(
        this.loginForm.controls.username.value,
        this.loginForm.controls.password.value
      )
      .subscribe(
        (dataUSER: any) => {
          console.log("dd"+dataUSER.token);
          sessionStorage.setItem("Token",dataUSER.token);
          sessionStorage.setItem("currentUser", JSON.stringify(dataUSER.user));
          setTimeout(() => {
            $('#closemodalfooter').trigger('click');
            let decodedData = this.userservice.getDecodedAccessToken(sessionStorage.getItem('Token'))
            // if (decodedData.roles && decodedData.roles.includes('Superadmin')) {
            //   sessionStorage.setItem('currentActiveRole', "superadmin");
            //   this.router.navigate(['./superadmin-home']);
            // }
            // else if (decodedData.roles && decodedData.roles.includes('admin')) {
            //   sessionStorage.setItem('currentActiveRole', "admin");
            //   this.router.navigate(['./admin-home']);
            // }
            // else if (decodedData.roles && decodedData.roles.includes('employee')) {
            //   sessionStorage.setItem('currentActiveRole', "employee");
            //   this.router.navigate(['./mitarbeiter-home']);
            // } else 
            if (decodedData.roles && decodedData.roles.includes('admin')) {
              sessionStorage.setItem('currentActiveRole', "admin");
              this.router.navigate(['./admin-dashboard']);
            }
            else
            if (decodedData.roles && decodedData.roles.includes('b2b')) {
              sessionStorage.setItem('currentActiveRole', "b2b");
              this.router.navigate(['./b2b-dashboard']);
            }
            else {
              sessionStorage.setItem('currentActiveRole', "customer");
              this.router.navigate(['./dashboard']);
            }
            
            
            // this.router.navigate(["./dashboard"]);
          }, 100);
            //  document.body.classList.remove("modal-open");
       
       
        },

        (error) => {
          $("#forget-btn").css("display","block");
          Swal.fire(error,"","error");
          $('#closemodalfooter').trigger('click');
          this.router.navigate(["./"]);
        }
      );
  }

  forgetpassword() {
    $("#passwordwindow").css("display","block");
    $("#loginwindow").css("display","none");
  }
    
  back() {
    $("#passwordwindow").css("display","none");
    $("#loginwindow").css("display","block");
  }
  
  forgot()
  {
    
    $("#loaderouterid").css("display", "block");
    if (this.forgotForm.controls.username.value) {
          this.userservice.sendLinkForgotPassword({"email":this.forgotForm.controls.username.value})
            .subscribe(data => {
              if (data['status'] == 200) {
                Swal.fire(data['msg'],"","success")
                setTimeout(() => {
                  $('#closemodalfooter').trigger('click');
                  $("#loaderouterid").css("display", "none");
                  this.router.navigate(["./dashboard"]);
                }, 100);
            
              }
              else{
                Swal.fire(data['msg'],"","error")
                $("#loaderouterid").css("display", "none");
              }
            }, error => {
            
              console.log("Error", error['error']);
              this.router.navigate(['/']);
              $("#loaderouterid").css("display", "none");
            });
        
  
    }
  }

  HideAndShow() {
    console.log("ravi");
    if (this.showPassword == false) {
      this.showPassword = true;
    } else {
      this.showPassword = false;
    }
  }
}
