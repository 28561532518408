import { Component, OnInit, AfterViewInit } from "@angular/core";
import Swal from "sweetalert2";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
  CheckboxControlValueAccessor,
} from "@angular/forms";
// import VideoConverter from 'convert-video'
import { UserService } from "../user.service";
import { first } from "rxjs/operators";
import { error } from "util";
import { Router } from "@angular/router";
// import { SearchCountryField, CountryISO } from "ngx-intl-tel-input";
import { ReturnStatement } from "@angular/compiler";
import intlTelInput from "intl-tel-input";
import { MatChipInputEvent } from "@angular/material/chips";
import { Observable, Observer } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ActivatedRoute, Params } from "@angular/router";
type unit = 'bytes' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB';
type unitPrecisionMap = {
  [u in unit]: number;
};

const defaultPrecisionMap: unitPrecisionMap = {
  bytes: 0,
  KB: 0,
  MB: 1,
  GB: 1,
  TB: 2,
  PB: 2
};

// import { CompanySelectDialogComponent } from "../company-select-dialog/company-select-dialog.component"
// import { MatDialog } from '@angular/material';
import * as $ from "jquery";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { HttpEvent, HttpEventType, HttpResponse } from "@angular/common/http";
export interface Fruit {
  name: string;
}
export interface Fruitnew {
  name: string;
}
@Component({
  selector: 'app-add-exercise',
  templateUrl: './add-exercise.component.html',
  styleUrls: ['./add-exercise.component.css']
})
export class AddExerciseComponent implements OnInit {
  loginRole = sessionStorage.getItem("currentActiveRole");
  title = this.userService.getDecodedAccessToken(sessionStorage.getItem("Token"))
    .title;
  loginid = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).id;
  lastname = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).lastname;
  firstname = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).firstname;
  customer_no = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).customerno;
  queryID:any;
  COMPANYNAME = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).companyname;
  routeParams: any;
  documentlist=[];
  allupload=false;
  personalInfoFormGroup: FormGroup;
  personalInfoFormGroup1:FormGroup;
  personalInfoFormGroup0:FormGroup;
  secondstep: FormGroup;
  registerDone = false;
  registraionError = false;
  registerbtn = true;
  countryName: any;
  imagecount:number;
  fiorettomediacustomer: any = [];
  uploadedFiles: Array<File> = [];
  Kundeoption: Observable<any>;
  Kundecontrol = new FormControl();
  kunde_id: any = "";
  projectdata: any;
  documentid: any = [];
  constructor(
    private _formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    
    private activatedRoute: ActivatedRoute // private dialog: MatDialog
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.queryID = params["name"];

      // this.statusCustomer();
      // this.userService.statusCustomer({ "id": this.pagedItems[0]._id })
    });
    // this.SparteFormGroup = this.fb.group({
    //   name: '',
    //   quantities: this.fb.array([]) ,
    // });
  }
  private readonly units: unit[] = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
  ticket_no = "";
  filearray: any[]= [];
  filearraynew: any[] = [];
  l: number = 0;
  items = ["Javascript", "Typescript"];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  objectjsonnew: any;
  fruits: Fruit[] = [];
  lengthfile: number = 0;
  uploadlength: number = 0;
  typesOptionsArray: string[] = [];
  selecteditem:string[]=[];
  progressInfos = [];
  // message = '';
  progress = [];
  fileInfos: Observable<any>;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  itemList: string[]=[
    'Matte', 
    'Bett', 
    'Spiegel', 
    'Strohhalm', 
    'Eiswürfel', 
    'Hocker',  
    'Widerstandsband', 
    'Pezziball', 
    'Stab', 
'Kettlebell',  
'Faszienrolle', 
'Seil', 
'Hantel (klein)', 
'Handtuch', 
'Tape', 
'Tennisball', 
'Murmeln', 
'Zopfgummi', 
'Tisch', 
'Knete',
  ]
  selecteditemList = this.itemList;
  onKey(value) { 
    this.selecteditemList = this.search(value);
    }
    search(value: string) { 
      let filter = value.toLowerCase();
      return this.itemList.filter(option => option.toLowerCase().startsWith(filter));
    }

    getdatanew(value)
    {
      this.selecteditem.push(value);
console.log("asdsaddsadasdA"+value);
$('#inputtools').val('');
this.onKey('');
    }
  addOpt(event: MatChipInputEvent, index: number): void {
    const input = event.input;
    const value = event.value;
    console.log("dsdsad" + index);
    console.log("input" + input);
    console.log("value" + value);
    console.log("valuenew" + value.trim());
    console.log("valuenew" + this.typesOptionsArray);
    // Add our fruit
    if(this.typesOptionsArray.length>19)
    {
      Swal.fire(
        "Begrenzen Sie 20 Tags",
        "",
        "error"
      );
    }
    else{
      if ((value || "").trim()) {
        this.typesOptionsArray.push(value.trim());
      }
      // Reset the input value
      if (input) {
        input.value = "";
      }
let tagscount:any=20-this.typesOptionsArray.length;
$('#manual_tagscount').html(tagscount)

    }
   
  }

  removeOpt(opt: string, index: number): void {
    const optIndex = this.typesOptionsArray.indexOf(opt);
    if (optIndex >= 0) {
      this.typesOptionsArray.splice(optIndex, 1);
    }
  }

  telInputObject(obj) {
    console.log(obj);
    obj.setCountry("de");
  }

  logout() {
    console.log("in logout function");

    localStorage.removeItem("token");
    this.router.navigate(["./"]);
  }
  choosecategory(value)
  {
    this.personalInfoFormGroup0.patchValue({
      'exercise_category':value
    });

$('#exercise_category').val(value);
if(value=='Therapie')
{
$('#Therapie').css('background-image','linear-gradient(#555a5a, #555a5a) ');
$('#Prävention').css('background-image','linear-gradient(#11878d, #2dbfc6) ');
}
else{
  $('#Prävention').css('background-image','linear-gradient(#555a5a, #555a5a) ');
  $('#Therapie').css('background-image','linear-gradient(#11878d, #2dbfc6) ');
}
  }
  ngOnInit() {
    this.progress[0] = 0;
    this.progress[1] = 0;
    this.progress[2] = 0;
    this.documentid.push('','','');
    this.documentlist.push('','','');
    this.loginRole = this.loginRole;
    this.routeParams = this.activatedRoute.snapshot.routeConfig.path;

    this.personalInfoFormGroup = this._formBuilder.group({
      exercise_name: ["", Validators.required],
      exercise_name1: ["", Validators.required],
      comment1: [""],
      comment: ["", Validators.required],
      manual_tags: ["", Validators.required],
      tools:[""],
      difficully_level:["", Validators.required]
   
      
    });
this.personalInfoFormGroup1=this._formBuilder.group({
 
 
  upload_file: ["", Validators.required],
  explain: ["", Validators.required],
  implementation: ["", Validators.required],
  allupload:["",Validators.required]
});
this.personalInfoFormGroup0=this._formBuilder.group({
 
  exercise_category:["", Validators.required]
 
  
});
    this.typesOptionsArray.push();
  }
  navigateWithexcersicename() {
    this.router.navigate(["/exercise_name"], {
      queryParams: { name: "prevention_course" },
    });
    // this.queryID = this.selectedUser.customerno;
    // this.ngOnInit()
  }
  add_exercise() {
    this.router.navigate(["/add_exercise"], {
      queryParams: { name: this.queryID },
    });
    // this.queryID = this.selectedUser.customerno;
    // this.ngOnInit()
  }
  navigateWithexcersicename1() {
    this.router.navigate(["/exercise_name"], {
      queryParams: { name: "therapeuts_praxen" },
    });
  }
  patchkundeValue() {
    this.kunde_id = this.Kundecontrol.value.id;
    this.Kundecontrol.setValue(this.Kundecontrol.value.value);

    console.log("kundenew" + this.kunde_id);
  }
  removeData(j) {
    delete this.filearraynew[j];
    this.filearray[j] = '';
    $("#logopreview"+j).css('display',"block");
    $("#removepreview"+j).css('display',"none");
    $("#videoSourceWrapper"+j).css('display',"none");
    $('#progressbar'+j).css("display","none");
    $("#previewdetails"+j).html('');
    this.allupload=false;
    this.personalInfoFormGroup.patchValue({
      allupload:""
     });
     this.progress[j] = 0;
    //  this.userService.deleted__documents({"filename":this.documentid[j]}).subscribe((result:any)=>{

    //  })
  }

  async handleImageChange(event: any, index) {
    this.personalInfoFormGroup.patchValue({
      allupload:""
     }); 
    this.progress[index] = 0;
    $("#result").html("");
    event.preventDefault();
    var files = event.target.files; //FileList object
    var filesLength = files.length;

    for (let i = 0; i < filesLength; i++) {
      let f = files[i];
      let Size1 = f.size;
let Size = this.dataconvert(Size1);
      var fileReader = new FileReader();
     
      if(index==1)
      {
        let targetVideoFormat = 'mp4'
      //   let newfile = await VideoConverter.convert(f, targetVideoFormat);
      // const data = await fetch(newfile.data);
      // const blob = await data.blob();
      fileReader.readAsDataURL(f); 
      this.filearraynew[index]=f;
      this.filearray[index] = this.filearraynew[index];
      }
      else if(index==2)
      {
        let targetVideoFormat = 'mp4'
      //   let newfile = await VideoConverter.convert(f, targetVideoFormat);
      // const data = await fetch(newfile.data);
      // const blob = await data.blob();
      fileReader.readAsDataURL(f); 
      this.filearraynew[index]=f;
      this.filearray[index] = this.filearraynew[index];
      }
      else
      {
        fileReader.readAsDataURL(f);
        this.filearraynew[index]=f;
        this.filearray[index] = this.filearraynew[index];
      }

      //var target:EventTarget;
      fileReader.onload = function (e) {
        let  ImageName = (e.target as any).result;
          let typeofimage = f.type;
          let dateofdocument = f.lastModified;
            var date1 = new Date(dateofdocument).toLocaleDateString("en-IN", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric"
            });

        
           
              $("#videoSourceWrapper"+index).attr("src",URL.createObjectURL(files[i]));
           
            $("#videoSourceWrapper"+index).css('display',"block");
            $("#removepreview"+index).css('display',"block");
            var date = date1.replace(/[/]/g, ".");

                  $("#logopreview"+index).css('display',"none");
                  let previewdetails="<div> <b class='limitword' title='"+f.name+"'>Dokumentenname: " +
                  f.name +
                  "</b> </div><div> <b > Dateigröße: " +
                  Size +
                  "</b>  </div><div> <b>Dateityp: " +
                  typeofimage +
                  "</b> </div><div> <b>Datum des Dokuments: " +
                  date +
                  "</b> </div>";
          $("#previewdetails"+index).html(previewdetails);
      };



$('#progressbar'+index).css("display","flex");
this.progress[index] = 0;

        const formData = new FormData();
        formData.append("document",this.filearray[index]);
        this.userService.uploaddocumentwithoutticketno(
          formData
        ).subscribe((event: HttpEvent<any>) => {
          switch (event.type) {
    
            case HttpEventType.Sent:
              console.log('Request has been made!');
              
              break;
            case HttpEventType.ResponseHeader:
              console.log('Response header has been received!');
              break;
            case HttpEventType.UploadProgress:
              console.log(event.total);
              console.log(event.loaded);
             
              this.progress[index] = Math.round(event.loaded / event.total * 100);
             
              console.log(`Uploaded! ${this.progress}%`);
              break;
            case HttpEventType.Response:
              console.log('User successfully created!', event.body);
              
              setTimeout(() => {
            
                $('#progressbar'+index).css("display","none");
              this.documentid[index]=event.body.document_unique_id;
              let StringTypeCasting:any = Math.round(
                this.filearray[index].size / 1024
              );
              let typeofimage = this.filearray[index].type;
              let dateofdocument = this.filearray[index].lastModified;
let tags={
  StringTypeCasting,
  typeofimage,
  dateofdocument
}
let document_name='';
if(index==0)
{
  document_name = "Übungsbild";
}
else if(index==1)
{
document_name = "Erklärvideo";
}
else{
document_name = "Umsetzungsvideo";
}
              this.documentlist[index]={
                document_unique_id:event.body.document_unique_id,
                document_type:  "Allgemeines Dokument",
                document_sub_type: " ",
                user_id: this.loginid,
                companycode: "42160 Jomiretto GmbH",
                brand: "Coursula",
                tags: [tags],
                upload_by: "exercise",
             
                document_name:document_name,
                created_by:this.loginid
              };
              
           if(this.documentid[1]!='' && this.documentid[0]!=''  && this.documentid[2]!='')
           {
            this.personalInfoFormGroup.patchValue({
              allupload:"0,00"
             });
            
            this.allupload=true;
           }

              }, 1500);
    
          }
        })






      }
  
    }



  dataconvert(bytes: number = 0, precision: number | unitPrecisionMap = defaultPrecisionMap): string {
  
      if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) return '?';
  
      let unitIndex = 0;
  
      while (bytes >= 1024) {
        bytes /= 1024;
        unitIndex++;
      }
  
      const unit = this.units[unitIndex];
  
      if (typeof precision === 'number') {
        return `${bytes.toFixed(+precision)} ${unit}`;
      }
      return `${bytes.toFixed(precision[unit])} ${unit}`;
    }
  
 


 


countimage()
{
  this.imagecount=0;
  if(this.filearray.length>0)
  {
 for(let i=0;i<this.filearray.length;i++)
 {
  console.log("countimage"+JSON.stringify(this.filearray[i]));
   if(this.filearray[i]!='')
   {
  this.imagecount= this.imagecount+1;
   }
 }
  }
  console.log("countimage"+this.imagecount);
}


  





  public imagePath;
  public pdfPath;
  imgURL: any = [];
  public message: string;
  imgshow = "";

  get personalInfoForm() {
    return this.personalInfoFormGroup.controls;
  }
  get personalInfoForm1() {
    return this.personalInfoFormGroup1.controls;
  }
  get personalInfoForm0() {
    return this.personalInfoFormGroup0.controls;
  }
  

  calcount(id,type)
  {
    let commentcount:any;
    if(type=='comment')
    {
  let comment=  this.personalInfoForm.comment.value.length;

 commentcount=500-comment;
    }
    if(type=='comment1')
    {
  let comment=  this.personalInfoForm.comment1.value.length;

 commentcount=500-comment;
    }
    if(type=='exercise_name')
    {
  let comment=  this.personalInfoForm.exercise_name.value.length;

 commentcount=35-comment;
    }
    if(type=='exercise_name1')
    {
  let comment=  this.personalInfoForm.exercise_name1.value.length;

 commentcount=65-comment;
    }
$('#'+id).html(commentcount);
  }


  




 

  addproject() {
    if (this.documentlist.length == this.documentid.length) {
      $("#loaderouterid").css("display", "block");
      let   companycodenew = "60-co-";
  
      let product_type_new = [];
      for (var i = 0; i < this.typesOptionsArray.length; i++) {
        product_type_new[i] = this.typesOptionsArray[i];
      }
let toolsnew;
if(this.personalInfoForm.tools.value=='')
{
  toolsnew=[];
 
}
else{
  toolsnew=this.personalInfoForm.tools.value
}


      let data = {
        comment: this.personalInfoForm.comment.value,
        comment1: this.personalInfoForm.comment1.value,
        exercise_name: this.personalInfoForm.exercise_name.value,
        exercise_name1: this.personalInfoForm.exercise_name1.value,
        difficully_level: this.personalInfoForm.difficully_level.value,
        tools:toolsnew,
        exercise_category:this.personalInfoForm0.exercise_category.value,
        tags: product_type_new,
        document: this.documentid,
        documentlist: this.documentlist,
        created_by: this.loginid,
        exercise_id: companycodenew,
      };
      console.log(data);

      this.userService
        .registerexercise(data)
        .pipe(first())
        .subscribe(
          (data) => {
            Swal.fire(
              "Übung erfolgreich hinzugefügt. Ihre Übung-ID " +
              data['exercise_id'] +
                "",
              "",
              "success"
            );
            this.ticket_no = "";

            $("#loaderouterid").css("display", "none");
            setTimeout(() => {
              Swal.close();
              this.router.navigate(["./exercise-lists"]);
            }, 1000);
          },
          (error) => {
            this.registraionError = true;
            this.registerDone = false;
            console.log("Error", error["error"]);
            $("#loaderouterid").css("display", "none");
            setTimeout(() => {
              Swal.close();
              this.add_exercise();
            }, 1000);
            // $("html, body").animate(
            //   { scrollTop: "0" }, 3000);
          }
        );
    }
  }
}

