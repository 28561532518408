import { Component, OnInit, AfterViewInit } from "@angular/core";
import {CdkDragDrop, moveItemInArray,copyArrayItem,transferArrayItem} from '@angular/cdk/drag-drop';
import Swal from "sweetalert2";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
  CheckboxControlValueAccessor,
} from "@angular/forms";
import { UserService } from "../user.service";
import { concatMapTo, count, first } from "rxjs/operators";
import { error } from "util";
import { Router } from "@angular/router";
// import { SearchCountryField, CountryISO } from "ngx-intl-tel-input";
import { ReturnStatement } from "@angular/compiler";
import intlTelInput from "intl-tel-input";
import { MatChipInputEvent } from "@angular/material/chips";
import { Observable, Observer } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ActivatedRoute, Params } from "@angular/router";
// import { CompanySelectDialogComponent } from "../company-select-dialog/company-select-dialog.component"
// import { MatDialog } from '@angular/material';
import * as $ from "jquery";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
// import { randomBytes } from "crypto";
export interface Fruit {
  name: string;
}
export interface Fruitnew {
  name: string;
}
@Component({
  selector: 'app-add-treatment-plan',
  templateUrl: './add-treatment-plan.component.html',
  styleUrls: ['./add-treatment-plan.component.css']
})
export class AddTreatmentPlanComponent implements OnInit {

  loginRole = sessionStorage.getItem("currentActiveRole");
  title = this.userService.getDecodedAccessToken(sessionStorage.getItem("Token"))
    .title;
  loginid = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).id;
  lastname = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).lastname;
  firstname = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).firstname;
  customer_no = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).customerno;
  queryID: any;
  checkeye='open'
  COMPANYNAME = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).companyname;
  routeParams: any;
  searchby='';
  personalInfoFormGroup: FormGroup;
  personalInfoFormGroupadd: FormGroup;
  minDate:Date;
  minDateforend:Date;
  maxDate11:Date;
  totalexercise:number=1;
  personalInfoFormGroup0: FormGroup;

  secondstep: FormGroup;
  registerDone = false;
  registraionError = false;
  registerbtn = true;
  countryName: any;
  fiorettomediacustomer: any = [];
  uploadedFiles: Array<File> = [];
  Kundeoption: Observable<any>;
  Kundecontrol = new FormControl();
  kunde_id: any = "";
  projectdata: any;
  exercisesequencelist: any[][] = [];
  phase: any = [];
  exercisesequencelistsearch:any =[];
  oldtypesOptionsArray: any[] = [];
  exerciseoption: Observable<any[]>
  searchlist=[];
  documentid: any[][] = [];
  constructor(
    private _formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    
    private activatedRoute: ActivatedRoute // private dialog: MatDialog



    
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.queryID = params["name"];

      // this.statusCustomer();
      // this.userService.statusCustomer({ "id": this.pagedItems[0]._id })
    });
    // this.SparteFormGroup = this.fb.group({
    //   name: '',
    //   quantities: this.fb.array([]) ,
    // });
  }
  ticket_no = "";
  filearray: any[] = [];
  filearraynew: any[] = [];
  l: number = 0;
  items = ["Javascript", "Typescript"];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  objectjsonnew: any;
  fruits: Fruit[] = [];
  lengthfile: number = 0;
  uploadlength: number = 0;
  startdate:any;
  exercisedata: any = [];
  typesOptionsArray: string[] = [];
  selecteditem: string[] = [];
  exercise_controls = new FormControl();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  itemList: string[] = [
    "Matte",
    "Bett",
    "Spiegel",
    "Strohhalm",
    "Eiswürfel",
    "Hocker",
    "Widerstandsband",
    "Pezziball",
    "Stab",
    "Kettlebell",
    "Faszienrolle",
    "Seil",
    "Hantel (klein)",
    "Handtuch",
    "Tape",
    "Tennisball",
    "Murmeln",
    "Zopfgummi",
    "Tisch",
    "Knete",
  ];
  selecteditemList = this.itemList;
  onKey(value) {
    this.selecteditemList = this.search(value);
  }
  search(value: string) {
    let filter = value.toLowerCase();
    return this.itemList.filter((option) =>
      option.toLowerCase().startsWith(filter)
    );
  }

  quantities(): FormArray {
    return this.personalInfoFormGroup.get("secondstep") as FormArray;
  }


  newQuantity(): FormGroup {
    return this._formBuilder.group({
      duration: ["", [Validators.required,Validators.pattern('^[0-9]*$')]],
    });
  }
  addmorefield() {
    this.quantities().push(this.newQuantity());
    this.exercisesequencelist.push([]);
  }
  removeQuantity(i: number, index: any) {
    this.quantities().removeAt(i);
    this.phase.splice(index,1);
    this.exercisesequencelist[index].splice(0,this.exercisesequencelist[index].length);
    // this.uploadedFiles.splice(index, 1);
    // this.imgURL.splice(index, 1);
  }
  getexercisenew() {
    // this.getexercise(
    //   this.exercise_controls.value.id,
    //   this.exercise_controls.value.name,
    //   this.exercise_controls.value.img,
    //   this.exercise_controls.value.diff
    // );

    if (this.typesOptionsArray.length > 19) {
      Swal.fire("Begrenzen Sie 20 Tags", "", "error");
    } else {
      console.log("ffffffffffff" + JSON.stringify(this.searchlist));
      if ((this.exercise_controls.value.name || "").trim()) {
        this.typesOptionsArray.push(this.exercise_controls.value.name);
        this.setsearchlist();
        this.exercise_controls.setValue(" ");
        $("#datanew").trigger("click");
      }
      // Reset the input value
     
    }


  }
  getdatanew(value) {
    this.selecteditem.push(value);
    console.log("asdsaddsadasdA" + value);
    $("#inputtools").val("");
    this.onKey("");
  }
  addOpt(event: MatChipInputEvent, index: number): void {
    const input = event.input;
    const value = event.value;
    console.log("dsdsad" + index);
    console.log("input" + input);
    console.log("value" + value);
    console.log("valuenew" + value.trim());
    console.log("valuenew" + this.typesOptionsArray);
    // Add our fruit
    if (this.typesOptionsArray.length > 19) {
      Swal.fire("Begrenzen Sie 20 Tags", "", "error");
    } else {
      console.log("ffffffffffff"+JSON.stringify(this.searchlist) );
      if ((value || "").trim()) {
        this.typesOptionsArray.push(value.trim());
        this.setsearchlist();
       this.exercise_controls.setValue(' ');
       $('#datanew').trigger('click');
      }
      // Reset the input value
      if (input) {
        input.value = "";
      }


    }

    
  }

  checkphase()
  {
    if(this.phase.length>0)
    {

if(this.phase.length-1==this.personalInfoForm.secondstep.value.length)
{
  return 1;
}
else{
  return 0;
}

    }
    else{
      return 0;
    }
  }


//   getstartdate(event)
//   {
//  console.log("startdate111"+this.personalInfoFormGroup.controls.start_date.value);
//  this.minDateforend=this.personalInfoFormGroup.controls.start_date.value;
//     let numWeeks = 12;
// let now = new Date(this.personalInfoFormGroup.controls.start_date.value);
// now.setDate(now.getDate() + numWeeks * 7);
// this.maxDate11=now;

//   }


exerciselength11(index) {
  let acc = 0;
  for (let i = 0; i < index; i++) {
    acc += this.exercisesequencelistsearch[i].length;
  }
  return acc;
}


drop(event: CdkDragDrop<any[]>) {
  let lengthtotal = this.exerciselength(this.exercisesequencelist.length) - 1;
  let indexnewcurrent: any = lengthtotal - event.currentIndex;
  let indexnewprevious: any = lengthtotal - event.previousIndex;

if (event.previousContainer === event.container) {
let key1;
let count = 0;
$.each(this.exercisesequencelist, function (key, element) {
  let coutexercise = element.length;
  count = count + coutexercise;
  if (indexnewcurrent < count) {
    key1 = key;
    return false;
  }
});

let key1previous;
let countprevious = 0;
$.each(
  this.exercisesequencelist,
  function (keyprevious, elementprevious) {
    let coutexerciseprevious = elementprevious.length;
    countprevious = countprevious + coutexerciseprevious;
    if (indexnewprevious < countprevious) {
      key1previous = keyprevious;
      return false;
    }
  }
);

if (this.exercisesequencelist[key1previous].length > 1) {
  console.log("key1previous" + key1previous);
  console.log("key1previous" + key1);
  if (key1previous == key1) {
    let totalpreviouscount = 0;
    if (key1 >= 0) {
      for (let k = 0; k < key1; k++) {
        totalpreviouscount =
          totalpreviouscount + this.exercisesequencelist[k].length;
      }
    }


    let movedata = {
      id: event.previousContainer.data[key1][
        indexnewprevious - totalpreviouscount
      ].id,
      value:
        event.previousContainer.data[key1][
          indexnewprevious - totalpreviouscount
        ].value,
      img: event.previousContainer.data[key1][
        indexnewprevious - totalpreviouscount
      ].img,
      diff: event.previousContainer.data[key1][
        indexnewprevious - totalpreviouscount
      ].diff,
      tags: event.previousContainer.data[key1][
        indexnewprevious - totalpreviouscount
      ].tags,
    };

    if (
      indexnewcurrent - totalpreviouscount >
      indexnewprevious - totalpreviouscount
    ) {
      for (
        let i = (indexnewprevious - totalpreviouscount) + 1;
        i <= indexnewcurrent - totalpreviouscount;
        i++
      ) {
        let movedata1 = {
          id: event.previousContainer.data[key1][i].id,
          value: event.previousContainer.data[key1][i].value,
          img: event.previousContainer.data[key1][i].img,
          diff: event.previousContainer.data[key1][i].diff,
          tags: event.previousContainer.data[key1][i].tags,
        };

        this.exercisesequencelist[key1][i - 1] = movedata1;
      }

      this.exercisesequencelist[key1][
        (indexnewcurrent - totalpreviouscount)
      ] = movedata;
    } else {
      for (
        let i = (indexnewprevious - totalpreviouscount) - 1;
        i >= indexnewcurrent - totalpreviouscount;
        i--
      ) {
        let movedata1 = {
          id: event.previousContainer.data[key1][i].id,
          value: event.previousContainer.data[key1][i].value,
          img: event.previousContainer.data[key1][i].img,
          diff: event.previousContainer.data[key1][i].diff,
          tags: event.previousContainer.data[key1][i].tags,
        };

        this.exercisesequencelist[key1][i + 1] = movedata1;
      }

      this.exercisesequencelist[key1][
        (indexnewcurrent - totalpreviouscount)
      ] = movedata;
    }
  } else {
    let totalpreviouscountnewprevious = 0;
    if (key1previous > 0) {
      for (let k = 0; k < key1previous; k++) {
        totalpreviouscountnewprevious =
          totalpreviouscountnewprevious +
          this.exercisesequencelist[k].length;
      }
    }
    console.log(
      "totalpreviouscountnewprevious" +
      totalpreviouscountnewprevious +
        "previousIndex" +
        event.previousIndex +
        "indexnewprevious" +
        indexnewprevious +
        "currentIndex" +
        event.currentIndex +
        "indexnewcurrent" +
        indexnewcurrent
    );

    let movedata = {
      id: event.previousContainer.data[key1previous][
        indexnewprevious - totalpreviouscountnewprevious
      ].id,
      value:
        event.previousContainer.data[key1previous][
          indexnewprevious - totalpreviouscountnewprevious
        ].value,
      img: event.previousContainer.data[key1previous][
        indexnewprevious - totalpreviouscountnewprevious
      ].img,
      diff: event.previousContainer.data[key1previous][
        indexnewprevious - totalpreviouscountnewprevious
      ].diff,
      tags: event.previousContainer.data[key1previous][
        (indexnewprevious - totalpreviouscountnewprevious)
      ].tags,
    };

    if (
      (indexnewprevious - totalpreviouscountnewprevious) + 1 ==
      this.exercisesequencelist[key1previous].length
    ) {
      this.exercisesequencelist[key1previous].splice(
        indexnewprevious - totalpreviouscountnewprevious,
        1
      );
    } else {
      for (
        let i = indexnewprevious - totalpreviouscountnewprevious + 1;
        i < this.exercisesequencelist[key1previous].length;
        i++
      ) {
        let movedata1 = {
          id: event.previousContainer.data[key1previous][i].id,
          value: event.previousContainer.data[key1previous][i].value,
          img: event.previousContainer.data[key1previous][i].img,
          diff: event.previousContainer.data[key1previous][i].diff,
          tags: event.previousContainer.data[key1previous][i].tags,
        };

        this.exercisesequencelist[key1previous][i - 1] = movedata1;
      }
      this.exercisesequencelist[key1previous].splice(
        this.exercisesequencelist[key1previous].length - 1,
        1
      );
    }

    let totalpreviouscount = 0;
    if (key1 > 0) {
      for (let k = 0; k < key1; k++) {
        totalpreviouscount =
          totalpreviouscount + this.exercisesequencelist[k].length;
      }
    }
    console.log(
      "totalpreviouscount" +
      totalpreviouscount 
        
    );
    let lastarraystartindex;
    if (indexnewcurrent > (totalpreviouscount)) {
      lastarraystartindex = indexnewcurrent - (totalpreviouscount);
    } else {
      lastarraystartindex = totalpreviouscount - indexnewcurrent;
    }
 
    let currentarraylength = this.exercisesequencelist[key1].length;

    if (lastarraystartindex <= currentarraylength - 1) {
     
      for (
        let m = currentarraylength - 1;
        m >= lastarraystartindex;
        m--
      ) {
        let movedata1 = {
          id: event.previousContainer.data[key1][m].id,
          value: event.previousContainer.data[key1][m].value,
          img: event.previousContainer.data[key1][m].img,
          diff: event.previousContainer.data[key1][m].diff,
          tags: event.previousContainer.data[key1][m].tags,
        };
        if (m == currentarraylength) {
          this.exercisesequencelist[key1].push(movedata1);
        } else {
          this.exercisesequencelist[key1][m + 1] = movedata1;
        }
      }
    }
    this.exercisesequencelist[key1][lastarraystartindex] = movedata;
  }
} else {
  Swal.fire(
    "In dieser Phase ist nur noch 1 Übung übrig. Also nicht bewegen",
    "",
    "error"
  );
}

}
  else {

   
    var datanew = {
      id: event.previousContainer.data[event.previousIndex].id,
    };
    let key1;
    let count = 0;
    $.each(this.exercisesequencelist, function (key, element) {
      let coutexercise = element.length;
      count = count + coutexercise;
      if (indexnewcurrent < count) {
        key1 = key;
        return false;
      }
    });


    let add = 0;
    let indexnew = 0;
    if(this.personalInfoForm.secondstep.value.length>0)
    {
      if(this.personalInfoForm.secondstep.value[this.personalInfoForm.secondstep.value.length-1].duration!='')  
      {
        if(isNaN(this.personalInfoForm.secondstep.value[this.personalInfoForm.secondstep.value.length-1].duration)){
          Swal.fire("Bitte nur numerischen Wert in Dauer eingeben in","","error")
       }else{
        add=1;
        indexnew=this.personalInfoForm.secondstep.value.length;
        this.phase[this.personalInfoForm.secondstep.value.length]=this.personalInfoForm.secondstep.value[this.personalInfoForm.secondstep.value.length-1].duration;
       }  
      }
      else{
        add=0;
      } 
    }
    else
    {
    if(this.personalInfoForm.duration.value!='')  
    {
      if(isNaN(this.personalInfoForm.duration.value)){
        Swal.fire("Bitte nur numerischen Wert in Dauer eingeben in","","error")
     }else{
      add=1;
      indexnew=0;
      this.phase[0]=this.personalInfoForm.duration.value;
     }
     
    }
    else{
      add=0;
    } 
    }
    
    
    let totalpreviouscountnewprevious11111111 = 0;
    if (key1 > 0) {
      for (let k = 0; k < key1; k++) {
        totalpreviouscountnewprevious11111111 =
          totalpreviouscountnewprevious11111111 +
          this.exercisesequencelist[k].length;
      }
    }
    if (add == 1) {
      if (this.searchby == "yes") {
        if (this.exercisesequencelist[indexnew].length > 0) {
          let currentindex = indexnewcurrent + 1 - totalpreviouscountnewprevious11111111;
        
          console.log(
            "currentindex" +
              currentindex +
              "currentindex" +
              event.currentIndex +
              "indexnewcurrent" +
              indexnewcurrent +
              "totalpreviouscountnewprevious11111111" +
              totalpreviouscountnewprevious11111111
          );

          if (key1 == undefined) {
            this.exercisesequencelist[indexnew].push({
              id: event.previousContainer.data[event.previousIndex].id,
              value:
                event.previousContainer.data[event.previousIndex].value,
              img: event.previousContainer.data[event.previousIndex].img,
              diff: event.previousContainer.data[event.previousIndex].diff,
              tags: event.previousContainer.data[event.previousIndex].tags,
            });
          } else {
            if (this.exercisesequencelist[key1].length > currentindex) {
              let currentmovedata = {
                id: this.exercisesequencelist[key1][currentindex].id,
                value: this.exercisesequencelist[key1][currentindex].value,
                img: this.exercisesequencelist[key1][currentindex].img,
                diff: this.exercisesequencelist[key1][currentindex].diff,
                tags: this.exercisesequencelist[key1][currentindex].tags,
              };
              this.exercisesequencelist[key1][currentindex] = {
                id: event.previousContainer.data[event.previousIndex].id,
                value:
                  event.previousContainer.data[event.previousIndex].value,
                img: event.previousContainer.data[event.previousIndex].img,
                diff: event.previousContainer.data[event.previousIndex]
                  .diff,
                tags: event.previousContainer.data[event.previousIndex]
                  .tags,
              };

              // this.exercisesequencelist[key1].push([]);

              for (
                var i = this.exercisesequencelist[key1].length - 1;
                i > currentindex;
                i--
              ) {
                this.exercisesequencelist[key1][i + 1] = {
                  id: this.exercisesequencelist[key1][i].id,
                  value: this.exercisesequencelist[key1][i].value,
                  img: this.exercisesequencelist[key1][i].img,
                  diff: this.exercisesequencelist[key1][i].diff,
                  tags: this.exercisesequencelist[key1][i].tags,
                };
              }

              this.exercisesequencelist[key1][currentindex + 1] =
                currentmovedata;
            } else {
              this.exercisesequencelist[key1].push({
                id: event.previousContainer.data[event.previousIndex].id,
                value:
                  event.previousContainer.data[event.previousIndex].value,
                img: event.previousContainer.data[event.previousIndex].img,
                diff: event.previousContainer.data[event.previousIndex]
                  .diff,
                tags: event.previousContainer.data[event.previousIndex]
                  .tags,
              });
            }
          }
        } else {
          this.exercisesequencelist[indexnew].push({
            id: event.previousContainer.data[event.previousIndex].id,
            value: event.previousContainer.data[event.previousIndex].value,
            img: event.previousContainer.data[event.previousIndex].img,
            diff: event.previousContainer.data[event.previousIndex].diff,
            tags: event.previousContainer.data[event.previousIndex].tags,
          });
        }
      } else {
        $("#loaderouterid").css("display", "block");
        $("#loadertext").html("Please wait for extract therapy Plan..");
        $("#loadertext").css("width", "320px");
        $("#loadertext").css("margin-left", "-160px");
        let exerciselistdata = [];
        const data = this.userService
          .getexercisesequence(datanew)
          .subscribe(
            (success: any) => {
              if (success.status == "error") {
              } else {
                exerciselistdata = success;
                for (let i = 0; i < exerciselistdata.length; i++) {
                  if (this.exercisesequencelist[indexnew].length > 0) {
                    let currentindex =
                      indexnewcurrent +
                      1 -
                      totalpreviouscountnewprevious11111111;
                    if (key1 == undefined) {
                      this.exercisesequencelist[indexnew].push({
                        id: event.previousContainer.data[
                          event.previousIndex
                        ].id,
                        value:
                          event.previousContainer.data[event.previousIndex]
                            .value,
                        img: event.previousContainer.data[
                          event.previousIndex
                        ].img,
                        diff: event.previousContainer.data[
                          event.previousIndex
                        ].diff,
                        tags: event.previousContainer.data[
                          event.previousIndex
                        ].tags,
                      });
                    } else {
                      if (
                        this.exercisesequencelist[key1].length >
                        currentindex
                      ) {
                        let currentmovedata = {
                          id: this.exercisesequencelist[key1][currentindex]
                            .id,
                          value:
                            this.exercisesequencelist[key1][currentindex]
                              .value,
                          img: this.exercisesequencelist[key1][currentindex]
                            .img,
                          diff: this.exercisesequencelist[key1][
                            currentindex
                          ].diff,
                          tags: this.exercisesequencelist[key1][
                            currentindex
                          ].tags,
                        };
                        this.exercisesequencelist[key1][currentindex] = {
                          id: event.previousContainer.data[
                            event.previousIndex
                          ].id,
                          value:
                            event.previousContainer.data[
                              event.previousIndex
                            ].value,
                          img: event.previousContainer.data[
                            event.previousIndex
                          ].img,
                          diff: event.previousContainer.data[
                            event.previousIndex
                          ].diff,
                          tags: event.previousContainer.data[
                            event.previousIndex
                          ].tags,
                        };

                        // this.exercisesequencelist[key1].push([]);

                        for (
                          var j =
                            this.exercisesequencelist[key1].length - 1;
                          j > currentindex;
                          j--
                        ) {
                          this.exercisesequencelist[key1][j + 1] = {
                            id: this.exercisesequencelist[key1][j].id,
                            value: this.exercisesequencelist[key1][j].value,
                            img: this.exercisesequencelist[key1][j].img,
                            diff: this.exercisesequencelist[key1][j].diff,
                            tags: this.exercisesequencelist[key1][j].tags,
                          };
                        }

                        this.exercisesequencelist[key1][currentindex + 1] =
                          currentmovedata;
                      } else {
                        this.exercisesequencelist[key1].push({
                          id: event.previousContainer.data[
                            event.previousIndex
                          ].id,
                          value:
                            event.previousContainer.data[
                              event.previousIndex
                            ].value,
                          img: event.previousContainer.data[
                            event.previousIndex
                          ].img,
                          diff: event.previousContainer.data[
                            event.previousIndex
                          ].diff,
                          tags: event.previousContainer.data[
                            event.previousIndex
                          ].tags,
                        });
                      }
                    }
                  } else {
                    this.exercisesequencelist[indexnew].push({
                      id: exerciselistdata[i][event.previousIndex]._id,
                      value:
                        exerciselistdata[i][event.previousIndex]
                          .exercise_name1,
                      img: exerciselistdata[i][1].image,
                      diff: exerciselistdata[i][event.previousIndex]
                        .difficully_level,
                      tags: exerciselistdata[i][event.previousIndex].tags,
                    });
                  }
                }
                $("#loadertext").html("");
                $("#loadertext").css("width", "100px");
                $("#loaderouterid").css("display", "none");
                $("#loadertext").css("margin-left", "-50px");
              }
            },
            (rejected) => {
              console.log(rejected);
            }
          );
      }
    } else {
      Swal.fire(
        "Bitte geben Sie die Dauer als numerischen Wert ein",
        "",
        "error"
      );
    }


  }

}
//   drop(event: CdkDragDrop<any[]>) {
  
//     // console.log("sasdaasd"+JSON.stringify(event.previousContainer)+"    ,     "+JSON.stringify(event.container));
//     if (event.previousContainer === event.container) {

//       // moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

//       let key1;
//       let count=0;
//       $.each(this.exercisesequencelist, function(key, element) {
//       let coutexercise=element.length;
//       count=count+coutexercise;
//       if(event.currentIndex<count)
//       {
//         key1=key;
//         return false;
//       }
//       });

//       let key1previous;
//       let countprevious=0;
//       $.each(this.exercisesequencelist, function(keyprevious, elementprevious) {
//       let coutexerciseprevious=elementprevious.length;
//       countprevious=countprevious+coutexerciseprevious;
//       if(event.previousIndex<countprevious)
//       {
//         key1previous=keyprevious;
//         return false;
//       }
//       });

//       console.log("INDEX"+event.currentIndex+""+event.previousIndex+""+key1+""+key1previous);  
//       console.log("INDEX"+JSON.stringify(event.previousContainer.data));  
//       console.log("INDEX"+JSON.stringify(event.container.data));  

// if(this.exercisesequencelist[key1previous].length>1)
// {

// if(key1previous==key1)
// {
//   let totalpreviouscount=0;
// if(key1>0)
// {
// for(let k=0;k<key1;k++)
// {
//   totalpreviouscount=totalpreviouscount+this.exercisesequencelist[k].length;
// }
// }

//   let movedata={
//     id: event.previousContainer.data[key1][event.previousIndex-totalpreviouscount].id,
//     value: event.previousContainer.data[key1][event.previousIndex-totalpreviouscount].value,
//     img: event.previousContainer.data[key1][event.previousIndex-totalpreviouscount].img,
//     diff: event.previousContainer.data[key1][event.previousIndex-totalpreviouscount].diff,
//     tags: event.previousContainer.data[key1][event.previousIndex-totalpreviouscount].tags
//   }


//   if(event.currentIndex-totalpreviouscount>event.previousIndex-totalpreviouscount)
//   {

//     for(let i=event.previousIndex-totalpreviouscount+1;i<=event.currentIndex-totalpreviouscount;i++)
//     {
    
//       let movedata1={
//         id: event.previousContainer.data[key1][i].id,
//         value: event.previousContainer.data[key1][i].value,
//         img: event.previousContainer.data[key1][i].img,
//         diff: event.previousContainer.data[key1][i].diff,
//         tags: event.previousContainer.data[key1][i].tags
//       }
    
//       this.exercisesequencelist[key1][i-1]=movedata1;
    
//     }



//   }
//   else{
  
// for(let i=event.previousIndex-totalpreviouscount-1;i>=event.currentIndex-totalpreviouscount;i--)
// {

//   let movedata1={
//     id: event.previousContainer.data[key1][i].id,
//     value: event.previousContainer.data[key1][i].value,
//     img: event.previousContainer.data[key1][i].img,
//     diff: event.previousContainer.data[key1][i].diff,
//     tags: event.previousContainer.data[key1][i].tags
//   }

//   this.exercisesequencelist[key1][i+1]=movedata1;

// }

//   }

//   this.exercisesequencelist[key1][event.currentIndex-totalpreviouscount]=movedata;

  
// }
// else{

 
//   let totalpreviouscountnewprevious=0;
//   if(key1previous>0)
//   {
//   for(let k=0;k<key1previous;k++)
//   {
//     totalpreviouscountnewprevious=totalpreviouscountnewprevious+this.exercisesequencelist[k].length;
//   }
//   }



//   let movedata={
//     id: event.previousContainer.data[key1previous][event.previousIndex-totalpreviouscountnewprevious].id,
//     value: event.previousContainer.data[key1previous][event.previousIndex-totalpreviouscountnewprevious].value,
//     img: event.previousContainer.data[key1previous][event.previousIndex-totalpreviouscountnewprevious].img,
//     diff: event.previousContainer.data[key1previous][event.previousIndex-totalpreviouscountnewprevious].diff,
//     tags: event.previousContainer.data[key1previous][event.previousIndex-totalpreviouscountnewprevious].tags
//   }






//   if(event.previousIndex-totalpreviouscountnewprevious+1==this.exercisesequencelist[key1previous].length)
//   {
//     this.exercisesequencelist[key1previous].splice(event.previousIndex-totalpreviouscountnewprevious, 1);   
//   }
//   else
//   {
//   for(let i=event.previousIndex-totalpreviouscountnewprevious+1;i<this.exercisesequencelist[key1previous].length;i++)
//   {
  
//     let movedata1={
//       id: event.previousContainer.data[key1previous][i].id,
//       value: event.previousContainer.data[key1previous][i].value,
//       img: event.previousContainer.data[key1previous][i].img,
//       diff: event.previousContainer.data[key1previous][i].diff,
//       tags: event.previousContainer.data[key1previous][i].tags
//     }
  
//     this.exercisesequencelist[key1previous][i-1]=movedata1;
//   }
//   this.exercisesequencelist[key1previous].splice(this.exercisesequencelist[key1previous].length-1, 1);   
//   }

//   let totalpreviouscount=0;
// if(key1>0)
// {
// for(let k=0;k<key1;k++)
// {
//   totalpreviouscount=totalpreviouscount+this.exercisesequencelist[k].length;
// }
// }

// let lastarraystartindex;
// if(event.currentIndex>totalpreviouscount+1)
// {
// lastarraystartindex=event.currentIndex-(totalpreviouscount+1);
// }
// else{
//   lastarraystartindex=(totalpreviouscount+1)-event.currentIndex;  
// }

// let currentarraylength=this.exercisesequencelist[key1].length;

// if(lastarraystartindex<=currentarraylength)
// {

//   console.log("INDEX333333ll"+currentarraylength);
//   console.log("INDEX333333ll"+lastarraystartindex);

// for(let m=currentarraylength-1;m>=lastarraystartindex;m--)
// {

//   let movedata1={
//     id: event.previousContainer.data[key1][m].id,
//     value: event.previousContainer.data[key1][m].value,
//     img: event.previousContainer.data[key1][m].img,
//     diff: event.previousContainer.data[key1][m].diff,
//     tags: event.previousContainer.data[key1][m].tags
//   }
// if(m==currentarraylength)
// {
//   this.exercisesequencelist[key1].push(movedata1);
// }
// else
// {
//   this.exercisesequencelist[key1][m+1]=movedata1;
// }
  

// }
// }
// this.exercisesequencelist[key1][lastarraystartindex]=movedata;



// }
// }
// else
// {
//   Swal.fire("Only 1 exercise left in this phase.so don't move","","error")
// }





//     } else {

//       let add=0;
//       let indexnew=0;

//       let key1;
//       let count=0;
//       $.each(this.exercisesequencelist, function(key, element) {
//       let coutexercise=element.length;
//       count=count+coutexercise;
//       if(event.currentIndex<=count)
//       {
//         key1=key;
//         return false;
//       }
//       });
          
//       if(this.personalInfoForm.secondstep.value.length>0)
//       {
//         if(this.personalInfoForm.secondstep.value[this.personalInfoForm.secondstep.value.length-1].duration!='')  
//         {
//           if(isNaN(this.personalInfoForm.secondstep.value[this.personalInfoForm.secondstep.value.length-1].duration)){
//             Swal.fire("Bitte nur numerischen Wert in Dauer eingeben in","","error")
//          }else{
//           add=1;
//           indexnew=this.personalInfoForm.secondstep.value.length;
//           this.phase[this.personalInfoForm.secondstep.value.length]=this.personalInfoForm.secondstep.value[this.personalInfoForm.secondstep.value.length-1].duration;
//          }  
//         }
//         else{
//           add=0;
//         } 
//       }
//       else
//       {
//       if(this.personalInfoForm.duration.value!='')  
//       {
//         if(isNaN(this.personalInfoForm.duration.value)){
//           Swal.fire("Bitte nur numerischen Wert in Dauer eingeben in","","error")
//        }else{
//         add=1;
//         indexnew=0;
//         this.phase[0]=this.personalInfoForm.duration.value;
//        }
       
//       }
//       else{
//         add=0;
//       } 
//       }
      
      

// if(add==1)
// {
// if(this.searchby=='yes')
// {
//   if(this.exercisesequencelist[indexnew].length>0)
//   {

//     this.exercisesequencelist[key1].push({
//       id: event.previousContainer.data[event.previousIndex].id,
//       value: event.previousContainer.data[event.previousIndex].value,
//       img: event.previousContainer.data[event.previousIndex].img,
//       diff: event.previousContainer.data[event.previousIndex].diff,
//       tags: event.previousContainer.data[event.previousIndex].tags
//     });
  
// }
// else{
//   this.exercisesequencelist[indexnew].push({
//     id: event.previousContainer.data[event.previousIndex].id,
//     value: event.previousContainer.data[event.previousIndex].value,
//     img: event.previousContainer.data[event.previousIndex].img,
//     diff: event.previousContainer.data[event.previousIndex].diff,
//     tags: event.previousContainer.data[event.previousIndex].tags
//   });
// }
                    
                      
                      
// }
// else
// {
//       $("#loaderouterid").css("display", "block");
//       $('#loadertext').html("Please wait for extract exercise sequence..");
// let exerciselistdata=[];
// var datanew = { id: event.previousContainer.data[event.previousIndex].id };
// const data = this.userService.getexercisesequence(datanew).subscribe(
//   (success: any) => {

//     if (success.status == "error") {

//     } else {
//       exerciselistdata=success;
//       for(let i=0;i<exerciselistdata.length;i++)
//       {
//         if(this.exercisesequencelist[indexnew].length>0)
//   {
//         this.exercisesequencelist[key1].push({
//           id: exerciselistdata[i][event.previousIndex]._id,
//           value: exerciselistdata[i][event.previousIndex].exercise_name1,
//           img: exerciselistdata[i][1].image,
//           diff: exerciselistdata[i][event.previousIndex].difficully_level,
//           tags: exerciselistdata[i][event.previousIndex].tags,
//         });
//       }
//       else
//       {
//         this.exercisesequencelist[indexnew].push({
//           id: exerciselistdata[i][event.previousIndex]._id,
//           value: exerciselistdata[i][event.previousIndex].exercise_name1,
//           img: exerciselistdata[i][1].image,
//           diff: exerciselistdata[i][event.previousIndex].difficully_level,
//           tags: exerciselistdata[i][event.previousIndex].tags,
//         });
//       }
//       }

     
//       // this.exercisesequencelist[indexnew].splice(event.currentIndex, 1);

//       $('#loadertext').html("");
//       $("#loaderouterid").css("display", "none");
//     }
//   },
//   (rejected) => {
//     console.log(rejected);
//   }
// );





// }

// }
// else{
//   Swal.fire("Bitte geben Sie die Dauer als numerischen Wert ein","","error")
// }
// console.log("dddddddddddddddddddvvvvvvvv"+JSON.stringify(event.container.data));
// console.log("dddddddddddddddddddvvvvvvvv"+JSON.stringify(event.previousIndex));
// console.log("dddddddddddddddddddvvvvvvvv"+JSON.stringify(event.currentIndex));
    
//     }
 
//   }



  calculatelength()
  {
    let count=0;
for(let i=0;i<this.phase.length;i++)
{
  for(let j=0;j<this.exercisesequencelist[i].length;j++)
{
  count++;
}
}
return count;
  }
setsearchlist()
{
  if(this.searchby=='yes')
  {
  this.exercisesequencelistsearch=[];
  if(this.typesOptionsArray.length>0)
  {
  for(let i=0;i<this.searchlist.length;i++)
      {
console.log("ffffffffffff"+this.searchlist[i]);
this.exercisesequencelistsearch.push({
  id: this.searchlist[i].id,
  value: this.searchlist[i].name,
  img: this.searchlist[i].img,
  diff: this.searchlist[i].diff,
  tags: this.searchlist[i].tags,
});
      }
    }
  }
  else{
    this.exercisesequencelistsearch=[];
    if(this.typesOptionsArray.length>0)
    {
    for(let i=0;i<this.searchlist.length;i++)
        {

  console.log("ffffffffffff"+this.searchlist[i]);
  this.exercisesequencelistsearch.push({
    id: this.searchlist[i].id,
    value: this.searchlist[i].name,
    img: this.searchlist[i].img,
    diff: this.searchlist[i].diff,
    tags: '',
  });
        }
      }
  }
}


  removeOpt(opt: string, index: number): void {
    console.log("removeOpt");
    const optIndex = this.typesOptionsArray.indexOf(opt);
    if (optIndex >= 0) {
      this.typesOptionsArray.splice(optIndex, 1);
    }
    this.exercise_controls.setValue('');
    this.setsearchlist();
  }

  telInputObject(obj) {
    console.log(obj);
    obj.setCountry("de");
  }

  logout() {
    console.log("in logout function");

    localStorage.removeItem("token");
    this.router.navigate(["./"]);
  }

  openinformation(id,index,tags)
  {
    $("#loaderouterid").css("display", "block");
    this.getcurrentUser(id);


  }

  choosecategory11(value) {
    console.log("dddddddddddd" + value);
    this.personalInfoFormGroupadd.patchValue({
      exercise_categoryedit: value,
    });

    $("#exercise_categoryedit").val(value);
    if (value == "Therapieedit") {
      $("#Therapieedit").css("background-color", "gray");
      $("#Präventionedit").css("background-color", "white");
    } else {
      $("#Präventionedit").css("background-color", "gray");
      $("#Therapieedit").css("background-color", "white");
    }



  }


  calcount11(id, type) {
    let commentcount: any;
    if (type == "comment") {
      let comment = this.personalInfoFormGroupadd.controls.comment.value.length;

      commentcount = 500 - comment;
    }
    if (type == "comment1") {
      let comment = this.personalInfoFormGroupadd.controls.comment1.value.length;

      commentcount = 500 - comment;
    }
    if (type == "exercise_name") {
      let comment = this.personalInfoFormGroupadd.controls.exercise_name.value.length;

      commentcount = 35 - comment;
    }
    if (type == "exercise_name1") {
      let comment = this.personalInfoFormGroupadd.controls.exercise_name1.value.length;

      commentcount = 35 - comment;
    }
    $("#" + id).html(commentcount);
  }

  getcurrentUser(openid?, currentData?) {
    const that = this;

      // console.log("call api")
      this.userService.getEditexercise(openid).subscribe(function (data1) {


        setData(data1);
      });
    

    function setData(data1) {
   

      console.log("dddddddddddd" + JSON.stringify(data1));
      console.log("dddddddddddd" + data1.element.exercise_category);
      that.choosecategory11(data1.element.exercise_category);

      that.calcount11("commentcount", "comment");
      let commentlength: any = 500 - data1.element.comment.length;
      $("#commentcount").html(commentlength);
      let commentlength1: any = 500 - data1.element.comment1.length;
      $("#comment1count").html(commentlength1);
      let exercise_name: any = 35 - data1.element.exercise_name.length;
      $("#exercise_namecount").html(exercise_name);
      let exercise_name1: any = 35 - data1.element.exercise_name1.length;
      $("#exercise_name1count").html(exercise_name1);
      let tags: any = 20 - data1.element.tags.length;
      $("#manual_tagscount").html(tags);

      that.personalInfoFormGroupadd.patchValue({
        exercise_name: data1.element.exercise_name,
        exercise_name1: data1.element.exercise_name1,
        difficully_level: data1.element.difficully_level,
        tools: data1.element.tools,
        comment: data1.element.comment,
        comment1: data1.element.comment1,
      });
      that.oldtypesOptionsArray = data1.element.tags;
      console.log("dddddddddddd" + that.oldtypesOptionsArray);
      $("#logopreview0").html(
        "<img src='" + data1.image1 + "' style='width: 100%;height: 300px'>"
      );
      $("#logopreview1").html(
        "<video  style='width: 100%;height: 300px' controls><source  src='" +
          data1.image2 +
          "' type='video/mp4'></video>"
      );
      $("#logopreview2").html(
        "<video  style='width: 100%;height: 300px' controls><source  src='" +
          data1.image3 +
          "' type='video/mp4'></video>"
      );
      $("#loaderouterid").css("display", "none");
    }

    // this.choosecategory();
    // $('#loaderouterid').css("display","none");
  }


  choosecategory(value) {
   this.exercisesequencelistsearch=[];
this.typesOptionsArray=[];
 this.searchby=value;
 this.getallexerciselist();
 setTimeout(() => {
  this.exercise_controls.setValue(' ');
 }, 500);

  }
  ngOnInit() {

    this.personalInfoFormGroupadd = this._formBuilder.group({
      exercise_name: ["", Validators.required],
      exercise_name1: ["", Validators.required],
      comment1: [""],
      comment: ["", Validators.required],
      manual_tags: ["", Validators.required],
      tools: ["", Validators.required],
      difficully_level: ["", Validators.required],

      upload_file: ["", Validators.required],
      explain: ["", Validators.required],
      implementation: ["", Validators.required],
      exercise_categoryedit: ["", Validators.required],
    });

    // this.exercisesequencelist.push({
    //   id: '',
    //   value: '10 Medical Question come here..',
    //   img: '',
    //   diff: '',
    //   tags: '',
    // });
  

    this.searchby='yes';
    this.getallexerciselist();
    this.loginRole = this.loginRole;
    this.routeParams = this.activatedRoute.snapshot.routeConfig.path;

    this.personalInfoFormGroup = this._formBuilder.group({
      secondstep: this._formBuilder.array([]),
      therapyplan_name: ["", Validators.required],
      duration:["",[Validators.required,Validators.pattern('^[0-9]*$')]]
      // start_date:["", Validators.required],
      // end_date:["", Validators.required]
      // exercise_name: [""],
      // exercise_name1: ["", Validators.required],
      // comment1: [""],
      // comment: ["", Validators.required],
      // manual_tags: ["", Validators.required],
      // tools:["", Validators.required],
      // difficully_level:["", Validators.required],
    });

//     this.personalInfoFormGroup.patchValue({
//       start_date:new Date()
//     })


// this.minDate = new Date();
// this.minDateforend=new Date();
// console.log("startdate"+this.minDate);
//     let numWeeks = 12;
// let now = new Date(this.personalInfoFormGroup.controls.start_date.value);
// now.setDate(now.getDate() + numWeeks * 7);
// this.maxDate11=now;
// console.log("startdate"+this.maxDate11);









    this.personalInfoFormGroup0 = this._formBuilder.group({
      exercise_category: ["", Validators.required],
    });
    // this.personalInfoFormGroup=new FormGroup({
    //   start_date:new FormControl(this.minDate),
    //   end_date:new FormControl(this.minDate),
    // });

    this.exerciseoption = this.exercise_controls.valueChanges.pipe(
      startWith(""),
      map((value) => (typeof value == "string" ? value : value.name)),
      map((name) =>this._filterexercisedata(name)
      )
    );

    this.typesOptionsArray.push();
    this.exercisesequencelist.push([]);
  }
  exerciselength(index)
  {
    let acc = 0;
    for (let i = 0; i<index; i++) {
        acc += this.exercisesequencelist[i].length;
    }
    return acc;
  }

  setdays(index)
  {
    console.log("setdays"+index);
    if(this.phase.length>0)
    {
    if(index=='0')
    {
    this.phase[index]=this.personalInfoForm.duration.value;
    }
    else{
      if(this.personalInfoForm.secondstep.value[index].duration!='')
      {
      this.phase[index]=this.personalInfoForm.secondstep.value[index].duration;
      }
     
    }
  }
  }


  getexercise(id,name,img,diff,tags,data11,index) {
   





    // if( this.exercisesequencelist.length>19)
    // {
    //   Swal.fire("Maximal 20 Übungen zur Übungsabläufe hinzugefügt added","","error");
    // }
    // else{
let add=0;
let indexnew=0;
console.log("phase"+this.personalInfoForm.secondstep.value.length);

if(this.personalInfoForm.secondstep.value.length>0)
{

  console.log("phaseif"+JSON.stringify(this.personalInfoForm.secondstep.value.length));
  console.log("phaseif"+JSON.stringify(this.personalInfoForm.secondstep.value));

  console.log("phaseif"+JSON.stringify(this.personalInfoForm.secondstep.value[this.personalInfoForm.secondstep.value.length-1].duration));
  if(this.personalInfoForm.secondstep.value[this.personalInfoForm.secondstep.value.length-1].duration!='')  
  {
    if(isNaN(this.personalInfoForm.secondstep.value[this.personalInfoForm.secondstep.value.length-1].duration)){
      Swal.fire("Bitte nur numerischen Wert in Dauer eingeben in","","error")
   }else{
    add=1;
    indexnew=this.personalInfoForm.secondstep.value.length;
    this.phase[this.personalInfoForm.secondstep.value.length]=this.personalInfoForm.secondstep.value[this.personalInfoForm.secondstep.value.length-1].duration;
   }

    
  }
  else{
    add=0;
  } 
}
else
{
  console.log("phaseif"+this.personalInfoForm.duration.value);
if(this.personalInfoForm.duration.value!='')  
{
  if(isNaN(this.personalInfoForm.duration.value)){
    Swal.fire("Bitte nur numerischen Wert in Dauer eingeben in","","error")
 }else{
  add=1;
  indexnew=0;
  this.phase[0]=this.personalInfoForm.duration.value;
 }
 
}
else{
  add=0;
} 
}


console.log("exercisesequencelist" + indexnew);


if(add==1)
{

  
if(this.searchby=='yes')
    {
 
    let l = "b";
    console.log("exercisesequencelist" + this.exercisesequencelist[indexnew].length);
    this.exercisesequencelist[indexnew].push({
      sno:this.totalexercise,
      id: id,
      value: name,
      img: img,
      diff: diff,
      tags: tags,
      data: data11,
    });
    console.log("exercisesequencelist" + this.exercisesequencelist[indexnew].length);
  }
  else
{

  this.exercisesequencelist[indexnew].push({
    sno:this.totalexercise,
    id: id,
    value: name,
    img: img,
    diff: diff,
    tags: tags,
    data: data11,
  });


let lastindex=this.exercisesequencelist[indexnew].length;

  $("#loaderouterid").css("display", "block");
      $('#loadertext').html("Bitte warten Sie auf den Auszug der Übungssequenz..");



      let exerciselistdata=[];
 
      var datanew = { id: id };
      const data = this.userService.getexercisesequence(datanew).subscribe(
        (success: any) => {
      
          if (success.status == "error") {
      
          } else {
       
            exerciselistdata=success;
            console.log("ddddddddddddddddddd"+JSON.stringify(exerciselistdata));
      
            for(let i=0;i<exerciselistdata.length;i++)
            {
            
              console.log("ddddddddddddddddddd"+JSON.stringify(exerciselistdata[i][0]._id));
              console.log("ddddddddddddddddddd"+JSON.stringify(exerciselistdata[i][1].image));
              this.exercisesequencelist[indexnew].push({
                sno:this.totalexercise,
                id: exerciselistdata[i][0]._id,
                value: exerciselistdata[i][0].exercise_name1,
                img: exerciselistdata[i][1].image,
                diff: exerciselistdata[i][0].difficully_level,
                tags: exerciselistdata[i][0].tags,
              });
            }
      
            
            this.exercisesequencelist[indexnew].splice(lastindex-1, 1);
      
            $('#loadertext').html("");
            $("#loaderouterid").css("display", "none");
          }
        },
        (rejected) => {
          console.log(rejected);
        }
      );
      



}
}
else{
  Swal.fire("Bitte geben Sie die Dauer als numerischen Wert ein","","error")
}
  // }
  console.log("phaseif"+JSON.stringify(this.phase));
  


  }



  loopingexerciselist(userData: string | any[]) {

    let exerciselist = [];
    if(this.searchby=='yes')
    {
    console.log("kundenew" + JSON.stringify(userData));
    for (let i = 0; i < userData.length; i++) {
      // console.log(userData[i].roles.includes('b2b') , userData[i].roles)
      console.log(
        "kundenew" + JSON.stringify(userData[i].element.exercise_name1)
      );
      exerciselist.push({
        name: userData[i].element.exercise_name1,
        id: userData[i].element._id,
        tags: userData[i].element.tags,
        img: userData[i].img,
        diff: userData[i].element.difficully_level,
      });
    }
  }
  else{
    console.log("kundenew" + JSON.stringify(userData));
    for (let i = 0; i < userData.length; i++) {
      // console.log(userData[i].roles.includes('b2b') , userData[i].roles)
   
      exerciselist.push({
        name: userData[i].exercisesequence_name,
        id: userData[i]._id,
        tags: '',
        img: '',
        diff: userData[i].exercise_id.length,
      });
    }
  }
    console.log("broooker!!!!!!!!!!!!!!!!!!!!!" + JSON.stringify(exerciselist));

    return exerciselist;
  }

  getallexerciselist() {
let exercise_category='Therapie';
if(this.searchby=='yes')
{
console.log("sddd"+exercise_category);
    this.userService
      .getsearchexerciseinsequence(exercise_category)
      .pipe(first())
      .subscribe((data: any) => {
        this.exercisedata = this.loopingexerciselist(data);

        console.log("exercisedata" + this.exercisedata);
      });
    }
    else{
      
    this.userService
    .getSearchexercisesequence(exercise_category)
    .pipe(first())
    .subscribe((data: any) => {
      this.exercisedata = this.loopingexerciselist(data);

      console.log("exercisedata" + this.exercisedata);
    });
    }
  }

  _filterexercisedata(value: string): string[] {

  let searchlist=this.exercisedata;
  let searchlistnewlist=[];
  console.log("kundenew11" +JSON.stringify(searchlist));
    for(let i=0;i<this.typesOptionsArray.length;i++)
    {
const filterValue = this.typesOptionsArray[i].toLowerCase();
// searchlist = searchlist.filter(
//   (option) => {
//     const savageMatch =
//       JSON.stringify(option.name).toLowerCase().indexOf(filterValue) !== -1;

//     console.log(savageMatch);
//     if (savageMatch) return true;
//   }
//   // option.tags.toLowerCase().includes(filterValue)
// );
searchlist = searchlist.filter(function(itm){
  return JSON.stringify(itm.name).toLowerCase().indexOf(filterValue) > -1 || JSON.stringify(itm.tags).toLowerCase().indexOf(filterValue) > -1;
});
    }

    const filterValue11 = value.toLowerCase();
    searchlistnewlist = this.exercisedata.filter(function(itm){
      return JSON.stringify(itm.name).toLowerCase().indexOf(filterValue11) > -1 || JSON.stringify(itm.tags).toLowerCase().indexOf(filterValue11) > -1;
    });
    searchlist = searchlist.filter(function(itm){
      return JSON.stringify(itm.name).toLowerCase().indexOf(filterValue11) > -1 || JSON.stringify(itm.tags).toLowerCase().indexOf(filterValue11) > -1;
    });
    
    console.log("kundenew11" +JSON.stringify(searchlist));
    this.searchlist=searchlist;
    return searchlistnewlist;
  }
  removesequence(index,i) {
    console.log(i);
    this.exercisesequencelist[index].splice(i, 1);
    if(this.exercisesequencelist[index].length>0)
    {

    }
    else{
      this.phase.splice(index,1);
    }
  }
  navigateWithexcersicename() {
    this.router.navigate(["/exercise_name"], {
      queryParams: { name: "prevention_course" }, 
    });
    // this.queryID = this.selectedUser.customerno;
    // this.ngOnInit()
  }
  add_exercise() {
    this.router.navigate(["/add-treatement-plan"]);
    // this.queryID = this.selectedUser.customerno;
    // this.ngOnInit()
  }
  navigateWithexcersicename1() {
    this.router.navigate(["/exercise_name"], {
      queryParams: { name: "therapeuts_praxen" },
    });
  }
  patchkundeValue() {
    this.kunde_id = this.Kundecontrol.value.id;
    this.Kundecontrol.setValue(this.Kundecontrol.value.value);

    console.log("kundenew" + this.kunde_id);
  }

  public imagePath;
  public pdfPath;
  imgURL: any = [];
  public message: string;
  imgshow = "";

  get personalInfoForm() {
    return this.personalInfoFormGroup.controls;
  }

  get personalInfoForm0() {
    return this.personalInfoFormGroup0.controls;
  }
 

  calcount(id, type) {
    let therapyplan_namecount: any;
    if (type == "therapyplan_name") {
      let therapyplan_name = this.personalInfoForm.therapyplan_name
        .value.length;

      therapyplan_namecount = 35 - therapyplan_name;
    }
    $("#" + id).html(therapyplan_namecount);
  }

  save_exercise_sequence() {
    $("#loaderouterid").css("display", "block");

    let companycodenew = "";

    companycodenew = "60-co-";

        this.ticket_no = companycodenew + "" + new Date().valueOf();
let dataarray=[];
for (let j = 0; j < this.phase.length; j++) {
        for (let i = 0; i <  this.exercisesequencelist[j].length; i++) {
    // let datannn={'exercise_id1':this.exercisesequencelist[i].id}
          dataarray.push({"phase":j+1,"days":this.phase[j],"exercise":this.exercisesequencelist[j][i].id});
        }
      }
      console.log("dataarray" + JSON.stringify(dataarray));
        
        let datanew = {
          treatementplan_name: this.personalInfoForm.therapyplan_name
            .value,
          created_by: this.loginid,
          exercise_id: dataarray,
        };

        this.userService
        .registertreatementplan(datanew)
        .pipe(first())
        .subscribe(
          (data) => {
      
            Swal.fire(
              "Nachbehandlungsplan erfolgreich hinzugefügt.",
              "",
              "success"
            );
            this.ticket_no = "";

            $("#loaderouterid").css("display", "none");
            setTimeout(() => {
              Swal.close();
              this.router.navigate(["./treatment-plan-list"]);
            }, 1000);
          },
          (error) => {
            Swal.fire("Error" + error["error"] + "", "", "error");

            $("#loaderouterid").css("display", "none");
            setTimeout(() => {
              Swal.close();
              this.add_exercise();
            }, 1000);
            // $("html, body").animate(
            //   { scrollTop: "0" }, 3000);
          }
        );





    
  }
}
