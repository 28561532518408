import { Component, OnInit, ViewChild } from "@angular/core";

import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "../user.service";
import { first } from "rxjs/operators";
import { AgmMap } from "@agm/core";
// import {} from "googlemaps";
import { PagerService } from "../pager.service";
import { environment } from "src/environments/environment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Color } from "ng2-charts";
import { ChartOptions } from 'chart.js';
import 'chart.piecelabel.js';
type unit = 'bytes' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB';
type unitPrecisionMap = {
  [u in unit]: number;
};

const defaultPrecisionMap: unitPrecisionMap = {
  bytes: 0,
  KB: 0,
  MB: 1,
  GB: 1,
  TB: 2,
  PB: 2
};
@Component({
  selector: 'app-patient-dashboard',
  templateUrl: './patient-dashboard.component.html',
  styleUrls: ['./patient-dashboard.component.css']
})
export class PatientDashboardComponent implements OnInit {

  private readonly units: unit[] = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
  defaultCenter = { lat: 55.5815245, lng: 36.8251383 };
  currentCenter = Object.assign({}, this.defaultCenter);
  zoom = 3;

  @ViewChild(AgmMap, null) agmMap;
  showCustomer = false;
  showmekFinanz = false;
  showFiorettoImmob = false;
  showBirema = false;
  showFiorettoMedia = false;
  showAirmage = false;
  showHoraiDetektei = false;
  showVarioImport = false;
  showSterbVorsoge = false;
  showCheckntrack = false;
  customerDocList: any = [];
  customerDocListsecond :any=[];
  latitude: any;
  longitude: any;
  pastdata;
  phase:any=[];
  phasepast:any=[];
  exercisesequencelist:any=[];
  exercisesequencelistpast:any=[];
  token: any;
  origin = {};
  personalInfoFormGroupmodal: FormGroup;
  destination = {};
  questionarray=[]; 
  pager: any = {};
  questionarraypast=[];
  pagersecond: any = {};
  pagerGDOC: any = {};
  pagedItems: any[];
  pagedItemssecond: any[];
  pagedItemsGDOC: any[];
  startRecord: any;
  endRecord: any;
  startRecordsecond: any;
  endRecordsecond: any;
  startRecordGDOC: any;
  endRecordGDOC: any;
  routeParams: any;
  ImgUrl: string = "";
  completepast=0;
  href: string = "";
  ShowDiv: boolean = false;
  oldtypesOptionsArray: any[] = [];
  complete=0;
  TimeLineData: [] = [];
  loginRole = sessionStorage.getItem("currentActiveRole");
  title = this.userService.getDecodedAccessToken(sessionStorage.getItem("Token"))
    .title;
  lastname = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).lastname;
  firstname = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).firstname;
  id = this.userService.getDecodedAccessToken(sessionStorage.getItem("Token")).id;
  COMPANYNAME = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).companyname;
  localData: any;
  documents: any;
  tags: any[] = [];
  previewid: string;
  queryID;
  brokername:any;
  date: Date;
  treatmentdata:any='';
  pasttreatmentdata:any=[];
  exercisedata:any;
  api_url:any;
  time;
  idpast;
  itemList: string[] = [
    "Matte",
    "Bett",
    "Spiegel",
    "Strohhalm",
    "Eiswürfel",
    "Hocker",
    "Widerstandsband",
    "Pezziball",
    "Stab",
    "Kettlebell",
    "Faszienrolle",
    "Seil",
    "Hantel (klein)",
    "Handtuch",
    "Tape",
    "Tennisball",
    "Murmeln",
    "Zopfgummi",
    "Tisch",
    "Knete",
  ];

percentage:number=0;

  pieChartLabels:string[] = ['Abgeschlossene','Verbleibend'];
  pieChartData:number[] = [];
  pieChartDatapast:number[] = [];
  colors: Color[] = [
    {
      backgroundColor: [
        'green',
        '#17949a'
      ]
    }
  ];

  
  pieChartType:string = 'pie';


  
  pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    
    },
    tooltips: {
      enabled: true,
      mode: 'single',
      callbacks: {
        datalabel: function (tooltipItems, data) {
          console.log("dddddddd");
          return ""+data.datasets[0].data[tooltipItems.index] + ' %';
        }
        
      }
    },
     plugins: {
        datalabels: {
            formatter: (value, ctx) => {
                let sum = 0;
                let dataArr = ctx.chart.data.datasets[0].data;
                dataArr.map(data => {
                    sum += data;
                });
                let percentage = (value*100 / sum).toFixed(2)+" %";
                return percentage;
            },
            color: '#fff',
        },
        labels: {
          fontColor: '#fff'
        },
    }
   
  };
  

  selecteditemList = this.itemList;
  constructor(
    
    private router: Router,
    private userService: UserService,
    private pagerService: PagerService,
    private activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.queryID = params["id"];
      console.log(this.queryID);
    });
  }
  chartClicked(e:any):void {
    console.log(e);
  }
 
  chartHovered(e:any):void {
    console.log("dddddddddddd"+e);
  }
  GetFormattedDate(date:any) {
    var today = new Date(date);
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    
  return    dd + '.'+ mm + '.' + yyyy;
}
  getenddate()
  {
    let length=this.treatmentdata.exercise_id.length-1;
    console.log("exertciselength"+length)
    return this.GetFormattedDate(this.treatmentdata.exercise_id[length].dates.end_date);
  }
  getenddate1(exercise_id)
  {
    let length=exercise_id.length-1;
    console.log("exertciselength"+length)
    return this.GetFormattedDate(exercise_id[length].dates.end_date);
  }


  calculatelength() {
    let count = 0;
    for (let i = 0; i < this.phase.length; i++) {
      for (let j = 0; j < this.exercisesequencelist[i].length; j++) {
        count++;
      }
    }
    return count;
  }

  calculatelengthpast()
  {
    let count = 0;
    for (let i = 0; i < this.phasepast.length; i++) {
      for (let j = 0; j < this.exercisesequencelistpast[i].length; j++) {
        count++;
      }
    }
    return count;
  }


  exerciselength(index) {
    let acc = 0;
    for (let i = 0; i < index; i++) {
      acc += this.exercisesequencelist[i].length;
    }
    return acc;
  }


  exerciselengthpast(index) {
    let acc = 0;
    for (let i = 0; i < index; i++) {
      acc += this.exercisesequencelistpast[i].length;
    }
    return acc;
  }


  editRecordpast(id, accordianId,cl) {
   
    this.exercisesequencelistpast=[];
    this.phasepast=[];
   accordianId = "collapse"
    event.preventDefault();
    let element1: HTMLElement = document.getElementById('ulpast' + id) as HTMLElement;
    let element1new: HTMLElement = document.getElementById('cardbodyidpast') as HTMLElement;
    let element: HTMLElement = document.getElementById('clickpast' + id) as HTMLElement;
    let accordian: HTMLElement = document.getElementById(accordianId);
    if (element.innerHTML == "Schließen") {
        element1new.after(accordian);
        accordian.classList.add("collapse");
        // accordian.classList.add("collapse");
        accordian.classList.remove("collapse-show");
        element.innerHTML = "Öffnen";
    
    } else {
    
      if(this.idpast!='')
      {
      let elementnew1: HTMLElement = document.getElementById('clickpast' + this.idpast) as HTMLElement;
      if(elementnew1)
      {
      elementnew1.innerHTML = "Öffnen";
      }
      }
        element1.after(accordian);
        accordian.classList.remove("collapse");
        accordian.classList.add("collapse-show");
        element.innerHTML = "Schließen";
        this.idpast = id;
        this.getcurrentUser(this.idpast);
        
    }
    // console.log(element.innerHTML);

  }

  getcurrentUser(id)
  {
    this.completepast=0;
    $("#loaderouterid").css("display", "block");
    var datanew1 = { starttreatment_id: id };
    this.userService.getexercisebytherapywithoutstatus(datanew1).subscribe(
                   (success1: any) => {
                     if(success1.data.length>0)
                     {
this.pastdata=success1;
this.completeexercisepast(success1);
let m=0;

for (
  let j = 0;
  j < success1.data.length;
  j++
) {
 
  this.phasepast.push([]);

  // this.phasepast[j] = {start_date:success1.data[j].start_date,end_date:success1.data[j].end_date};


  this.exercisesequencelistpast.push([]);
  let savedataarray=[];
console.log("test1111111111"+success1.data[j].exercise.length)
  for(let i=0;i<success1.data[j].exercise.length;i++)
  {
    savedataarray.push({"name":success1.data[j].exercise[i].name,"savedate": success1.data[j].exercise[i].savedate})


    this.exercisesequencelistpast[j].push({
      id: success1.data[j].exercise[i].id,
      value: success1.data[j].exercise[i].name,
      img: success1.data[j].exercise[i].image,
      diff: success1.data[j].exercise[i].difficully_level,
      tags: success1.data[j].exercise[i].tags,
      watch: success1.data[j].exercise[i].watch,
      view: success1.data[j].exercise[i].view,
      savedate: success1.data[j].exercise[i].savedate,
    });
    m++;
  }
  this.phasepast[j] = {start_date:success1.data[j].start_date,end_date:success1.data[j].end_date,savedate:savedataarray};
}


let remper:number=m-this.completepast;

this.pieChartDatapast= [this.completepast,remper]
console.log("test1111111111a"+this.completepast)
console.log("test1111111111b"+remper)
$("#loaderouterid").css("display", "none");

          

                     }

    });
  }

  getcurrentUser11(openid) {
    const that = this;
 
      // console.log("call api")
      this.userService.getEditexercise(openid).subscribe(function (data1) {
        setData(data1);
      });
    

    function setData(data1) {
      console.log("dddddddddddd" + JSON.stringify(data1));
      console.log("dddddddddddd" + data1.element.exercise_category);
      that.choosecategory11(data1.element.exercise_category);

      // that.calcount("commentcount", "comment");
      let commentlength: any = 500 - data1.element.comment.length;
      $("#commentcount").html(commentlength);
      let commentlength1: any = 500 - data1.element.comment1.length;
      $("#comment1count").html(commentlength1);
      let exercise_name: any = 35 - data1.element.exercise_name.length;
      $("#exercise_namecount").html(exercise_name);
      let exercise_name1: any = 35 - data1.element.exercise_name1.length;
      $("#exercise_name1count").html(exercise_name1);
      let tags: any = 20 - data1.element.tags.length;
      $("#manual_tagscount").html(tags);

      that.personalInfoFormGroupmodal.patchValue({
        exercise_name: data1.element.exercise_name,
        exercise_name1: data1.element.exercise_name1,
        difficully_level: data1.element.difficully_level,
        tools: data1.element.tools,
        comment: data1.element.comment,
        comment1: data1.element.comment1,
      });
      that.oldtypesOptionsArray = data1.element.tags;
      $("#logopreview0").html(
        "<img src='" + data1.image1 + "' style='width: 100%;height: 300px'>"
      );
      $("#logopreview1").html(
        "<video  style='width: 100%;height: 300px' controls><source  src='" +
          data1.image2 +
          "' type='video/mp4'></video>"
      );
      $("#logopreview2").html(
        "<video  style='width: 100%;height: 300px' controls><source  src='" +
          data1.image3 +
          "' type='video/mp4'></video>"
      );
      $("#loaderouterid").css("display", "none");
    }
  }


  openinformation(id,index,tags)
        {
          $("#loaderouterid").css("display", "block");
          this.getcurrentUser11(id);
        }
        choosecategory11(value) {
          console.log("dddddddddddd" + value);
          this.personalInfoFormGroupmodal.patchValue({
            exercise_category: value,
          });
      
          $("#exercise_category").val(value);
          if (value == "Therapie") {
            $("#Therapie").css("background-color", "gray");
            $("#Prävention").css("background-color", "white");
          } else {
            $("#Prävention").css("background-color", "gray");
            $("#Therapie").css("background-color", "white");
          }
      
      
      
        }


getquestion()
{
let start_treatment=this.treatmentdata._id;
this.userService.getquestionbystarttretmentid({starttreatment_id:start_treatment}).subscribe((success:any)=>{
this.questionarray=[];

for(let i=0;i<=10;i++)
{

  this.questionarray.push(success.data[0].questiondetails[i])

}
console.log("ggg"+JSON.stringify(this.questionarray))

})
}


getquestionbystarttretmentidwithoutstatus()
{
  let start_treatment=this.idpast;
this.userService.getquestionbystarttretmentidwithoutstatus({starttreatment_id:start_treatment}).subscribe((success:any)=>{
this.questionarraypast=[];

for(let i=0;i<=10;i++)
{

  this.questionarraypast.push(success.data[0].questiondetails[i])

}
console.log("ggg"+JSON.stringify(this.questionarraypast))

})
}



getquestion1(indexedDB)
{
  this.questionarray=[];
let start_treatment=this.treatmentdata._id;
this.userService.getquestionbystarttretmentid({starttreatment_id:start_treatment}).subscribe((success:any)=>{

  let start=(6*(indexedDB-1))+11;

for(let i=start;i<=(5+start);i++)
{
  console.log("ddd"+i)
  if(success.data[0].questiondetails[i]==null)
  {

  }
  else{
  this.questionarray.push(success.data[0].questiondetails[i])
  }
}

})
}

getquestionbystarttretmentidwithoutstatus1(indexedDB)
{
  this.questionarraypast=[];
  let start_treatment=this.idpast;
this.userService.getquestionbystarttretmentidwithoutstatus({starttreatment_id:start_treatment}).subscribe((success:any)=>{

  let start=(6*(indexedDB-1))+11;

for(let i=start;i<=(5+start);i++)
{
  console.log("ddd"+i)
  if(success.data[0].questiondetails[i]==null)
  {

  }
  else{
  this.questionarraypast.push(success.data[0].questiondetails[i])
  }
}

})
}
ngOnInit() {
  


    this.personalInfoFormGroupmodal = this._formBuilder.group({
      exercise_name: ["", Validators.required],
      exercise_name1: ["", Validators.required],
      comment1: [""],
      comment: ["", Validators.required],
      manual_tags: ["", Validators.required],
      tools: ["", Validators.required],
      difficully_level: ["", Validators.required],
    
      upload_file: ["", Validators.required],
      explain: ["", Validators.required],
      implementation: ["", Validators.required],
      exercise_category: ["", Validators.required],
    });

    this.api_url=environment.API_URL
    this.routeParams = this.activatedRoute.snapshot.routeConfig.path;
    this.userService
    .getEditUser(this.queryID)
    .pipe(first())
    .subscribe((user: any) => {
      this.localData = user;
     
  
    });

$('#loaderouterid').css("display","block");





    var datanew = { id: this.queryID };
    const data = this.userService.getstarttreatmentbypatientid(datanew).subscribe(
      (success: any) => {
        if(success)
        {
        this.treatmentdata=success;
        var datanew1 = { starttreatment_id:    this.treatmentdata._id };
        this.userService.getexercisebytherapy(datanew1).subscribe(
           (success1: any) => {
             if(success1.data.length>0)
             {
             this.exercisedata=success1;

this.completeexercise(success1);

// let completeper:number=Math.floor((100/success.exercise_id.length)*this.complete)
// console.log("completeper"+completeper)
let remper:number=success.exercise_id.length-this.complete;
this.pieChartData= [this.complete,remper]

let m=0;
for (
  let j = 0;
  j < success1.data.length;
  j++
) {
 
  this.phase.push([]);



  this.exercisesequencelist.push([]);
  let savedataarray=[];;
  for(let i=0;i<success1.data[j].exercise.length;i++)
  {


    savedataarray.push({"name":success1.data[j].exercise[i].name,"savedate": success1.data[j].exercise[i].savedate})

    this.exercisesequencelist[j].push({
      id: success1.data[j].exercise[i].id,
      value: success1.data[j].exercise[i].name,
      img: success1.data[j].exercise[i].image,
      diff: success1.data[j].exercise[i].difficully_level,
      tags: success1.data[j].exercise[i].tags,
      watch: success1.data[j].exercise[i].watch,
      view: success1.data[j].exercise[i].view,
      savedate: success1.data[j].exercise[i].savedate,
    });
    m++;
  }


  this.phase[j] = {start_date:success1.data[j].start_date,end_date:success1.data[j].end_date,savedate:savedataarray};

}
$("#loaderouterid").css("display", "none");

             }
             else{
              $("#loaderouterid").css("display", "none");
             }
     console.log("RUNNING"+JSON.stringify( this.exercisedata))
           });

          }
          else{
            $("#loaderouterid").css("display", "none");
          }
         
      });






      var datanew11 = { id: this.queryID };
      const data11 = this.userService.getstarttreatmentbypatientid1(datanew11).subscribe(
        (success: any) => {
          if(success)
          {
          this.pasttreatmentdata=success;


  
            }
            else{
              $("#loaderouterid").css("display", "none");
            }
           
        });

    
  }
  convertTime12to24(time12h) {
    console.log("hourse"+time12h);
    let data = time12h.split(' ');
    console.log("hourse"+data);
    let time = data[1].split(':');
    console.log("hourse"+time);
  console.log("hourse"+time[0]);
  console.log("hourse"+time[1]);
  console.log("hourse"+time[2]);
  console.log("hourse"+data[2]);
  console.log("hourse"+data[0]);
    if (time[0] === '12') {
      time[0] = '00';
    }
  
    if (data[2] === 'PM') {
      time[0] = parseInt(time[0], 10) + 12;
    }
  
    return `${String(time[0]).padStart(2, '0')}:${String(time[1]).padStart(2, '0')}`;
  }

  opensavedatemodal(dates,lessonname?)
  {

    console.log("dates"+JSON.stringify(dates))
console.log("dates"+JSON.stringify(dates[0]))
console.log("dates"+JSON.stringify(dates[0].savedate[0]))
    let html='<table class="table"><tr style="background: #184397;color: white;"><th>Übungsname</th><th>Datum</th><th>Zeit</th></tr>';
     for(let i=0;i<dates.length;i++)
     {

      for(let j=0;j<dates[i].savedate.length;j++)
      {
let date=dates[i].savedate[j].split("/");
let date111=date[2].split(",");
let month=date[1].padStart(2,'0');
let newdate=month+"."+date[0]+'.'+date111[0];
let lessonname=dates[i].name;

var time = this.convertTime12to24(date111[1]);




      html+='<tr><td>'+lessonname+'</td><td>'+newdate+'</td><td>'+time+'</td></tr>';
      }
     }
     html+='</table>';
$('#savedataid').html(html)
$('#buttonsavedate').trigger('click')
  }


  completeexercise(data)
  {
for(let i=0;i<data.data.length;i++)
{
  for(let k=0;k<data.data[i].exercise.length;k++)
  {
    if(data.data[i].exercise[k].watch=='1')
    {
      console.log("INDEXXXXXXXXXX")
      this.complete++;
    }
  }
}
$('#loaderouterid').css("display","none");
  }

  completeexercisepast(data)
  {
for(let i=0;i<data.data.length;i++)
{
  for(let k=0;k<data.data[i].exercise.length;k++)
  {
    if(data.data[i].exercise[k].watch=='1')
    {
      console.log("INDEXXXXXXXXXX")
      this.completepast++;
    }
  }
}
$('#loaderouterid').css("display","none");
  }
  //Kunde
  Cus() {
    if (
      this.userService.getDecodedAccessToken(localStorage.getItem("token"))
        .roles == "customer"
    ) {
      this.showCustomer = false;
    }
  }

  //User Logout
  logout() {
    localStorage.removeItem("token");
    this.router.navigate(["./"]);
  }

 
  //Check if the user is loggedIn or not


  patient_dashboard()
  {
    this.router.navigate(["/patient-dashboard"], {
      queryParams: { id: this.queryID }
    });
  }


}

