import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../user.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {
  currentActiveRole = sessionStorage.getItem('currentActiveRole');
  constructor( private UserService:UserService,private router:Router,private activateRoutes:ActivatedRoute) {  
     if(this.currentActiveRole!='admin')
  {
    this.gotodashboard();
  }
   }

  ngOnInit() {
  }
  gotodashboard()
    {
      console.log(this.currentActiveRole);
     if(this.currentActiveRole=='b2b')
     {
      this.router.navigate(['./b2b-dashboard']);
     }
     if(this.currentActiveRole=='employee')
     {
      this.router.navigate(['./employee-dashboard']);
     }
     if(this.currentActiveRole=='superadmin')
     {
      this.router.navigate(['./superadmin-dashboard']);
     }
     if(this.currentActiveRole=='admin')
     {
      this.router.navigate(['./admin-dashboard']);
     }
     if(this.currentActiveRole=='customer')
     {
      this.router.navigate(['./dashboard']);
     }
    }
}
