import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

import { UserService } from "../user.service";

@Component({
  selector: 'app-doctor-membership-plan',
  templateUrl: './doctor-membership-plan.component.html',
  styleUrls: ['./doctor-membership-plan.component.css']
})
export class DoctorMembershipPlanComponent implements OnInit {


    queryID:any;
    routeParams: any;
    constructor(private userService: UserService,
      private activatedRoute: ActivatedRoute,
      public router: Router,
    ) {
        this.activatedRoute.queryParams.subscribe(params => {
          this.queryID = params['name'];
         
          // this.statusCustomer();
          // this.userService.statusCustomer({ "id": this.pagedItems[0]._id })
        });
  
  
  
       }
      title = this.userService.getDecodedAccessToken(sessionStorage.getItem("Token"))
      .title;
      currentActiveRole = sessionStorage.getItem('currentActiveRole');
    lastname = this.userService.getDecodedAccessToken(
      sessionStorage.getItem("Token")
    ).lastname;
    firstname = this.userService.getDecodedAccessToken(
      sessionStorage.getItem("Token")
    ).firstname;
    COMPANYNAME = this.userService.getDecodedAccessToken(
      sessionStorage.getItem("Token")
    ).companyname;
  
    // loginRole = this.userService.getDecodedAccessToken(sessionStorage.getItem('token')).roles;
    loginRole = sessionStorage.getItem("currentActiveRole");
    ngOnInit() {
      let data = $("#goinsidediv").val();
      console.log("dddddddddddddddddddddddddddd" + data);
      this.routeParams = this.activatedRoute.snapshot.routeConfig.path;
    }
  
  
    add_doctor_membershipplan() {
      this.router.navigate(['/add-doctor-membershipplan']);
      // this.queryID = this.selectedUser.customerno;
      // this.ngOnInit()
    }
    doctor_membershipplan_management()
    {
      this.router.navigate(['/doctor-membershipplan-management']);
    }
   
  
  

}
