import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { UserService } from "../user.service";
import intlTelInput from "intl-tel-input";
@Component({
  selector: 'app-maindaten',
  templateUrl: './maindaten.component.html',
  styleUrls: ['./maindaten.component.css']
})
export class MaindatenComponent implements OnInit {

  api_url;
  toolslist;
  countryName:any;
  fiorettourl;
  persionaldetails:FormGroup
  companyTitle:any=false;
  constructor(private route: ActivatedRoute, private UserService: UserService,private router:Router,private formbuilder:FormBuilder) {
 
  }
  telInputObject(obj) {
    console.log(obj);
    obj.setCountry("de");
  }
  ngAfterViewInit() {
    const input = document.querySelector("#phone");
    console.log("querySelector" + input);
    intlTelInput(input, {
      //utilsScript: "build/js/utils.js",
    });
  }
  ngOnInit() {


    this.persionaldetails=this.formbuilder.group({
      title:["",Validators.required],
      companyName:["",Validators.required],
  
      firstName:["",Validators.required],
      lastName:["",Validators.required],
      phone_number:["",Validators.required],
      email:["",[Validators.required,Validators.email]],
      street:["",Validators.required],
      streetNumber:["",Validators.required],
      postCode:["",Validators.required],
      city:["",Validators.required],
      additionalReference:["" ],
      countryOfResidence:["",Validators.required]
    })
    
    $("#loaderouterid").css("display", "block");
    this.fiorettourl = environment.fiorettourl;
    this.api_url = environment.API_URL;
    this.UserService.appuserdatabyid('').subscribe((result: any) => {
 
      this.persionaldetails.patchValue({
        title:result.data.title,
        companyName:result.data.companyname,
        firstName:result.data.firstname,
        lastName:result.data.lastname,
        phone_number:result.data.contactno,
        email:result.data.email,
        street:result.data.strassa,
        streetNumber:result.data.strno,
        postCode:result.data.plz,
        city:result.data.city,
        additionalReference:result.data.additionalReference,
        countryOfResidence:result.data.current_country,
      })
      console.log(result);
      $("#loaderouterid").css("display", "none");
    });
  }

  titleChange()
  {
    if (this.persionaldetails.get("title").value == "Firma") {
      this.companyTitle = true;
      this.persionaldetails.patchValue({ companyName: "" });
    } else {
      this.companyTitle = false;
      this.persionaldetails.patchValue({ companyName: " " });
    }
  }
  

  handleAllFields(data) {
    console.log("original data = ", data);
    const splitArr = data.address_components;
    this.getAllFields(splitArr);
  }
  
  handleAddressChangeland(data) {
    const splitArr = data.address_components;
    this.getCountryland(splitArr);
  }
  getCountryland(data): any {
    let that = this;
    const splitArr = data;
    // console.log(splitArr);
    splitArr.forEach(function (i, k) {
      let content: any = i.types;
      //// console.log(content);
      if (content.length > 1) {
        const countryArr = content;
        for (let index = 0; index < content.length; index++) {
          if (countryArr[index] === "country") {
            that.countryName = i.long_name;
          }

          let localityCountry = that.countryName;
          that.persionaldetails.patchValue({
            countryOfResidence: localityCountry,
          });
        }
      }
    });
  }
  getAllFields(data): any {
    let that = this;
    const splitArr = data;
    let str = "";
    //console.log("data", splitArr);
    splitArr.forEach(function (i, k) {
      let content: any = i.types;
      const found = content.find((element) => (element = "street_number"));
      if (found === "street_number") {
        that.persionaldetails.patchValue({ streetNumber: i.short_name });
      } else if (found === "postal_code") {
       that.persionaldetails.patchValue({ postCode: i.short_name });
      } else if (found === "route") {
        str = i.short_name;
      }
      if (content.length > 1) {
        const countryArr = content;
        ////console.log(countryArr);
        for (let index = 0; index < content.length; index++) {
          if (countryArr[index] === "country") {
            console.log("country", i);
            that.persionaldetails.patchValue({
              countryOfResidence: i.long_name,
            });
          }
          if (countryArr[index] === "locality") {
           that.persionaldetails.patchValue({ city: i.short_name });
          }
        }
      }
    });
    if (str.length > 0) {
     that.persionaldetails.patchValue({ street: str });
    } else {
     that.persionaldetails.patchValue({ street: "" });
    }
  }


}
