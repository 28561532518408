

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTabChangeEvent } from '@angular/material';
import { UserService } from '../user.service';
import * as $ from "jquery"
@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
  routeParams:any;
  headername;
  constructor(
    private activatedRoute: ActivatedRoute, 
    private router: Router,
    private userService: UserService,
    ) { }

    activeTab = '0';
    activeTabHeading = ""
    ngOnInit() {
    
      this.routeParams = this.activatedRoute.snapshot.routeConfig.path;
     
      this.headername="Privacy Policy";

  }

 

  


 
}
