import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "../user.service";


@Component({
  selector: 'app-prevention-courses',
  templateUrl: './prevention-courses.component.html',
  styleUrls: ['./prevention-courses.component.css']
})
export class PreventionCoursesComponent implements OnInit {
  queryID:any;
  routeParams: any;
  constructor(private userService: UserService,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    ) {
      this.activatedRoute.queryParams.subscribe(params => {
        this.queryID = params['name'];
       
        // this.statusCustomer();
        // this.userService.statusCustomer({ "id": this.pagedItems[0]._id })
      });



     }
    title = this.userService.getDecodedAccessToken(sessionStorage.getItem("Token"))
    .title;
    currentActiveRole = sessionStorage.getItem('currentActiveRole');
  lastname = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).lastname;
  firstname = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).firstname;
  COMPANYNAME = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).companyname;

  // loginRole = this.userService.getDecodedAccessToken(sessionStorage.getItem('token')).roles;
  loginRole = sessionStorage.getItem("currentActiveRole");
  ngOnInit() {
    let data = $("#goinsidediv").val();
    console.log("dddddddddddddddddddddddddddd" + data);
    this.routeParams = this.activatedRoute.snapshot.routeConfig.path;
  }
  

  logout() {
    console.log("in logout function");

    localStorage.removeItem("token");
    this.router.navigate(["./"]);
  }
  navigateWithexcersicename() {
    this.router.navigate(['/exercise_name'], { queryParams: { name: 'prevention_course' } });
    // this.queryID = this.selectedUser.customerno;
    // this.ngOnInit()
  }
  add_exercise() {
    this.router.navigate(['/add-prevention-course']);
    // this.queryID = this.selectedUser.customerno;
    // this.ngOnInit()
  }
  exercise_manage()
  {
    this.router.navigate(['/prevention-course-list']);
  }
  navigateWithexcersicename1()
  {
    this.router.navigate(['/exercise_name'], { queryParams: { name: 'therapeuts_praxen' } });
  }
}


