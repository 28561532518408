import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import intlTelInput from "intl-tel-input";
import Swal from 'sweetalert2';
import { UserService } from '../user.service';
import { environment } from 'src/environments/environment';
import { first } from 'rxjs/operators';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { jsPDF } from "jspdf";

@Component({
  selector: 'app-purchase-patient-prevention-course',
  templateUrl: './purchase-patient-prevention-course.component.html',
  styleUrls: ['./purchase-patient-prevention-course.component.css']
})

export class PurchasePatientPreventionCourseComponent implements OnInit {
  public payPalConfig?: IPayPalConfig;
  showSuccess:any;
  headername:any;
  pagename:any;
  companyTitle:boolean=false
  persionaldetails:FormGroup
  businessdetails:FormGroup
  businessdetails1:FormGroup
  paymentdetails:FormGroup
  paymentdetailslogin:FormGroup
  paymentdetailsloginmethod:FormGroup
  imagePath:any
  imgURL:any
  plandetails:any;
  amount:any
  id:any;
  api_url:any;
  granttotal:any;
  autoGeneratedCustomerNumber:any;
  customerNo:any;
  vipprice:any;
  coupencodeb2b='';
  countryName:any;
  netprice:any;
  discount=0;
  discountamount;
  userid='';
  b2bid='';
  todaydate=new Date();
sessionb2bid;
sessionpreventionid;
sessionuserid;
checkboxaccept:any=false;
  token = this.UserService.getdecode(sessionStorage.getItem('Token'));
  constructor(private formbuilder:FormBuilder,private routers:Router,private router:ActivatedRoute,private UserService:UserService) { 
    this.router.queryParams.subscribe(params => {
      $("#loaderouterid").css("display", "block");   
      this.id = params["id"];
      this.userid = params["userid"];
      this.b2bid = params["b2bid"];
      // sessionStorage.removeItem("preventionid");
      // sessionStorage.removeItem("b2bid");
      // sessionStorage.removeItem("userid");
    
if(this.userid!='' && this.userid!=undefined  && this.b2bid!='' && this.b2bid!=undefined )
{
  if(sessionStorage.getItem('preventionid')==null)
  {
    console.log("userid"+this.userid);
  sessionStorage.setItem('preventionid',this.id);
  sessionStorage.setItem('b2bid',this.b2bid);
  sessionStorage.setItem('userid',this.userid);
if(this.token==null)
{
  console.log("backToLogin");
// window.location.reload();
this.backToLogin();
  // $("#loaderouterid").css("display", "none"); 
}
else{
if(this.token.id!=this.userid)
{
sessionStorage.removeItem("Token");
console.log("backToLogin");
// window.location.reload();
this.backToLogin();
}
else
{
  // sessionStorage.removeItem("Token");
  // window.location.reload();
  $("#loaderouterid").css("display", "none"); 
}
}
  }
  else{
    $("#loaderouterid").css("display", "none"); 
  }
}
else{

  $("#loaderouterid").css("display", "none"); 
}

    console.log(this.id);
    });

  }

  telInputObject(obj) {
    console.log(obj);
    obj.setCountry("de");
  }


  backToLogin() {
console.log("backToLogin");
this.UserService.editUserwithoutlogin(this.userid).subscribe((resultnew)=>{
  this.UserService
  .apploginwithoutpass(resultnew.email, resultnew.password)
  .subscribe(
    (dataUSER: any) => {
    
      sessionStorage.setItem("Token",dataUSER.token);
      sessionStorage.setItem("currentUser", JSON.stringify(dataUSER.user));
      setTimeout(() => {
        sessionStorage.setItem('currentActiveRole', "customer");
        window.location.reload();
        // $('#closemodal').trigger('click');
        // this.routers.navigate(["./dashboard"]);
      }, 100);
       
     
    },

    (error) => {
      Swal.fire("  Ungültiger Benutzername und oder Passwort","","error")
      this.routers.navigate([""]);
    }
  );

})
  }

  initConfig() {
    this.payPalConfig = {
    currency: 'EUR',
    vault: "false",
    clientId: environment.PAYPAL_CLIENTID,
    createOrderOnClient: (data) => <ICreateOrderRequest>{
      intent: 'CAPTURE',
      purchase_units: [
        {
          amount: {
            currency_code: 'EUR',
            value: this.granttotal.replace(",","."),
            breakdown: {
              item_total: {
                currency_code: 'EUR',
                value: this.granttotal.replace(",",".")
              }
            }
          },
          items: [
            {
              name: this.plandetails.name,
              quantity: '1',
              category: 'DIGITAL_GOODS',
              unit_amount: {
                currency_code: 'EUR',
                value: this.granttotal.replace(",","."),
              },
            }
          ]
        }
      ]
    },
    advanced: {
      commit: 'true',
      extraQueryParams: [  { name: "enable-funding", value:"sepa,sofort,giropay" } ]
    },
    
    style: {
      tagline: false,
      label: 'paypal',
      layout: 'vertical',
      size: 'responsive',
      height: 43,
    },
    onApprove: (data, actions) => {
      // console.log('onApprove - transaction was approved, but not authorized', data, actions);
      actions.order.get().then(details => {
        console.log('onApprove - you can get full order details inside onApprove: ', details);
      });
    },
    onClientAuthorization: (data11:any) => {
      console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data11);
  //  console.log(data.links);
  //  console.log(data.payer);
  //  console.log(data.create_time);
  //  console.log(data.id);
  //  console.log(data.intent);
  // let datanew:any=data11;
   console.log(data11.purchase_units[0].payments.captures[0].id);
  //  console.log(data.status);
  //  console.log(data.update_time);
  $("#loaderouterid").css("display", "block");
if(this.token==null)
{

  this.paynow(data11.purchase_units[0].payments.captures[0].id)
      
}
else{

  this.paynowwithlogin(data11.purchase_units[0].payments.captures[0].id)
 
 
}
      this.showSuccess = true;
    },
    onCancel: (data, actions) => {
      console.log('OnCancel', data, actions);
      $("#loaderouterid").css("display", "none");
    },
    onError: err => {
      console.log('OnError', err);
      $("#loaderouterid").css("display", "none");
    },
    onClick: (data, actions) => {
      // $("#loaderouterid").css("display", "block");
      console.log('onClick', data, actions);
    },
  };
  }


  ngAfterViewInit() {
    const input = document.querySelector("#phone");
    console.log("querySelector" + input);
    intlTelInput(input, {
      //utilsScript: "build/js/utils.js",
    });

//     let accept:any = document.getElementById("accept");
//     if (accept.checked == true) {
// this.checkboxaccept=true;
//     }
//     else{
//       this.checkboxaccept=false;
//     }

  }

  clickcheck(event) {
    $('#checkboxerror').html("");
    let accept:any = document.getElementById("accept");
    if (accept.checked == true) {
this.checkboxaccept=true;
$('#originalbutton').css("display","block");
$('#simplebutton').css("display","none");
    }
    else{
      this.checkboxaccept=false;
      $('#originalbutton').css("display","none");
$('#simplebutton').css("display","block");
    }
  }


  paypal()
  {
    let accept:any = document.getElementById("accept");
    if (accept.checked == true) {
      $('#checkboxerror').html("");
this.checkboxaccept=true;
    }
    else{
      $('#checkboxerror').html("Bitte akzeptieren Sie unsere Datenschutzbestimmungen und Allgemeinen Geschäftsbedingungen");
      this.checkboxaccept=false;
    }
  }



 



  ngOnInit() {


    // this.exportAsPDF();
console.log("b2bid"+sessionStorage.getItem('b2bid'))


this.sessionb2bid=sessionStorage.getItem('b2bid');
this.sessionpreventionid=sessionStorage.getItem('preventionid');
this.sessionuserid=sessionStorage.getItem('userid');

// if(this.token==null)
// {
//   console.log("tokennull");
//   location.href="/#/online-shop";
// }
// else
// {
// if(this.token.id==this.sessionuserid)
// {
//   console.log("tokenmatch");
// }
// else{
// location.href="/#/online-shop";
// console.log("tokenmatceh");
// }
// }

// this.UserService.getlastinvoice_no("{id:ss}").subscribe((result:any)=>{
//   console.log("this"+result);
//   });
  
  

// this.UserService.getLastdocument("{id:ss}").subscribe((result:any)=>{
// console.log("this"+result);
// });


  this.vipprice=environment.VIPPRICE.replace(".",",");

  this.initConfig();
this.api_url=environment.API_URL
let data={"id":this.id}
$("#loaderouterid").css("display", "block");
this.UserService.getpatientproductplandetails(data).subscribe((result:any)=>{
this.plandetails=result[0];
console.log("exercise_idphasewise"+JSON.stringify(this.plandetails.exercise_idphasewise));
this.discount=0;
this.calculateamount();
$("#loaderouterid").css("display", "none");
})



    this.headername="Purchase Präventionskursn"
    this.pagename="";
    this.persionaldetails=this.formbuilder.group({
      title:["",Validators.required],
      companyName:["",Validators.required],
  
      firstName:["",Validators.required],
      lastName:["",Validators.required],
      phone_number:["",Validators.required],
      email:["",[Validators.required,Validators.email]]
    })
this.paymentdetails=this.formbuilder.group({

})

this.paymentdetailslogin=this.formbuilder.group({

})
this.paymentdetailsloginmethod=this.formbuilder.group({

})

    // this.businessdetails1=this.formbuilder.group({
    //   website_url:["",[Validators.required,Validators.pattern("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?")]],
    //   picpdf:[""],
    
    // })
    this.businessdetails=this.formbuilder.group({

      street:["",Validators.required],
      streetNumber:["",Validators.required],
      postCode:["",Validators.required],
      city:["",Validators.required],
      additionalReference:["" ],
      countryOfResidence:["",Validators.required]
    })
  }

  paynow(paymentdata)
  {

    $("#loaderouterid").css("display", "block");




    let name=this.persionaldetails.controls.title.value+" "+this.persionaldetails.controls.firstName.value+" "+this.persionaldetails.controls.lastName.value
    let price = this.plandetails.price.replace(",", ".");
  
      let fullprice:any;
      if(this.coupencodeb2b!='')
      {
      fullprice = ((price / 100) * (100 - this.plandetails.discount)).toFixed(2);
      }
  else{
    fullprice = price;
  }


$('#name').html(name);
$('#count').html("-Laufzeit: "+this.plandetails.totalexercisecount+" Präventionsübungen");
$('#productprice').html("-Preis: "+fullprice.replace(".", ",")+" € einmalig für den Präventionskurs");
$('#productname').html("-Dein Produkt: "+this.plandetails.name+"");
$('#address1').html(this.businessdetails.controls.street.value+" "+this.businessdetails.controls.streetNumber.value);
$('#address2').html(this.businessdetails.controls.postCode.value+" "+this.businessdetails.controls.city.value);
$('#productpricetotal').html(fullprice.replace(".", ","));
$('#productpricetotal1').html(fullprice.replace(".", ","));
let withouttax:any =   ((fullprice/100)*(100- this.plandetails.tax)).toFixed(2);
let taxamount:any =   ((fullprice/100)*(this.plandetails.tax)).toFixed(2);
$('#withouttax').html(withouttax.replace(".", ",")+" €");
$('#finaltotal').html(fullprice.replace(".", ",")+" €");
$('#taxamount').html(taxamount.replace(".", ",")+" €");
  
if(this.coupencodeb2b!='')
{
$('#discounttext').html("-Rabatt: "+this.plandetails.discount+"%");
}
else{
  $('#discounttext').html("");
}



this.UserService.getlastinvoice_no("{id:ss}").subscribe((resultinvoice:any)=>{
  let year=new Date().getFullYear();
let invoice_no="60-co-"+resultinvoice;
$('#invoice_no1').html(invoice_no+"/"+year);
$('#invoice_no2').html(invoice_no+"/"+year);


    let statusbrandarraynew=['Coursula']
    let b2b_id=this.coupencodeb2b;
    let coupen_code=$('#coupen_code').val();
    let statusarraynew=['Kunde']
    let brokerbrandarraynew = [];
    let brokerarraynew = [];
        let roles = ['customer'];
        // let companies = ['coursula'];
        // let rolesCompaniesArray = ['coursula_customer']
        let companies = ['ontrain'];
        let rolesCompaniesArray = ['ontrain_customer']
    
        let data = {
          roles: roles,
          companies: companies,
          companies_with_roles: rolesCompaniesArray,
          title: this.persionaldetails.controls.title.value,
          companyname: this.persionaldetails.controls.companyName.value,
          firstname: this.persionaldetails.controls.firstName.value,
          lastname: this.persionaldetails.controls.lastName.value,
          email: this.persionaldetails.controls.email.value,
          dateofbirth: '',
          nationality: '',
          birth_place: '',
          customer_status: 'Kunde',
          strassa: this.businessdetails.controls.street.value,
          strno: this.businessdetails.controls.streetNumber.value,
          plz: this.businessdetails.controls.postCode.value,
          city: this.businessdetails.controls.city.value,
          additionalReference: this.businessdetails.controls.additionalReference.value,
          current_country: this.businessdetails.controls.countryOfResidence.value,
          password: '',
          contactno: this.persionaldetails.controls.phone_number.value,
          customerno: this.customerNo,
          emailsendornot: "1",
          status: '1',
          companycode: ['c42150_customer'],              
          statusbrandarray: statusbrandarraynew,
          statusarray:statusarraynew,
          brokerbrandarray: brokerbrandarraynew,
          brokerarray:brokerarraynew,       
          broker: ''  ,
          attempts : "0",
          blocked_at : "",
          email_sent : "1",
          email_verify : "0",
          mobile_verify : "0",  
          device_type : 0,
          online_status : 0,
          deviceModel : "",
          OSVersion : "",
          appVersion : "",
          name: this.plandetails.name,
          description: this.plandetails.description,
          exercise_id:this.plandetails.exercise_id[0],
          exercise_idphasewise:this.plandetails.exercise_idphasewise,
          noofdays:this.plandetails.noofdays,
          commission: this.plandetails.commission,
          prevention_course_id:this.plandetails.prevention_course_id._id,
          coupen_code:coupen_code,
          price: this.plandetails.price,
          discount: this.plandetails.discount,
      
          b2bid: b2b_id,
          document: this.plandetails.newdocument,
          tax:this.plandetails.tax,
          txn_id:paymentdata,
          exercisecount:this.plandetails.totalexercisecount,
          invoice_no:invoice_no
        }
        let formData = new FormData();
        formData.append("data",JSON.stringify(data))
        this.UserService
        .coursula_customer_register(formData)
        .subscribe(
          (dataresult) => {


            $('#MyDIv').css("display","block");



            let   companycodenew = "60-co-";
            let that=this;
                  var values:any = {
                    image: "",
                    document_type: "",
                    document_sub_type: "",
                    user_id: "",
                    product_partner: "",
                    companycode: "",
                    brand: "",
                    tags: [],
                    upload_by: "",
                    bill_type: "",
                    ticket_no: "",
                    document_name:"",
                    uniquedata:"",
                    invoice_no:"",
pricewithtax:"",
tax:"",
pricewithouttax:"",
year:"",
                  };
                  let uniquedata:string=new Date().getTime().toString();
                  let pdfnew = new jsPDF("portrait", "pt", "a4");
                  var width = pdfnew.internal.pageSize.width;
                  pdfnew.html(document.getElementById("MyDIv"), {
                            html2canvas: {
                              // insert html2canvas options here, e.g.
                              width: width,
                            },
                            callback: function (pdfnew) {
                             
                              this.pdffile = pdfnew.output("blob");
                              
                              values.image = this.pdffile;
                              values.document_type = "Allgemeines Dokument";
                              values.document_sub_type = "Buchhaltung";
                              values.document_name="Prevention Course Invoice";
                              values.user_id = dataresult['id'];
                              values.product_partner = " ";
                      
                              values.companycode = "42160 Jomiretto GmbH";
                              values.brand = "Coursula";
                              values.upload_by = "business_administrator";
                              values.ticket_no = companycodenew;
                              values.invoice_no = invoice_no;
values.pricewithtax = fullprice;
values.tax = taxamount;
values.pricewithouttax = withouttax;
values.year = year;
                              //values.created_by = this.id.toString()
                              values.tags.push(Math.round(this.pdffile.size / 1024));
                              //values.tags.push(MainType);
                              // values.tags.push(Date);
                              values.tags.push("application/pdf");
            
                              values.tags.push(new Date().getTime());
                              values.uniquedata = uniquedata;
            
                              
                              const formData1 = new FormData();
                              formData1.append("document_type", values.document_type);
                              formData1.append("document_sub_type", values.document_sub_type);
                              formData1.append("product_partner", values.product_partner);
                              formData1.append("user", values.user_id);
                              formData1.append("companycode", values.companycode);
                              formData1.append("brand", values.brand);
                              formData1.append("tags", values.tags);
                              formData1.append("upload_by", values.upload_by);
                              formData1.append("bill_type", "Eingehend");
                              formData1.append("ticket_no", values.ticket_no);
                              formData1.append("document_name", values.document_name);
                              formData1.append("uniquedata", values.uniquedata);
                              formData1.append("invoice_no", values.invoice_no);
                              formData1.append("pricewithtax", values.pricewithtax);
                              formData1.append("tax", values.tax);
                              formData1.append("pricewithouttax", values.pricewithouttax);
                              formData1.append("year", values.year);
                              if (values.image !== "") {
                                formData1.append("document", values.image);
                              }
                              $('#MyDIv').css("display","none");


formData1.append("companyname", that.persionaldetails.controls.companyName.value);
formData1.append("lastname", that.persionaldetails.controls.lastName.value);

formData1.append("price", that.plandetails.price);
formData1.append("discount", that.plandetails.discount);
formData1.append("email", that.persionaldetails.controls.email.value);
formData1.append("title", that.persionaldetails.controls.title.value);
formData1.append("product_name", that.plandetails.name);
formData1.append("exercisecount", that.plandetails.totalexercisecount);
formData1.append("coupen_code", coupen_code);



                              // values.tags = [];
                              that.UserService.callApiMultipartnewwithoutlogininpreventioncourse(formData1).subscribe((result) => {
                              let document_unique_id=result.document_unique_id;
                           
        
let purchasemembershipresponsedata=dataresult['purchasemembershipresponse'];

let senddata={"_id":purchasemembershipresponsedata._id,"document_unique_id":document_unique_id}
that.UserService.updatedocument_unique_id(senddata).subscribe((result)=>{
  console.log(data);
//   Swal.fire(
//  "Ihr Präventionskurs wurde erfolgreich gekauft. Bitte überprüfen Sie Ihren E-Mail-Posteingang, um Ihren Login-Link zu erhalten",
//    "",
//     "success",
//   );
//   setTimeout(() => {
//     Swal.close();
//     $("#loaderouterid").css("display", "none");
//     sessionStorage.removeItem("preventionid");
//     sessionStorage.removeItem("b2bid");
//     sessionStorage.removeItem("userid");
//     that.routers.navigate(["./patient-product"]);

//   }, 1000);

  Swal.fire({
    title: '',
    html: "<div style='text-align: right;cursor:pointer;position: absolute;right: 12px;top: 11px;font-size: 27px;' id='gotopreventioncourse11'><i class='fa fa-times'></i></div><br><br><span style='font-size: 26px;'>Herzlichen Glückwunsch, du hast Erfolgreich den Präventionskurs "+that.plandetails.name+" erworben. Wir haben Dir eine E-Mail gesandt mit welcher du deinen Login aktivieren kannst." +
        "</span><br><br><br>" +
        '<button type="button" role="button" id="gotopreventioncourse" tabindex="0" class="SwalBtn1 btn btn-primary customSwalBtn">OK</button>',
    showCancelButton: false,
    showConfirmButton: false,
    allowOutsideClick: false
});
const someInput = document.getElementById("gotopreventioncourse");
someInput.addEventListener(
  "click",
  function () {
    Swal.close();
    that.routers.navigate(["./"]);
  },
  false
);
const someInput11 = document.getElementById("gotopreventioncourse11");
someInput11.addEventListener(
  "click",
  function () {
    Swal.close();
    that.routers.navigate(["./"]);
  },
  false
);




})







         


          })
        }

        });







          },
          (error) => {
            console.log(error);
            Swal.fire("E-Mail-ID bereits verwendet",
            "",
               "error",
            );
            // Swal.fire(error,"","error")
            $("#loaderouterid").css("display", "none");
          }
        );

});
  
console.log(this.amount)
  }

calculateamount()
{
  let price=this.plandetails.price.replace(",",".");
this.netprice= (
  (price / 100) *
  (100 - this.discount)
).toFixed(2).replace(".",",");

this.discountamount=((price / 100)*(this.discount)).toFixed(2).replace(".",",");
this.amount=((price/100)
  * (100-this.discount)).toFixed(2)
  this.granttotal=parseFloat(this.amount).toFixed(2).replace(".",",");
}

cancelcoupencode()
{
  $("#loaderouterid").css("display", "block");
  $('#coupencodemsg').html("");
  $('#coupen_code').val("");
  this.discount=0;
this.coupencodeb2b='';
this.calculateamount();
$('#cancelid').css("display","none");
$('#applyid').css("display","block");
$('#afterappliedcoupen').css("display","none");
$("#loaderouterid").css("display", "none");
}
  checkcoupencode()
  {
    $("#loaderouterid").css("display", "block");
    let coupen_code=$('#coupen_code').val();
    this.coupencodeb2b='';
    $('#cancelid').css("display","none");
    $('#applyid').css("display","block");
    $('#afterappliedcoupen').css("display","none");
    
    if(coupen_code.replace(' ','')=='')
    {
    $('#coupencodemsg').html("Bitte Gutscheincode eingeben");
    $('#coupencodemsg').css("color","red");
    $("#loaderouterid").css("display", "none");
    }
    else{

      let data={id:this.id,coupen_code:coupen_code}
this.UserService.checkcoupencode(data).subscribe((result:any)=>{
if(result.length>0)
{
  $('#coupencodemsg').html("Gutscheincode angewendet "+result[0].discount+" %");
  $('#coupencodemsg').css("color","green");
  $('#cancelid').css("display","block");
  $('#applyid').css("display","none");
  $('#afterappliedcoupen').css("display","block");
this.discount=result[0].discount;
console.log("b2b_id1"+result[0].b2bid._id);
this.coupencodeb2b=result[0].b2bid._id
this.calculateamount();
}
else{
  $('#coupencodemsg').html("Bitte geben Sie einen gültigen Gutscheincode ein");
  $('#coupencodemsg').css("color","red");
  $('#coupen_code').val("");
  
}
console.log("result"+result)
console.log("b2b_id1"+this.coupencodeb2b);
$("#loaderouterid").css("display", "none");
})


    }
    
  }



exportAsPDF() {

  let data = document.getElementById("MyDIv");
  let pdf = new jsPDF("portrait", "pt", "a4");
  var width = pdf.internal.pageSize.width;
  pdf.html(document.getElementById("MyDIv"), {
    html2canvas: {
      width: width,
    },
    callback: function (pdf) {
      pdf.save("MaklerVollmacht.pdf");
    },
  });
}


  paynowwithlogin(paymentdata)
  {

    console.log("b2b_id1"+this.coupencodeb2b);



    this.UserService.editUserwithoutlogin(this.token.id).subscribe((resultnew)=>{
      console.log("b2b_id1"+this.coupencodeb2b);


    let name=this.token.title+" "+this.token.firstname+" "+this.token.lastname
    let price = this.plandetails.price.replace(",", ".");
    let fullprice:any;
    if(this.coupencodeb2b!='')
    {
    fullprice = ((price / 100) * (100 - this.plandetails.discount)).toFixed(2);
    }
else{
  fullprice = price;
}
      
$('#name').html(name);
$('#count').html("-Laufzeit: "+this.plandetails.totalexercisecount+" Präventionsübungen");
$('#productprice').html("-Preis: "+fullprice.replace(".", ",")+" € einmalig für den Präventionskurs");
$('#productname').html("-Dein Produkt: "+this.plandetails.name+"");
$('#address1').html(resultnew.strassa+" "+resultnew.strno);
$('#address2').html(resultnew.plz+" "+resultnew.city);
$('#productpricetotal').html(fullprice.replace(".", ","));
$('#productpricetotal1').html(fullprice.replace(".", ","));
let withouttax:any =   ((fullprice/100)*(100- this.plandetails.tax)).toFixed(2);
let taxamount:any =   ((fullprice/100)*(this.plandetails.tax)).toFixed(2);
$('#withouttax').html(withouttax.replace(".", ",")+" €");
$('#finaltotal').html(fullprice.replace(".", ",")+" €");
$('#taxamount').html(taxamount.replace(".", ",")+" €");
if(this.coupencodeb2b!='')
{
$('#discounttext').html("-Rabatt: "+this.plandetails.discount+"%");
}
else{
  $('#discounttext').html("");
}
this.UserService.getlastinvoice_no("{id:ss}").subscribe((result:any)=>{
  let year=new Date().getFullYear();
let invoice_no="60-co-"+result;
$('#invoice_no1').html(invoice_no+"/"+year);
$('#invoice_no2').html(invoice_no+"/"+year);

$('#MyDIv').css("display","block");

            let   companycodenew = "60-co-";
      let that=this;
            var values:any = {
              image: "",
              document_type: "",
              document_sub_type: "",
              user_id: "",
              product_partner: "",
              companycode: "",
              brand: "",
              tags: [],
              upload_by: "",
              bill_type: "",
              ticket_no: "",
              document_name:"",
              uniquedata:"",
              invoice_no:"",
              pricewithtax:"",
              tax:"",
              pricewithouttax:"",
              year:"",
            };
            let uniquedata:string=new Date().getTime().toString();
            let data = document.getElementById("MyDIv");
            console.log("data"+JSON.stringify(data));
            let pdfnew = new jsPDF("portrait", "pt", "a4");
            var width = pdfnew.internal.pageSize.width;
            console.log("data"+width);
            pdfnew.html(document.getElementById("MyDIv"), {
                      html2canvas: {
                        // insert html2canvas options here, e.g.
                        width: width,
                      },
                      callback: function (pdfnew) {

                       
                        // pdfnew.save("MaklerVollmacht.pdf");
                        this.pdffile = pdfnew.output("blob");
                        values.image = this.pdffile;
                        values.document_type = "Allgemeines Dokument";
                        values.document_sub_type = "Buchhaltung";
                        values.document_name="Prevention Course Invoice";
                        values.user_id = that.token.id;
                        values.product_partner = " ";
                
                        values.companycode = "42160 Jomiretto GmbH";
                        values.brand = "Coursula";
                        values.upload_by = "business_administrator";
                        values.ticket_no = companycodenew;
                        values.invoice_no = invoice_no;
                        values.pricewithtax = fullprice;
                        values.tax = taxamount;
                        values.pricewithouttax = withouttax;
                        values.year = year;
                        //values.created_by = this.id.toString()
                        values.tags.push(Math.round(this.pdffile.size / 1024));
                        //values.tags.push(MainType);
                        // values.tags.push(Date);
                        values.tags.push("application/pdf");

                        values.tags.push(new Date().getTime());
                        values.uniquedata = uniquedata;
                        $('#MyDIv').css("display","none");
                        
                        const formData1 = new FormData();
                        formData1.append("document_type", values.document_type);
                        formData1.append("document_sub_type", values.document_sub_type);
                        formData1.append("product_partner", values.product_partner);
                        formData1.append("user", values.user_id);
                        formData1.append("companycode", values.companycode);
                        formData1.append("brand", values.brand);
                        formData1.append("tags", values.tags);
                        formData1.append("upload_by", values.upload_by);
                        formData1.append("bill_type", "Eingehend");
                        formData1.append("ticket_no", values.ticket_no);
                        formData1.append("document_name", values.document_name);
                        formData1.append("uniquedata", values.uniquedata);
                        formData1.append("invoice_no", values.invoice_no);
                        formData1.append("pricewithtax", values.pricewithtax);
                        formData1.append("tax", values.tax);
                        formData1.append("pricewithouttax", values.pricewithouttax);
                        formData1.append("year", values.year);
                        if (values.image !== "") {
                          formData1.append("document", values.image);
                        }
                        console.log("b2b_id1"+this.coupencodeb2b);

                        // values.tags = [];
                        that.UserService.uploaddocumentnewinpreventioncourse(formData1).subscribe((result) => {
                        let document_unique_id=result.document_unique_id;
                     


                        console.log("b2b_id1"+that.coupencodeb2b);



                        let b2b_id='';
                        if(that.sessionuserid!=null)
                        {
                        if(that.token.id==that.sessionuserid)
                        {
                          b2b_id=that.sessionb2bid;
                          console.log("b2b_id3"+b2b_id);
                        }
                        else
                        {
                          b2b_id=that.coupencodeb2b;
                          console.log("b2b_id2"+b2b_id);
                        }
                      }
                      else{
                        b2b_id=that.coupencodeb2b;
                        console.log("b2b_id1"+b2b_id);
                      }
                      console.log("b2b_id"+b2b_id);
                        let coupen_code=$('#coupen_code').val();
                        $("#loaderouterid").css("display", "block");
                            let data = {
                              title: that.token.title,
                              firstname: that.token.firstname,
                              lastname: that.token.lastname,
                              email: that.token.email,
                              companyname:that.token.companyname,
                              created_by: that.token.id,
                              name: that.plandetails.name,
                              description: that.plandetails.description,
                              exercise_id:that.plandetails.exercise_id[0],
                              noofdays:that.plandetails.noofdays,
                              exercise_idphasewise:that.plandetails.exercise_idphasewise,
                              prevention_course_id:that.plandetails.prevention_course_id._id,
                              coupen_code:coupen_code,
                              price: that.plandetails.price,
                              commission: that.plandetails.commission,
                              discount: that.plandetails.discount,
                              
                              b2bid: b2b_id,
                              document: that.plandetails.newdocument,
                              tax:that.plandetails.tax,
                              txn_id:paymentdata,
                              document_unique_id:document_unique_id,
                              exercisecount:that.plandetails.totalexercisecount,
                              invoice_no:invoice_no
                            }
                            let formData = new FormData();
                            formData.append("data",JSON.stringify(data))
                            that.UserService
                            .coursula_customer_register(formData)
                            .subscribe(
                              (data) => {

                                Swal.fire({
                                  title: '',
                                  html: "<div style='text-align: right;cursor:pointer;position: absolute;right: 12px;top: 11px;font-size: 27px;' id='gotopreventioncourse11'><i class='fa fa-times'></i></div><br><br><span style='font-size: 26px;'>Herzlichen Glückwunsch, du hast Erfolgreich den Präventionskurs "+that.plandetails.name+" erworben. Du kannst deinen Präventionskurs im Mitgliedschaftsbereich Präventionskurs starten." +
                                      "</span><br><br><br>" +
                                      '<button type="button" role="button" id="gotopreventioncourse" tabindex="0" class="SwalBtn1 btn btn-primary customSwalBtn">OK</button>',
                                  showCancelButton: false,
                                  showConfirmButton: false,
                                  allowOutsideClick: false,
                                  // showCloseButton: true,
                                  icon:"success",
                              });
                              const someInput = document.getElementById("gotopreventioncourse");
                              someInput.addEventListener(
                                "click",
                                function () {
                                  Swal.close();
                                  that.routers.navigate(["./prevention-course"]);
                                },
                                false
                              );
                              const someInput11 = document.getElementById("gotopreventioncourse11");
                              someInput11.addEventListener(
                                "click",
                                function () {
                                  Swal.close();
                                  that.routers.navigate(["./prevention-course"]);
                                },
                                false
                              );
                              
                              

                              //   Swal.fire({
                              //     title: '',
                              //     html: "<span style='font-size: 26px;'>Ihr Präventionskurs wurde erfolgreich gekauft. Bitte überprüfen Sie Ihren E-Mail-Posteingang, um Ihren Login-Link zu erhalten" +
                              //         "</span><br><br><br>" +
                              //         '<button type="button" role="button" id="gotopreventioncourse" tabindex="0" class="SwalBtn1 btn btn-primary customSwalBtn">OK</button>',
                              //     showCancelButton: false,
                              //     showConfirmButton: false,
                              //     allowOutsideClick: false
                              // });
                              // const someInput = document.getElementById("gotopreventioncourse");
                              // someInput.addEventListener(
                              //   "click",
                              //   function () {
                              //     Swal.close();
                              //     that.routers.navigate(["./prevention-course"]);
                              //   },
                              //   false
                              // );
                        
                                // Swal.fire(
                                //   "Ihr Präventionskurs wurde erfolgreich gekauft. Bitte überprüfen Sie Ihren E-Mail-Posteingang, um Ihren Login-Link zu erhalten",
                                //     "",
                                //      "success",
                                //    );
                                   setTimeout(() => {
                                    //  Swal.close();
                                     $("#loaderouterid").css("display", "none");
                                     sessionStorage.removeItem("preventionid");
                                     sessionStorage.removeItem("b2bid");
                                     sessionStorage.removeItem("userid");
                                    //  that.routers.navigate(["./prevention-course"]);
                                   }, 1000);
         






 
                              },
                              (error) => {
                                console.log(error);
                                Swal.fire("E-Mail-ID bereits verwendet",
                                "",
                                   "error",
                                );
                                // Swal.fire(error,"","error")
                                $("#loaderouterid").css("display", "none");
                              }
                            );










                        })
                        }
      
                        });


                      });
                      });





  
console.log(this.amount)
  }


  checkemail()
  {
    let data={email:this.persionaldetails.controls.email.value}
      this.UserService
      .checkemail(data)
      .pipe(first())
      .subscribe((data11) => {
  if(data11['status']=='200')
  {
    $('#checkemailerror').html("")
  }
  else{
    $('#checkemailerror').html("E-Mail-ID ist nicht verfügbar")
  }
  });
  
  }


  nextCompanies() {
 let data={email:this.persionaldetails.controls.email.value}
    this.UserService
    .checkemail(data)
    .pipe(first())
    .subscribe((data11) => {
if(data11['status']=='200')
{
    let company_array = [];

      company_array = company_array.concat("coursula");
    
    let temp_set = new Set(company_array);
    company_array = Array.from(temp_set);
    this.UserService
      .getLastUser()
      .pipe(first())
      .subscribe((data) => {
        this.autoGeneratedCustomerNumber = data.toString();
        var nums = [this.autoGeneratedCustomerNumber];
        var nums1 = [];
        var c_100 = [];
        var c_110 = [];
        var c_120 = [];
        var c_130 = [];
        var c_140 = [];
        var c_150 = [];
        // console.log("CUSTOMERNO"+nums)
        var tmp = company_array; //this.companyArr;
        if (tmp) {
          tmp.forEach(function (i, k) {
            var cusNo = i.substring(0, 2);
            if (cusNo == "ce" || cusNo == "st") {
              c_140.push(cusNo);
            }
            if (
              cusNo == "fi" ||
              cusNo == "fi" ||
              cusNo == "ch" ||
              cusNo == "ai"
            ) {
              c_130.push(cusNo);
            }
            if (cusNo == "va" || cusNo == "bi") {
              c_120.push(cusNo);
            }
            if (cusNo == "co") {
              c_150.push(cusNo);
            }
          });
          if (c_140.length > 0) {
            c_140.unshift("40");
          }
          if (c_130.length > 0) {
            c_130.unshift("30");
          }
          if (c_120.length > 0) {
            c_120.unshift("20");
          }
          if (c_150.length > 0) {
            c_150.unshift("60");
          }
          nums1 = nums1.concat(c_150);
          nums1 = nums1.concat(c_140);
          nums1 = nums1.concat(c_130);
          nums1 = nums1.concat(c_120);

          nums1.unshift(this.autoGeneratedCustomerNumber);
          console.log("cdata" + nums1);
          var cusNo = nums1.join("-");
          this.customerNo = cusNo;
          console.log("CUSTOMERNO" + nums);
          return this.customerNo;
        }
      });
    }
else{
  Swal.fire("Email Id is already registered. Please click on Login button","","error")
  setTimeout(() => {
    Swal.close();
    $('#backfirst').trigger('click')
  }, 1000);
}
    });
    // setTimeout(()=>{
    //   $('#phone').trigger('click');
    // },1000)
  }
  handleAllFields(data) {
    console.log("original data = ", data);
    const splitArr = data.address_components;
    this.getAllFields(splitArr);
  }
  
  handleAddressChangeland(data) {
    const splitArr = data.address_components;
    this.getCountryland(splitArr);
  }
  getCountryland(data): any {
    let that = this;
    const splitArr = data;
    // console.log(splitArr);
    splitArr.forEach(function (i, k) {
      let content: any = i.types;
      //// console.log(content);
      if (content.length > 1) {
        const countryArr = content;
        for (let index = 0; index < content.length; index++) {
          if (countryArr[index] === "country") {
            that.countryName = i.long_name;
          }

          let localityCountry = that.countryName;
          that.businessdetails.patchValue({
            countryOfResidence: localityCountry,
          });
        }
      }
    });
  }
  getAllFields(data): any {
    let that = this;
    const splitArr = data;
    let str = "";
    //console.log("data", splitArr);
    splitArr.forEach(function (i, k) {
      let content: any = i.types;
      const found = content.find((element) => (element = "street_number"));
      if (found === "street_number") {
        that.businessdetails.patchValue({ streetNumber: i.short_name });
      } else if (found === "postal_code") {
       that.businessdetails.patchValue({ postCode: i.short_name });
      } else if (found === "route") {
        str = i.short_name;
      }
      if (content.length > 1) {
        const countryArr = content;
        ////console.log(countryArr);
        for (let index = 0; index < content.length; index++) {
          if (countryArr[index] === "country") {
            console.log("country", i);
            that.businessdetails.patchValue({
              countryOfResidence: i.long_name,
            });
          }
          if (countryArr[index] === "locality") {
           that.businessdetails.patchValue({ city: i.short_name });
          }
        }
      }
    });
    if (str.length > 0) {
     that.businessdetails.patchValue({ street: str });
    } else {
     that.businessdetails.patchValue({ street: "" });
    }
  }
  previewpdf(files)
  {
    console.log("files.length" + files.length);
    if (files.length === 0) {
      return;
    }
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
     Swal.fire("Only images are supported.","","error")
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = _event => {
      this.imgURL = reader.result;
    };
  }
  changepdf()
  {
    $("#picpdf").trigger("click");
  }


  titleChange()
  {
    if (this.persionaldetails.get("title").value == "Firma") {
      this.companyTitle = true;
      this.persionaldetails.patchValue({ companyName: "" });
    } else {
      this.companyTitle = false;
      this.persionaldetails.patchValue({ companyName: " " });
    }
  }

}
