import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import intlTelInput from "intl-tel-input";
import Swal from "sweetalert2";
import { UserService } from "../user.service";
import { environment } from "src/environments/environment";
import { first } from 'rxjs/operators';
import { jsPDF } from "jspdf";
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
@Component({
  selector: "app-purchase-doctor-plan",
  templateUrl: "./purchase-doctor-plan.component.html",
  styleUrls: ["./purchase-doctor-plan.component.css"],
})
export class PurchaseDoctorPlanComponent implements OnInit {
  public payPalConfig?: IPayPalConfig;
  showSuccess:any;
  headername: any;
  pagename: any;
  companyTitle: boolean = false;
  persionaldetails: FormGroup;
  businessdetails: FormGroup;
  businessdetails1: FormGroup;
  paymentdetails: FormGroup;
  imagePath: any;
  imgURL: any;
  plandetails: any;
  amount: any;
  id: any;
  api_url: any;
  premium:any;
  todaydate=new Date();
  vipprice:any;
  checkboxaccept:any=false;
  countryName: any;
  customerNo:any;
  granttotal:any
  netprice:any;
  autoGeneratedCustomerNumber:any;
  constructor(
    private formbuilder: FormBuilder,
    private router: ActivatedRoute,
    private UserService: UserService,
    private routers:Router
  ) {
    this.router.queryParams.subscribe((params) => {
      this.id = params["id"];
      this.premium = params["premium"];
      console.log(this.id);
    });
  }
  telInputObject(obj) {
    console.log(obj);
    obj.setCountry("de");
  }
  
  paypal()
  {
    let accept:any = document.getElementById("accept");
    if (accept.checked == true) {
      $('#checkboxerror').html("");
this.checkboxaccept=true;
    }
    else{
      $('#checkboxerror').html("Bitte akzeptieren Sie unsere Datenschutzbestimmungen und Allgemeinen Geschäftsbedingungen");
      this.checkboxaccept=false;
    }
  }


  initConfig() {
    this.payPalConfig = {
    currency: 'EUR',
    vault: "false",
    clientId: environment.PAYPAL_CLIENTID,
    createOrderOnClient: (data) => <ICreateOrderRequest>{
      intent: 'CAPTURE',
      purchase_units: [
        {
          amount: {
            currency_code: 'EUR',
            value: this.granttotal.replace(",","."),
            breakdown: {
              item_total: {
                currency_code: 'EUR',
                value: this.granttotal.replace(",",".")
              }
            }
          },
          items: [
            {
              name: this.plandetails.name,
              quantity: '1',
              category: 'DIGITAL_GOODS',
              unit_amount: {
                currency_code: 'EUR',
                value: this.granttotal.replace(",","."),
              },
            }
          ]
        }
      ]
    },
    advanced: {
      commit: 'true',
      // extraQueryParams: [ { name: "disable-funding", value:"credit,card"} ]
      extraQueryParams: [  { name: "enable-funding", value:"sepa,sofort,giropay" } ]
    },
    style: {
      tagline: false,
      label: 'paypal',
      layout: 'vertical',
      size: 'responsive',
      height: 43,
    },
    onApprove: (data, actions) => {
      // console.log('onApprove - transaction was approved, but not authorized', data, actions);
      actions.order.get().then(details => {
        console.log('onApprove - you can get full order details inside onApprove: ', details);
      });
    },
    onClientAuthorization: (data11:any) => {
      console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data11);
  //  console.log(data.links);
  //  console.log(data.payer);
  //  console.log(data.create_time);
  //  console.log(data.id);
  //  console.log(data.intent);
  // let datanew:any=data11;
   console.log(data11.purchase_units[0].payments.captures[0].id);
  //  console.log(data.status);
  //  console.log(data.update_time);
  $("#loaderouterid").css("display", "block");
       this.paynow(data11.purchase_units[0].payments.captures[0].id)
      this.showSuccess = true;
    },
    onCancel: (data, actions) => {
      console.log('OnCancel', data, actions);
    },
    onError: err => {
      console.log('OnError', err);
    },
    onClick: (data, actions) => {
      
      console.log('onClick', data, actions);
    },
  };
  }

  checkemail()
  {
    let data={email:this.persionaldetails.controls.email.value}
      this.UserService
      .checkemail(data)
      .pipe(first())
      .subscribe((data11) => {
  if(data11['status']=='200')
  {
    $('#checkemailerror').html("")
  }
  else{
    $('#checkemailerror').html("E-Mail-ID ist nicht verfügbar")
  }
  });
  
  }


  nextCompanies() {
    let data={email:this.persionaldetails.controls.email.value}
       this.UserService
       .checkemail(data)
       .pipe(first())
       .subscribe((data11) => {
   if(data11['status']=='200')
   {
       let company_array = [];
   
         company_array = company_array.concat("coursula");
       
       let temp_set = new Set(company_array);
       company_array = Array.from(temp_set);
       this.UserService
         .getLastUser()
         .pipe(first())
         .subscribe((data) => {
           this.autoGeneratedCustomerNumber = data.toString();
           var nums = [this.autoGeneratedCustomerNumber];
           var nums1 = [];
           var c_100 = [];
           var c_110 = [];
           var c_120 = [];
           var c_130 = [];
           var c_140 = [];
           var c_150 = [];
           // console.log("CUSTOMERNO"+nums)
           var tmp = company_array; //this.companyArr;
           if (tmp) {
             tmp.forEach(function (i, k) {
               var cusNo = i.substring(0, 2);
               if (cusNo == "ce" || cusNo == "st") {
                 c_140.push(cusNo);
               }
               if (
                 cusNo == "fi" ||
                 cusNo == "fi" ||
                 cusNo == "ch" ||
                 cusNo == "ai"
               ) {
                 c_130.push(cusNo);
               }
               if (cusNo == "va" || cusNo == "bi") {
                 c_120.push(cusNo);
               }
               if (cusNo == "co") {
                 c_150.push(cusNo);
               }
             });
             if (c_140.length > 0) {
               c_140.unshift("40");
             }
             if (c_130.length > 0) {
               c_130.unshift("30");
             }
             if (c_120.length > 0) {
               c_120.unshift("20");
             }
             if (c_150.length > 0) {
               c_150.unshift("60");
             }
             nums1 = nums1.concat(c_150);
             nums1 = nums1.concat(c_140);
             nums1 = nums1.concat(c_130);
             nums1 = nums1.concat(c_120);
   
             nums1.unshift(this.autoGeneratedCustomerNumber);
             console.log("cdata" + nums1);
             var cusNo = nums1.join("-");
             this.customerNo = cusNo;
             console.log("CUSTOMERNO" + nums);
             return this.customerNo;
           }
         });



         
       }
   else{
     Swal.fire("E-Mail-ID ist nicht verfügbar","","error")
     setTimeout(() => {
       Swal.close();
       $('#backfirst').trigger('click')
     }, 1000);
   }

   
       });


       if(this.persionaldetails.controls.title.value=='Firma')
       {
         this.businessdetails1
         .get("website_url")
         .setValidators([Validators.required,Validators.pattern("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?")]);
       this.businessdetails1.get("website_url").updateValueAndValidity(); 
       }
       else
       {
         this.businessdetails1.get("website_url").clearValidators();
         this.businessdetails1.get("website_url").updateValueAndValidity();
       }
       

       // setTimeout(()=>{
       //   $('#phone').trigger('click');
       // },1000)
     }
  ngAfterViewInit() {
    const input = document.querySelector("#phone");
    console.log("querySelector" + input);
    intlTelInput(input, {
      //utilsScript: "build/js/utils.js",
    });
  }

  ngOnInit() {
    this.initConfig();
    this.vipprice=environment.VIPPRICE.replace(".",",");
    this.api_url = environment.API_URL;
    let data = { id: this.id };
    $("#loaderouterid").css("display", "block");
    this.UserService.getdoctorplandetails(data).subscribe((result: any) => {
      this.plandetails = result;
      let price=result.price.replace(",",".");
      this.netprice= (
        (price / 100) *
        (100 - result.discount)
      ).toFixed(2).replace(".",",")
      this.amount=((price/100)
        * (100-this.plandetails.discount)).toFixed(2)
      
      if(this.premium=='yes')
      {
        this.granttotal=(parseFloat(environment.VIPPRICE)+parseFloat(this.amount)).toFixed(2).replace(".",",");
      }
      else{
        this.granttotal=parseFloat(this.amount).toFixed(2).replace(".",",");
      }
      $("#loaderouterid").css("display", "none");
    });
    this.headername = "Mitgliedschaft für Ärzte kaufen";
    this.pagename = "";
    this.persionaldetails = this.formbuilder.group({
      title: ["", Validators.required],
      companyName: ["", Validators.required],

      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      phone_number: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
    });
    this.paymentdetails = this.formbuilder.group({});
    this.businessdetails1 = this.formbuilder.group({
      website_url: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
          ),
        ],
      ],
      picpdf: [""],
    });
    this.businessdetails = this.formbuilder.group({
      street: ["", Validators.required],
      streetNumber: ["", Validators.required],
      postCode: ["", Validators.required],
      city: ["", Validators.required],
      additionalReference: [""],
      countryOfResidence: ["", Validators.required],
    });
  }

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
  
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [day, month, year].join(".");
  }


  clickcheck(event) {
    $('#checkboxerror').html("");
    let accept:any = document.getElementById("accept");
    if (accept.checked == true) {
this.checkboxaccept=true;
    }
    else{
      this.checkboxaccept=false;
    }
  }

  paynow(paymentdata)
  {




    $("#loaderouterid").css("display", "block");



let start_date=this.formatDate(new Date());

let end_date = new Date();
end_date.setMonth(end_date.getMonth() + parseInt(this.plandetails.duration));
let enddate=this.formatDate(end_date)
    let name=this.persionaldetails.controls.title.value+" "+this.persionaldetails.controls.firstName.value+" "+this.persionaldetails.controls.lastName.value
    let price = this.plandetails.price.replace(",", ".");
    let fullprice:any = ((price / 100) * (100 - this.plandetails.discount))
      .toFixed(2);
      let oneprice=(((price/100)*(100-this.plandetails.discount))/this.plandetails.duration).toFixed(2).replace(".",",");

$('#name').html(name);
$('#count').html("-Laufzeit: "+this.plandetails.duration+" Monate (vom "+start_date+" bis zum "+enddate+")");
$('#productprice').html("-Preis: "+oneprice+" € mtl. ("+fullprice.replace(".", ",")+" für die gesamte Laufzeit) Der Gesamtbetrag für die gewählte Laufzeit wird im Voraus gezahlt.");


$('#productname').html("-Dein Produkt: Mitgliedschaft für Therapeuten "+this.plandetails.duration+" Monate");
$('#address1').html(this.businessdetails.controls.street.value+" "+this.businessdetails.controls.streetNumber.value);
$('#address2').html(this.businessdetails.controls.postCode.value+" "+this.businessdetails.controls.city.value);
$('#productpricetotal').html(fullprice.replace(".", ","));
$('#productpricetotal1').html(fullprice.replace(".", ","));
let withouttax:any =   ((fullprice/100)*(100- this.plandetails.tax)).toFixed(2);
let taxamount:any =   ((fullprice/100)*(this.plandetails.tax)).toFixed(2);
$('#withouttax').html(withouttax.replace(".", ",")+" €");
$('#finaltotal').html(fullprice.replace(".", ",")+" €");
$('#taxamount').html(taxamount.replace(".", ",")+" €");
$('#month').html(this.plandetails.duration);


this.UserService.getlastinvoice_no("{id:ss}").subscribe((resultinvoice:any)=>{
  let year=new Date().getFullYear();
let invoice_no="60-co-"+resultinvoice;
$('#invoice_no1').html(invoice_no+"/"+year);
$('#invoice_no2').html(invoice_no+"/"+year);





    $("#loaderouterid").css("display", "block");
    let statusbrandarraynew=['Coursula']
    let statusarraynew=['B2B']
    let brokerbrandarraynew = [];
    let brokerarraynew = [];
        let roles = ['b2b'];
        // let companies = ['coursula'];
        // let rolesCompaniesArray = ['coursula_b2b']

        let companies = ['ontrain'];
        let rolesCompaniesArray = ['ontrain_b2b']
    
        let data = {
          roles: roles,
          companies: companies,
          companies_with_roles: rolesCompaniesArray,
          title: this.persionaldetails.controls.title.value,
          companyname: this.persionaldetails.controls.companyName.value,
          firstname: this.persionaldetails.controls.firstName.value,
          lastname: this.persionaldetails.controls.lastName.value,
          email: this.persionaldetails.controls.email.value,
          dateofbirth: '',
          nationality: '',
          birth_place: '',
          customer_status: 'B2B',
          strassa: this.businessdetails.controls.street.value,
          strno: this.businessdetails.controls.streetNumber.value,
          plz: this.businessdetails.controls.postCode.value,
          city: this.businessdetails.controls.city.value,
          additionalReference: this.businessdetails.controls.additionalReference.value,
          current_country: this.businessdetails.controls.countryOfResidence.value,
          password: '',
          contactno: this.persionaldetails.controls.phone_number.value,
          customerno: this.customerNo,
          emailsendornot: "1",
          status: '1',
          companycode: ['c42150_b2b'],              
          statusbrandarray: statusbrandarraynew,
          statusarray:statusarraynew,
          brokerbrandarray: brokerbrandarraynew,
          brokerarray:brokerarraynew,
          website_url:this.businessdetails1.controls.website_url.value,
          broker: ''  ,
          attempts : "0",
          blocked_at : "",
          email_sent : "1",
          email_verify : "0",
          mobile_verify : "0",  
          device_type : 0,
          online_status : 0,
          deviceModel : "",
          OSVersion : "",
          appVersion : "",
          name: this.plandetails.name,
          description: this.plandetails.description,
          price: this.plandetails.price,
          discount: this.plandetails.discount,
          noofpatients: this.plandetails.noofpatients,
          duration: this.plandetails.duration,
          document: this.plandetails.document,
          tax:this.plandetails.tax,
          premium:this.premium,
          premiumamount:this.vipprice,
          txn_id:paymentdata,
          plantype:"Doctor",
          invoice_no:invoice_no
        }
        let formData = new FormData();
        if(this.imagePath.length>0)
        {
        for (var i = 0; i < this.imagePath.length; i++) {
          formData.append(
            "logo",
            this.imagePath[i],
            this.imagePath[i].name
          );
        }
      }
      else
      {
        formData.append(
          "logo",
          ""
        );
      }
        formData.append("data",JSON.stringify(data))
        this.UserService
        .coursula_b2b_register(formData)
        .subscribe(
          (dataresult) => {







            $('#MyDIv').css("display","block");



            let   companycodenew = "60-co-";
            let that=this;
                  var values:any = {
                    image: "",
                    document_type: "",
                    document_sub_type: "",
                    user_id: "",
                    product_partner: "",
                    companycode: "",
                    brand: "",
                    tags: [],
                    upload_by: "",
                    bill_type: "",
                    ticket_no: "",
                    document_name:"",
                    uniquedata:"",
                    invoice_no:"",
pricewithtax:"",
tax:"",
pricewithouttax:"",
year:"",
                  };
                  let uniquedata:string=new Date().getTime().toString();
                  let pdfnew = new jsPDF("portrait", "pt", "a4");
                  var width = pdfnew.internal.pageSize.width;
                  pdfnew.html(document.getElementById("MyDIv"), {
                            html2canvas: {
                              // insert html2canvas options here, e.g.
                              width: width,
                            },
                            callback: function (pdfnew) {
                              this.pdffile = pdfnew.output("blob");
                              
                              values.image = this.pdffile;
                              values.document_type = "Allgemeines Dokument";
                              values.document_sub_type = "Buchhaltung";
                              values.document_name="Mitgliedschaft plan";
                              values.user_id = dataresult['id']._id;
                              values.product_partner = " ";
                      
                              values.companycode = "42160 Jomiretto GmbH";
                              values.brand = "Coursula";
                              values.upload_by = "business_administrator";
                              values.ticket_no = companycodenew;
                              values.invoice_no = invoice_no;
                              values.pricewithtax = fullprice;
                              values.tax = taxamount;
                              values.pricewithouttax = withouttax;
                              values.year = year;
                              //values.created_by = this.id.toString()
                              values.tags.push(Math.round(this.pdffile.size / 1024));
                              //values.tags.push(MainType);
                              // values.tags.push(Date);
                              values.tags.push("application/pdf");
            
                              values.tags.push(new Date().getTime());
                              values.uniquedata = uniquedata;
            
                              
                              const formData1 = new FormData();
                              formData1.append("document_type", values.document_type);
                              formData1.append("document_sub_type", values.document_sub_type);
                              formData1.append("product_partner", values.product_partner);
                              formData1.append("user", values.user_id);
                              formData1.append("companycode", values.companycode);
                              formData1.append("brand", values.brand);
                              formData1.append("tags", values.tags);
                              formData1.append("upload_by", values.upload_by);
                              formData1.append("bill_type", "Eingehend");
                              formData1.append("ticket_no", values.ticket_no);
                              formData1.append("document_name", values.document_name);
                              formData1.append("uniquedata", values.uniquedata);
                              formData1.append("invoice_no", values.invoice_no);
                              formData1.append("pricewithtax", values.pricewithtax);
                              formData1.append("tax", values.tax);
                              formData1.append("pricewithouttax", values.pricewithouttax);
                              formData1.append("year", values.year);
                              if (values.image !== "") {
                                formData1.append("document", values.image);
                              }
                              $('#MyDIv').css("display","none");

                              formData1.append("companyname", that.persionaldetails.controls.companyName.value);
                              formData1.append("lastname", that.persionaldetails.controls.lastName.value);
                              
                              formData1.append("price", that.plandetails.price);
                              formData1.append("discount", that.plandetails.discount);
                              formData1.append("duration", that.plandetails.duration);
                              formData1.append("email", that.persionaldetails.controls.email.value);
                              formData1.append("title", that.persionaldetails.controls.title.value);
                              formData1.append("product_name", that.plandetails.name);
                              formData1.append("premiumamount", that.vipprice);
                              formData1.append("premium", that.premium);
                              
                              
                              // values.tags = [];
                              that.UserService.callApiMultipartnewwithoutlogininprevention(formData1).subscribe((result) => {
                              let document_unique_id=result.document_unique_id;
                           
        
let purchasemembershipresponsedata=dataresult['membershipplanresponse'];

let senddata={"_id":purchasemembershipresponsedata._id,"document_unique_id":document_unique_id}
that.UserService.updatemembershipdocument_unique_id(senddata).subscribe((result)=>{

          //   console.log(data);
          //   Swal.fire(
          //  "Ihr Plan wurde erfolgreich gekauft. Bitte überprüfen Sie Ihren E-Mail-Posteingang, um Ihren Login-Link zu erhalten",
          //    "",
          //     "success",
          //   );
          //   setTimeout(() => {
          //     Swal.close();
          //     $("#loaderouterid").css("display", "none");
          //     that.routers.navigate(["./plans-b2b"]);
          //   }, 1000);



          Swal.fire({
            title: '',
            html: "<div style='text-align: right;cursor:pointer;position: absolute;right: 12px;top: 11px;font-size: 27px;' id='gotopreventioncourse11'><i class='fa fa-times'></i></div><br><br><span style='font-size: 26px;'>Herzlichen Glückwunsch, du hast die Mitgliedschaft "+that.plandetails.name+" erworben. Wir haben Dir eine E-Mail gesandt mit welcher du deinen Login aktivieren kannst." +
                "</span><br><br><br>" +
                '<button type="button" role="button" id="gotopreventioncourse" tabindex="0" class="SwalBtn1 btn btn-primary customSwalBtn">OK</button>',
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            icon:"success",
        });
        const someInput = document.getElementById("gotopreventioncourse");
        someInput.addEventListener(
          "click",
          function () {
            Swal.close();
            that.routers.navigate(["./"]);
          },
          false
        );
        const someInput11 = document.getElementById("gotopreventioncourse11");
        someInput11.addEventListener(
          "click",
          function () {
            Swal.close();
            that.routers.navigate(["./"]);
          },
          false
        );
          //   Swal.fire({
          //     title: '',
          //     html: "<span style='font-size: 26px;'>Ihr Plan wurde erfolgreich gekauft. Bitte überprüfen Sie Ihren E-Mail-Posteingang, um Ihren Login-Link zu erhalten" +
          //         "</span><br><br><br>" +
          //         '<button type="button" role="button" id="gotopreventioncourse" tabindex="0" class="SwalBtn1 btn btn-primary customSwalBtn">OK</button>',
          //     showCancelButton: false,
          //     showConfirmButton: false,
          //     allowOutsideClick: false
          // });
          // const someInput = document.getElementById("gotopreventioncourse");
          // someInput.addEventListener(
          //   "click",
          //   function () {
          //     Swal.close();
          //     that.routers.navigate(["./plans"]);
          //   },
          //   false
          // );


          })







         


        })
      }

      });







          },
          (error) => {
            console.log(error);
            Swal.fire("E-Mail-ID bereits verwendet",
            "",
               "error",
            );
            // Swal.fire(error,"","error")
            $("#loaderouterid").css("display", "none");
          }
        );
});
  
console.log(this.amount)
  }
  handleAllFields(data) {
    console.log("original data = ", data);
    const splitArr = data.address_components;
    this.getAllFields(splitArr);
  }

  handleAddressChangeland(data) {
    const splitArr = data.address_components;
    this.getCountryland(splitArr);
  }
  getCountryland(data): any {
    let that = this;
    const splitArr = data;
    // console.log(splitArr);
    splitArr.forEach(function (i, k) {
      let content: any = i.types;
      //// console.log(content);
      if (content.length > 1) {
        const countryArr = content;
        for (let index = 0; index < content.length; index++) {
          if (countryArr[index] === "country") {
            that.countryName = i.long_name;
          }

          let localityCountry = that.countryName;
          that.businessdetails.patchValue({
            countryOfResidence: localityCountry,
          });
        }
      }
    });
  }
  getAllFields(data): any {
    let that = this;
    const splitArr = data;
    let str = "";
    //console.log("data", splitArr);
    splitArr.forEach(function (i, k) {
      let content: any = i.types;
      const found = content.find((element) => (element = "street_number"));
      if (found === "street_number") {
        that.businessdetails.patchValue({ streetNumber: i.short_name });
      } else if (found === "postal_code") {
        that.businessdetails.patchValue({ postCode: i.short_name });
      } else if (found === "route") {
        str = i.short_name;
      }
      if (content.length > 1) {
        const countryArr = content;
        ////console.log(countryArr);
        for (let index = 0; index < content.length; index++) {
          if (countryArr[index] === "country") {
            console.log("country", i);
            that.businessdetails.patchValue({
              countryOfResidence: i.long_name,
            });
          }
          if (countryArr[index] === "locality") {
            that.businessdetails.patchValue({ city: i.short_name });
          }
        }
      }
    });
    if (str.length > 0) {
      that.businessdetails.patchValue({ street: str });
    } else {
      that.businessdetails.patchValue({ street: "" });
    }
  }
  previewpdf(files) {
    console.log("files.length" + files.length);
    if (files.length === 0) {
      return;
    }
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      Swal.fire("Only images are supported.", "", "error");
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
  }
  changepdf() {
    $("#picpdf").trigger("click");
  }

  titleChange() {
    if (this.persionaldetails.get("title").value == "Firma") {
      this.companyTitle = true;
      this.persionaldetails.patchValue({ companyName: "" });
      this.businessdetails1.patchValue({ website_url: "" });
      this.businessdetails1
      .get("website_url")
      .setValidators([Validators.required,Validators.pattern(
        "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
      )]);
    this.businessdetails1.get("website_url").updateValueAndValidity();
    } else {
      this.companyTitle = false;
      this.persionaldetails.patchValue({ companyName: " " });
      this.businessdetails1.patchValue({ website_url: " " });
      this.businessdetails1
      .get("website_url")
      .clearValidators();
    this.businessdetails1.get("website_url").updateValueAndValidity();
    }
  }
}
