import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from "../user.service";
import { environment } from "src/environments/environment";
import { ActivatedRoute, Router } from '@angular/router';
import { Options } from '@angular-slider/ngx-slider';
import { OwlOptions } from "ngx-owl-carousel-o";
import Swal from 'sweetalert2';
import * as $ from "jquery"
@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.css']
})

export class PlansComponent implements OnInit {
  carousel:any;
  @ViewChild('carousel', { static: true }) OwlOptions: PlansComponent;
  customOptions: OwlOptions = {
    items: 5,
    loop: false,
    navText: [
      '<i class="fa fa-chevron-left"></i>',
      '<i class="fa fa-chevron-right"></i>',
    ],
    margin: 10,
  
    // nav:true,
  
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  };
  
  api_url;
  id

  patientvalue=10;
durationvalue=12;

patientfloor=10;
patientceil=100;
patientstep=10;

durationfloor=12;
durationceil=36;
durationstep=12;

lastduration;
netprice:any;
patient: Options  = {
  floor: this.patientfloor,
  ceil: this.patientceil,
  step: this.patientstep,
  showTicks: true
};

duration: Options = {
  floor: this.durationfloor,
  ceil: this.durationceil,
  step: this.durationstep,
  showTicks: true
};
  constructor( private service:UserService,private router:Router,private route:ActivatedRoute) { this.api_url=environment.API_URL;}
  therapyplan;
  headername:any;
  valuename:any;

  add(arr, name) {
    const { length } = arr;
    const id = length + 1;
    const found = arr.some(el => el.value === name);
    if (!found) arr.push({ id, value: name });
    return arr;
  }
  getallpatient(data)
  {
    let newarray=[];
for(let l=0;l<data.length;l++)
{
  if(!this.add(newarray,data[l].noofpatients))
  {
    newarray.push({value: data[l].noofpatients});
  }


}
return newarray;
  }

  getallduration(data)
  {
    let newarray=[];
for(let l=0;l<data.length;l++)
{
  if(!this.add(newarray,data[l].duration))
  {
    newarray.push({ value: data[l].duration});
  }
}
return newarray;
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id')

    $("#loaderouterid").css("display", "block");
    this.service.getdoctorplan('').subscribe((data:any) =>  {
if(data.length>0)
{
     this.therapyplan = data;
     let price=data[0].price.replace(",",".");
     this.netprice= (
       (price / 100) *
       (100 - data[0].discount)
     ).toFixed(2).replace(".",",")
     let noofpatients=data[0].noofpatients;
     this.service.getdoctorplanbynoofpatient({"patient":noofpatients}).subscribe((success:any) =>  {
       this.patientvalue=noofpatients
       this.durationvalue=data[0].duration;
    //    if(data[0].noofpatients==data[data.length-1].noofpatients)
    //    {
    //    this.patient={
    //      floor: 1,
    //      ceil: data[data.length-1].noofpatients,
    //      step: 10,
    //      showTicks: true
    //    }
    //  }
    //  else{
     
    //    this.patient={
    //      floor: data[0].noofpatients,
    //      ceil: data[data.length-1].noofpatients,
    //      step: 10,
    //      showTicks: true
    //    }
    //  }
     
    this.patient = {
      showTicksValues: true,
      stepsArray:this.getallpatient(data),
    };
    this.duration = {
      showTicksValues: true,
      stepsArray:this.getallduration(success),
     
    };
    //  if(success[0].duration==success[success.length-1].duration)
    //  {
    //  this.duration={
    //    floor: 1,
    //    ceil: success[success.length-1].duration,
    //    step: 12,
    //    showTicks: true
    //  }
    //  }
    //  else{
    //    this.duration={
    //      floor: success[0].duration,
    //      ceil: success[success.length-1].duration,
    //      step: 12,
    //      showTicks: true
    //    } 
    //  }
     
     
       $("#loaderouterid").css("display", "none");
       });
      }
      else
      {
        
      }
     $("#loaderouterid").css("display", "none");
       console.log(this.therapyplan);
     });
     this.headername="Mitgliedschaft für Ärzte"
     this.valuename="test";
  }


  changepatient(event)
  {
    let noofpatient=event.value;
    $("#loaderouterid").css("display", "block");
    this.service.getdoctorplanbynoofpatient({"patient":noofpatient}).subscribe((success:any) =>  {
      this.durationvalue=success[0].duration;
  $('#discount').html(success[0].discount+" % <br> Off");
  $('#planname').html(success[0].name);
  let price=success[0].price.replace(",",".");
  let finalamount= (
    (price / 100) *
    (100 - success[0].discount)
  ).toFixed(2);

  $('#finalamount').html(finalamount.replace(".",","));
  $('#price').html(success[0].price);
  
  $('#no_of_patient').html(success[0].noofpatients+" Patient");
  $('#duration').html("Laufzeit  "+success[0].duration+" Monate");
  $('#description').html(success[0].description);
  $('#therapistid').val(success[0]._id);
  $("#totalprice").val(finalamount);
  $('#vipprice').html('');
  $('#premium').val('no');

  let image='<img src="'+this.api_url+'document/downloaddocument/'+success[0].document[0]+'" class="img-fluid heightimg image_placeholder" alt="" style="border-radius: 7px 0px 0px 7px; object-fit: cover;">'
  
// var image = '<div class="owl-slide">'+
// '                            <owl-carousel-o [options]="customOptions">'+
// '                                <ng-container>'+
// '                                    <ng-template carouselSlide [id]="0">'+
// '                                        <div class="sliderstyle">'+
// '                                            <img src="'+this.api_url+'document/downloaddocument/'+success[0].document[0]+'"'+
// '                                                class="img-fluid heightimg" alt="" style="border-radius: 7px 0px 0px 7px;'+
// '    object-fit: cover;">'+
// '                                        </div>'+
// '                                    </ng-template>'+
// '                                </ng-container>'+
// '                                <ng-container>'+
// '                                    <ng-template carouselSlide [id]="1">'+
// ''+
// '                                        <div class="sliderstyle">'+
// ''+
// '                                            <video width="100%" height="300" class="heightimg" controls>'+
// '                                                <source'+
// '                                                    src="'+this.api_url+'document/downloaddocument/'+success[0].document[1]+'"'+
// '                                                    type="video/mp4" style="border-radius: 7px 0px 0px 7px;'+
// '    object-fit: cover;">'+
// ''+
// '                                            </video>'+
// ''+
// '                                        </div>'+
// '                                    </ng-template>'+
// '                                </ng-container>'+
// '                            </owl-carousel-o>'+
// '                        </div>';
	

  
  $('#image').html(image);
  // $('.owl-slider').trigger('refresh.owl.carousel');
  // const anyService = this.carousel as any;
  // const carouselService = anyService.carouselService as CarouselService;

  //  carouselService.refresh();
  this.duration = {
    showTicksValues: true,
    stepsArray:this.getallduration(success),
   
  };
    // if(success[0].duration==success[success.length-1].duration)
    // {
    // this.duration={
    //   floor: 1,
    //   ceil: success[success.length-1].duration,
    //   step: 12,
    //   showTicks: true
    // }
    // }
    // else{
    //   this.duration={
    //     floor: success[0].duration,
    //     ceil: success[success.length-1].duration,
    //     step: 12,
    //     showTicks: true
    //   } 
    // }
    
      $("#loaderouterid").css("display", "none");
      });
  
  
  
  
  
  console.log("event"+JSON.stringify(event))
  }
  
  
  changedurationstart(event)
  {
    this.lastduration=event.value;
   
  }
  
  changeduration(event)
  {
  // let lastduration=this.durationvalue;
    let duration=event.value;
    let noofpatient=this.patientvalue
    $("#loaderouterid").css("display", "block");
    this.service.getdoctorplanbynoofpatientandduration({"patient":noofpatient,"duration":duration}).subscribe((success:any) =>  {
  if(success.length>0)
  {
    //   this.durationvalue=success[0].duration;
    // if(success[0].duration==success[success.length-1].duration)
    // {
    // this.duration={
    //   floor: 0,
    //   ceil: success[success.length-1].duration,
    //   step: 12,
    //   showTicks: true
    // }
    // }
    // else{
    //   this.duration={
    //     floor: success[0].duration,
    //     ceil: success[success.length-1].duration,
    //     step: 12,
    //     showTicks: true
    //   } 
    // }
    $('#discount').html(success[0].discount+" % <br> Off");
  $('#planname').html(success[0].name);
  let price=success[0].price.replace(",",".");
  let finalamount= (
    (price / 100) *
    (100 - success[0].discount)
  ).toFixed(2);

  $('#finalamount').html(finalamount.replace(".",","));
  $('#price').html(success[0].price);
  
  $('#no_of_patient').html(success[0].noofpatients+" Patient");
  $('#duration').html("Laufzeit  "+success[0].duration+" Monate");
  $('#description').html(success[0].description);
  $('#therapistid').val(success[0]._id);
  $("#totalprice").val(finalamount);
  $('#vipprice').html('');
  $('#premium').val('no');
  let image='<img src="'+this.api_url+'document/downloaddocument/'+success[0].document[0]+'" class="img-fluid heightimg image_placeholder" alt="" style="border-radius: 7px 0px 0px 7px; object-fit: cover;">'


  $('#image').html(image);
  

      $("#loaderouterid").css("display", "none");
  }
  else{
    this.durationvalue=this.lastduration
    Swal.fire("No Plan Found","","error")
    $("#loaderouterid").css("display", "none");
  }
  
      });
  console.log("event"+JSON.stringify(event))
  }
  
  
navigateWithb2bID() {


  // Swal.fire({
  //   title: "Sind Sie sicher, dass Sie diesen Arzt-Mitgliedsplan kaufen?",
  //   showCancelButton: true,
  //   confirmButtonText: "Speichern",
  //   cancelButtonText: "Verwerfen"
  // }).then(result => {
  //   if (result.value) {
    
      let id=$('#therapistid').val();
      let premium = $("#premium").val();
      this.router.navigate(["/purchase-doctor-plan"], {
        queryParams: { id: id ,premium:premium}
      });
  //   }
  //   else{
  //     $("#loaderouterid").css("display", "none");
  //   }
  // });



  // this.queryID = this.selectedUser.customerno;
  // this.ngOnInit()
}
clickcheck(event) {
  let vippremium: any = document.getElementById("vippremium");
  if (vippremium.checked == true) {
    let finalamount = $("#totalprice").val().replace(",",".");
    let vipprice:any=environment.VIPPRICE.replace(".",",");
    let granttotal = (parseFloat(finalamount) + parseFloat(vipprice)).toFixed(2).replace(".",",");

    let data='<div ><ins class="new-price" style="text-decoration: none; font-size: 15px !important"><b>VIP Premium: </b><i class="fa fa-eur" aria-hidden="true"></i><span >'
    +vipprice+
    '</span></ins><br><ins class="new-price" style="text-decoration: none; font-size: 15px !important"><b>Total Amount: </b><i class="fa fa-eur" aria-hidden="true"></i><span >'
    +granttotal+
    '</span></ins></div>';
$('#vipprice').html(data);
$('#premium').val('yes');

    console.log("true" + finalamount);
  } else {
    $('#vipprice').html('');
    $('#premium').val('no');
    console.log("false");
  }
}



}
