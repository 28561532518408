import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGaurdService implements CanActivate {

  constructor(
    private userService: UserService,
    public router: Router
    ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean 
  {
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

    if(!this.userService.isAuthenticated()){
console.log("hhhhhhhhhhhhhhhh");
      this.router.navigate(['./login']);
      return false;
    }



return true;
  }


  


}
