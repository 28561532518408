import { Component, OnInit, AfterViewInit } from "@angular/core";
import {CdkDragDrop, moveItemInArray,copyArrayItem,transferArrayItem} from '@angular/cdk/drag-drop';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
  CheckboxControlValueAccessor,
} from "@angular/forms";
import { UserService } from "../user.service";

import { first } from "rxjs/operators";
import { error } from "util";
import { Router } from "@angular/router";
import { PagerService } from "../pager.service";
import { MatChipInputEvent } from "@angular/material/chips";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ActivatedRoute, Params } from "@angular/router";
import * as $ from "jquery";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { DomSanitizer } from "@angular/platform-browser";
import Swal from "sweetalert2";
import { data } from "jquery";
import { HttpEvent, HttpEventType } from "@angular/common/http";
type unit = 'bytes' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB';
type unitPrecisionMap = {
  [u in unit]: number;
};

const defaultPrecisionMap: unitPrecisionMap = {
  bytes: 0,
  KB: 0,
  MB: 1,
  GB: 1,
  TB: 2,
  PB: 2
};
@Component({
  selector: 'app-patient-product-management',
  templateUrl: './patient-product-management.component.html',
  styleUrls: ['./patient-product-management.component.css']
})
export class PatientProductManagementComponent implements OnInit {

    disableEditSubmit=false
    routeParams: string;
    personalInfoFormGroup: FormGroup;
    exerciseformgroup:FormGroup
    pager: any = {};
    typesOptionsArray: any[] = [];
    typesOptionsArrayedit: any[] = [];
    oldtypesOptionsArray: any[] = [];
    customerList: any=[];
    recordCount: any;
    pagedItems: any;
    startRecord: number;
    progress=[]
    endRecord: any;
    l = 0;
    b2bid='';
    popupData: any;
    b2bdata:any;
    editid: any = "";
    editidnew: any = "";
    private readonly units: unit[] = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    currentUserData: any;
    filearray: any[]= [];
    currentopendata: any;
    ticket_no: any;
    exercisedata: any = [];
    filearraynew: any[] = [];
    exercisesequencelistsearch:any =[];
    searchby='';
    lengthfile: any = 0;
    uploadlength: any = 0;
    documentid: any = [];
    exerciselist: any = [];
    prevention_course_id='';
    b2b_controls= new FormControl();
    exercisesequencelist: any = [];
    exerciseoption: Observable<any[]>
    b2boption: Observable<any[]>
    searchlist=[];
    id:any;
    exercise_controls = new FormControl();
    values: any;
    constructor(
      private _formBuilder: FormBuilder,
      private userService: UserService,
      private router: Router,
     
      private pagerService: PagerService,
      private activatedRoute: ActivatedRoute,
      private sanitizer: DomSanitizer
    ) {}
    loginRole = sessionStorage.getItem("currentActiveRole");
    title = this.userService.getDecodedAccessToken(sessionStorage.getItem("Token"))
      .title;
    loginid = this.userService.getDecodedAccessToken(
      sessionStorage.getItem("Token")
    ).id;
    lastname = this.userService.getDecodedAccessToken(
      sessionStorage.getItem("Token")
    ).lastname;
    firstname = this.userService.getDecodedAccessToken(
      sessionStorage.getItem("Token")
    ).firstname;
    COMPANYNAME = this.userService.getDecodedAccessToken(
      sessionStorage.getItem("Token")
    ).companyname;
    ngOnInit() {
      
      this.personalInfoFormGroup = this._formBuilder.group({ 
        name:["", Validators.required],
        grossprice:[""],
        description:["",Validators.required],
        discount:["",[Validators.required, Validators.pattern("^[0-9]*$"),Validators.max(100)]],
        price:["",[Validators.required, Validators.pattern("\\d+\\,\\d{2}")]],
        tax:["",[Validators.required, Validators.pattern("^[0-9]*$"),Validators.max(100)]],
        commission:["",[Validators.required, Validators.pattern("^[0-9]*$"),Validators.max(100)]],
        upload_file:[""],
        coupen_code:["",Validators.required],
        upload_video:[""]
      }
        );
  
      this.loginRole = this.loginRole;
      this.routeParams = this.activatedRoute.snapshot.routeConfig.path;
  
      $("#loaderouterid").css("display", "block");
      // this.getallexerciesequence();
      this.getallexerciesequence();
      this.getallexerciselist();
      this.getallb2b();
        this.exerciseoption = this.exercise_controls.valueChanges.pipe(
          startWith(""),
          map((value) => (typeof value == "string" ? value : value.name)),
          map((name) =>this._filterexercisedata(name)
          )
        );

      this.b2boption = this.b2b_controls.valueChanges.pipe(
        startWith(""),
        map((value) => (typeof value == "string" ? value : value.name)),
        map((name) =>this._filterb2bdata(name)
        )
      );
    


    }



    _filterexercisedata(value: string): string[] {
      if(this.exercise_controls.value=='')
      {
      this.prevention_course_id='';
      }

      const filterValue11 = value.toLowerCase();
      let searchlistnewlist = this.exercisedata.filter(function(itm){
        return JSON.stringify(itm.name).toLowerCase().indexOf(filterValue11) > -1;
      });
  
      console.log("searchlistnewlist"+searchlistnewlist);
      return searchlistnewlist;
    }
  
    _filterb2bdata(value: string): string[] {
      if(this.b2b_controls.value=='')
      {
      this.b2bid='';
      }

      const filterValue11 = value.toLowerCase();
      let searchlistnewlist = this.b2bdata.filter(function(itm){
        return JSON.stringify(itm.name).toLowerCase().indexOf(filterValue11) > -1;
      });
  
      console.log("searchlistnewlist"+searchlistnewlist);
      return searchlistnewlist;

    }


    getallexerciselist() {


      this.userService
        .getSearchpreventioncourse('')
        .pipe(first())
        .subscribe((data: any) => {
          this.exercisedata = this.loopingexerciselist(data);
  
          console.log("exercisedata" + this.exercisedata);
        });
    }
  


    getallb2b()
    {
      this.userService
      .getBroker('coursula')
      .pipe(first())
      .subscribe((data: any) => {
        this.b2bdata = this.loopingb2blist(data);

        console.log("b2bdata" + this.b2bdata);
      });
    }

    loopingexerciselist(userData: string | any[]) {
      let exerciselist = [];
      console.log("kundenew" + JSON.stringify(userData));
      for (let i = 0; i < userData.length; i++) {
       
        exerciselist.push({
          name: userData[i].preventioncourse_name,
          id: userData[i]._id,
          length: userData[i].exercise_sequence_id.length,
        });
      }
  
    
      return exerciselist;
    }
    loopingb2blist(userData: string | any[]) {
      let b2blist = [];
      console.log("kundenew" + JSON.stringify(userData));
      for (let i = 0; i < userData.length; i++) {
       
        b2blist.push({
          name: userData[i].firstname+" "+userData[i].lastname+" ("+userData[i].customerno+")",
          id: userData[i]._id,
        });
      }
  
    
      return b2blist;
    }


  
    exercise_management() {
      this.router.navigate(["/treatment-list"], {});
    }
    getdivoutside() {
      let accordianId = "collapse";
      let accordian: HTMLElement = document.getElementById(accordianId);
      let element1new: HTMLElement = document.getElementById(
        "cardbodyid"
      ) as HTMLElement;
      element1new.after(accordian);
      accordian.classList.add("collapse");
      // accordian.classList.add("collapse");
      accordian.classList.remove("collapse-show");
    }
  
  
    getb2b_controls() {
    
      
      let l = "b";
    
  this.b2bid=this.b2b_controls.value.id;
      this.b2b_controls.setValue(this.b2b_controls.value.name);
  
    }


  
  
  
    onKey(event) {
      console.log();
      // this.values = event.target.value;
      var value = event.keyCode;
      this.values = event.target.value;
      console.log(value);
      if (value == "13") {
        // without type info
        // this.customerList = [];
        const that = this;
        console.log(that.customerList);
  
        // this.values = event.target.value;
        if (event.target.value != "") {
          console.log(event.target.value);
          // var datanew={currentrole:this.loginRole,id:this.loginid}
          this.userService
            .getSearchpatientproduct(event.target.value)
            .subscribe(function (data1) {
              that.customerList = data1;
  
              that.setPage(1);
  
              that.recordCount = that.customerList.length;
              //this.customerList.push(data1);
              //return this.customerList.push(data1);
            });
        } else {
          this.getallexerciesequence();
        }
      }
    }
  
  
    getallexerciesequence(id='') {
  
      if(id!='')
      {
     let   accordianId = "collapse"
        // let element1: HTMLElement = document.getElementById('ul' + id) as HTMLElement;
        let element1new: HTMLElement = document.getElementById('cardbodyid') as HTMLElement;
        let element: HTMLElement = document.getElementById('click' + id) as HTMLElement;
        let accordian: HTMLElement = document.getElementById(accordianId);
          if (element.innerHTML == "Schließen") {
            console.log("element",element1new)
            element1new.after(accordian);
            accordian.classList.add("collapse");
            // accordian.classList.add("collapse");
            accordian.classList.remove("collapse-show");
            element.innerHTML = "Öffnen";
            this.getdivoutside();
          }
        }
          var datanew = { id: "" };
          const data = this.userService.getpatientproduct(datanew).subscribe(
            (success: any) => {
           
              // if success and error give response
            
              if (success.status == "error") {
                Swal.fire("Patientenprodukt nicht gefunden", "", "error");
              } else {
                console.log("success.status"+success);
                this.customerList = success;
                this.setPage(1);
                this.recordCount = success.length;
                console.log(this.customerList);
                $("#loaderouterid").css("display", "none");
              }
            },
            (rejected) => {
              console.log(rejected);
            }
          );
       this.filearray=[];
        }
  
    
        editRecord(id, accordianId,cl) {
          this.popupData = cl;
  
         accordianId = "collapse"
          event.preventDefault();
          let element1: HTMLElement = document.getElementById('ul' + id) as HTMLElement;
          let element1new: HTMLElement = document.getElementById('cardbodyid') as HTMLElement;
          let element: HTMLElement = document.getElementById('click' + id) as HTMLElement;
          let accordian: HTMLElement = document.getElementById(accordianId);
           console.log("element1new"+ element.innerHTML)
          console.log("element"+element);
          console.log("else accordian1",accordian)
          if (element.innerHTML == "Schließen") {
        
              console.log("element",element1new)
              element1new.after(accordian);
              accordian.classList.add("collapse");
              // accordian.classList.add("collapse");
              accordian.classList.remove("collapse-show");
              element.innerHTML = "Öffnen";
          
          } else {
          
            if(this.id!='')
            {
            let elementnew1: HTMLElement = document.getElementById('click' + this.id) as HTMLElement;
            if(elementnew1)
            {
            console.log("elementelse",elementnew1)
            elementnew1.innerHTML = "Öffnen";
            }
            }
              console.log("else accordian",accordian)
              console.log("else element",element1)
              element1.after(accordian);
              accordian.classList.remove("collapse");
              accordian.classList.add("collapse-show");
              element.innerHTML = "Schließen";
              this.id = id;
              this.getcurrentUser(this.id);
             
      
          
              
          }
          // console.log(element.innerHTML);
      
        }
  
        dataconvert(bytes: number = 0, precision: number | unitPrecisionMap = defaultPrecisionMap): string {
    
          if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) return '?';
      
          let unitIndex = 0;
      
          while (bytes >= 1024) {
            bytes /= 1024;
            unitIndex++;
          }
      
          const unit = this.units[unitIndex];
      
          if (typeof precision === 'number') {
            return `${bytes.toFixed(+precision)} ${unit}`;
          }
          return `${bytes.toFixed(precision[unit])} ${unit}`;
        }
      
      
        handleImageChange(event: any, index) {
      
          this.progress[index] = 0;
          console.log("asdasdsad"+this.filearray);
          $("#result").html("");
          console.log(this.loginid);
          event.preventDefault();
          const removeData = (j,indexw) => {
      console.log("asdaadadasd"+indexw);
      
            
            console.log("sdddd"+this.filearraynew);
            console.log("sdddd"+j);
            delete this.filearraynew[j];
            console.log("sdddd"+this.filearraynew);
         
            this.filearray[j] = '';
            $('#logopreview'+indexw).html('<p style="text-align: center;padding: 105px 27px 106px 38px;border: 1px solid;"> Vorschau</p>');
      
            console.log("sdddd"+this.filearray);
          };
      
          var files = event.target.files; //FileList object
          // var output = document.getElementById("result");
      
          var filesLength = files.length;
      
          for (let i = 0; i < filesLength; i++) {
            let f = files[i];
            let newsize = this.l;
            this.l = this.l + 1;
            // this.filearraynew.splice(index, 0, f);
      
            this.filearraynew[index]=f;
            this.filearray[index] = this.filearraynew[index];
            let Size1 = f.size;
      let Size = this.dataconvert(Size1);
            var fileReader = new FileReader();
            //var target:EventTarget;
            fileReader.onload = function (e) {
              //var file = e.target;
         
              let extension = f.name.substr(f.name.lastIndexOf(".") + 1);
         
              let ImageName;
          
                ImageName = (e.target as any).result;
      
                let typeofimage = f.type;
               
                let dateofdocument = f.lastModified;
                // var d = new Date(dateofdocument);
                // var date =
                //   d.getDate() + "." + (d.getMonth() + 1) + "." + d.getFullYear();
      
      
       
                  var date1 = new Date(dateofdocument).toLocaleDateString("en-IN", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric"
                  });
          
                  var date = date1.replace(/[/]/g, ".");
      console.log("dddddddddddd"+f.size);
      //           let Size1:any = Math.round(f.size / 1024);
      // let Size = Size1 > 1024 ? Size1.charAt(0) + "MB" : Size1 + "KB"
      
                $("#logopreview"+index).html(
                  '<div class="pip" style="width: 100%;display: inline-block;" "id=\'pipremove' +
                    index +
                    "'>" +
                    '<div class="removepreview" id="removepreviewid' +
                    index +
                    '" style="background: #184297;right:0px;border-radius: 50%;width:30px;height:30px;font-size: 14px; text-align: center; padding: 6px;color: white;position: absolute;margin-left: 257px;margin-top: 2px;margin-right: 0 !important;cursor: pointer;">X</div>' +
                    "" +
                    '<embed class="imageThumb" style="width: 100%;height:210px" src="' +
                    ImageName +
                    '" title="' +
                    f.name +
                    '"/></embed>' +
                    "<div> <b class='limitword' title='"+f.name+"'>Dokumentenname: " +
                    f.name +
                    "</b> </div><div> <b > Dateigröße: " +
                    Size +
                    "</b>  </div><div> <b>Dateityp: " +
                    typeofimage +
                    "</b> </div><div> <b>Datum des Dokuments: " +
                    date +
                    "</b> </div></div>"
                )
              
      
              // $(`<div> <b>  ${Math.round((f.size / 1024))} </b> KB </div>`).insertAfter(".pip")
              $("#removepreviewid" + index).click(function () {
                removeData(index,index);
                // $("#pipremove" + i).remove();
                $(this).parent(".pip").remove();
              });
            };
            fileReader.readAsDataURL(f);
          }
      
        }
      
      
        get personalInfoForm() {
          return this.personalInfoFormGroup.controls;
        }
  
  
        getcurrentUser(id)
        {
          this.progress[0] = 0;
          this.progress[1] = 0;
          this.filearray[0]='';
          this.filearray[1]='';
          let that=this
          this.userService.getEditpatientproduct(id).subscribe(function (data1) {
            that.currentUserData = data1;
            console.log(data1)
            
            setData(data1)
          });
  
          function setData(data1) {
            that.currentopendata = data1;
  
             that.personalInfoFormGroup.patchValue({
  name:data1.element.name,
  description:data1.element.description,
  price:data1.element.price,
  discount:data1.element.discount,
  commission:data1.element.commission,
  coupen_code:data1.element.coupen_code,
  tax:data1.element.tax,
            })

that.gettax();
            that.b2b_controls.setValue(data1.element.b2bid.firstname+" "+data1.element.b2bid.lastname+" ("+data1.element.b2bid.customerno+")")

that.b2bid=data1.element.b2bid._id;


  that.exercise_controls.setValue(data1.element.prevention_course_id.preventioncourse_name)

that.prevention_course_id=data1.element.prevention_course_id._id;

            $('#logopreview0').html("<img src='"+data1.image1+"' style='width: 100%;  object-fit: contain;  height: 200px;'>");
            $('#logopreview1').html("<embed src='"+data1.image2+"' style='width: 100%;    height: 200px;'></embed>");
          }
        }
        gettax()
        {
          let netprice=this.personalInfoFormGroup.controls.price.value.replace(",",".");
          let tax=this.personalInfoFormGroup.controls.tax.value;
    let grossprice=(netprice-((netprice/100)*tax)).toFixed(2);
    this.personalInfoFormGroup.patchValue({
      grossprice:grossprice.replace(".",",")
    });
    
        }
        getexercise() {
    
      
          let l = "b";
        
      this.prevention_course_id=this.exercise_controls.value.id;
          this.exercise_controls.setValue(this.exercise_controls.value.name);
        
          console.log("exercisesequencelist" + this.prevention_course_id);
        }
    update_exercise()
    {
      $("#loaderouterid").css("display", "block");
  
  
      if(this.filearray.length>0)
      {
        console.log("dfffffffffffffffff");
        
      for (let l = 0; l < 2; l++) {
      
        if(this.filearray[l])
        {
          console.log("dfffffffffffffffff"+this.filearray[l]);
          this.lengthfile++;
        }
        else{
          this.documentid[l]=this.currentopendata.element.document[l];
        }
        }
  
          // this.documentid=this.currentopendata.element.document;
      
          // customerNo.substring(5,customerNo.length);
          this.ticket_no = this.currentopendata.element.patientproduct_id;
  
  if(this.lengthfile>0)
  {
          for (let i = 0; i < this.filearray.length; i++) {
            if(this.filearray[i]!='')
            {
            
                        var values = {
                          image: "",
                          document_type: "",
                          document_sub_type: "",
                          user_id: "",
                          product_partner: "",
                          companycode: "",
                          brand: "",
                          tags: [],
                          upload_by: "",
                          bill_type: "",
                          ticket_no: "",
                          document_name:"",
                        };
                        let url = this.filearray[i];
                        let reader = new FileReader();
                   
                        reader.readAsDataURL(url);
                        reader.onload = () => {
                          // this.fileName = url.name + " " + url.type;
                          let base64ImgString = (reader.result as string).split(",")[1];
            
                          let StringTypeCasting = Math.round(
                            this.filearray[i].size / 1024
                          );
                          let typeofimage = this.filearray[i].type;
                          let dateofdocument = this.filearray[i].lastModified;
                          console.log("this.StringTypeCasting " + StringTypeCasting);
                          values.image = this.filearray[i];
                          values.document_type = "Allgemeines Dokument";
                          values.document_sub_type = " ";
                          values.document_name = "Membershipplan Picture";
                          values.user_id = this.loginid;
                          values.product_partner = " ";
                          values.companycode = "42160 Jomiretto GmbH";
                          values.brand = "Coursula";
                          values.upload_by = "exercise";
                          values.bill_type = "";
                          values.ticket_no = this.ticket_no;
                          values.tags.push(StringTypeCasting.toString());
                          values.tags.push(typeofimage);
                          values.tags.push(dateofdocument);
                          this.uploadDocument1(values, i);
            
                          // this.documentid='';
                          values.tags = [];
                        };
            }
                    }
                  }
                  else{
                    console.log("dddddddddddddd"+this.currentopendata.element.document[0]);
                    this.documentid[0]=this.currentopendata.element.document[0];
                    this.documentid[1]=this.currentopendata.element.document[1];
                    console.log("dddddddddddddd"+this.documentid);
                    this.updatemembershipplan();
                  }
  
        }
        else{
          console.log("dddddddddddddd"+this.currentopendata.element.document[0]);
          this.documentid[0]=this.currentopendata.element.document[0];
          this.documentid[1]=this.currentopendata.element.document[1];
          console.log("dddddddddddddd"+this.documentid);
          this.updatemembershipplan();
        }
   
    }
  
      
    uploadDocument1(values: any, index: any) {
      this.progress[index] = 0;
      // this.progressInfos[index] = { value: 0, fileName: values.image.name };
      let length = this.filearray.length;
      console.log(length);
  
      const formData = new FormData();
      formData.append("document_type", values.document_type);
      formData.append("document_sub_type", values.document_sub_type);
      formData.append("product_partner", values.product_partner);
      formData.append("user", values.user_id);
      formData.append("companycode", values.companycode);
      formData.append("brand", values.brand);
      formData.append("tags", values.tags);
      formData.append("upload_by", values.upload_by);
      formData.append("bill_type", "");
      formData.append("ticket_no", values.ticket_no);
      formData.append("document_name", values.document_name);
    
      formData.append("olddocument_id", this.currentopendata.element.document[index]);
      // formData.append("index", index);
      if (values.image !== "") {
        formData.append("document", values.image);
      }
  
      this.userService.uploadmembershipproductdocument(
        formData
      ).subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
  
          case HttpEventType.Sent:
            console.log('Request has been made!');
            
            break;
          case HttpEventType.ResponseHeader:
            console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            console.log(event.total);
            console.log(event.loaded);
            let percentage=Math.round(event.loaded / event.total * 100);
            if(percentage>0)
            {
            this.progress[index] = percentage;
            }
            console.log(`Uploaded! ${this.progress}%`);
            break;
          case HttpEventType.Response:
            console.log('User successfully created!', event.body);
            
            setTimeout(() => {
              this.uploadlength++;
              // this.progress[index] = 0;
              console.log(index);
              console.log(event.body.document_unique_id);
               this.documentid[index]=event.body.document_unique_id;
              this.updatemembershipplan();
            }, 1500);
  
        }
      })
    }
      
        updatemembershipplan()
        {
          
      console.log("sssssssssssssssssssssssssssss");
      if (this.lengthfile == this.uploadlength) {
        let data = {
          prevention_course_id: this.prevention_course_id,
      name: this.personalInfoForm.name.value,
      description: this.personalInfoForm.description.value,
      price: this.personalInfoForm.price.value,
      discount: this.personalInfoForm.discount.value,
      coupen_code: this.personalInfoForm.coupen_code.value,
      commission: this.personalInfoForm.commission.value,
     b2bid:this.b2bid,
      tax:this.personalInfoForm.tax.value,
      document: this.documentid,
      created_by: this.loginid,
      patientproduct_id: this.ticket_no,
          _id: this.currentopendata.element._id,
        };
        console.log(data);
      
        this.userService
          .updatepatientproduct(data)
          .pipe(first())
          .subscribe(
            (data) => {
              Swal.fire(
                "Patientenprodukt erfolgreich aktualisiert.",
                "",
                "success"
              );
              this.ticket_no = "";
      
              $("#loaderouterid").css("display", "none");
              setTimeout(() => {
                Swal.close();
              this.getallexerciesequence(this.currentopendata.element._id);
              }, 1000);
            },
            (error) => {
              console.log("Error", error["error"]);
              $("#loaderouterid").css("display", "none");
              setTimeout(() => {
                Swal.close();
               
              }, 1000);
              // $("html, body").animate(
              //   { scrollTop: "0" }, 3000);
            }
          );
          }
        }
      
  
    onSubmit() {
      const that = this;
      console.log();
  
      // this.values = event.target.value;
      if (this.values != "") {
        // var datanew={currentrole:this.loginRole,id:this.loginid}
        this.userService
          .getSearchpatientproduct(this.values)
          .subscribe(function (data1) {
            that.customerList = data1;
  
            that.setPage(1);
  
            that.recordCount = that.customerList.length;
            //this.customerList.push(data1);
            //return this.customerList.push(data1);
          });
      } else {
        this.getallexerciesequence();
      }
    }
  
    setPage(page: number) {
      // this.getdivoutside();
      // get pager object from service
      this.pager = this.pagerService.getPager(this.customerList.length, page);
  
      // get current page of items
      this.pagedItems = this.customerList.slice(
        this.pager.startIndex,
        this.pager.endIndex + 1
      );
      if (this.customerList.length > 0) {
        this.startRecord =
          this.pager.currentPage * this.pagerService.getDefaultPageSize() -
          this.pagerService.getDefaultPageSize() +
          1;
        this.endRecord =
          this.pager.currentPage * this.pagerService.getDefaultPageSize() >
          this.customerList.length
            ? this.customerList.length
            : this.pager.currentPage * this.pagerService.getDefaultPageSize();
      } else {
        this.startRecord = 0;
        this.endRecord = 0;
      }
    }
  
  
  

}
