import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from "@angular/router";
import { UserService } from '../user.service';
import { PagerService } from '../pager.service';
import intlTelInput from "intl-tel-input";
import { first, map, startWith } from "rxjs/operators";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { MatChipInputEvent } from '@angular/material';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
export interface Broker {
  name: string;
  value: string;
}
@Component({
  selector: 'app-patient-management',
  templateUrl: './patient-management.component.html',
  styleUrls: ['./patient-management.component.css']
})
export class PatientManagementComponent implements OnInit {
  registerDone = false;
  registraionError = false;
  searchlist=[];
  token: any;
  title = this.userService.getDecodedAccessToken(sessionStorage.getItem("Token"))
    .title;
  loginid = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).id;
  exerciseoption: Observable<any[]>
  customerno = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).customerno;
  lastname = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).lastname;
  firstname = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).firstname;
  COMPANYNAME = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).companyname;
  userData: any = [];
  usedpatient: any = "0";
  noofpatients: any = "0";
  remnew: any = "0";
  membershipid = '';
  start_date: any;
  duration: any = '';
  plantype: any = '';
  startRecord: any;
  oldtypesOptionsArray: any[] = [];
  pager: any = {};
  customerList: any = [];
  selectedtherapyplan;
  pagedItems: any[];
  id: any;
  roleCompanies = {
    admin: [],
    customer: [],
    b2b: [],
    employee: []
  };
  b2b = false;
  employee = false;
  admin = false;
  user = false;
  customer = false;
  endRecord: any;
  error: { [index: string]: any } = {};
  recordCount: any;
  popupData: any = [];
  disableEditSubmit: boolean = true;
  brokerList: Broker[] = [];
  roleCompaniesbrand = {
    admin: [],
    customer: [],
    b2b: [],
    employee: []
  };
  roleCompaniesbrandnew = [];

  roleCompaniescode = {
    admin: [],
    customer: [],
    b2b: [],
    employee: []
  };


  mekFinanz = false;
  values = "";
  fiorettoimmob = false;
  birema = false;
  fiorettomedia = false;
  airmage = false;
  horaidetektei = false;
  varioimport = false;
  coursula = false;
  sterbvorsoge = false;
  checkntrack = false;
  coursula_b2b = false;
  brokerbrandarray = []
  brokerarray = []

  statusbrandarray = []
  statusarray = []
  employeebrandarray = []
  employeearray = []
  currentUserData: any;
  roleFormGroup: FormGroup;
  companyFormGroup: FormGroup;
  broker: any;
  nationality: string = "";
  Benutzer = false;
  customerFormGroup: FormGroup;
  brokerFormControl = new FormControl();
  optionsValue: string[] = [
    "Afghanisch",
    "Ägyptisch",
    "Albanisch",
    "Algerisch",
    "Andorranisch",
    "Angolanisch",
    "Antiguanisch",
    "Äquatorialguineisch",
    "Argentinisch",
    "Armenisch",
    "Aserbaidschanisch",
    "Äthiopisch",
    "Australisch",
    "Bahamaisch",
    "Bahrainisch",
    "Bangladeschisch",
    "Barbadisch",
    "Belgisch",
    "Belizisch",
    "Beninisch",
    "Bhutanisch",
    "Bolivianisch",
    "Bosnisch herzegowinisch",
    "Botsuanisch",
    "Brasilianisch",
    "Bruneiisch",
    "Bulgarisch",
    "Burkinisch",
    "Burundisch",
    "Chilenisch",
    "Chinesisch",
    "Costa-ricanisch",
    "Dänisch",
    "Kongolesisch",
    "Deutsch",
    "Dominikanisch",
    "Dschibutisch",
    "Ecuadorianisch",
    "Salvadorianisch",
    "Ivorisch",
    "Englisch",
    "Eritreisch",
    "Estnisch",
    "Fidschianisch",
    "Finnisch",
    "Französisch",
    "Gabunisch",
    "Gambisch",
    "Georgisch",
    "Ghanaisch",
    "Grenadisch",
    "Griechisch",
    "Grönländisch",
    "Britisch",
    "Guatemaltekisch",
    "Guineisch",
    "Guinea-bissauisch",
    "Guyanisch",
    "Haitianisch",
    "Honduranisch",
    "Indisch",
    "Indonesisch",
    "Irakisch",
    "Iranisch",
    "Irisch",
    "Isländisch",
    "Israelisch",
    "Italienisch",
    "Jamaikanisch",
    "Japanisch",
    "Jemenitisch",
    "Jordanisch",
    "Kambodschanisch",
    "Kamerunisch",
    "Kanadisch",
    "Kapverdisch",
    "Kasachisch",
    "Katarisch",
    "Kenianisch",
    "Kirgisisch",
    "Kiribatisch",
    "Kolumbianisch",
    "Komorisch",
    "Kongolesisch",
    "Kosovarisch",
    "Kroatisch",
    "Kubanisch",
    "Kuwaitisch",
    "Laotisch",
    "Lesothisch",
    "Lettisch",
    "Libanesisch",
    "Liberianisch",
    "Libysch",
    "Liechtensteinisch",
    "Litauisch",
    "Luxemburgisch",
    "Madagassisch",
    "Malawisch",
    "Malaysisch",
    "Maledivisch",
    "Malisch",
    "Maltesisch",
    "Marokkanisch",
    "Marshallisch",
    "Mauretanisch",
    "Mauritisch",
    "Mazedonisch",
    "Mexikanisch",
    "Mikronesisch",
    "Moldauisch",
    "Monegassisch",
    "Mongolisch",
    "Montenegrinisch",
    "Mosambikanisch",
    "Myanmarisch",
    "Namibisch",
    "Nauruisch",
    "Nepalesisch",
    "Neuseeländisch",
    "Nicaraguanisch",
    "Niederländisch",
    "Nigrisch",
    "Nigerianisch",
    "Nordkoreanisch",
    "Norwegisch",
    "Ömanisch",
    "Österreichisch",
    "Pakistanisch",
    "Palästinensisch",
    "Palauisch",
    "Panamaisch",
    "Papua-neuguineisch",
    "Paraguayisch",
    "Peruanisch",
    "Philippinisch",
    "Polnisch",
    "Portugiesisch",
    "Puerto-ricanisch",
    "Kongolesisch",
    "Ruandisch",
    "Rumänisch",
    "Russisch",
    "Salomonisch",
    "Sambisch",
    "Samoanisch",
    "San-marinesisch",
    "Saudi-arabisch",
    "Schottisch",
    "Schwedisch",
    "Schweizerisch",
    "Senegalesisch",
    "Serbisch",
    "Seychellisch",
    "Sierra-leonisch",
    "Simbabwisch",
    "Singapurisch",
    "Slowenisch",
    "Somalisch",
    "Spanisch",
    "Sri-lankisch",
    "Lucianisch",
    "Vincentisch",
    "Südafrikanisch",
    "Sudanesisch",
    "Südkoreanisch",
    "Surinamisch",
    "Swasiländisch",
    "Syrisch",
    "São-toméisch",
    "Tadschikisch",
    "Taiwanisch",
    "Tansanisch",
    "Thailändisch",
    "Tibetisch",
    "Timoresisch",
    "Togoisch",
    "Tongaisch",
    "Tschadisch",
    "Tschechisch",
    "Tunesisch",
    "Türkisch",
    "Turkmenisch",
    "Tuvaluisch",
    "Ugandisch",
    "Ukrainisch",
    "Ungarisch",
    "Uruguayisch",
    "Amerikanisch",
    "Üsbekisch",
    "Vanuatuisch",
    "Vatikanisch",
    "Venezolanisch",
    "Vietnamesisch",
    "Walisisch",
    "Weißrussisch",
    "Zentralafrikanisch"
  ];
  optionsValueland: string[] = [
    "Afghanisch",
    "Ägyptisch",
    "Albanisch",
    "Algerisch",
    "Andorranisch",
    "Angolanisch",
    "Antiguanisch",
    "Äquatorialguineisch",
    "Argentinisch",
    "Armenisch",
    "Aserbaidschanisch",
    "Äthiopisch",
    "Australisch",
    "Bahamaisch",
    "Bahrainisch",
    "Bangladeschisch",
    "Barbadisch",
    "Belgisch",
    "Belizisch",
    "Beninisch",
    "Bhutanisch",
    "Bolivianisch",
    "Bosnisch herzegowinisch",
    "Botsuanisch",
    "Brasilianisch",
    "Bruneiisch",
    "Bulgarisch",
    "Burkinisch",
    "Burundisch",
    "Chilenisch",
    "Chinesisch",
    "Costa-ricanisch",
    "Dänisch",
    "Kongolesisch",
    "Deutsch",
    "Dominikanisch",
    "Dschibutisch",
    "Ecuadorianisch",
    "Salvadorianisch",
    "Ivorisch",
    "Englisch",
    "Eritreisch",
    "Estnisch",
    "Fidschianisch",
    "Finnisch",
    "Französisch",
    "Gabunisch",
    "Gambisch",
    "Georgisch",
    "Ghanaisch",
    "Grenadisch",
    "Griechisch",
    "Grönländisch",
    "Britisch",
    "Guatemaltekisch",
    "Guineisch",
    "Guinea-bissauisch",
    "Guyanisch",
    "Haitianisch",
    "Honduranisch",
    "Indisch",
    "Indonesisch",
    "Irakisch",
    "Iranisch",
    "Irisch",
    "Isländisch",
    "Israelisch",
    "Italienisch",
    "Jamaikanisch",
    "Japanisch",
    "Jemenitisch",
    "Jordanisch",
    "Kambodschanisch",
    "Kamerunisch",
    "Kanadisch",
    "Kapverdisch",
    "Kasachisch",
    "Katarisch",
    "Kenianisch",
    "Kirgisisch",
    "Kiribatisch",
    "Kolumbianisch",
    "Komorisch",
    "Kongolesisch",
    "Kosovarisch",
    "Kroatisch",
    "Kubanisch",
    "Kuwaitisch",
    "Laotisch",
    "Lesothisch",
    "Lettisch",
    "Libanesisch",
    "Liberianisch",
    "Libysch",
    "Liechtensteinisch",
    "Litauisch",
    "Luxemburgisch",
    "Madagassisch",
    "Malawisch",
    "Malaysisch",
    "Maledivisch",
    "Malisch",
    "Maltesisch",
    "Marokkanisch",
    "Marshallisch",
    "Mauretanisch",
    "Mauritisch",
    "Mazedonisch",
    "Mexikanisch",
    "Mikronesisch",
    "Moldauisch",
    "Monegassisch",
    "Mongolisch",
    "Montenegrinisch",
    "Mosambikanisch",
    "Myanmarisch",
    "Namibisch",
    "Nauruisch",
    "Nepalesisch",
    "Neuseeländisch",
    "Nicaraguanisch",
    "Niederländisch",
    "Nigrisch",
    "Nigerianisch",
    "Nordkoreanisch",
    "Norwegisch",
    "Ömanisch",
    "Österreichisch",
    "Pakistanisch",
    "Palästinensisch",
    "Palauisch",
    "Panamaisch",
    "Papua-neuguineisch",
    "Paraguayisch",
    "Peruanisch",
    "Philippinisch",
    "Polnisch",
    "Portugiesisch",
    "Puerto-ricanisch",
    "Kongolesisch",
    "Ruandisch",
    "Rumänisch",
    "Russisch",
    "Salomonisch",
    "Sambisch",
    "Samoanisch",
    "San-marinesisch",
    "Saudi-arabisch",
    "Schottisch",
    "Schwedisch",
    "Schweizerisch",
    "Senegalesisch",
    "Serbisch",
    "Seychellisch",
    "Sierra-leonisch",
    "Simbabwisch",
    "Singapurisch",
    "Slowenisch",
    "Somalisch",
    "Spanisch",
    "Sri-lankisch",
    "Lucianisch",
    "Vincentisch",
    "Südafrikanisch",
    "Sudanesisch",
    "Südkoreanisch",
    "Surinamisch",
    "Swasiländisch",
    "Syrisch",
    "São-toméisch",
    "Tadschikisch",
    "Taiwanisch",
    "Tansanisch",
    "Thailändisch",
    "Tibetisch",
    "Timoresisch",
    "Togoisch",
    "Tongaisch",
    "Tschadisch",
    "Tschechisch",
    "Tunesisch",
    "Türkisch",
    "Turkmenisch",
    "Tuvaluisch",
    "Ugandisch",
    "Ukrainisch",
    "Ungarisch",
    "Uruguayisch",
    "Amerikanisch",
    "Üsbekisch",
    "Vanuatuisch",
    "Vatikanisch",
    "Venezolanisch",
    "Vietnamesisch",
    "Walisisch",
    "Weißrussisch",
    "Zentralafrikanisch"
  ];
  myControl = new FormControl();
  myControlland = new FormControl();
  customerNo: string;
  companyTitleShow = false;
  buttonid = '';
  indexid = '';
  member;
  selectedUser = {
    customerno: "",
    _id: "",
    roles: [],
    companies_with_roles: []
  };
  exercisesequencelist: any[][] = [];
  editid: any = "";
  exerciseformgroup: FormGroup
  treatmentdata: any;
  phase: any = []
  minDate: Date;
  minDateforend: Date;
  minDatesecondstep: Date[] = [];
  minDateforendsecondstep: Date[] = [];
  maxDate11: Date;
  exercisesequencelistsearch: any = [];
  typesOptionsArrayedit: any[] = [];
  searchby = '';
  exercise_controls = new FormControl();
  exercisedata: any = [];
  routeParams: any;
  loginRole = sessionStorage.getItem("currentActiveRole");
  preventioncourselist;
  api_url: any;
  brokerListOptions: Observable<Broker[]>;
  filteredOptions: Observable<string[]>;
  filteredOptionsland: Observable<string[]>;
  secondsSinceEpoch: any;
  personalInfoFormGroupmodal: FormGroup;
  personalInfoFormGroupadd: FormGroup;
  addressFormGroup: FormGroup;
  moreinfoFormGroup: FormGroup;
  constructor(private userService: UserService,
    private pagerService: PagerService, private _formBuilder: FormBuilder,
    private router: Router,) { }

  filtercustomer(success, companyName) {
    let newsuccess = [];
    console.log("stringify" + JSON.stringify(success));
    console.log("ddd" + success.length);
    for (let i = 0; i < success.length; i++) {
      let brokerbrandarray = success[i].brokerbrandarray;
      let brokerarray = success[i].brokerarray;
      let a = brokerbrandarray.indexOf(
        companyName.charAt(0).toUpperCase() + companyName.slice(1)
      );
      console.log("i" + i);
      console.log("a" + a);

      if (a != -1) {
        let brokervaluenew = brokerarray[a];
        console.log("ddd" + brokervaluenew);
        console.log("ddd" + this.customerno);
        if (brokervaluenew == this.customerno) {
          console.log("ifke ander");
        } else {
          newsuccess.push(i);
          // success.splice(i,1);
          console.log("ifelse ke ander" + this.customerno);
        }
      } else {
        newsuccess.push(i);
        console.log("ifelseelse ke ander" + this.customerno);
      }
    }

    console.log("stringify" + JSON.stringify(success));

    for (let i1 = 0; i1 < newsuccess.length; i1++) {
      delete success[newsuccess[i1]];
      // success.splice(newsuccess[i1],1);
    }
    console.log(newsuccess);
    console.log("stringify" + JSON.stringify(success));
    success = success.filter(function () {
      return true;
    });
    console.log("stringify" + JSON.stringify(success));
    return success;
  }
  _filterexercisedata(value: string): string[] {
    
    let searchlist=this.exercisedata;
    let searchlistnewlist=[];
    console.log("kundenew11" +JSON.stringify(searchlist));
      for(let i=0;i<this.typesOptionsArrayedit.length;i++)
      {
  const filterValue = this.typesOptionsArrayedit[i].toLowerCase();
  searchlist = searchlist.filter(function(itm){
    return JSON.stringify(itm.name).toLowerCase().indexOf(filterValue) > -1 || JSON.stringify(itm.tags).toLowerCase().indexOf(filterValue) > -1;
  });
      }
  
      const filterValue11 = value.toLowerCase();
      searchlistnewlist = this.exercisedata.filter(function(itm){
        return JSON.stringify(itm.name).toLowerCase().indexOf(filterValue11) > -1 || JSON.stringify(itm.tags).toLowerCase().indexOf(filterValue11) > -1;
      });
      searchlist = searchlist.filter(function(itm){
        return JSON.stringify(itm.name).toLowerCase().indexOf(filterValue11) > -1 || JSON.stringify(itm.tags).toLowerCase().indexOf(filterValue11) > -1;
      });
      
      console.log("kundenew11" +JSON.stringify(searchlist));
      this.searchlist=searchlist;
      return searchlistnewlist;
    }
  ngOnInit() {
    this.api_url = environment.API_URL
    this.exerciseformgroup = this._formBuilder.group({
      edit_id: [""],
      start_date: ["", Validators.required],
      Behandlung_name: ["", Validators.required],
      end_date: ["", Validators.required],
      secondstep: this._formBuilder.array([]),
    });

    this.brokerListOptions = this.brokerFormControl.valueChanges.pipe(
      startWith(""),
      map(value => (typeof value === "string" ? value : value.name)),
      map(name => (name ? this._brokerfilter(name) : this.brokerList.slice()))
    );

    this.exerciseoption = this.exercise_controls.valueChanges.pipe(
      startWith(""),
      map((value) => (typeof value == "string" ? value : value.name)),
      map((name) =>this._filterexercisedata(name)
      )
    );

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map(value => this._filter(value))
    );

    this.filteredOptionsland = this.myControlland.valueChanges.pipe(
      startWith(""),
      map(value => this._filterland(value))
    );
    var myCurrentDate = new Date();
    var myPastDate = new Date(myCurrentDate);
    myPastDate.setDate(myPastDate.getDate() - 1);

    let data = Math.round(myPastDate.getTime() / 1000);

    this.secondsSinceEpoch = data;
    console.log("hkjhhjjh" + this.secondsSinceEpoch);
    this.roleFormGroup = this._formBuilder.group({
      roles: this._formBuilder.array([], Validators.required)
    });

    this.companyFormGroup = this._formBuilder.group({
      companies: this._formBuilder.array([], Validators.required)
    });
    this.personalInfoFormGroupmodal = this._formBuilder.group({
      exercise_name: ["", Validators.required],
      exercise_name1: ["", Validators.required],
      comment1: [""],
      comment: ["", Validators.required],
      manual_tags: ["", Validators.required],
      tools: ["", Validators.required],
      difficully_level: ["", Validators.required],

      upload_file: ["", Validators.required],
      explain: ["", Validators.required],
      implementation: ["", Validators.required],
      exercise_category: ["", Validators.required],
    });
    this.customerFormGroup = this._formBuilder.group({
      title: ["", Validators.required],
      status: ["", Validators.required],
      employeetype: ["", Validators.required],

      companyname: ["", Validators.required],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: ["", Validators.required],
      dob: ["", Validators.required],
      nationality: ["", Validators.required],
      birthPlace: ["", Validators.required],
      street: ["", Validators.required],
      streetNumber: ["", Validators.required],
      postCode: ["", Validators.required],
      city: ["", Validators.required],
      additionalReference: [""],
      countryOfResidence: ["", Validators.required],
      id: [""],
      password: ["", Validators.required],
      phone_number: ["", Validators.required],
      customerno: [""],
      broker: [""],
      brokerbrand: [""],
      statusbrand: [""],
      employeebrand: [""],
      registration_number: [""],
      Vermittlerstatus: [""],
      broker_type: [""],

    });


    this.personalInfoFormGroupadd = this._formBuilder.group({
      status: ["", Validators.required],
      title: ["", Validators.required],
      companyName: [" ", Validators.required],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],
      dob: ["", Validators.required],
      nationality: ["", Validators.required],
      birthPlace: ["", Validators.required],
    });

    this.addressFormGroup = this._formBuilder.group({
      street: ["", Validators.required],
      streetNumber: ["", Validators.required],
      postCode: ["", Validators.required],
      city: ["", Validators.required],
      additionalReference: [""],
      countryOfResidence: ["", Validators.required],
    });

    this.moreinfoFormGroup = this._formBuilder.group({
      password: ["", Validators.required],
      phone_number: ["", Validators.required],
      customerno: ["", Validators.required],
    });

    this.exerciseformgroup.patchValue({
      start_date: new Date()
    })

    // this.minDate = new Date();
    // this.minDateforend=new Date();

    let numWeeks = 12;
    let now = new Date(this.exerciseformgroup.controls.start_date.value);
    now.setDate(now.getDate() + numWeeks * 7);
    this.maxDate11 = now;
    $("#loaderouterid").css("display", "block");

    this.getactiveplan()

    this.customerList = [];
    // this.userService.getCustomerscoursula("coursula", true).subscribe(
    this.userService.getCustomerscoursula("ontrain", true).subscribe(
      (success: any) => {
        // if success and error give response
        console.log("cefimaaaaaaaaaaaaa");
        if (success.status == "error") {
          this.error = success.message;
        } else {
          // var success = this.filtercustomer(success, "coursula");
          var success = this.filtercustomer(success, "ontrain");
          this.brokerList = this.getBrokersData(success);
          this.customerList = success;
          console.log(this.customerList);
          console.log("this.customerList" + success);
          console.log("this.customerno" + this.customerno);
          this.setPage(1);
          this.recordCount = success.length;
          $("#loaderouterid").css("display", "none");
        }
      },
      rejected => {
        console.log(rejected);
      }
    );



  }
  private _brokerfilter(name: string): Broker[] {
    console.log("_brokerfilter" + name);
    console.log("_broker" + JSON.stringify(this.brokerList));
    const filterValue = name.toLowerCase();
    return this.brokerList.filter(option =>
      option.name.toLowerCase().includes(filterValue)
    );
  }
  private _filter(value: string): string[] {
    console.log("_filter" + value);
    const filterValue = value.toLowerCase();
    return this.optionsValue.filter(option =>
      option.toLowerCase().includes(filterValue)
    );
  }

  private _filterland(value: string): string[] {
    console.log("_filterland" + value);
    const filterValue = value.toLowerCase();
    return this.optionsValueland.filter(option =>
      option.toLowerCase().includes(filterValue)
    );
  }
  calculatelength() {
    let count = 0;
    for (let i = 0; i < this.phase.length; i++) {
      for (let j = 0; j < this.exercisesequencelist[i].length; j++) {
        count++;
      }
    }
    return count;
  }
  
  calcounttreatement(id, type) {
    let Behandlung_namecount: any;
    if (type == "Behandlung_name") {
      let Behandlung_name = this.exerciseformgroup.controls.Behandlung_name
        .value.length;

      Behandlung_namecount = 35 - Behandlung_name;
    }
    $("#" + id).html(Behandlung_namecount);
  }

  
  checkphase() {
    if (this.phase.length > 0) {
      if (
        this.phase.length - 1 ==
        this.exerciseformgroup.controls.secondstep.value.length
      ) {
        return 1;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }
  setPage(page: number) {
    this.getdivoutside();
    // get pager object from service
    this.pager = this.pagerService.getPager(this.customerList.length, page);

    // get current page of items
    this.pagedItems = this.customerList.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );
    if (this.customerList.length > 0) {
      this.startRecord =
        this.pager.currentPage * this.pagerService.getDefaultPageSize() -
        this.pagerService.getDefaultPageSize() +
        1;
      this.endRecord =
        this.pager.currentPage * this.pagerService.getDefaultPageSize() >
          this.customerList.length
          ? this.customerList.length
          : this.pager.currentPage * this.pagerService.getDefaultPageSize();
    } else {
      this.startRecord = 0;
      this.endRecord = 0;
    }
  }
  removeQuantity(i: number, index: any) {
    this.quantities().removeAt(i);
    this.phase.splice(index, 1);
    this.exercisesequencelist[index].splice(
      0,
      this.exercisesequencelist[index].length
    );
    // this.uploadedFiles.splice(index, 1);
    // this.imgURL.splice(index, 1);
  }
  update_exercise_sequence()
  {
    $("#loaderouterid").css("display", "block");

    let companycodenew = "";

    companycodenew = "60-co-";

    this.userService.getLastdocument1()
      .pipe(first())
      .subscribe((data1) => {
        console.log("ticket_no" + data1);
  
let dataarray=[];

for (let j = 0; j < this.phase.length; j++) {
  for (let i = 0; i < this.exercisesequencelist[j].length; i++) {
    // let datannn={'exercise_id1':this.exercisesequencelist[i].id}
    dataarray.push({
      phase: j + 1,
      dates: this.phase[j],
      exercise: this.exercisesequencelist[j][i].id,
    });
  }
}
        
        let datanew = {
            Behandlung_name: this.exerciseformgroup.controls.Behandlung_name
            .value,
            edit_id: this.exerciseformgroup.controls.edit_id.value,
          exercise_id: dataarray,
        };

        this.userService
        .updatestarttreatment(datanew)
        .pipe(first())
        .subscribe(
          (data) => {
      
            Swal.fire(
              "Behandlungs erfolgreich aktualisiert.",
              "",
              "success"
            );
      
            // $('#clicksequenceedit'+this.exerciseformgroup.controls.edit_id.value).trigger('click');
            $("#loaderouterid").css("display", "none");
            
            setTimeout(() => {
              Swal.close();
              this.getallcustomer(this.editid);
              this.exercisesequencelist=[];
          //  this.exercise_management();
            }, 1000);
          },
          (error) => {
            Swal.fire("Error" + error["error"] + "", "", "error");

            $("#loaderouterid").css("display", "none");
            setTimeout(() => {
              Swal.close();
              this.getallcustomer(this.editid);
              this.exercisesequencelist=[];
            }, 1000);
            // $("html, body").animate(
            //   { scrollTop: "0" }, 3000);
          }
        );





      });
  }
  drop(event: CdkDragDrop<any[]>) {
    let lengthtotal = this.exerciselength(this.exercisesequencelist.length) - 1;
    let indexnewcurrent: any = lengthtotal - event.currentIndex;
    let indexnewprevious: any = lengthtotal - event.previousIndex;
    if (event.previousContainer === event.container) {
      let key1;
      let count = 0;
      $.each(this.exercisesequencelist, function (key, element) {
        let coutexercise = element.length;
        count = count + coutexercise;
        if (indexnewcurrent < count) {
          key1 = key;
          return false;
        }
      });

      let key1previous;
      let countprevious = 0;
      $.each(
        this.exercisesequencelist,
        function (keyprevious, elementprevious) {
          let coutexerciseprevious = elementprevious.length;
          countprevious = countprevious + coutexerciseprevious;
          if (indexnewprevious < countprevious) {
            key1previous = keyprevious;
            return false;
          }
        }
      );

      if (this.exercisesequencelist[key1previous].length > 1) {
        console.log("key1previous" + key1previous);
        console.log("key1previous" + key1);
        if (key1previous == key1) {
          let totalpreviouscount = 0;
          if (key1 >= 0) {
            for (let k = 0; k < key1; k++) {
              totalpreviouscount =
                totalpreviouscount + this.exercisesequencelist[k].length;
            }
          }

      
          let movedata = {
            id: event.previousContainer.data[key1][
              indexnewprevious - totalpreviouscount
            ].id,
            value:
              event.previousContainer.data[key1][
                indexnewprevious - totalpreviouscount
              ].value,
            img: event.previousContainer.data[key1][
              indexnewprevious - totalpreviouscount
            ].img,
            diff: event.previousContainer.data[key1][
              indexnewprevious - totalpreviouscount
            ].diff,
            tags: event.previousContainer.data[key1][
              indexnewprevious - totalpreviouscount
            ].tags,
          };

          if (
            indexnewcurrent - totalpreviouscount >
            indexnewprevious - totalpreviouscount
          ) {
            for (
              let i = (indexnewprevious - totalpreviouscount) + 1;
              i <= indexnewcurrent - totalpreviouscount;
              i++
            ) {
              let movedata1 = {
                id: event.previousContainer.data[key1][i].id,
                value: event.previousContainer.data[key1][i].value,
                img: event.previousContainer.data[key1][i].img,
                diff: event.previousContainer.data[key1][i].diff,
                tags: event.previousContainer.data[key1][i].tags,
              };

              this.exercisesequencelist[key1][i - 1] = movedata1;
            }

            this.exercisesequencelist[key1][
              (indexnewcurrent - totalpreviouscount)
            ] = movedata;
          } else {
            for (
              let i = (indexnewprevious - totalpreviouscount) - 1;
              i >= indexnewcurrent - totalpreviouscount;
              i--
            ) {
              let movedata1 = {
                id: event.previousContainer.data[key1][i].id,
                value: event.previousContainer.data[key1][i].value,
                img: event.previousContainer.data[key1][i].img,
                diff: event.previousContainer.data[key1][i].diff,
                tags: event.previousContainer.data[key1][i].tags,
              };

              this.exercisesequencelist[key1][i + 1] = movedata1;
            }

            this.exercisesequencelist[key1][
              (indexnewcurrent - totalpreviouscount)
            ] = movedata;
          }
        } else {
          let totalpreviouscountnewprevious = 0;
          if (key1previous > 0) {
            for (let k = 0; k < key1previous; k++) {
              totalpreviouscountnewprevious =
                totalpreviouscountnewprevious +
                this.exercisesequencelist[k].length;
            }
          }
          console.log(
            "totalpreviouscountnewprevious" +
            totalpreviouscountnewprevious +
              "previousIndex" +
              event.previousIndex +
              "indexnewprevious" +
              indexnewprevious +
              "currentIndex" +
              event.currentIndex +
              "indexnewcurrent" +
              indexnewcurrent
          );

          let movedata = {
            id: event.previousContainer.data[key1previous][
              indexnewprevious - totalpreviouscountnewprevious
            ].id,
            value:
              event.previousContainer.data[key1previous][
                indexnewprevious - totalpreviouscountnewprevious
              ].value,
            img: event.previousContainer.data[key1previous][
              indexnewprevious - totalpreviouscountnewprevious
            ].img,
            diff: event.previousContainer.data[key1previous][
              indexnewprevious - totalpreviouscountnewprevious
            ].diff,
            tags: event.previousContainer.data[key1previous][
              (indexnewprevious - totalpreviouscountnewprevious)
            ].tags,
          };

          if (
            (indexnewprevious - totalpreviouscountnewprevious) + 1 ==
            this.exercisesequencelist[key1previous].length
          ) {
            this.exercisesequencelist[key1previous].splice(
              indexnewprevious - totalpreviouscountnewprevious,
              1
            );
          } else {
            for (
              let i = indexnewprevious - totalpreviouscountnewprevious + 1;
              i < this.exercisesequencelist[key1previous].length;
              i++
            ) {
              let movedata1 = {
                id: event.previousContainer.data[key1previous][i].id,
                value: event.previousContainer.data[key1previous][i].value,
                img: event.previousContainer.data[key1previous][i].img,
                diff: event.previousContainer.data[key1previous][i].diff,
                tags: event.previousContainer.data[key1previous][i].tags,
              };

              this.exercisesequencelist[key1previous][i - 1] = movedata1;
            }
            this.exercisesequencelist[key1previous].splice(
              this.exercisesequencelist[key1previous].length - 1,
              1
            );
          }

          let totalpreviouscount = 0;
          if (key1 > 0) {
            for (let k = 0; k < key1; k++) {
              totalpreviouscount =
                totalpreviouscount + this.exercisesequencelist[k].length;
            }
          }
          console.log(
            "totalpreviouscount" +
            totalpreviouscount 
              
          );
          let lastarraystartindex;
          if (indexnewcurrent > (totalpreviouscount)) {
            lastarraystartindex = indexnewcurrent - (totalpreviouscount);
          } else {
            lastarraystartindex = totalpreviouscount - indexnewcurrent;
          }
       
          let currentarraylength = this.exercisesequencelist[key1].length;

          if (lastarraystartindex <= currentarraylength - 1) {
           
            for (
              let m = currentarraylength - 1;
              m >= lastarraystartindex;
              m--
            ) {
              let movedata1 = {
                id: event.previousContainer.data[key1][m].id,
                value: event.previousContainer.data[key1][m].value,
                img: event.previousContainer.data[key1][m].img,
                diff: event.previousContainer.data[key1][m].diff,
                tags: event.previousContainer.data[key1][m].tags,
              };
              if (m == currentarraylength) {
                this.exercisesequencelist[key1].push(movedata1);
              } else {
                this.exercisesequencelist[key1][m + 1] = movedata1;
              }
            }
          }
          this.exercisesequencelist[key1][lastarraystartindex] = movedata;
        }
      } else {
        Swal.fire(
          "In dieser Phase ist nur noch 1 Übung übrig. Also nicht bewegen",
          "",
          "error"
        );
      }

      // moveItemInArray(
      //   event.container.data,
      //   event.previousIndex,
      //   event.currentIndex
      // );
    } else {
      console.log("container1" + JSON.stringify(event.previousContainer.data));
      console.log(
        "container1" +
          JSON.stringify(event.container.data) +
          "sssss" +
          event.currentIndex
      );

      var datanew = {
        id: event.previousContainer.data[event.previousIndex].id,
      };
      let key1;
      let count = 0;
      $.each(this.exercisesequencelist, function (key, element) {
        let coutexercise = element.length;
        count = count + coutexercise;
        if (indexnewcurrent < count) {
          key1 = key;
          return false;
        }
      });

      console.log("container1" + key1);

      let add = 0;
      let indexnew = 0;
      if (this.searchby == "both") {
        $("#loaderouterid").css("display", "block");
        $("#loadertext").html("Please wait for extract therapy Plan..");
        $("#loadertext").css("width", "320px");
        $("#loadertext").css("margin-left", "-160px");
        const data = this.userService.gettherapyplannew(datanew).subscribe(
          (success: any) => {
            if (success.status == "error") {
              $("#loadertext").html("");
              $("#loadertext").css("width", "100px");
              $("#loaderouterid").css("display", "none");
              $("#loadertext").css("margin-left", "-50px");
            } else {
              this.selectedtherapyplan = success;
              let totalphase =
                this.selectedtherapyplan[this.selectedtherapyplan.length - 1][2]
                  .phase;
              if (
                this.exerciseformgroup.controls.secondstep.value.length > 0
              ) {
                let m: number = 0;
                let datearray = [];
                let enddatearray = [];
                let phaselength = this.phase.length;
                let lastenddate = new Date(
                  this.exerciseformgroup.controls.secondstep.value[
                    phaselength - 2
                  ].end_date
                );
                let startdate;
                let enddate;
                for (
                  let j = phaselength - 1;
                  j < totalphase + (phaselength - 1);
                  j++
                ) {
                  this.quantities().push(this.newQuantity());
                  this.exercisesequencelist.push([]);
                  lastenddate.setDate(lastenddate.getDate() + 1);
                  startdate = new Date(lastenddate);
                  enddate = new Date(startdate);
                  enddate.setDate(
                    enddate.getDate() +
                      (parseInt(this.selectedtherapyplan[m][3].days) - 1)
                  );
                  datearray.push({ start_date: startdate });
                  enddatearray.push({ end_date: enddate });
                  lastenddate = new Date(enddate);
                  this.phase[j + 1] = {
                    start_date: startdate,
                    end_date: enddate,
                  };
                  this.minDatesecondstep[j] = startdate;
                  this.minDateforendsecondstep[j] = startdate;
                  for (let k = 0; k < this.selectedtherapyplan.length; k++) {
                    let index: any = j + 1 - (phaselength - 1);
                    if (
                      this.selectedtherapyplan[k][2].phase == parseInt(index)
                    ) {
                      this.exercisesequencelist[j + 1].push({
                        id: this.selectedtherapyplan[k][0]._id,
                        value: this.selectedtherapyplan[k][0].exercise_name1,
                        img: this.selectedtherapyplan[k][1].image,
                        diff: this.selectedtherapyplan[k][0].difficully_level,
                        tags: this.selectedtherapyplan[k][0].tags,
                      });
                      m++;
                    }
                  }
                  this.quantities().at(j).patchValue({ start_date: startdate });
                  this.quantities().at(j).patchValue({ end_date: enddate });
                }
              } else {
                let now = new Date(
                  this.exerciseformgroup.controls.start_date.value
                );
                now.setDate(
                  now.getDate() +
                    (parseInt(this.selectedtherapyplan[0][3].days) - 1)
                );
                this.exerciseformgroup.patchValue({
                  end_date: now,
                });
                this.phase[0] = {
                  start_date:
                    this.exerciseformgroup.controls.start_date.value,
                  end_date: now,
                };
                let m: number = 0;
                for (let i = 0; i < this.selectedtherapyplan.length; i++) {
                  if (this.selectedtherapyplan[i][2].phase == 1) {
                    this.exercisesequencelist[0].push({
                      id: this.selectedtherapyplan[i][0]._id,
                      value: this.selectedtherapyplan[i][0].exercise_name1,
                      img: this.selectedtherapyplan[i][1].image,
                      diff: this.selectedtherapyplan[i][0].difficully_level,
                      tags: this.selectedtherapyplan[i][0].tags,
                    });
                    m++;
                  }
                }

                let lastenddate = new Date(
                  this.exerciseformgroup.controls.end_date.value
                );
                let startdate;
                let enddate;
                for (let j = 0; j < totalphase - 1; j++) {
                  this.quantities().push(this.newQuantity());
                  this.exercisesequencelist.push([]);
                  lastenddate.setDate(lastenddate.getDate() + 1);
                  startdate = new Date(lastenddate);
                  enddate = new Date(startdate);
                  enddate.setDate(
                    enddate.getDate() +
                      (parseInt(this.selectedtherapyplan[m][3].days) - 1)
                  );

                  lastenddate = new Date(enddate);
                  this.phase[j + 1] = {
                    start_date: startdate,
                    end_date: enddate,
                  };
                  this.minDatesecondstep[j] = startdate;
                  this.minDateforendsecondstep[j] = startdate;
                  for (let k = 0; k < this.selectedtherapyplan.length; k++) {
                    if (this.selectedtherapyplan[k][2].phase == j + 2) {
                      this.exercisesequencelist[j + 1].push({
                        id: this.selectedtherapyplan[k][0]._id,
                        value: this.selectedtherapyplan[k][0].exercise_name1,
                        img: this.selectedtherapyplan[k][1].image,
                        diff: this.selectedtherapyplan[k][0].difficully_level,
                        tags: this.selectedtherapyplan[k][0].tags,
                      });
                      m++;
                    }
                  }
                  this.quantities().at(j).patchValue({ start_date: startdate });
                  this.quantities().at(j).patchValue({ end_date: enddate });
                }
              }

              $("#loadertext").html("");
              $("#loadertext").css("width", "100px");
              $("#loaderouterid").css("display", "none");
              $("#loadertext").css("margin-left", "-50px");
            }
          },
          (rejected) => {
            console.log(rejected);
          }
        );
      } else {
        if (this.exerciseformgroup.controls.secondstep.value.length > 0) {
          if (
            this.exerciseformgroup.controls.secondstep.value[
              this.exerciseformgroup.controls.secondstep.value.length - 1
            ].start_date != "" &&
            this.exerciseformgroup.controls.secondstep.value[
              this.exerciseformgroup.controls.secondstep.value.length - 1
            ].end_date != ""
          ) {
            add = 1;
            indexnew =
              this.exerciseformgroup.controls.secondstep.value.length;
            this.phase[
              this.exerciseformgroup.controls.secondstep.value.length
            ] = {
              start_date:
                this.exerciseformgroup.controls.secondstep.value[
                  this.exerciseformgroup.controls.secondstep.value.length -
                    1
                ].start_date,
              end_date:
                this.exerciseformgroup.controls.secondstep.value[
                  this.exerciseformgroup.controls.secondstep.value.length -
                    1
                ].end_date,
            };
          } else {
            add = 0;
          }
        } else {
          if (
            this.exerciseformgroup.controls.start_date.value != "" &&
            this.exerciseformgroup.controls.end_date.value != ""
          ) {
            add = 1;
            indexnew = 0;
            this.phase[0] = {
              start_date: this.exerciseformgroup.controls.start_date.value,
              end_date: this.exerciseformgroup.controls.end_date.value,
            };
          } else {
            add = 0;
          }
        }

        let totalpreviouscountnewprevious11111111 = 0;
        if (key1 > 0) {
          for (let k = 0; k < key1; k++) {
            totalpreviouscountnewprevious11111111 =
              totalpreviouscountnewprevious11111111 +
              this.exercisesequencelist[k].length;
          }
        }

        if (add == 1) {
          if (this.searchby == "yes") {
            if (this.exercisesequencelist[indexnew].length > 0) {
              let currentindex =
                indexnewcurrent + 1 - totalpreviouscountnewprevious11111111;

              console.log(
                "currentindex" +
                  currentindex +
                  "currentindex" +
                  event.currentIndex +
                  "indexnewcurrent" +
                  indexnewcurrent +
                  "totalpreviouscountnewprevious11111111" +
                  totalpreviouscountnewprevious11111111
              );

              if (key1 == undefined) {
                this.exercisesequencelist[indexnew].push({
                  id: event.previousContainer.data[event.previousIndex].id,
                  value:
                    event.previousContainer.data[event.previousIndex].value,
                  img: event.previousContainer.data[event.previousIndex].img,
                  diff: event.previousContainer.data[event.previousIndex].diff,
                  tags: event.previousContainer.data[event.previousIndex].tags,
                });
              } else {
                if (this.exercisesequencelist[key1].length > currentindex) {
                  let currentmovedata = {
                    id: this.exercisesequencelist[key1][currentindex].id,
                    value: this.exercisesequencelist[key1][currentindex].value,
                    img: this.exercisesequencelist[key1][currentindex].img,
                    diff: this.exercisesequencelist[key1][currentindex].diff,
                    tags: this.exercisesequencelist[key1][currentindex].tags,
                  };
                  this.exercisesequencelist[key1][currentindex] = {
                    id: event.previousContainer.data[event.previousIndex].id,
                    value:
                      event.previousContainer.data[event.previousIndex].value,
                    img: event.previousContainer.data[event.previousIndex].img,
                    diff: event.previousContainer.data[event.previousIndex]
                      .diff,
                    tags: event.previousContainer.data[event.previousIndex]
                      .tags,
                  };

                  // this.exercisesequencelist[key1].push([]);

                  for (
                    var i = this.exercisesequencelist[key1].length - 1;
                    i > currentindex;
                    i--
                  ) {
                    this.exercisesequencelist[key1][i + 1] = {
                      id: this.exercisesequencelist[key1][i].id,
                      value: this.exercisesequencelist[key1][i].value,
                      img: this.exercisesequencelist[key1][i].img,
                      diff: this.exercisesequencelist[key1][i].diff,
                      tags: this.exercisesequencelist[key1][i].tags,
                    };
                  }

                  this.exercisesequencelist[key1][currentindex + 1] =
                    currentmovedata;
                } else {
                  this.exercisesequencelist[key1].push({
                    id: event.previousContainer.data[event.previousIndex].id,
                    value:
                      event.previousContainer.data[event.previousIndex].value,
                    img: event.previousContainer.data[event.previousIndex].img,
                    diff: event.previousContainer.data[event.previousIndex]
                      .diff,
                    tags: event.previousContainer.data[event.previousIndex]
                      .tags,
                  });
                }
              }
            } else {
              this.exercisesequencelist[indexnew].push({
                id: event.previousContainer.data[event.previousIndex].id,
                value: event.previousContainer.data[event.previousIndex].value,
                img: event.previousContainer.data[event.previousIndex].img,
                diff: event.previousContainer.data[event.previousIndex].diff,
                tags: event.previousContainer.data[event.previousIndex].tags,
              });
            }
          } else {
            $("#loaderouterid").css("display", "block");
            $("#loadertext").html("Please wait for extract therapy Plan..");
            $("#loadertext").css("width", "320px");
            $("#loadertext").css("margin-left", "-160px");
            let exerciselistdata = [];
            const data = this.userService
              .getexercisesequence(datanew)
              .subscribe(
                (success: any) => {
                  if (success.status == "error") {
                  } else {
                    exerciselistdata = success;
                    for (let i = 0; i < exerciselistdata.length; i++) {
                      if (this.exercisesequencelist[indexnew].length > 0) {
                        let currentindex =
                          indexnewcurrent +
                          1 -
                          totalpreviouscountnewprevious11111111;
                        if (key1 == undefined) {
                          this.exercisesequencelist[indexnew].push({
                            id: event.previousContainer.data[
                              event.previousIndex
                            ].id,
                            value:
                              event.previousContainer.data[event.previousIndex]
                                .value,
                            img: event.previousContainer.data[
                              event.previousIndex
                            ].img,
                            diff: event.previousContainer.data[
                              event.previousIndex
                            ].diff,
                            tags: event.previousContainer.data[
                              event.previousIndex
                            ].tags,
                          });
                        } else {
                          if (
                            this.exercisesequencelist[key1].length >
                            currentindex
                          ) {
                            let currentmovedata = {
                              id: this.exercisesequencelist[key1][currentindex]
                                .id,
                              value:
                                this.exercisesequencelist[key1][currentindex]
                                  .value,
                              img: this.exercisesequencelist[key1][currentindex]
                                .img,
                              diff: this.exercisesequencelist[key1][
                                currentindex
                              ].diff,
                              tags: this.exercisesequencelist[key1][
                                currentindex
                              ].tags,
                            };
                            this.exercisesequencelist[key1][currentindex] = {
                              id: event.previousContainer.data[
                                event.previousIndex
                              ].id,
                              value:
                                event.previousContainer.data[
                                  event.previousIndex
                                ].value,
                              img: event.previousContainer.data[
                                event.previousIndex
                              ].img,
                              diff: event.previousContainer.data[
                                event.previousIndex
                              ].diff,
                              tags: event.previousContainer.data[
                                event.previousIndex
                              ].tags,
                            };

                            // this.exercisesequencelist[key1].push([]);

                            for (
                              var j =
                                this.exercisesequencelist[key1].length - 1;
                              j > currentindex;
                              j--
                            ) {
                              this.exercisesequencelist[key1][j + 1] = {
                                id: this.exercisesequencelist[key1][j].id,
                                value: this.exercisesequencelist[key1][j].value,
                                img: this.exercisesequencelist[key1][j].img,
                                diff: this.exercisesequencelist[key1][j].diff,
                                tags: this.exercisesequencelist[key1][j].tags,
                              };
                            }

                            this.exercisesequencelist[key1][currentindex + 1] =
                              currentmovedata;
                          } else {
                            this.exercisesequencelist[key1].push({
                              id: event.previousContainer.data[
                                event.previousIndex
                              ].id,
                              value:
                                event.previousContainer.data[
                                  event.previousIndex
                                ].value,
                              img: event.previousContainer.data[
                                event.previousIndex
                              ].img,
                              diff: event.previousContainer.data[
                                event.previousIndex
                              ].diff,
                              tags: event.previousContainer.data[
                                event.previousIndex
                              ].tags,
                            });
                          }
                        }
                      } else {
                        this.exercisesequencelist[indexnew].push({
                          id: exerciselistdata[i][event.previousIndex]._id,
                          value:
                            exerciselistdata[i][event.previousIndex]
                              .exercise_name1,
                          img: exerciselistdata[i][1].image,
                          diff: exerciselistdata[i][event.previousIndex]
                            .difficully_level,
                          tags: exerciselistdata[i][event.previousIndex].tags,
                        });
                      }
                    }
                    $("#loadertext").html("");
                    $("#loadertext").css("width", "100px");
                    $("#loaderouterid").css("display", "none");
                    $("#loadertext").css("margin-left", "-50px");
                  }
                },
                (rejected) => {
                  console.log(rejected);
                }
              );
          }
        } else {
          Swal.fire(
            "Bitte geben Sie die Dauer als numerischen Wert ein",
            "",
            "error"
          );
        }
      }
    }
  }

  getexercise(id, name, img, diff, tags, data11, index) {
    var datanew = { id: id };


    let add = 0;
    let indexnew = 0;
    if (this.searchby == "both") {

      $("#loaderouterid").css("display", "block");
      $("#loadertext").html("Please wait for extract therapy Plan..");
      $("#loadertext").css("width", "320px");
      $("#loadertext").css("margin-left", "-160px");
      const data = this.userService.gettherapyplannew(datanew).subscribe(
        (success: any) => {
          if (success.status == "error") {
            $("#loadertext").html("");
            $("#loadertext").css("width", "100px");
            $("#loaderouterid").css("display", "none");
            $("#loadertext").css("margin-left", "-50px");
          } else {
            this.selectedtherapyplan = success;
            let totalphase =
              this.selectedtherapyplan[this.selectedtherapyplan.length - 1][2]
                .phase;
            if (
              this.exerciseformgroup.controls.secondstep.value.length > 0
            ) {
              let m: number = 0;
              let datearray = [];
              let enddatearray = [];
              let phaselength=this.phase.length;
              let lastenddate =new Date(
                this.exerciseformgroup.controls.secondstep.value[phaselength-2].end_date
              );
              let startdate;
              let  enddate;
              for (let j = phaselength-1; j < ((totalphase)+(phaselength-1)); j++) {
                this.quantities().push(this.newQuantity());
                this.exercisesequencelist.push([]);
                lastenddate.setDate(lastenddate.getDate() + 1);               
                startdate = new Date(lastenddate);
               enddate = new Date(startdate);
               enddate.setDate(
               enddate.getDate() +
                    (parseInt(this.selectedtherapyplan[m][3].days)-1)
                );
                datearray.push({ start_date: startdate});
                enddatearray.push({end_date: enddate });
                lastenddate=new Date(enddate);;
  this.phase[j + 1] = {
                  start_date:startdate,
                  end_date:enddate,
                };
                this.minDatesecondstep[j] = startdate;
                this.minDateforendsecondstep[j] = startdate;
                for (let k = 0; k < this.selectedtherapyplan.length; k++) {
                  let index:any=((j+1)-(phaselength-1));
                  if (this.selectedtherapyplan[k][2].phase == parseInt(index)) {
                    this.exercisesequencelist[j +  1].push({
                      id: this.selectedtherapyplan[k][0]._id,
                      value: this.selectedtherapyplan[k][0].exercise_name1,
                      img: this.selectedtherapyplan[k][1].image,
                      diff: this.selectedtherapyplan[k][0].difficully_level,
                      tags: this.selectedtherapyplan[k][0].tags,
                    });
                    m++;
                  }
                }
                this.quantities().at(j).patchValue({start_date:startdate});
                this.quantities().at(j).patchValue({end_date:enddate}); 
              }
  
  
            } else {
              let now = new Date(
                this.exerciseformgroup.controls.start_date.value
              );
              now.setDate(
                now.getDate() + (parseInt(this.selectedtherapyplan[0][3].days)-1)
              );
              this.exerciseformgroup.patchValue({
                end_date: now,
              });
              this.phase[0] = {
                start_date:
                  this.exerciseformgroup.controls.start_date.value,
                end_date: now,
              };
              let m: number = 0;
              for (let i = 0; i < this.selectedtherapyplan.length; i++) {
               
                if (this.selectedtherapyplan[i][2].phase == 1) {
                  this.exercisesequencelist[0].push({
                    id: this.selectedtherapyplan[i][0]._id,
                    value: this.selectedtherapyplan[i][0].exercise_name1,
                    img: this.selectedtherapyplan[i][1].image,
                    diff: this.selectedtherapyplan[i][0].difficully_level,
                    tags: this.selectedtherapyplan[i][0].tags,
                  });
                  m++;
                }
              }
      
              let lastenddate =new Date(
                this.exerciseformgroup.controls.end_date.value
              ); 
              let startdate;
              let  enddate;
              for (let j = 0; j < totalphase-1; j++) {
                this.quantities().push(this.newQuantity());
                this.exercisesequencelist.push([]);
                lastenddate.setDate(lastenddate.getDate() + 1);               
                startdate = new Date(lastenddate);
               enddate = new Date(startdate);
               enddate.setDate(
               enddate.getDate() +
                    (parseInt(this.selectedtherapyplan[m][3].days)-1)
                );
               
                lastenddate=new Date(enddate);;
  this.phase[j + 1] = {
                  start_date:startdate,
                  end_date:enddate,
                };
                this.minDatesecondstep[j] = startdate;
                this.minDateforendsecondstep[j] = startdate;
                for (let k = 0; k < this.selectedtherapyplan.length; k++) {
                  if (this.selectedtherapyplan[k][2].phase == j + 2) {
                    this.exercisesequencelist[j +  1].push({
                      id: this.selectedtherapyplan[k][0]._id,
                      value: this.selectedtherapyplan[k][0].exercise_name1,
                      img: this.selectedtherapyplan[k][1].image,
                      diff: this.selectedtherapyplan[k][0].difficully_level,
                      tags: this.selectedtherapyplan[k][0].tags,
                    });
                    m++;
                  }
                }
                this.quantities().at(j).patchValue({start_date:startdate});
                this.quantities().at(j).patchValue({end_date:enddate});              
              }
  
            }
  
            $("#loadertext").html("");
              $("#loadertext").css("width", "100px");
              $("#loaderouterid").css("display", "none");
              $("#loadertext").css("margin-left", "-50px");
          }
  
  
  
        },
        (rejected) => {
          console.log(rejected);
        }
      );
    } else {



      if (this.exerciseformgroup.controls.secondstep.value.length > 0) {
        if (
          this.exerciseformgroup.controls.secondstep.value[
            this.exerciseformgroup.controls.secondstep.value.length - 1
          ].start_date != "" &&
          this.exerciseformgroup.controls.secondstep.value[
            this.exerciseformgroup.controls.secondstep.value.length - 1
          ].end_date != ""
        ) {
          add = 1;
          indexnew = this.exerciseformgroup.controls.secondstep.value.length;
          this.phase[
            this.exerciseformgroup.controls.secondstep.value.length
          ] = {
            start_date:
              this.exerciseformgroup.controls.secondstep.value[
                this.exerciseformgroup.controls.secondstep.value.length - 1
              ].start_date,
            end_date:
              this.exerciseformgroup.controls.secondstep.value[
                this.exerciseformgroup.controls.secondstep.value.length - 1
              ].end_date,
          };
        } else {
          add = 0;
        }
      } else {
        if (
          this.exerciseformgroup.controls.start_date.value != "" &&
          this.exerciseformgroup.controls.end_date.value != ""
        ) {
          add = 1;
          indexnew = 0;
          this.phase[0] = {
            start_date: this.exerciseformgroup.controls.start_date.value,
            end_date: this.exerciseformgroup.controls.end_date.value,
          };
        } else {
          add = 0;
        }
      }




      if (add == 1) {
        if (this.searchby == "yes") {
          let l = "b";
          console.log("exercisesequencelist" + this.exercisesequencelist.length);
          this.exercisesequencelist[indexnew].push({
            id: id,
            value: name,
            img: img,
            diff: diff,
            tags: tags,
            data: data11,
          });
          console.log("exercisesequencelist" + this.exercisesequencelist.length);
        }
       else {
          this.exercisesequencelist[indexnew].push({
            id: id,
            value: name,
            img: img,
            diff: diff,
            tags: tags,
            data: data11,
          });
  
          let lastindex = this.exercisesequencelist[indexnew].length;
  
          $("#loaderouterid").css("display", "block");
          $("#loadertext").html("Please wait for extract therapy Plan..");
          $("#loadertext").css("width", "320px");
          $("#loadertext").css("margin-left", "-160px");
  
          let exerciselistdata = [];
  
          var datanew = { id: id };
          const data = this.userService.getexercisesequence(datanew).subscribe(
            (success: any) => {
              if (success.status == "error") {
              } else {
                exerciselistdata = success;
                console.log(
                  "ddddddddddddddddddd" + JSON.stringify(exerciselistdata)
                );
  
                for (let i = 0; i < exerciselistdata.length; i++) {
                  console.log(
                    "ddddddddddddddddddd" +
                      JSON.stringify(exerciselistdata[i][0]._id)
                  );
                  console.log(
                    "ddddddddddddddddddd" +
                      JSON.stringify(exerciselistdata[i][1].image)
                  );
                  this.exercisesequencelist[indexnew].push({
                    id: exerciselistdata[i][0]._id,
                    value: exerciselistdata[i][0].exercise_name1,
                    img: exerciselistdata[i][1].image,
                    diff: exerciselistdata[i][0].difficully_level,
                    tags: exerciselistdata[i][0].tags,
                  });
                }
  
                this.exercisesequencelist[indexnew].splice(lastindex - 1, 1);
  
                $("#loadertext").html("");
                $("#loadertext").css("width", "100px");
                $("#loaderouterid").css("display", "none");
                $("#loadertext").css("margin-left", "-50px");
              }
            },
            (rejected) => {
              console.log(rejected);
            }
          );
        }
      } else {
        Swal.fire("Bitte Startdatum und Ablaufdatum auswählen", "", "error");
      }


    }
















    // }
  }

  getdivoutside() {
    let accordianId = "collapse";
    let accordian: HTMLElement = document.getElementById(accordianId);
    let element1new: HTMLElement = document.getElementById(
      "cardbodyid"
    ) as HTMLElement;
    element1new.after(accordian);
    accordian.classList.add("collapse");
    // accordian.classList.add("collapse");
    accordian.classList.remove("collapse-show");
  }
  saveCurrentSelectedUser(data, index, id, member) {
    this.buttonid = id;
    this.indexid = id;
    this.member = member;
    //  window.location.reload();
    console.log("reload"+member);
    console.log("data", data);
    this.selectedUser = data;
  }
  editRecordofsequenceedit(id, accordianId, cl) {
    // $("#loaderouterid").css("display", "block");
    this.exercisesequencelist = [];

    let element: HTMLElement = document.getElementById(
      "clicksequenceedit" + id
    ) as HTMLElement;
    accordianId = "cardbodyidedit";


    let element1: HTMLElement = document.getElementById(
      "ul" + id
    ) as HTMLElement;
    let element1new: HTMLElement = document.getElementById(
      "cardbodyid"
    ) as HTMLElement;
    let elementnew111: HTMLElement = document.getElementById(
      "click" + id
    ) as HTMLElement;

    let accordian: HTMLElement = document.getElementById(accordianId);
    let accordian111: HTMLElement = document.getElementById('cardbodyid');
    // if (elementnew111.innerHTML == "Übungsliste ausblenden") {
    // $("#cardbodyid").css("display", "none");
    // element1new.after(accordian111);
    // elementnew111.innerHTML = "Übungsliste anzeigen";
    // // $("#loaderouterid").css("display", "none");
    // this.getdivoutside();
    // }








    if (element.innerHTML == "Schließen") {
      $("#cardbodyidedit").css("display", "none");
      element1new.after(accordian);
      element.innerHTML = "Behandlung ändern";
      $("#loaderouterid").css("display", "none");
      this.getdivoutside();
    } else {
      if (this.editid != "") {
        let elementnew1: HTMLElement = document.getElementById(
          "clicksequenceedit" + this.editid
        ) as HTMLElement;
        if (elementnew1) {
          elementnew1.innerHTML = "Behandlung ändern";
        }
      }
      element1.after(accordian);
      $("#cardbodyidedit").css("display", "block");
      element.innerHTML = "Schließen";
      this.editid = id;
      this.getcurrentexercisesequenceedit(this.editid);
    }
  }



  addmorefield() {
    this.quantities().push(this.newQuantity());
    this.exercisesequencelist.push([]);
    console.log("phaselength" + this.phase.length);
    if (this.phase.length == "1") {
      let now = new Date(this.exerciseformgroup.controls.end_date.value);
      now.setDate(now.getDate() + 1);
      this.exerciseformgroup.patchValue({
        secondstep: [{ start_date: now }],
      });
      this.minDatesecondstep[0] = now;
      this.minDateforendsecondstep[0] = now;
    } else {
           let now = new Date(
          this.exerciseformgroup.controls.secondstep.value[this.phase.length-2].end_date
        );
        console.log("now"+now);
      now.setDate(now.getDate() + 1);
      this.quantities().at((this.phase.length-2)+1).patchValue({start_date:now});  
      this.minDatesecondstep[this.phase.length-1] = now;
      this.minDateforendsecondstep[this.phase.length-1] = now;
    }
  }

  setsearchlist()
  {
    if(this.searchby=='yes')
    {
    this.exercisesequencelistsearch=[];
    if(this.typesOptionsArrayedit.length>0)
    {
    for(let i=0;i<this.searchlist.length;i++)
        {
  console.log("ffffffffffff"+this.searchlist[i]);
  this.exercisesequencelistsearch.push({
    id: this.searchlist[i].id,
    value: this.searchlist[i].name,
    img: this.searchlist[i].img,
    diff: this.searchlist[i].diff,
    tags: this.searchlist[i].tags,
  });
        }
      }
    }
    else   if(this.searchby=='both')
    {
    this.exercisesequencelistsearch=[];
    if(this.typesOptionsArrayedit.length>0)
    {
    for(let i=0;i<this.searchlist.length;i++)
        {
  console.log("ffffffffffff"+this.searchlist[i]);
  this.exercisesequencelistsearch.push({
    id: this.searchlist[i].id,
    value: this.searchlist[i].name,
    img: this.searchlist[i].img,
    diff: this.searchlist[i].diff,
    tags: '',
  });
        }
      }
    }
    else{
      this.exercisesequencelistsearch=[];
      if(this.typesOptionsArrayedit.length>0)
      {
      for(let i=0;i<this.searchlist.length;i++)
          {
  
    console.log("ffffffffffff"+this.searchlist[i]);
    this.exercisesequencelistsearch.push({
      id: this.searchlist[i].id,
      value: this.searchlist[i].name,
      img: this.searchlist[i].img,
      diff: this.searchlist[i].diff,
      tags: '',
    });
          }
        }
    }
  }


  addOptedit(event: MatChipInputEvent, index: number): void {
    const input = event.input;
    const value = event.value;
    // Add our fruit
    if (this.typesOptionsArrayedit.length > 19) {
      Swal.fire("Begrenzen Sie 20 Tags", "", "error");
    } else {
      console.log("ffffffffffff"+JSON.stringify(this.searchlist) );
      if ((value || "").trim()) {
        this.typesOptionsArrayedit.push(value.trim());
        this.setsearchlist();
       this.exercise_controls.setValue(' ');
       $('#datanew').trigger('click');
      }
      // Reset the input value
      if (input) {
        input.value = "";
      }


    }

    
  }



  getcurrentexercisesequenceedit(id) {


    let element: HTMLElement = document.getElementById(
      "clicksequenceedit" + id
    ) as HTMLElement;
    let accordianId = "cardbodyidedit";

    let element1new: HTMLElement = document.getElementById(
      "cardbodyid"
    ) as HTMLElement;
    let accordian: HTMLElement = document.getElementById(accordianId);

    $("#loaderouterid").css("display", "block");
    var datanew = { id: id };
    const data = this.userService.getstarttreatmentbypatientid(datanew).subscribe(
      (success: any) => {
        this.treatmentdata = success;
        console.log("success" + JSON.stringify(success));
        if (success) { // if success and error give response
          if (success.status == "error") {
            Swal.fire("Therapiepläneliste nicht gefunden", "", "error");
            $("#cardbodyidedit").css("display", "none");
            element1new.after(accordian);
            element.innerHTML = "Behandlung ändern";
            $("#loaderouterid").css("display", "none");
            this.getdivoutside();
          } else {
            let commentlength: any = 500 - success.Behandlung_name.length;
            $("#Behandlung_namecount").html(commentlength);
            this.choosecategoryedit('yes', success);
            this.exerciseformgroup.patchValue({
              Behandlung_name: success.Behandlung_name,
              edit_id: success._id,
            });
            let lastphase = success.exercise_id[success.exercise_id.length - 1].phase - 1;
            let newindex = success.exercise_id.length - 1;

            for (
              let j = 0;
              j < success.exercise_id[newindex].phase;
              j++
            ) {
              if (lastphase > this.phase.length) {
                this.phase.push([]);
              }
              // if(lastphase>this.phase.length)
              // {
              this.exercisesequencelist.push([]);
              // }
              if (j != 0) {
                if (lastphase > this.quantities().length) {
                  this.quantities().push(this.newQuantity());
                }
              }
            }



            for (let j = 0; j < success.exercise_id.length; j++) {

              let index = success.exercise_id[j].phase - 1;
              console.log("index" + index);
              if (index == 0) {
                this.exerciseformgroup.patchValue({
                  start_date: success.exercise_id[j].dates.start_date,
                });


                let d = new Date(success.exercise_id[j].dates.end_date);
                d.setHours(0, 0, 0, 0)

                this.exerciseformgroup.patchValue({
                  end_date: d,
                });
                // this.exerciseformgroup.patchValue({
                //   end_date: success.exercise_id[j].dates.end_date,
                // });
                this.minDate = new Date(success.exercise_id[j].dates.start_date);
                this.minDateforend = new Date(success.exercise_id[j].dates.start_date);

              } else {

                console.log("dddddddddd" + success.exercise_id[j].dates.start_date);
                this.quantities().at(index - 1).patchValue({ start_date: new Date(success.exercise_id[j].dates.start_date) });
                this.quantities().at(index - 1).patchValue({ end_date: new Date(success.exercise_id[j].dates.end_date) });
                this.minDatesecondstep[index - 1] = success.exercise_id[j].dates.start_date;
                this.minDateforendsecondstep[index - 1] = success.exercise_id[j].dates.start_date;
              }

            }

            let numWeeks = 12;
            let now = new Date(success.exercise_id[0].dates.start_date);
            now.setDate(now.getDate() + numWeeks * 7);
            this.maxDate11 = now;
            // this.exerciselist = success;

          }

        }
        else {
          Swal.fire("Keine Laufbehandlung", "", "error");
          $("#cardbodyidedit").css("display", "none");
          element1new.after(accordian);
          element.innerHTML = "Behandlung ändern";
          $("#loaderouterid").css("display", "none");
          this.getdivoutside();
        }
      },
      (rejected) => {
        console.log(rejected);
      }
    );
  }
  exerciselength(index) {
    let acc = 0;
    for (let i = 0; i < index; i++) {
      acc += this.exercisesequencelist[i].length;
    }
    return acc;
  }
  quantities(): FormArray {
    return this.exerciseformgroup.get("secondstep") as FormArray;
  }
  newQuantity(): FormGroup {
    return this._formBuilder.group({
      start_date: ["", Validators.required],
      end_date: ["", Validators.required],
    });
  }

  choosecategoryedit(searchby, value?) {

    this.exercisesequencelistsearch = [];
    this.typesOptionsArrayedit = [];
    this.searchby = searchby;
    this.getallexerciselist(value);
    setTimeout(() => {
      this.exercise_controls.setValue(' ');
    }, 500);

  }

  removeOptedit(opt: string, index: number): void {
    console.log("removeOpt");
    const optIndex = this.typesOptionsArrayedit.indexOf(opt);
    if (optIndex >= 0) {
      this.typesOptionsArrayedit.splice(optIndex, 1);
    }
    this.exercise_controls.setValue(' ');
  }

  addMonthsUTC (date, count) {
    if (date && count) {
      var m, d = (date = new Date(+date)).getUTCDate()
  
      date.setUTCMonth(date.getUTCMonth() + count, 1)
      m = date.getUTCMonth()
      date.setUTCDate(d)
      if (date.getUTCMonth() !== m) date.setUTCDate(0)
    }
    return date
  }
  getstatus(start_date,duration)
  {
    let enddate=new Date(start_date);
    enddate = this.addMonthsUTC(enddate, parseInt(duration));
    let currentdate=new Date();
    if(enddate.getTime()<currentdate.getTime())
    {
      return 1;
    }
    else{
      return 0;
    }
    
  }
  upgradeplan(id, plantype) {
    console.log("plantype"+plantype)
    if (plantype == "Therapist") {
      this.router.navigate(["/upgrade-plan"], { queryParams: { id: id,b2bid:this.loginid } });
    } else {
      this.router.navigate(["/upgrade-plan-doctor"], {
        queryParams: { id: id,b2bid:this.loginid },
      });
    }
  }
  openinformation(id,index,tags)
  {
    $("#loaderouterid").css("display", "block");
    this.getcurrentUser11(id);
  }
  choosecategory11(value) {
    console.log("dddddddddddd" + value);
    this.personalInfoFormGroupmodal.patchValue({
      exercise_category: value,
    });

    $("#exercise_category").val(value);
    if (value == "Therapie") {
      $("#Therapie").css("background-color", "gray");
      $("#Prävention").css("background-color", "white");
    } else {
      $("#Prävention").css("background-color", "gray");
      $("#Therapie").css("background-color", "white");
    }



  }
  getcurrentUser11(openid) {
    const that = this;
 
      // console.log("call api")
      this.userService.getEditexercise(openid).subscribe(function (data1) {
        setData(data1);
      });
    

    function setData(data1) {
      console.log("dddddddddddd" + JSON.stringify(data1));
      console.log("dddddddddddd" + data1.element.exercise_category);
      that.choosecategory11(data1.element.exercise_category);

      // that.calcount("commentcount", "comment");
      let commentlength: any = 500 - data1.element.comment.length;
      $("#commentcount").html(commentlength);
      let commentlength1: any = 500 - data1.element.comment1.length;
      $("#comment1count").html(commentlength1);
      let exercise_name: any = 35 - data1.element.exercise_name.length;
      $("#exercise_namecount").html(exercise_name);
      let exercise_name1: any = 35 - data1.element.exercise_name1.length;
      $("#exercise_name1count").html(exercise_name1);
      let tags: any = 20 - data1.element.tags.length;
      $("#manual_tagscount").html(tags);

      that.personalInfoFormGroupmodal.patchValue({
        exercise_name: data1.element.exercise_name,
        exercise_name1: data1.element.exercise_name1,
        difficully_level: data1.element.difficully_level,
        tools: data1.element.tools,
        comment: data1.element.comment,
        comment1: data1.element.comment1,
      });
      that.oldtypesOptionsArray = data1.element.tags;
      console.log("dddddddddddd" + that.oldtypesOptionsArray);
      $("#logopreview0").html(
        "<img src='" + data1.image1 + "' style='width: 100%;height: 300px'>"
      );
      $("#logopreview1").html(
        "<video  style='width: 100%;height: 300px' controls><source  src='" +
          data1.image2 +
          "' type='video/mp4'></video>"
      );
      $("#logopreview2").html(
        "<video  style='width: 100%;height: 300px' controls><source  src='" +
          data1.image3 +
          "' type='video/mp4'></video>"
      );
      $("#loaderouterid").css("display", "none");
    }
  }
  getallexerciselist(therapydata) {
    let exercise_category = 'Therapie';
    if (this.searchby == 'yes') {
      console.log("sddd" + exercise_category);
      this.userService
        .getsearchexerciseinsequence(exercise_category)
        .pipe(first())
        .subscribe((data: any) => {
          this.exercisedata = this.loopingexerciselist(data, therapydata);
          // setTimeout(() => {
          //   $('.openinformationclass').html('<i class="fa fa-eye"></i>');
          // }, 1000);
          console.log("exercisedata" + this.exercisedata);
        });
    }
    else if (this.searchby == 'both') {
      console.log("sddd" + exercise_category);
      this.userService
        .getsearchtherapyinsequence('')
        .pipe(first())
        .subscribe((data: any) => {
          this.exercisedata = this.loopingexerciselist(data, therapydata);

          console.log("exercisedata" + this.exercisedata);
        });
    }
    else {

      this.userService
        .getSearchexercisesequence(exercise_category)
        .pipe(first())
        .subscribe((data: any) => {
          this.exercisedata = this.loopingexerciselist(data, therapydata);
          // setTimeout(() => {
          //   $('.openinformationclass').html('<i class="fa fa-eye"></i>');
          // }, 1000);
          console.log("exercisedata" + this.exercisedata);
        });
    }

  }


  prevention_course(id) {
    $("#loaderouterid").css("display", "block");
    // console.log("test"+id)

    var datanew = { id: "" };
    const data = this.userService.getpatientproduct(datanew).subscribe(
      (success: any) => {

        // if success and error give response

        if (success.status == "error") {
          Swal.fire("Patientenprodukt nicht gefunden", "", "error");
          $("#loaderouterid").css("display", "none");
        } else {
          $('#patient_id').val(id);
          $('#openmodel').trigger('click');
          console.log("success.status" + success);
          this.preventioncourselist = success;
          $("#loaderouterid").css("display", "none");
        }
      },
      (rejected) => {
        console.log(rejected);
      }
    );
  }
  sendpreventioncourse(id,price,discount,name,exercisecount,fundedby)
  {
    console.log("datanew"+exercisecount)
    console.log("datanew"+fundedby)
    $("#loaderouterid").css("display", "block");
    $('#close').trigger('click');
    var datanew = { patientproductid: id ,
      patientid:$('#patient_id').val(),
      price:price,
      discount:discount,
      name:name,
      exercisecount:exercisecount,
      fundedby:fundedby,
    };
    console.log("datanew"+JSON.stringify(datanew))
    const data = this.userService.sendreferallink(datanew).subscribe(
      (success: any) => {

        Swal.fire({
          
          title: '',
          html: "<span style='font-size: 26px;'>Präventionskurs erfolgreich empfohlen" +
              "</span><br><br><br>" +
              '',
          showCancelButton: false,
          showConfirmButton: true,
          allowOutsideClick: false,
          showCloseButton: true,
         icon:"success",
      });

        // Swal.fire("Präventionskurs erfolgreich empfohlen", "", "success");
        $("#loaderouterid").css("display", "none");
      },
   
      (rejected) => {
        console.log(rejected);
      }
      );
  }

  patient_dashboard() {
    this.router.navigate(["/patient-dashboard"], {
      queryParams: { id: this.selectedUser._id }
    });
  }
  navigateWithstarttreatement() {
    this.router.navigate(["/start-treatment"], {
      queryParams: { id: this.selectedUser._id }
    });
    // this.queryID = this.selectedUser.customerno;
    // this.ngOnInit()
  }

getactiveplan()
{
  var datanew = { id: "" };
  this.userService
    .getpurchasemembershipplanwithstatus(datanew)
    .subscribe(
      (success: any) => {
  
        // if success and error give response
        if (success.status == "error") {
          Swal.fire("Übungsliste nicht gefunden", "", "error");
        } else {
          if (success.length > 0) {
            let noofpatients = success[0].noofpatients;
            let usedpatient = success[0].usedpatient;
            let rem = noofpatients - usedpatient;
            this.remnew = rem;
            this.noofpatients = noofpatients;
            this.usedpatient = usedpatient;
            this.membershipid = success[0]._id;
            this.start_date = success[0].start_date;
            this.duration = success[0].duration;
            this.plantype = success[0].plantype;
          } else {
            this.remnew = "0";
            this.usedpatient = "0";
            this.noofpatients = "0";
            this.membershipid = "0";
            this.start_date = '';
            this.duration = '';
            this.plantype = '';
          }
        }
      },
      (rejected) => {
        console.log(rejected);
      }
    );
}


  changestatus(buttonid, indexid, selectedUser) {
    $("#loaderouterid").css("display", "block");



    Swal.fire({
      title: "Sind Sie sicher, dass Sie diese Behandlung beenden möchten?",
      showCancelButton: true,
      confirmButtonText: "Speichern",
      cancelButtonText: "Verwerfen"
    }).then(result => {
      if (result.value) {
        var datanew = { id: buttonid, membershipid: this.membershipid };
        const data = this.userService.updatestarttreatmentstatus(datanew).subscribe(
          (success: any) => {


            Swal.fire("Behandlung erfolgreich beenden", "", "success");

            setTimeout(() => {
              Swal.close();
              this.getallcustomer(this.editid);
               
                 this.getactiveplan()
              this.exercisesequencelist = [];
              $("#loaderouterid").css("display", "none");
            }, 1000);




          });
      }
      else {
        $("#loaderouterid").css("display", "none");
      }
    });



  }
  getallcustomer(id) {
    console.log("idd" + id);

    if (id != '') {

      let element: HTMLElement = document.getElementById(
        "clicksequenceedit" + id
      ) as HTMLElement;
      console.log("idd" + element);
      let accordianId = "cardbodyidedit";
      let accordian: HTMLElement = document.getElementById(accordianId);
      let element1new: HTMLElement = document.getElementById(
        "cardbodyid"
      ) as HTMLElement;
      if (element.innerHTML == "Schließen") {
        $("#cardbodyidedit").css("display", "none");
        element1new.after(accordian);
        element.innerHTML = "Öffnen";
        this.getdivoutside();
      }
    }

    var companyName: any = "";
    if (this.routeParams == "benutzer-cnt") companyName = "checkntrack";
    // if (this.routeParams == "finance") companyName = "coursula";
    if (this.routeParams == "finance") companyName = "ontrain";
    if (this.routeParams == "real-estate") companyName = "fiorettoimmob";
    if (this.routeParams == "birema") companyName = "birema";
    if (this.routeParams == "fioretto-media") companyName = "fiorettomedia";
    if (this.routeParams == "airmage") companyName = "airmage";
    if (this.routeParams == "horaidetektei") companyName = "horaidetektei";
    if (this.routeParams == "vario-import") companyName = "varioimport";
    if (this.routeParams == "sterbevorsorgebymek") companyName = "sterbvorsoge";

    if (this.loginRole == "b2b") {
      // const data = this.userService.getCustomerscoursula("coursula", true).subscribe(
      const data = this.userService.getCustomerscoursula("ontrain", true).subscribe(
        (success: any) => {
          // if success and error give response
          console.log("cefimaaaaaaaaaaaaa");
          if (success.status == "error") {
            this.error = success.message;
          } else {
            //var success = this.filtercustomer(success, "coursula");
            var success = this.filtercustomer(success, "ontrain");
            this.brokerList = this.getBrokersData(success);
            this.customerList = success;
            console.log(this.customerList);
            console.log("this.customerList" + success);
            console.log("this.customerno" + this.customerno);
            this.setPage(1);
            this.recordCount = success.length;
          }
        },
        rejected => {
          console.log(rejected);
        }
      );
    } else {
      const data = this.userService.getCustomerscoursula(companyName).subscribe(
        (success: any) => {
          // if success and error give response
          if (success.status == "error") {
            this.error = success.message;
          } else {
            this.brokerList = this.getBrokersData(success);
            this.customerList = success;
            this.setPage(1);
            this.recordCount = success.length;
            console.log(this.customerList);
          }
        },
        rejected => {
          console.log(rejected);
        }
      );
    }
  }

  loopingexerciselist(userData: string | any[], therapydata) {
    let exerciselist = [];

    // this.getcurrentexercisesequence(this.editid);

    // var datanew = { id: this.editid };
    // const data = this.userService.getstarttreatmentbypatientid(datanew).subscribe(
    //   (success: any) => {

    //     // if success and error give response
    //     if (success.status == "error") {



    //     } else {



    console.log("kundenewdata" + JSON.stringify(therapydata));
    if (therapydata) {
      for (let i = 0; i < therapydata.exercise_id.length; i++) {

        let index = therapydata.exercise_id[i].phase - 1;
        this.phase[index] = { start_date: therapydata.exercise_id[i].dates.start_date, end_date: therapydata.exercise_id[i].dates.end_date };
        this.exercisesequencelist[index].push({
          id: therapydata.exercise_id[i].exercise._id,
          value: therapydata.exercise_id[i].exercise.exercise_name1,
          img: therapydata.exercise_id[i].exercise.document[0],
          diff: therapydata.exercise_id[i].exercise.difficully_level,
          tags: therapydata.exercise_id[i].exercise.tags,
        });


      }
      $("#loaderouterid").css("display", "none");
    }
    //     }
    //   },
    //   (rejected) => {
    //     console.log(rejected);
    //   }
    // );







    if (this.searchby == 'yes') {
      console.log("kundenew" + JSON.stringify(userData));
      for (let i = 0; i < userData.length; i++) {
        // console.log(userData[i].roles.includes('b2b') , userData[i].roles)
        console.log(
          "kundenew" + JSON.stringify(userData[i].element.exercise_name1)
        );
        exerciselist.push({
          name: userData[i].element.exercise_name1,
          id: userData[i].element._id,
          tags: userData[i].element.tags,
          img: userData[i].element.document[0],
          diff: userData[i].element.difficully_level,
        });
      }
    }
    else if (this.searchby == 'both') {
      console.log("kundenewboth" + JSON.stringify(userData));
      for (let i = 0; i < userData.length; i++) {
        // console.log(userData[i].roles.includes('b2b') , userData[i].roles)

        exerciselist.push({
          name: userData[i].therapyplan_name,
          id: userData[i]._id,
          tags: '',
          img: '',
          diff: userData[i].exercise_id.length,
        });
      }
    }
    else {
      console.log("kundenew" + JSON.stringify(userData));
      for (let i = 0; i < userData.length; i++) {
        // console.log(userData[i].roles.includes('b2b') , userData[i].roles)

        exerciselist.push({
          name: userData[i].exercisesequence_name,
          id: userData[i]._id,
          tags: '',
          img: '',
          diff: userData[i].exercise_id.length,
        });
      }
    }
    console.log("broooker!!!!!!!!!!!!!!!!!!!!!" + JSON.stringify(exerciselist));



    return exerciselist;


  }


  checkDataAndCreateUpdateData(callSave?, element?, accordian?, close = "") {
    this.disableEditSubmit = true;
    console.log("disableEditSubmit" + this.disableEditSubmit);
    // console.log("inside check data" , this.customerFormGroup.value);
    let roles = [];
    let rolescode = [];
    let companies = new Set([]);
    let companiescode = new Set([]);
    let rolesCompaniesArray = [];
    let rolesCompaniescodeArray = [];
    let brokerbrandarraynew = [];
    let brokerarraynew = [];
    let statusbrandarraynew = [];
    let statusarraynew = [];
    let employeebrandarraynew = [];
    let employeearraynew = [];
    console.log("roleCompanies" + JSON.stringify(this.roleCompanies));
    for (let i in this.roleCompanies) {
      let role = i;
      let temp = this.roleCompanies[i];
      if (temp.length > 0) {
        if (role == "user") {
          roles.push("Benutzer");
        } else {
          
          roles.push(role);
        }
      }
      for (let j = 0; j < temp.length; j++) {
        companies.add(temp[j]);
        rolesCompaniesArray.push(temp[j] + `_${role}`);
      }
    }

    for (let i in this.roleCompaniescode) {
      let rolecode = i;
      let tempcode = this.roleCompaniescode[i];
      if (tempcode.length > 0) {
        if (rolecode == "user") {
          rolescode.push("Benutzer");
        } else {
          rolescode.push(rolecode);
        }
      }
      for (let j = 0; j < tempcode.length; j++) {
        companiescode.add(tempcode[j]);
        rolesCompaniescodeArray.push(tempcode[j] + `_${rolecode}`);
      }
    }
    console.log("roleCompanies" + JSON.stringify(rolesCompaniescodeArray));

    console.log("600" + this.customerFormGroup.value.phone_number);
    console.log("checkdata" + this.customerFormGroup.value.customerno);
    console.log("nationality" + this.customerFormGroup.value.nationality);
    console.log(
      "countryOfResidence" + this.customerFormGroup.value.countryOfResidence
    );
    let that = this;
    var serverrole = this.userData.roles;
    console.log("serverrolenew : " + this.userData.roles);
    console.log("serverrole: " + serverrole);
    console.log("serverrole: " + serverrole);
    if (serverrole.includes("Superadmin")) {
      roles.push("Superadmin");
    }


    brokerbrandarraynew=this.brokerbrandarray;
    brokerarraynew=this.brokerarray;

    statusbrandarraynew=this.statusbrandarray;
    statusarraynew=this.statusarray;

    employeebrandarraynew=this.employeebrandarray;
    employeearraynew=this.employeearray;

    console.log("companies" + Array.from(companies));
    let data = {
      roles: roles,
      companies: Array.from(companies),
      companies_with_roles: rolesCompaniesArray,
      companycode: rolesCompaniescodeArray,
      // Personal Information
      title: this.customerFormGroup.value.title,
      customer_status: this.customerFormGroup.value.status,
      employeetype: this.customerFormGroup.value.employeetype,
      companyname: this.customerFormGroup.value.companyname,
      firstname: this.customerFormGroup.value.firstName,
      lastname: this.customerFormGroup.value.lastName,
      email: this.customerFormGroup.value.email,
      dateofbirth: this.customerFormGroup.value.dob,
      nationality: this.customerFormGroup.value.nationality,
      birth_place: this.customerFormGroup.value.birthPlace,

      // Address Information
      strassa: this.customerFormGroup.value.street,
      strno: this.customerFormGroup.value.streetNumber,
      additionalReference: this.customerFormGroup.value.additionalReference,
      plz: this.customerFormGroup.value.postCode,
      city: this.customerFormGroup.value.city,
      current_country: this.customerFormGroup.value.countryOfResidence,

      //More Information
      _id: this.customerFormGroup.value.id,

      contactno: this.customerFormGroup.value.phone_number,
      customerno: this.customerFormGroup.value.customerno,
      broker: this.customerFormGroup.value.broker,
      brokerbrandarray: brokerbrandarraynew,
      brokerarray:brokerarraynew,

      statusbrandarray: statusbrandarraynew,
      statusarray:statusarraynew,

      employeebrandarray: employeebrandarraynew,
      employeearray:employeearraynew

      // status: '1',
    };

    for (let i in data) {
      console.log(this.userData.hasOwnProperty(i));
      console.log(i);
      console.log(this.userData);
      //sessionStorage.setItem('currentUser', JSON.stringify(this.userData));
      console.log("data" + JSON.stringify(data));
      console.log("disableEditSubmit" + this.disableEditSubmit);
      if (this.userData.hasOwnProperty(i)) {
        if (Array.isArray(data[i])) {
          // console.log(data[i], this.userData[i])
if(i=='brokerarray' || i=='brokerbrandarray'  || i=='statusbrandarray'  || i=='statusarray'   || i=='employeebrandarray'  || i=='employeearray' )
{
  if (
    JSON.stringify(data[i]) !=
    JSON.stringify(this.userData[i])
  ) {
    console.log(data[i], this.userData[i]);
    this.disableEditSubmit = false;
    console.log("if1");
  }
}
else{
          if (
            JSON.stringify(data[i].sort()) !=
            JSON.stringify(this.userData[i].sort())
          ) {
            console.log(data[i], this.userData[i]);
            this.disableEditSubmit = false;
            console.log("if1");
          }

        }
        } else {
          if (i == "contactno" && !this.userData[i].includes("+")) {
            if (data[i] != this.userData[i]) {
              console.log("if2");

              this.disableEditSubmit = false;
            }
          } else {
            if (data[i] != this.userData[i]) {
              console.log("if3");

              this.disableEditSubmit = false;
            }
          }
        }
      } else {
        if (data[i] != this.userData[i]) {
          console.log("if3");

          this.disableEditSubmit = false;
        } else {
          // this.disableEditSubmit = true
        }
        // console.log("else")
        //  this.disableEditSubmit = false
      }
    }
    console.log(this.disableEditSubmit, "disableEditSubmit");
    if (callSave) {
      console.log("hello");
      this.save(data, element, this.customerFormGroup.value.id, close);
    } }

    save(data, element?, accordian?, close?) {
      console.log("datasave", data);
      var current_id = this.userService.getDecodedAccessToken(
        sessionStorage.getItem("Token")
      ).id;
      console.log("current_id" + current_id);
      console.log("data_id" + data._id);
      var updateuserloginid = data._id;
      if (data._id == current_id) {
        sessionStorage.setItem("currentUser", JSON.stringify(data));
      }
  
  
  if(data.roles.length>0)
  {
  
  
  
  
  
      if (close == "close") {
        Swal.fire({
          title: "Sind Sie sicher, dass Sie ein Update wünschen?",
          showCancelButton: true,
          confirmButtonText: "Speichern",
          cancelButtonText: "Verwerfen"
        }).then(result => {
          console.log("result", result);
          if (result.value) {
         
  
            $("#loaderouterid").css("display", "block");
            console.log(result, "inside save");
            this.userService
              .updateUser(data)
              .pipe(first())
              .subscribe(
                data => {
                  //var current_id=this.userService.getDecodedAccessToken(sessionStorage.getItem('token')).id;
                  console.log("current_id" + current_id);
                  console.log("data_id" + updateuserloginid);
                  if (updateuserloginid == current_id) {
                    console.log("updateuserloginid");
                    //sessionStorage.setItem('currentUser', JSON.stringify(data));
                    this.token = data["token"];
                    sessionStorage.setItem("token", this.token);
                    sessionStorage.setItem(
                      "currentUser",
                      JSON.stringify(data["user"])
                    );
                  }
  
                  console.log("POST Request is successful ", data);
                  //sessionStorage.setItem('currentUser', JSON.stringify(data['user']));
                  this.registerDone = true;
                  // window.location.reload();
                },
                error => {
                  console.log("POST Request is   error ", error);
                  this.registraionError = true;
                  // console.log("Error", error['error']);
                }
              );
  
            setTimeout(() => {
              this.registerDone = false;
              $("#loaderouterid").css("display", "none");
            }, 1000);
            // this.nextCompanies()
            // let accordian: HTMLElement = document.getElementById("collapse");
            // let element1: HTMLElement = document.getElementById('click' + data._id) as HTMLElement;
            // let element1new: HTMLElement = document.getElementById('cardbodyid') as HTMLElement;
            // accordian.classList.add("collapse");
            // accordian.classList.add("collapse");
            // accordian.classList.remove("collapse-show");
            // element1.innerHTML = "Öffnen";
            // element1new.after(accordian);
          } else {
            this.getcurrentUser(null, this.currentUserData);
  
            // if (element) {
            //   accordian.classList.add("collapse");
            //   accordian.classList.remove("collapse-show");
            //   element.innerHTML = "Öffnen";
  
            // }
          }
  
          console.log("paaaaaaaaaaaa" + this.pager);
        
            // console.log("without b2b");
            var searchid = $("#searchid").val();
            console.log("serachid" + searchid);
            if (searchid == "") {
              // const data = this.userService.getCustomerscoursula("coursula", true).subscribe(
                const data = this.userService.getCustomerscoursula("ontrain", true).subscribe(
                (success: any) => {
                  // if success and error give response
                  console.log("cefimaaaaaaaaaaaaa");
                  if (success.status == "error") {
                    this.error = success.message;
                  } else {
                    // var success = this.filtercustomer(success, "coursula");
                    var success = this.filtercustomer(success, "ontrain");
                    this.brokerList = this.getBrokersData(success);
                    this.customerList = success;
                    console.log(this.customerList);
                    console.log("this.customerList" + success);
                    console.log("this.customerno" + this.customerno);
                    this.setPage(this.pager.currentPage);
                    this.recordCount = success.length;
                  }
                },
                rejected => {
                  console.log(rejected);
                }
              );
            } else {
  
              let { customerno } = JSON.parse(sessionStorage.getItem("currentUser"));
              let CustomerNumbernew = customerno;
  
              const data = this.userService
              .getSearch(this.values, CustomerNumbernew)
              .subscribe(
                (success: any) => {
                  // if success and error give response
                  if (success.status == "error") {
                    this.error = success.message;
                  } else {
                    this.brokerList = this.getBrokersData(success);
                    this.customerList = success;
                    this.setPage(this.pager.currentPage);
                    this.recordCount = success.length;
                    console.log(this.customerList);
                  }
                },
                rejected => {
                  console.log(rejected);
                }
              );
    
  
  
  
            }
            // window.location.reload();
          
  
          // $("#loaderouterid").css("display", "none");
        });
      } else {
        // Swal.fire({
        //   title: 'Sind Sie sicher, dass Sie ein Update wünschen?',
        //   showCancelButton: true,
        //   confirmButtonText: 'Speichern',
        //   cancelButtonText: 'Verwerfen'
        // }).then((result) => {
        //   console.log("result",result)
        //   if (result.value) {
  
        // let companies
  
        // let companies
        // console.log("data.companies", data.companies);
        // var customerno = data.customerno;
        // var splitdata = customerno.split("-");
        // var nums = [splitdata[0]];
        // var tmp = data.companies;
        // var nums1 = [];
        // var c_100 = [];
        // var c_110 = [];
        // var c_120 = [];
        // var c_130 = [];
        // var c_140 = [];
        // var c_150 = [];
        // if (tmp) {
        //   tmp.forEach(function(i, k) {
        //     var cusNo = i.substring(0, 2);
        //     if (cusNo == "ce" || cusNo == "st") {
        //       c_140.push(cusNo);
        //     }
        //     if (
        //       cusNo == "fi" ||
        //       cusNo == "fi" ||
        //       cusNo == "ch" ||
        //       cusNo == "ai"
        //     ) {
        //       c_130.push(cusNo);
        //     }
        //     if (cusNo == "va" || cusNo == "bi") {
        //       c_120.push(cusNo);
        //     }
        //   });
        //   if (c_140.length > 0) {
        //     c_140.unshift("40");
        //   }
        //   if (c_130.length > 0) {
        //     c_130.unshift("30");
        //   }
        //   if (c_120.length > 0) {
        //     c_120.unshift("20");
        //   }
  
        //   nums1 = nums1.concat(c_140);
        //   nums1 = nums1.concat(c_130);
        //   nums1 = nums1.concat(c_120);
        //   nums1.unshift(splitdata[0]);
  
        //   // nums.push(cusNo);
  
        //   var cusNo = nums1.join("-");
        //   var customerNo = cusNo;
        //   console.log("CUSTOMERNO" + customerNo);
        //   // data.customerno = customerNo;
        // }
  
        $("#loaderouterid").css("display", "block");
  
        this.userService
          .updateUser(data)
          .pipe(first())
          .subscribe(
            data => {
              if (updateuserloginid == current_id) {
                console.log("updateuserloginid");
                //sessionStorage.setItem('currentUser', JSON.stringify(data));
                this.token = data["token"];
                sessionStorage.setItem("token", this.token);
                sessionStorage.setItem("currentUser", JSON.stringify(data["user"]));
              }
              console.log("POST Request is successful ", data);
              this.registerDone = true;
              // window.location.reload();
            },
            error => {
              console.log("POST Request is   error ", error);
              this.registraionError = true;
              // console.log("Error", error['error']);
            }
          );
  
        setTimeout(() => {
          this.registerDone = false;
          $("#loaderouterid").css("display", "none");
        }, 1000);
     
  
        console.log("paaaaaaaaaaaa" + this.pager);
  
          // console.log("without b2b");
          var searchid = $("#searchid").val();
          console.log("serachid" + searchid);
          if (searchid == "") {
            // const data = this.userService.getCustomerscoursula("coursula", true).subscribe(
              const data = this.userService.getCustomerscoursula("ontrain", true).subscribe(
              (success: any) => {
                // if success and error give response
                console.log("cefimaaaaaaaaaaaaa");
                if (success.status == "error") {
                  this.error = success.message;
                } else {
                  // var success = this.filtercustomer(success, "coursula");
                  var success = this.filtercustomer(success, "ontrain");
                  this.brokerList = this.getBrokersData(success);
                  this.customerList = success;
                  console.log(this.customerList);
                  console.log("this.customerList" + success);
                  console.log("this.customerno" + this.customerno);
                  this.setPage(this.pager.currentPage);
                  this.recordCount = success.length;
                }
              },
              rejected => {
                console.log(rejected);
              }
            );
          } else {

            let { customerno } = JSON.parse(sessionStorage.getItem("currentUser"));
            let CustomerNumbernew = customerno;

            const data = this.userService
            .getSearch(this.values, CustomerNumbernew)
            .subscribe(
              (success: any) => {
                // if success and error give response
                if (success.status == "error") {
                  this.error = success.message;
                } else {
                  this.brokerList = this.getBrokersData(success);
                  this.customerList = success;
                  this.setPage(this.pager.currentPage);
                  this.recordCount = success.length;
                  console.log(this.customerList);
                }
              },
              rejected => {
                console.log(rejected);
              }
            );
  



          }
          // window.location.reload();
        
  
        // $("#loaderouterid").css("display", "none");
  
        // });
      }
  
  
  
    }
    else{
      Swal.fire("Bitte wählen Sie mindestens eine Rolle aus","","success")
      
    }
  
  // this.brokerarray=[];
  // this.brokerbrandarray=[];
  
    }
    brokerDisplayFn(user: Broker): string {
      console.log("sadasdasdasd");
      console.log("befor" + this.disableEditSubmit);
      if (user.name != "") {
        this.disableEditSubmit = false;
        console.log("after" + this.disableEditSubmit);
        return user && user.name ? user.name : "";
      } else {
        this.disableEditSubmit = true;
        console.log("aftertest" + this.disableEditSubmit);
        return user && user.name ? user.name : "";
      }
    }
  
    patchBrokerValue(event) {
      console.log(event);
      console.log("patchBrokerValue" + this.brokerFormControl.value.value);
      // this.customerFormGroup.patchValue({
      //   broker: this.brokerFormControl.value.value
      // });
   if(this.brokerFormControl.value.value)
   {
     let brokerbrand=this.customerFormGroup.value.brokerbrand
     if(this.brokerbrandarray.length>0)
     {
      console.log("patchBrokerValueif"+brokerbrand);
     let a = this.brokerbrandarray.indexOf(brokerbrand);
     console.log("patchBrokerValueif"+a);
     if(a!=-1)
     {
      console.log("patchBrokerValueif"+ this.brokerarray[a]);
      this.brokerarray[a]=this.brokerFormControl.value.value; 
      console.log("patchBrokerValueif"+ this.brokerarray[a]);
     }
     else
     {
      console.log("patchBrokerValueifelseif"+a);
      this.brokerbrandarray.push(brokerbrand)
      this.brokerarray.push(this.brokerFormControl.value.value)
     }
    }
     else
     {
      console.log("patchBrokerValueelse");
       this.brokerbrandarray.push(brokerbrand)
       this.brokerarray.push(this.brokerFormControl.value.value)
     }
    }
      // if (this.brokerFormControl.value.value) {
      // console.log("IF"+this.brokerFormControl.value.value)
      //   this.customerFormGroup.patchValue({
      //     broker: this.brokerFormControl.value.value
      //   })
      // }else{
      //   this.brokerFormControl.setValue("")
      // }
      console.log("patchBrokerValue" + this.brokerarray);
      console.log("patchBrokerValue" + this.brokerbrandarray);
       this.checkDataAndCreateUpdateData();
    }
  

    changebrandbroker(event) {
      console.log(event);
      // this.brokerFormControl.value.value
      let brokerListnew = [];
  
      this.brokerList =  this.getBrokersDatanew(this.customerList,this.customerFormGroup.value.brokerbrand);
      let a = this.brokerbrandarray.indexOf(this.customerFormGroup.value.brokerbrand);
  if(a!=-1)
  {
    console.log("patchBrokerValue" + this.brokerarray[a]);
    if (this.brokerarray[a]) {
      console.log("patchBrokerValue" + JSON.stringify(this.brokerList));
      for (let i = 0; i < this.brokerList.length; i++) {
        console.log("patchBrokerValue" + this.brokerList[i].value);
        if (this.brokerList[i].value == this.brokerarray[a]) {
  
          this.brokerFormControl.setValue(this.brokerList[i]);
          console.log("patchBrokerValue" + this.brokerList[i]);
        }
      }
    } 
  }
  else{
    console.log("patchBrokerValue" + this.brokerarray);
    this.brokerFormControl.setValue("")
  }
    }
    getBrokersDatanew(userData,company) {
    
      let brokerList = [];
      // console.log("broooker!!!!!!!!!!!!!!!!!!!!!"+JSON.stringify(userData));
      for (let i = 0; i < userData.length; i++) {
  
  
    
        // let companynew=
        if(userData[i].hasOwnProperty('companies_with_roles'))
        {
          console.log("broooker!!!!!!!!!!!!!!!!!!!!!"+JSON.stringify(userData[i]));
        if (userData[i].companies_with_roles.includes(company.toLowerCase()+"_b2b") && userData[i].status.includes("1")) {
          brokerList.push({
            name:
              userData[i].firstname +
              " " +
              userData[i].lastname +
              "(" +
              userData[i].customerno +
              ")",
            value: userData[i].customerno
            ,companynamenew:userData[i].companies_with_roles
          });
        }
      }
      }
  
      console.log("broooker!!!!!!!!!!!!!!!!!!!!!"+JSON.stringify(brokerList));
  
      return brokerList;
    }
  

  editRecord(id, accordianId, cl) {
    this.popupData = cl;
    console.log("editrrrrrrrrrrrr" + this.disableEditSubmit);
    accordianId = "collapse";
    event.preventDefault();
    let element1: HTMLElement = document.getElementById(
      "ul" + id
    ) as HTMLElement;
    let element1new: HTMLElement = document.getElementById(
      "cardbodyid"
    ) as HTMLElement;
    let element: HTMLElement = document.getElementById(
      "click" + id
    ) as HTMLElement;
    let accordian: HTMLElement = document.getElementById(accordianId);
    console.log("element1new" + element.innerHTML);
    console.log("element" + element);
    console.log("else accordian1", accordian);
    if (element.innerHTML == "Schließen") {
      console.log("element", element1new);
      element1new.after(accordian);
      accordian.classList.add("collapse");
      // accordian.classList.add("collapse");
      accordian.classList.remove("collapse-show");
      element.innerHTML = "Öffnen";

      let close = "close";
      console.log("elementelse0000000", element1);
      //this.checkDataAndCreateUpdateData(true, element, accordian,close)
    } else {
      setTimeout(() => {
        const input = document.querySelector("#phone");
        console.log("querySelector" + JSON.stringify(input));
        intlTelInput(input, {});
      }, 500);

      if (this.id != "") {
        let elementnew1: HTMLElement = document.getElementById(
          "click" + this.id
        ) as HTMLElement;
        if (elementnew1) {
          console.log("elementelse", elementnew1);
          elementnew1.innerHTML = "Öffnen";
        }
      }
      console.log("else accordian", accordian);
      console.log("else element", element1);
      element1.after(accordian);
      accordian.classList.remove("collapse");
      accordian.classList.add("collapse-show");
      element.innerHTML = "Schließen";
      this.id = id;
      this.getcurrentUser(this.id);
    }
    // console.log(element.innerHTML);
  }

  getBrokersData(userData) {
    let brokerList = [];
    this.userService.getUsers().subscribe((success: any) => {
      // if success and error give response
      if (success.status == "error") {
        this.error = success.message;
      } else {

        let userData = success;

        console.log("broooker!!!!!!!!!!!!!!!!!!!!!" + JSON.stringify(userData));
        for (let i = 0; i < userData.length; i++) {
          // console.log(userData[i].roles.includes('b2b') , userData[i].roles)
          // if (userData[i].roles.includes("b2b") && userData[i].companies_with_roles.includes("coursula_b2b") && userData[i].status.includes("1")) {
            if (userData[i].roles.includes("b2b") && userData[i].companies_with_roles.includes("ontrain_b2b") && userData[i].status.includes("1")) {
            brokerList.push({
              name:
                userData[i].firstname +
                " " +
                userData[i].lastname +
                "(" +
                userData[i].customerno +
                ")",
              value: userData[i].customerno
              , companynamenew: userData[i].companies_with_roles
            });
          }
        }



        // console.log(this.customerList);
      }
    }, (rejected) => {
      // console.log(rejected);
    });
    return brokerList;


  }
  onSubmit(): void {
    const that = this;

    console.log("asghgdj" + this.values);
    if (this.values != "") {
      var companyName: any = "";
      if (this.routeParams == "benutzer-cnt") companyName = "checkntrack";
      // if (this.routeParams == "finance") companyName = "coursula";
      if (this.routeParams == "finance") companyName = "ontrain";
      if (this.routeParams == "real-estate") companyName = "fiorettoimmob";
      if (this.routeParams == "birema") companyName = "birema";
      if (this.routeParams == "fioretto-media") companyName = "fiorettomedia";
      if (this.routeParams == "airmage") companyName = "airmage";
      if (this.routeParams == "horaidetektei") companyName = "horaidetektei";
      if (this.routeParams == "vario-import") companyName = "varioimport";
      if (this.routeParams == "sterbevorsorgebymek")
        companyName = "sterbvorsoge";
      const data = this.userService
        .getCustomerssearch(this.values, companyName)
        .subscribe(
          (success: any) => {
            // if success and error give response
            if (success.status == "error") {
              this.error = success.message;
            } else {
              // var success = this.filtercustomer(success, "coursula");
              var success = this.filtercustomer(success, "ontrain");
              this.brokerList = this.getBrokersData(success);
              this.customerList = success;
              console.log(this.customerList);
              console.log("this.customerList" + this.customerList);
              console.log("this.customerno" + this.customerno);
              this.setPage(1);
              this.recordCount = success.length;
            }
          },
          rejected => {
            console.log(rejected);
          }
        );

      // this.userService.getSearch(this.values).subscribe(function (data1) {
      //   that.customerList = data1;
      //   that.setPage(1);
      //   that.recordCount = that.customerList.length;
      //   //this.customerList.push(data1);
      //   //return this.customerList.push(data1);
      // });
    } else {
      var companyName: any = "";
      if (this.routeParams == "benutzer-cnt") companyName = "checkntrack";
      // if (this.routeParams == "finance") companyName = "coursula";
      if (this.routeParams == "finance") companyName = "ontrain";
      if (this.routeParams == "real-estate") companyName = "fiorettoimmob";
      if (this.routeParams == "birema") companyName = "birema";
      if (this.routeParams == "fioretto-media") companyName = "fiorettomedia";
      if (this.routeParams == "airmage") companyName = "airmage";
      if (this.routeParams == "horaidetektei") companyName = "horaidetektei";
      if (this.routeParams == "vario-import") companyName = "varioimport";
      if (this.routeParams == "sterbevorsorgebymek")
        companyName = "sterbvorsoge";
      const data = this.userService.getCustomerscoursula(companyName).subscribe(
        (success: any) => {
          // if success and error give response
          if (success.status == "error") {
            this.error = success.message;
          } else {
            this.brokerList = this.getBrokersData(success);
            that.customerList = success;
            that.setPage(1);
            that.recordCount = success.length;
            console.log(this.customerList);
          }
        },
        rejected => {
          console.log(rejected);
        }
      );
    }
  }
  onKey(event: any) {
    // without type info
    this.values = event.target.value;
    var value = event.keyCode;
    console.log(value);
    if (value == "13") {
      const that = this;
      that.customerList = [];
      this.values = event.target.value;
      let {customerno} = JSON.parse(sessionStorage.getItem("currentUser"));
      let CustomerNumbernew = customerno;
      if (this.values != "") {
        var companyName: any = "";
        if (this.routeParams == "benutzer-cnt") companyName = "checkntrack";
        // if (this.routeParams == "finance") companyName = "coursula";
      if (this.routeParams == "finance") companyName = "ontrain";
        if (this.routeParams == "real-estate") companyName = "fiorettoimmob";
        if (this.routeParams == "birema") companyName = "birema";
        if (this.routeParams == "fioretto-media") companyName = "fiorettomedia";
        if (this.routeParams == "airmage") companyName = "airmage";
        if (this.routeParams == "horaidetektei") companyName = "horaidetektei";
        if (this.routeParams == "vario-import") companyName = "varioimport";
        if (this.routeParams == "sterbevorsorgebymek")
          companyName = "sterbvorsoge";
        const data = this.userService
          .getSearchcoursula(this.values, CustomerNumbernew)
          .subscribe(
            (success: any) => {
              // if success and error give response
              if (success.status == "error") {
                this.error = success.message;
              } else {
                this.brokerList = this.getBrokersData(success);
                that.customerList = success;
                that.setPage(1);
                that.recordCount = success.length;
                console.log(this.customerList);
              }
            },
            rejected => {
              console.log(rejected);
            }
          );

        // this.userService.getCustomerssearch(this.values).subscribe(function (data1) {
        //   that.customerList = data1;
        //   that.setPage(1);
        //   that.recordCount = that.customerList.length;
        //this.customerList.push(data1);
        //return this.customerList.push(data1);
        // });
      } else {
        var companyName: any = "";
        if (this.routeParams == "benutzer-cnt") companyName = "checkntrack";
        // if (this.routeParams == "finance") companyName = "coursula";
        if (this.routeParams == "finance") companyName = "ontrain";
        if (this.routeParams == "real-estate") companyName = "fiorettoimmob";
        if (this.routeParams == "birema") companyName = "birema";
        if (this.routeParams == "fioretto-media") companyName = "fiorettomedia";
        if (this.routeParams == "airmage") companyName = "airmage";
        if (this.routeParams == "horaidetektei") companyName = "horaidetektei";
        if (this.routeParams == "vario-import") companyName = "varioimport";
        if (this.routeParams == "sterbevorsorgebymek")
          companyName = "sterbvorsoge";
        // const data = this.userService.getCustomerscoursula("coursula", "b2b").subscribe(
          const data = this.userService.getCustomerscoursula("ontrain", "b2b").subscribe(
          (success: any) => {
            // if success and error give response
            if (success.status == "error") {
              this.error = success.message;
            } else {
              // var success = this.filtercustomer(success, "coursula");
              var success = this.filtercustomer(success, "ontrain");
              this.brokerList = this.getBrokersData(success);
              that.customerList = success;
              that.setPage(1);
              that.recordCount = success.length;
              console.log(this.customerList);
            }
          },
          rejected => {
            console.log(rejected);
          }
        );
      }
    }
  }
  getcurrentUser(id?, currentData?) {

    this.disableEditSubmit = true;
    const that = this;
    this.b2b = false;
    this.employee = false;
    this.customer = false;
    this.admin = false;
    this.user = false;
    this.roleCompanies = {
      admin: [],
      customer: [],
      b2b: [],
      employee: []
    };




    this.roleCompaniesbrand = {
      admin: [],
      customer: [],
      b2b: [],
      employee: []
    };

    this.roleCompaniesbrandnew = [];


    this.roleCompaniescode = {
      admin: [],
      customer: [],
      b2b: [],
      employee: []
    };
    this.mekFinanz = false;
    this.fiorettoimmob = false;
    this.birema = false;
    this.fiorettomedia = false;
    this.airmage = false;
    this.horaidetektei = false;
    this.varioimport = false;
    this.sterbvorsoge = false;
    this.checkntrack = false;
    this.coursula = false;

    this.coursula_b2b = false;
    this.brokerbrandarray = [];
    this.brokerarray = [];
    this.statusbrandarray = []
    this.statusarray = []
    this.employeebrandarray = []
    this.employeearray = []



    // console.log(id, currentData, "currentdata")
    console.log("idnew" + id);
    if (currentData && !id) {


      setData(currentData);
    } else {
      // console.log("call api")
      this.userService.getEditUser(id).subscribe(function (data1) {
        that.currentUserData = data1;
        console.log("editRecord" + data1);

        setData(data1);
      });
    }

    function setData(data1) {




      that.userData = data1;
      const roles = (<FormArray>that.roleFormGroup.get("roles")) as FormArray;
      const companies = (<FormArray>(
        that.companyFormGroup.get("companies")
      )) as FormArray;
      that.broker = data1.broker;
      that.nationality = data1.nationality;
      console.log("Brokerdata: " + data1.companycode);
      // if(!data1.contactno.includes('+')){

      // }
      let rwc = data1.companies_with_roles;
      for (let i = 0; i < rwc.length; i++) {
        console.log("rwc[i]", rwc[i]);
        let temp = rwc[i].split("_");
        if (that.roleCompanies[temp[1]]) {
          that.roleCompanies[temp[1]].push(temp[0]);


        }
      }
      // that.roleCompaniesbrand['b2b'].push('Coursula');
      // that.roleCompaniesbrand['customer'].push('Coursula');
      // that.roleCompaniesbrandnew.push('Coursula');


      that.roleCompaniesbrand['b2b'].push('Ontrain');
      that.roleCompaniesbrand['customer'].push('Ontrain');
      that.roleCompaniesbrandnew.push('Ontrain');


      //       let rwcnew1 = data1.companies_with_roles;
      //       for (let i = 0; i < rwcnew1.length; i++) {
      //         let temp1 = rwcnew1[i].split("_");
      //         if (that.roleCompanies[temp1[1]]) {

      //          if(temp1[1]=='customer' || temp1[1]=='b2b')
      // {
      //   console.log("checknew"+temp1[0]);
      //   console.log("checknew"+that.roleCompaniesbrandnew);
      //   if(that.roleCompaniesbrandnew.includes(temp1[0].charAt(0).toUpperCase() + temp1[0].slice(1)))
      //   {
      // console.log("checknew"+temp1[0]);
      //   }
      //   else{
      //           that.roleCompaniesbrandnew.push('Coursula');
      //   }
      // }
      //         }
      //       }






      console.log("datanewwwwwwwwwwwwww" + JSON.stringify(that.roleCompaniesbrand['customer']));

      let rwcnew = data1.companycode;

      console.log("datanewwwwwwwwwwwwww" + rwcnew);
      for (let i = 0; i < rwcnew.length; i++) {
        console.log("rwcnew[i]", rwcnew[i]);
        let tempnew = rwcnew[i].split("_");
        if (that.roleCompaniescode[tempnew[1]]) {
          that.roleCompaniescode[tempnew[1]].push(tempnew[0]);
        }
      }







      if (data1.roles.includes("user") || data1.roles.includes("Benutzer")) {
        that.roleCompanies["user"] = ["checkntrack"];
        that.user = true;
      }
      if (data1.roles.includes("user") || data1.roles.includes("Benutzer")) {
        that.roleCompaniescode["user"] = ["c42150"];
      }
      // // console.log("data1",data1);
      data1.roles.forEach(role => {
        if (role === "b2b") {
          that.b2b = true;
          roles.push(new FormControl(role));
        }
        if (role === "employee") {
          that.employee = true;
          roles.push(new FormControl(role));
        }
        if (role === "customer") {
          that.customer = true;
          roles.push(new FormControl(role));
        }
        if (role === "admin") {
          that.admin = true;
          roles.push(new FormControl(role));
        }
        if (role === "Benutzer") {
          that.Benutzer = true;
          roles.push(new FormControl(role));
        }
      });

      data1.companies.forEach(comp => {
        console.log("compcompcompcompcompcomp" + comp);
        // if (comp === "coursula") {
          if (comp === "ontrain") {
          that.mekFinanz = true;
          companies.push(new FormControl({ value: comp, disabled: true }));
        }
        if (comp === "fiorettoimmob") {
          that.fiorettoimmob = true;
          companies.push(new FormControl({ value: comp, disabled: true }));
        }
        if (comp === "birema") {
          that.birema = true;
          companies.push(new FormControl({ value: comp, disabled: true }));
        }
        if (comp === "fiorettomedia") {
          that.fiorettomedia = true;
          companies.push(new FormControl({ value: comp, disabled: true }));
        }
        if (comp === "airmage") {
          that.airmage = true;
          companies.push(new FormControl({ value: comp, disabled: true }));
        }
        if (comp === "horaidetektei") {
          that.horaidetektei = true;
          companies.push(new FormControl({ value: comp, disabled: true }));
        }
        if (comp === "varioimport") {
          that.varioimport = true;
          companies.push(new FormControl({ value: comp, disabled: true }));
        }
        if (comp === "sterbvorsoge") {
          that.sterbvorsoge = true;
          companies.push(new FormControl({ value: comp, disabled: true }));
        }
        if (comp === "checkntrack") {
          that.checkntrack = true;
          companies.push(new FormControl({ value: comp, disabled: true }));
        }
        // if (comp === "coursula") {
          if (comp === "ontrain") {
          that.coursula = true;
          companies.push(new FormControl({ value: comp, disabled: true }));
        }
      });



      data1.companies_with_roles.forEach(comp => {
        console.log("compcompcompcompcompcomp" + comp);
        // if (comp === "coursula_b2b") {
        if (comp === "ontrain_b2b") {
          that.coursula_b2b = true;

        }

      });







      console.log("hjgjjhhjkjkh" + JSON.stringify(data1))
      // console.log("data1"+data1.brokerarray)
      // console.log("data1"+ this.brokerarray)
      // console.log("data1"+ data1.brokerarray.length)
      let brokerbrandnew = '';
      let brokerarraynew = '';
      let a1 = '';
      if (data1.hasOwnProperty('brokerbrandarray')) {
        // brokerbrandnew='Coursula';

        
        // a1 = data1.brokerbrandarray.indexOf('Coursula');
        // brokerbrandnew = 'Coursula';

        a1 = data1.brokerbrandarray.indexOf('Ontrain');
        brokerbrandnew = 'Ontrain';

      }
      else {
        brokerbrandnew = '';
      }

      if (data1.hasOwnProperty('brokerarray')) {
        brokerarraynew = data1.brokerarray[0];
      }
      else {
        brokerarraynew = '';
      }


      /*Status Set data*/
      let statusbrandnew = '';
      let statusarraynew = '';
      let a = '';
      if (data1.hasOwnProperty('statusbrandarray')) {
        // a = data1.statusbrandarray.indexOf('Coursula');
        // statusbrandnew = 'Coursula';
        a = data1.statusbrandarray.indexOf('Ontrain');
        statusbrandnew = 'Ontrain';
      }
      else {
        statusbrandnew = '';
      }

      if (data1.hasOwnProperty('statusarray')) {

        console.log("aaaaaaaaaaaa" + data1.statusarray);
        console.log("aaaaaaaaaaaa" + a);
        statusarraynew = data1.statusarray[a];
      }
      else {
        statusarraynew = '';
      }



      /*Employee Set data*/
      let employeebrandnew = '';
      let employeearraynew = '';
      let broker_type1 = '';
      let Vermittlerstatus = '';
      let registration_number = '';
      let ThirdTypeDoc = '';
      if (data1.hasOwnProperty('employeebrandarray')) {
        employeebrandnew = data1.employeebrandarray[0];
      }
      else {
        employeebrandnew = '';
      }

      if (data1.hasOwnProperty('employeearray')) {
        employeearraynew = data1.employeearray[0];
      }
      else {
        employeearraynew = '';
      }
      if (data1.hasOwnProperty('broker_type')) {
        broker_type1 = data1.broker_type;
      }
      else {
        broker_type1 = '';
      }
      if (data1.hasOwnProperty('registration_number')) {
        registration_number = data1.registration_number;
      }
      else {
        registration_number = '';
      }
      if (data1.hasOwnProperty('Vermittlerstatus')) {
        Vermittlerstatus = data1.Vermittlerstatus;
      }
      else {
        Vermittlerstatus = '';
      }
      if (data1.hasOwnProperty('ThirdTypeDoc')) {
        ThirdTypeDoc = data1.ThirdTypeDoc;
      }
      else {
        ThirdTypeDoc = '';
      }

      console.log("registration_number" + data1.registration_number)




      that.customerFormGroup.patchValue({
        title: data1.title,
        status: statusarraynew,
        employeetype: employeearraynew,
        companyname: data1.companyname,
        firstName: data1.firstname,
        lastName: data1.lastname,
        email: data1.email,
        dob: data1.dateofbirth,
        birthPlace: data1.birth_place,
        nationality: data1.nationality,
        customerNo: data1.customerno,
        street: data1.strassa,
        streetNumber: data1.strno,
        postCode: data1.plz,
        city: data1.city,
        additionalReference: data1.additionalReference,
        countryOfResidence: data1.current_country,
        id: data1._id,
        password: data1.password,
        phone_number: data1.contactno,
        customerno: data1.customerno,
        broker: data1.broker,
        brokerbrand: brokerbrandnew,
        statusbrand: statusbrandnew,
        employeebrand: employeebrandnew,
        registration_number: registration_number,
        Vermittlerstatus: Vermittlerstatus,
        broker_type: broker_type1
      });
      if (broker_type1 != '') {
        this.broker_typenew = broker_type1;
      }
      console.log("ddddddddddgggggggggggg" + ThirdTypeDoc);
      if (ThirdTypeDoc != '') {
        this.ThirdTypeDoc.setValue(ThirdTypeDoc);
      }
      // this.brokerarraynew=data1.brokerarray;
      // this.brokerarray=["kjl"]
      console.log("brokerarray" + data1.brokerarray);
      if (data1.brokerarray) {
        for (let k = 0; k < data1.brokerarray.length; k++) {
          console.log("data1" + data1.brokerarray[k])
          that.brokerarray.push(data1.brokerarray[k]);
        }
      }
      if (data1.brokerbrandarray) {
        for (let k1 = 0; k1 < data1.brokerbrandarray.length; k1++) {
          that.brokerbrandarray.push(data1.brokerbrandarray[k1]);
        }
      }

      if (data1.statusarray) {
        for (let k = 0; k < data1.statusarray.length; k++) {
          console.log("data1" + data1.statusarray[k])
          that.statusarray.push(data1.statusarray[k]);
        }
      }
      if (data1.statusbrandarray) {
        for (let k1 = 0; k1 < data1.statusbrandarray.length; k1++) {
          that.statusbrandarray.push(data1.statusbrandarray[k1]);
        }
      }



      if (data1.employeearray) {
        for (let k = 0; k < data1.employeearray.length; k++) {
          console.log("data1" + data1.employeearray[k])
          that.employeearray.push(data1.employeearray[k]);
        }
      }
      if (data1.employeebrandarray) {
        for (let k1 = 0; k1 < data1.employeebrandarray.length; k1++) {
          that.employeebrandarray.push(data1.employeebrandarray[k1]);
        }
      }

      // this..push(data1.brokerbrandarray)
      console.log("hjgjjhhjkjkh" + JSON.stringify(brokerarraynew))
      if (brokerarraynew) {
        for (let i = 0; i < that.brokerList.length; i++) {
          console.log("hjgjjhhjkjkh" + JSON.stringify(that.brokerList[i].value))
          if (that.brokerList[i].value == brokerarraynew) {

            that.brokerFormControl.setValue(that.brokerList[i]);
            console.log("that.brokerList[i]" + that.brokerList[i]);
          }
        }
      } else {
        that.brokerFormControl.setValue("");
      }

      if (data1.nationality) {
        console.log("data1.nationality" + data1.nationality);
        for (let i = 0; i < that.optionsValue.length; i++) {
          console.log("data1.nationality1" + data1.nationality);
          if (that.optionsValue[i] == data1.nationality) {
            that.myControl.setValue(data1.nationality);
          }
        }
      } else {
        that.myControl.setValue("");
      }

      if (data1.current_country) {
        for (let i = 0; i < that.optionsValue.length; i++) {
          if (that.optionsValueland[i] == data1.current_country) {
            that.myControlland.setValue(data1.current_country);
          }
        }
      } else {
        that.myControlland.setValue("");
      }

      that.customerNo = data1.customerno;
      that.companyTitleShow = data1.title === "Firma" ? true : false;
      console.log("CCC", data1);
      that.userData = data1;
    }
  }
}

