import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { UserService } from "../user.service";
@Component({
  selector: 'app-preventioncoursetoolslist',
  templateUrl: './preventioncoursetoolslist.component.html',
  styleUrls: ['./preventioncoursetoolslist.component.css']
})
export class PreventioncoursetoolslistComponent implements OnInit {
  id: any;
  api_url;
  questionindex: any;
  toolslist;
  fiorettourl;
  checkboxaccept:any=true;
  constructor(private route: ActivatedRoute, private UserService: UserService,private router:Router) {
    this.route.queryParams.subscribe((param) => {
      this.id = param["id"];
      this.questionindex = param["questionindex"];
    });
  }
  clickcheck(event) {
    $('#checkboxerror').html("");
    let accept:any = document.getElementById("accept");
    if (accept.checked == true) {
this.checkboxaccept=true;
    }
    else{
      this.checkboxaccept=false;
    }
  }
  
  paypal()
  {
    let accept:any = document.getElementById("accept");
    if (accept.checked == true) {
      $('#checkboxerror').html("");
this.checkboxaccept=true;
    }
    else{
      $('#checkboxerror').html("Bitte akzeptieren Sie unsere Allgemeinen Geschäftsbedingungen");
      this.checkboxaccept=false;
    }
  }


  ngOnInit() {
    $("#loaderouterid").css("display", "block");
    this.fiorettourl = environment.fiorettourl;
    this.api_url = environment.API_URL;
    let data = { purchasepreventioncourse_id: this.id };
    this.UserService.gettoolsbypreventioncourse(data).subscribe((result: any) => {
      if(result.data.length>0)
      {
      this.toolslist = result.data;
      }
      else{
        this.router.navigate(["/therapy-plan"]);
      }
      console.log(result);
      $("#loaderouterid").css("display", "none");
    });
  }

  navigateexercise_list() {
    this.router.navigate(["/prevention-course-exercise-list"],  { queryParams: { id: this.id} } );
    // this.router.navigate(["/exercise_list"],  { queryParams: { id: this.id,questionindex:this.questionindex } });
  }
}
