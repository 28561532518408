import { Component, OnInit, AfterViewInit } from "@angular/core";

import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
  CheckboxControlValueAccessor,
} from "@angular/forms";
import { UserService } from "../user.service";

import { first } from "rxjs/operators";
import { error } from "util";
import { Router } from "@angular/router";
import { PagerService } from "../pager.service";
import { MatChipInputEvent } from "@angular/material/chips";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ActivatedRoute, Params } from "@angular/router";
import * as $ from "jquery";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { DomSanitizer } from "@angular/platform-browser";
import Swal from "sweetalert2";
import { HttpEvent, HttpEventType } from "@angular/common/http";
type unit = "bytes" | "KB" | "MB" | "GB" | "TB" | "PB";
type unitPrecisionMap = {
  [u in unit]: number;
};

const defaultPrecisionMap: unitPrecisionMap = {
  bytes: 0,
  KB: 0,
  MB: 1,
  GB: 1,
  TB: 2,
  PB: 2,
};

// import * as AWS from 'aws-sdk';
export interface Fruit {
  name: string;
}
export interface Fruitnew {
  name: string;
}
@Component({
  selector: 'app-exercise-sequence-list',
  templateUrl: './exercise-sequence-list.component.html',
  styleUrls: ['./exercise-sequence-list.component.css']
})
export class ExerciseSequenceListComponent implements OnInit {
  routeParams: string;
  personalInfoFormGroup: FormGroup;
  personalInfoFormGroupadd:FormGroup;
  exerciseformgroup:FormGroup

  typesOptionsArray: any[] = [];
  typesOptionsArrayedit: any[] = [];
  oldtypesOptionsArray: any[] = [];
  customerList: any=[];
  recordCount: any;
  pagedItems: any;
  startRecord: number;
  progress = [];
  endRecord: any;
  l = 0;
  popupData: any;
  editid: any = "";
  editidnew: any = "";
  currentUserData: any;
  filearray: any[] = [];
  currentopendata: any;
  ticket_no: any;
  exercisedata: any = [];
  filearraynew: any[] = [];
  lengthfile: any = 0;
  uploadlength: any = 0;
  documentid: any = [];
  exerciselist: any = [];
  exercisesequencelist: any = [];
  exerciseoption: Observable<any[]>
  searchlist=[];
  exercise_controls = new FormControl();
  values: any;
  constructor(
    private _formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
  
    private pagerService: PagerService,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {}
  loginRole = sessionStorage.getItem("currentActiveRole");
  title = this.userService.getDecodedAccessToken(sessionStorage.getItem("Token"))
    .title;
    currentActiveRole = sessionStorage.getItem('currentActiveRole');
  loginid = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).id;
  lastname = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).lastname;
  firstname = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).firstname;
  COMPANYNAME = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).companyname;
  private readonly units: unit[] = ["bytes", "KB", "MB", "GB", "TB", "PB"];
  pager: any = {};
  selecteditem: string[] = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  itemList: string[] = [
    "Matte",
    "Bett",
    "Spiegel",
    "Strohhalm",
    "Eiswürfel",
    "Hocker",
    "Widerstandsband",
    "Pezziball",
    "Stab",
    "Kettlebell",
    "Faszienrolle",
    "Seil",
    "Hantel (klein)",
    "Handtuch",
    "Tape",
    "Tennisball",
    "Murmeln",
    "Zopfgummi",
    "Tisch",
    "Knete",
  ];
  selecteditemList = this.itemList;

  onKey1(value) {
    this.selecteditemList = this.search(value);
  }
  search(value: string) {
    let filter = value.toLowerCase();
    return this.itemList.filter((option) =>
      option.toLowerCase().startsWith(filter)
    );
  }

  getdatanew(value) {
    this.selecteditem.push(value);
    console.log("asdsaddsadasdA" + value);
    $("#inputtools").val("");
    this.onKey("");
  }

  ngOnInit() {
    this.loginRole = this.loginRole;
    this.routeParams = this.activatedRoute.snapshot.routeConfig.path;

    this.personalInfoFormGroup = this._formBuilder.group({
      exercise_name: ["", Validators.required],
      exercise_name1: ["", Validators.required],
      comment1: [""],
      comment: ["", Validators.required],
      manual_tags: ["", Validators.required],
      tools: ["", Validators.required],
      difficully_level: ["", Validators.required],

      upload_file: ["", Validators.required],
      explain: ["", Validators.required],
      implementation: ["", Validators.required],
      exercise_category: ["", Validators.required],
    });

    this.personalInfoFormGroupadd = this._formBuilder.group({
      exercise_name: ["", Validators.required],
      exercise_name1: ["", Validators.required],
      comment1: [""],
      comment: ["", Validators.required],
      manual_tags: ["", Validators.required],
      tools: ["", Validators.required],
      difficully_level: ["", Validators.required],

      upload_file: ["", Validators.required],
      explain: ["", Validators.required],
      implementation: ["", Validators.required],
      exercise_category: ["", Validators.required],
    });


    this.exerciseformgroup = this._formBuilder.group({
    
      exercise_category: ["", Validators.required],
      edit_id:[""],
      exercisesequence_name:["", Validators.required],
    });









    $("#loaderouterid").css("display", "block");
    // this.getallexerciesequence();
    this.getallexerciesequence();
    this.exerciseoption = this.exercise_controls.valueChanges.pipe(
      startWith(""),
      map((value) => (typeof value == "string" ? value : value.name)),
      map((name) =>this._filterexercisedata(name)
      )
    );
    this.typesOptionsArray.push();
    this.typesOptionsArrayedit.push();
  }
  openinformation(id)
  {
    $("#loaderouterid").css("display", "block");
    this.getcurrentUser(id);
  }


  _filterexercisedata(value: string): string[] {

    let searchlist=this.exercisedata;
    console.log("kundenew11" +JSON.stringify(searchlist));
      for(let i=0;i<this.typesOptionsArrayedit.length;i++)
      {
  const filterValue = this.typesOptionsArrayedit[i].toLowerCase();
  searchlist = searchlist.filter(function(itm){
    return JSON.stringify(itm.name).toLowerCase().indexOf(filterValue) > -1 || JSON.stringify(itm.tags).toLowerCase().indexOf(filterValue) > -1;
  });
      }
  
      const filterValue11 = value.toLowerCase();
      searchlist = searchlist.filter(function(itm){
        return JSON.stringify(itm.name).toLowerCase().indexOf(filterValue11) > -1 || JSON.stringify(itm.tags).toLowerCase().indexOf(filterValue11) > -1;
      });
      
      console.log("kundenew11" +JSON.stringify(searchlist));
      return searchlist;
    }
    removesequence(i) {
      console.log(i);
      this.exercisesequencelist.splice(i, 1);
    }
  getexercise() {
    let l = "b";
    console.log("exercisesequencelist" + this.exercisesequencelist.length);
    this.exercisesequencelist.push({
      id: this.exercise_controls.value.id,
      value: this.exercise_controls.value.name,
      img: this.exercise_controls.value.img,
      diff: this.exercise_controls.value.diff,
    });
  }

   getKeyByDinner(obj, searchid) {
    let returnKey:any = -1;
      
    console.log("kundenew1111111111111111111" + JSON.stringify(obj));
    $.each(obj, function(key, info) {

      console.log("kundenew1111111111111111111" + JSON.stringify(info));

        if (info.element._id == searchid) {
           returnKey = key;
            return false; 
        };   
    });
    
    return returnKey;       
           
}


  loopingexerciselist(userData,exerciselistdata) {
    let exerciselist = [];
    console.log("kundenewdata" + JSON.stringify(userData));

    for(let i=0;i<exerciselistdata[0].exercise_id.length;i++)
    {
      
      // console.log("kundenew1111111111111111111" + JSON.stringify(userData));
    let exercise_new_id=exerciselistdata[0].exercise_id[i];
    // console.log("kundenew1111111111111111111" + exercise_new_id);
  let key=  this.getKeyByDinner(userData, exercise_new_id);
  console.log("kundenew1111111111111111111key" + key);
    if(key!='-1')
    {
      this.exercisesequencelist.push({
        id: userData[key].element._id,
        value: userData[key].element.exercise_name1,
        img: userData[key].img,
        diff: userData[key].element.difficully_level,
      });
    }
    
   
    }
    




    for (let i = 0; i < userData.length; i++) {
      // console.log(userData[i].roles.includes('b2b') , userData[i].roles)
      console.log(
        "kundenew" + JSON.stringify(userData[i].element.exercise_name1)
      );

      exerciselist.push({
        name: userData[i].element.exercise_name1,
        id: userData[i].element._id,
        tags: userData[i].element.tags,
        img: userData[i].img,
        diff: userData[i].element.difficully_level,
      });   
    }

    console.log("broooker!!!!!!!!!!!!!!!!!!!!!" + JSON.stringify(exerciselist));

    return exerciselist;
  }
  getallexerciselist(exerciselist) {

    let exercise_category=this.exerciseformgroup.controls.exercise_category.value;
    console.log("sddd"+exercise_category);
        this.userService
          .getsearchexerciseinsequence(exercise_category)
          .pipe(first())
          .subscribe((data: any) => {
            this.exercisedata = this.loopingexerciselist(data,exerciselist);
    
            console.log("exercisedata" + this.exercisedata);
          });
      }

  addOptedit(event: MatChipInputEvent, index: number): void {
    const input = event.input;
    const value = event.value;
    // Add our fruit
    if (this.typesOptionsArrayedit.length > 19) {
      Swal.fire("Begrenzen Sie 20 Tags", "", "error");
    } else {
      console.log("ffffffffffff"+JSON.stringify(this.searchlist) );
      if ((value || "").trim()) {
        this.typesOptionsArrayedit.push(value.trim());
       this.exercise_controls.setValue(' ');
       $('#datanew').trigger('click');
      }
      // Reset the input value
      if (input) {
        input.value = "";
      }


    }

    
  }

  removeOptedit(opt: string, index: number): void {
    console.log("removeOpt");
    const optIndex = this.typesOptionsArrayedit.indexOf(opt);
    if (optIndex >= 0) {
      this.typesOptionsArrayedit.splice(optIndex, 1);
    }
    this.exercise_controls.setValue(' ');
  }
  choosecategory(value) {
    console.log("dddddddddddd" + value);
    this.personalInfoFormGroup.patchValue({
      exercise_category: value,
    });

    $("#exercise_category").val(value);
    if (value == "Therapie") {
      $("#Therapie").css("background-color", "gray");
      $("#Prävention").css("background-color", "white");
    } else {
      $("#Prävention").css("background-color", "gray");
      $("#Therapie").css("background-color", "white");
    }



  }




  choosecategoryedit(value) {

    if(typeof value == "object")
    {
    console.log("dddddddddddd" + value);
    this.exerciseformgroup.patchValue({
      exercise_category: value[0].exercisesequence_category,
    });

    $("#exercise_categoryedit").val(value[0].exercisesequence_category);
    if (value[0].exercisesequence_category == "Therapie") {
      $("#Therapieedit").css("background-color", "gray");
      $("#Präventionedit").css("background-color", "white");
    } else {
      $("#Präventionedit").css("background-color", "gray");
      $("#Therapieedit").css("background-color", "white");
    }
    this.getallexerciselist(value);
  }
  else{
    console.log("dddddddddddd" + value);
    this.exerciseformgroup.patchValue({
      exercise_category: value,
    });

    $("#exercise_categoryedit").val(value);
    if (value == "Therapie") {
      $("#Therapieedit").css("background-color", "gray");
      $("#Präventionedit").css("background-color", "white");
    } else {
      $("#Präventionedit").css("background-color", "gray");
      $("#Therapieedit").css("background-color", "white");
    }
    this.getallexerciselist(value);
  }
  }





  onKey(event) {
    console.log();
    // this.values = event.target.value;
    var value = event.keyCode;
    this.values = event.target.value;
    console.log(value);
    if (value == "13") {
      // without type info
      // this.customerList = [];
      const that = this;
      console.log(that.customerList);

      // this.values = event.target.value;
      if (event.target.value != "") {
        console.log(event.target.value);
        // var datanew={currentrole:this.loginRole,id:this.loginid}
        this.userService
          .getSearchexercisesequence(event.target.value)
          .subscribe(function (data1) {
            that.customerList = data1;

            that.setPage(1);

            that.recordCount = that.customerList.length;
            //this.customerList.push(data1);
            //return this.customerList.push(data1);
          });
      } else {
        this.getallexerciesequence();
      }
    }
  }
  onSubmit() {
    const that = this;
    console.log();

    // this.values = event.target.value;
    if (this.values != "") {
      // var datanew={currentrole:this.loginRole,id:this.loginid}
      this.userService
        .getSearchexercisesequence(this.values)
        .subscribe(function (data1) {
          that.customerList = data1;

          that.setPage(1);

          that.recordCount = that.customerList.length;
          //this.customerList.push(data1);
          //return this.customerList.push(data1);
        });
    } else {
      this.getallexerciesequence();
    }
  }
  editRecordofsequenceedit(id, accordianId, cl) {
    // $("#loaderouterid").css("display", "block");
    this.exercisesequencelist=[];

    let element: HTMLElement = document.getElementById(
      "clicksequenceedit" + id
    ) as HTMLElement;
    accordianId = "cardbodyidedit";

    this.popupData = cl;
    let element1: HTMLElement = document.getElementById(
      "ulsequence" + id
    ) as HTMLElement;
    let element1new: HTMLElement = document.getElementById(
      "cardbodysequence"
    ) as HTMLElement;
    let elementnew111: HTMLElement = document.getElementById(
      "clicksequence" + id
    ) as HTMLElement;

    let accordian: HTMLElement = document.getElementById(accordianId);
    let accordian111: HTMLElement = document.getElementById('cardbodyid');
    // if (elementnew111.innerHTML == "Übungsliste ausblenden") {
    // $("#cardbodyid").css("display", "none");
    // element1new.after(accordian111);
    // elementnew111.innerHTML = "Übungsliste anzeigen";
    // // $("#loaderouterid").css("display", "none");
    // this.getdivoutside();
    // }





  

   
    if (element.innerHTML == "Schließen") {
      $("#cardbodyidedit").css("display", "none");
      element1new.after(accordian);
      element.innerHTML = "Übungsablauf bearbeiten";
      $("#loaderouterid").css("display", "none");
      this.getdivoutside();
    } else {
      if (this.editid != "") {
        let elementnew1: HTMLElement = document.getElementById(
          "clicksequenceedit" + this.editid
        ) as HTMLElement;
        if (elementnew1) {
          elementnew1.innerHTML = "Übungsablauf bearbeiten";
        }
      }
      element1.after(accordian);
      $("#cardbodyidedit").css("display", "block");
      element.innerHTML = "Schließen";
      this.editid = id;
      this.getcurrentexercisesequenceedit(this.editid);
    }
  }

  editRecordofsequence(id, accordianId, cl) {
    $("#loaderouterid").css("display", "block");
    this.exerciselist = [];

    let element: HTMLElement = document.getElementById(
      "clicksequence" + id
    ) as HTMLElement;
    accordianId = "cardbodyid";

    this.popupData = cl;
    let element1: HTMLElement = document.getElementById(
      "ulsequence" + id
    ) as HTMLElement;
    let element1new: HTMLElement = document.getElementById(
      "cardbodysequence"
    ) as HTMLElement;

    let accordian: HTMLElement = document.getElementById(accordianId);

    let elementedit: HTMLElement = document.getElementById(
      "clicksequenceedit" + id
    ) as HTMLElement;
    let accordiannewedit: HTMLElement = document.getElementById('cardbodyidedit');





    if (elementedit.innerHTML == "Schließen") {
      $("#cardbodyidedit").css("display", "none");
      element1new.after(accordiannewedit);
      elementedit.innerHTML = "Öffnen";
      this.getdivoutside();
    }








    if (element.innerHTML == "Übungsliste ausblenden") {
      $("#cardbodyid").css("display", "none");
      element1new.after(accordian);
      element.innerHTML = "Übungsliste anzeigen";
      $("#loaderouterid").css("display", "none");
      this.getdivoutside();
    } else {
      if (this.editid != "") {
        let elementnew1: HTMLElement = document.getElementById(
          "clicksequence" + this.editid
        ) as HTMLElement;
        if (elementnew1) {
          elementnew1.innerHTML = "Übungsliste anzeigen";
        }
      }
      element1.after(accordian);
      $("#cardbodyid").css("display", "block");
      element.innerHTML = "Übungsliste ausblenden";
      this.editid = id;
      this.getcurrentexercisesequence(this.editid);
    }
  }

  getcurrentexercisesequence(id) {
    var datanew = { id: id };
    const data = this.userService.getexercisesequence(datanew).subscribe(
      (success: any) => {
        $("#loaderouterid").css("display", "none");
        // if success and error give response
        if (success.status == "error") {
          Swal.fire("Übungssequenzliste nicht gefunden", "", "error");
        } else {
          this.exerciselist = success;
          $("#loaderouterid").css("display", "none");
        }
      },
      (rejected) => {
        console.log(rejected);
      }
    );
  }

  getcurrentexercisesequenceedit(id) {
    var datanew = { id: id };
    const data = this.userService.getexercisesequencebyid(datanew).subscribe(
      (success: any) => {
        $("#loaderouterid").css("display", "none");
        // if success and error give response
        if (success.status == "error") {
          Swal.fire("Übungssequenzliste nicht gefunden", "", "error");
        } else {
          console.log("ddss"+success[0].exercisesequence_category);
          this.choosecategoryedit(success);
          this.exerciseformgroup.patchValue({
            exercisesequence_name: success[0].exercisesequence_name,
            edit_id:id,
          });
          let exercisesequence_namecount: any;
 
            let exercisesequence_name = this.exerciseformgroup.controls.exercisesequence_name
              .value.length;
            exercisesequence_namecount = 35 - exercisesequence_name;
          
          $("#exercisesequence_namecount" ).html(exercisesequence_namecount);

          // this.exerciselist = success;
          $("#loaderouterid").css("display", "none");
        }
      },
      (rejected) => {
        console.log(rejected);
      }
    );
  }

  update_exercise_sequence()
  {
    $("#loaderouterid").css("display", "block");

    let companycodenew = "";

    companycodenew = "60-co-";

    this.userService
      .getLastdocumentnew()
      .pipe(first())
      .subscribe((data1) => {
        console.log("ticket_no" + data1);
        // customerNo.substring(5,customerNo.length);
        this.ticket_no = companycodenew + "" + new Date().valueOf();

let dataarray=[];

        for (let i = 0; i < this.exercisesequencelist.length; i++) {
    // let datannn={'exercise_id1':this.exercisesequencelist[i].id}
          dataarray.push(this.exercisesequencelist[i].id);
        }

        
        let datanew = {
          exercisesequence_name: this.exerciseformgroup.controls.exercisesequence_name
            .value,
            exercisesequence_category: this.exerciseformgroup.controls.exercise_category.value,
            edit_id: this.exerciseformgroup.controls.edit_id.value,
          exercise_id: dataarray,
        };

        this.userService
        .updateerexercisesequence(datanew)
        .pipe(first())
        .subscribe(
          (data) => {
      
            Swal.fire(
              "Übungsabläufe erfolgreich aktualisiert.",
              "",
              "success"
            );
            this.ticket_no = "";
            // $('#clicksequenceedit'+this.exerciseformgroup.controls.edit_id.value).trigger('click');
            $("#loaderouterid").css("display", "none");
            
            setTimeout(() => {
              Swal.close();
              this.getallexerciesequence(this.editid);
              this.exercisesequencelist=[];
          //  this.exercise_management();
            }, 1000);
          },
          (error) => {
            Swal.fire("Error" + error["error"] + "", "", "error");

            $("#loaderouterid").css("display", "none");
            setTimeout(() => {
              Swal.close();
              this.getallexerciesequence(this.editid);
              this.exercisesequencelist=[];
            }, 1000);
            // $("html, body").animate(
            //   { scrollTop: "0" }, 3000);
          }
        );





      });
  }

  editRecord(id, accordianId, cl) {
    this.progress[0] = 0;
    this.progress[1] = 0;
    this.progress[2] = 0;
    let idnew=id;
    id=id+''+accordianId;
    accordianId = "collapse";
    $("#loaderouterid").css("display", "block");
    this.popupData = cl;
    let element1: HTMLElement = document.getElementById(
      "ul" + id
    ) as HTMLElement;
    let element1new: HTMLElement = document.getElementById(
      "cardbodyid"
    ) as HTMLElement;
    let element: HTMLElement = document.getElementById(
      "click" + id
    ) as HTMLElement;
    let accordian: HTMLElement = document.getElementById(accordianId);
    console.log("accordian" + accordian);
    if (element.innerHTML == "Schließen") {
      element1new.after(accordian);
      accordian.classList.add("collapse");
      accordian.classList.remove("collapse-show");
      element.innerHTML = "Öffnen";
      $("#loaderouterid").css("display", "none");
    } else {
      if (this.editid != "") {
        let elementnew1: HTMLElement = document.getElementById(
          "click" + this.editidnew
        ) as HTMLElement;
        if (elementnew1) {
          elementnew1.innerHTML = "Öffnen";
        }
      }

      element1.after(accordian);
      accordian.classList.remove("collapse");
      accordian.classList.add("collapse-show");
      element.innerHTML = "Schließen";
      this.editid = idnew;
      this.editidnew=id;
      this.getcurrentUser(this.editid);
    }
  }

  addOpt(event: MatChipInputEvent, index: number): void {
    const input = event.input;
    const value = event.value;
    console.log("dsdsad" + index);
    console.log("input" + input);
    console.log("value" + value);
    console.log("valuenew" + value.trim());
    console.log("valuenew" + this.typesOptionsArray);
    // Add our fruit
    if (this.typesOptionsArray.length > 19) {
      Swal.fire("Begrenzen Sie 20 Tags", "", "error");
    } else {
      if ((value || "").trim()) {
        this.typesOptionsArray.push(value.trim());
      }
      // Reset the input value
      if (input) {
        input.value = "";
      }
      let tagscount: any = 20 - this.typesOptionsArray.length;
      $("#manual_tagscount").html(tagscount);
    }
  }

  removeOpt(opt: string, index: number): void {
    const optIndex = this.typesOptionsArray.indexOf(opt);
    if (optIndex >= 0) {
      this.typesOptionsArray.splice(optIndex, 1);
    }
  }

  getcurrentUser(openid?, currentData?) {
    const that = this;
    if (currentData && !openid) {
      setData(currentData);
    } else {
      // console.log("call api")
      this.userService.getEditexercise(openid).subscribe(function (data1) {
        that.currentUserData = data1;
        console.log(data1);

        setData(data1);
      });
    }

    function setData(data1) {
      that.currentopendata = data1;

      console.log("dddddddddddd" + JSON.stringify(data1));
      console.log("dddddddddddd" + data1.element.exercise_category);
      that.choosecategory(data1.element.exercise_category);

      that.calcount("commentcount", "comment");
      let commentlength: any = 500 - data1.element.comment.length;
      $("#commentcount").html(commentlength);
      let commentlength1: any = 500 - data1.element.comment1.length;
      $("#comment1count").html(commentlength1);
      let exercise_name: any = 35 - data1.element.exercise_name.length;
      $("#exercise_namecount").html(exercise_name);
      let exercise_name1: any = 35 - data1.element.exercise_name1.length;
      $("#exercise_name1count").html(exercise_name1);
      let tags: any = 20 - data1.element.tags.length;
      $("#manual_tagscount").html(tags);

      that.personalInfoFormGroup.patchValue({
        exercise_name: data1.element.exercise_name,
        exercise_name1: data1.element.exercise_name1,
        difficully_level: data1.element.difficully_level,
        tools: data1.element.tools,
        comment: data1.element.comment,
        comment1: data1.element.comment1,
      });
      that.personalInfoFormGroupadd.patchValue({
        exercise_name: data1.element.exercise_name,
        exercise_name1: data1.element.exercise_name1,
        difficully_level: data1.element.difficully_level,
        tools: data1.element.tools,
        comment: data1.element.comment,
        comment1: data1.element.comment1,
      });




      that.oldtypesOptionsArray = data1.element.tags;
      console.log("dddddddddddd" + that.oldtypesOptionsArray);
      $("#logopreview0").html(
        "<img src='" + data1.image1 + "' style='width: 100%;height: 300px'>"
      );
      $("#logopreview1").html(
        "<video  style='width: 100%;height: 300px' controls><source  src='" +
          data1.image2 +
          "' type='video/mp4'></video>"
      );
      $("#logopreview2").html(
        "<video  style='width: 100%;height: 300px' controls><source  src='" +
          data1.image3 +
          "' type='video/mp4'></video>"
      );

      $("#logopreview01").html(
        "<img src='" + data1.image1 + "' style='width: 100%;height: 300px'>"
      );
      $("#logopreview11").html(
        "<video  style='width: 100%;height: 300px' controls><source  src='" +
          data1.image2 +
          "' type='video/mp4'></video>"
      );
      $("#logopreview21").html(
        "<video  style='width: 100%;height: 300px' controls><source  src='" +
          data1.image3 +
          "' type='video/mp4'></video>"
      );


      $("#loaderouterid").css("display", "none");
    }

    // this.choosecategory();
    // $('#loaderouterid').css("display","none");
  }
  calcountedit(id, type) {
    console.log("dddddddddddddddd");
    let exercisesequence_namecount: any;
    if (type == "exercisesequence_name") {
      let exercisesequence_name = this.exerciseformgroup.controls.exercisesequence_name
        .value.length;

      exercisesequence_namecount = 35 - exercisesequence_name;
    }
    $("#" + id).html(exercisesequence_namecount);
  }
  calcount(id, type) {
    let commentcount: any;
    if (type == "comment") {
      let comment = this.personalInfoForm.comment.value.length;

      commentcount = 500 - comment;
    }
    if (type == "comment1") {
      let comment = this.personalInfoForm.comment1.value.length;

      commentcount = 500 - comment;
    }
    if (type == "exercise_name") {
      let comment = this.personalInfoForm.exercise_name.value.length;

      commentcount = 35 - comment;
    }
    if (type == "exercise_name1") {
      let comment = this.personalInfoForm.exercise_name1.value.length;

      commentcount = 35 - comment;
    }
    $("#" + id).html(commentcount);
  }

  dataconvert(
    bytes: number = 0,
    precision: number | unitPrecisionMap = defaultPrecisionMap
  ): string {
    if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) return "?";

    let unitIndex = 0;

    while (bytes >= 1024) {
      bytes /= 1024;
      unitIndex++;
    }

    const unit = this.units[unitIndex];

    if (typeof precision === "number") {
      return `${bytes.toFixed(+precision)} ${unit}`;
    }
    return `${bytes.toFixed(precision[unit])} ${unit}`;
  }

  handleImageChange(event: any, index) {
    $("#result").html("");
    console.log(this.loginid);
    event.preventDefault();
    const removeData = (j, indexw) => {
      console.log("sdddd" + this.filearraynew);
      console.log("sdddd" + j);
      delete this.filearraynew[j];
      console.log("sdddd" + this.filearraynew);
      let newfilearray = this.filearraynew.filter(function () {
        return true;
      });
      if (newfilearray.length > 0) {
      } else {
        // this.showButton = false;
      }

      this.filearray = newfilearray;
      console.log("sdddd" + this.filearray);
      $("#logopreview" + indexw).html(
        '<p style="text-align: center;padding: 105px 27px 106px 38px;border: 1px solid;"> Vorschau</p>'
      );
    };

    var files = event.target.files; //FileList object
    // var output = document.getElementById("result");

    var filesLength = files.length;

    for (let i = 0; i < filesLength; i++) {
      let f = files[i];
      let newsize = this.l;
      this.l = this.l + 1;
      // this.filearraynew.splice(index, 0, f);

      this.filearraynew[index] = f;
      this.filearray = this.filearraynew;
      let Size1 = f.size;
      let Size = this.dataconvert(Size1);
      var fileReader = new FileReader();
      //var target:EventTarget;
      fileReader.onload = function (e) {
        //var file = e.target;

        let extension = f.name.substr(f.name.lastIndexOf(".") + 1);

        let ImageName;

        ImageName = (e.target as any).result;

        let typeofimage = f.type;

        let dateofdocument = f.lastModified;
        // var d = new Date(dateofdocument);
        // var date =
        //   d.getDate() + "." + (d.getMonth() + 1) + "." + d.getFullYear();

        var date1 = new Date(dateofdocument).toLocaleDateString("en-IN", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });

        var date = date1.replace(/[/]/g, ".");

        // let Size = Math.round(f.size / 1024);
        $("#logopreview" + index).html(
          '<div class="pip" style="width: 290px;display: inline-block;margin: 8px;" "id=\'pipremove' +
            newsize +
            "'>" +
            '<div class="removepreview" id="removepreviewid' +
            newsize +
            '" style="background: #184297;border-radius: 50%;width:30px;height:30px;font-size: 14px; text-align: center; padding: 6px;color: white;position: absolute;margin-left: 257px;margin-top: 2px;margin-right: 0 !important;cursor: pointer;">X</div>' +
            "" +
            '<img class="imageThumb" style="width: 100%;height:210px" src="' +
            ImageName +
            '" title="' +
            f.name +
            '"/>' +
            "<div> <b>Dokumentenname: " +
            f.name +
            "</b> </div><div> <b>Dateigröße: " +
            Size +
            "</b>  </div><div> <b>Dateityp: " +
            typeofimage +
            "</b> </div><div> <b>Datum des Dokuments: " +
            date +
            "</b> </div></div>"
        );

        // $(`<div> <b>  ${Math.round((f.size / 1024))} </b> KB </div>`).insertAfter(".pip")
        $("#removepreviewid" + newsize).click(function () {
          removeData(newsize, index);
          // $("#pipremove" + i).remove();
          $(this).parent(".pip").remove();
        });
      };
      fileReader.readAsDataURL(f);
    }
  }

  handleImageChangeexplain(event: any, index) {
    $("#result").html("");
    console.log(this.loginid);
    event.preventDefault();
    const removeData = (j) => {
      console.log("sdddd" + this.filearraynew);
      console.log("sdddd" + j);
      delete this.filearraynew[j];
      console.log("sdddd" + this.filearraynew);
      let newfilearray = this.filearraynew.filter(function () {
        return true;
      });
      if (newfilearray.length > 0) {
      } else {
        // this.showButton = false;
      }

      this.filearray = newfilearray;
      console.log("sdddd" + this.filearray);
    };

    var files = event.target.files; //FileList object
    // var output = document.getElementById("result");

    var filesLength = files.length;

    for (let i = 0; i < filesLength; i++) {
      let f = files[i];
      let newsize = this.l;
      this.l = this.l + 1;
      // this.filearraynew.splice(index, 0, f);

      this.filearraynew[index] = f;
      this.filearray = this.filearraynew;
      let Size1 = f.size;
      let Size = this.dataconvert(Size1);
      var fileReader = new FileReader();
      //var target:EventTarget;
      fileReader.onload = function (e) {
        //var file = e.target;

        let extension = f.name.substr(f.name.lastIndexOf(".") + 1);

        let ImageName;

        ImageName = (e.target as any).result;

        let typeofimage = f.type;

        let dateofdocument = f.lastModified;
        // var d = new Date(dateofdocument);
        // var date =
        //   d.getDate() + "." + (d.getMonth() + 1) + "." + d.getFullYear();

        var date1 = new Date(dateofdocument).toLocaleDateString("en-IN", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });

        var date = date1.replace(/[/]/g, ".");

        // let Size = Math.round(f.size / 1024);
        $("#logopreview" + index).html(
          '<div class="pip" style="width: 290px;display: inline-block;margin: 8px;" "id=\'pipremove' +
            newsize +
            "'>" +
            '<div class="removepreview" id="removepreviewid' +
            newsize +
            '" style="background: #184297;border-radius: 50%;width:30px;height:30px;font-size: 14px; text-align: center; padding: 6px;color: white;position: absolute;margin-left: 257px;margin-top: -30px;margin-right: 0 !important;cursor: pointer;">X</div>' +
            "" +
            '<video  style="width: 100%;height:210px"  controls><source src="' +
            (e.target as any).result +
            '" type="video/mp4"></video>' +
            "<div> <b>Dokumentenname: " +
            f.name +
            "</b> </div><div> <b>Dateigröße: " +
            Size +
            "</b>  </div><div> <b>Dateityp: " +
            typeofimage +
            "</b> </div><div> <b>Datum des Dokuments: " +
            date +
            "</b> </div></div>"
        );

        // $(`<div> <b>  ${Math.round((f.size / 1024))} </b> KB </div>`).insertAfter(".pip")
        $("#removepreviewid" + newsize).click(function () {
          removeData(newsize);
          // $("#pipremove" + i).remove();
          $(this).parent(".pip").remove();
        });
      };
      fileReader.readAsDataURL(f);
    }
  }

  handleImageChangeimplementation(event: any, index) {
    $("#result").html("");
    console.log(this.loginid);
    event.preventDefault();
    const removeData = (j) => {
      console.log("sdddd" + this.filearraynew);
      console.log("sdddd" + j);
      delete this.filearraynew[j];
      console.log("sdddd" + this.filearraynew);
      let newfilearray = this.filearraynew.filter(function () {
        return true;
      });
      if (newfilearray.length > 0) {
      } else {
        // this.showButton = false;
      }

      this.filearray = newfilearray;
      console.log("sdddd" + this.filearray);
    };

    var files = event.target.files; //FileList object
    // var output = document.getElementById("result");

    var filesLength = files.length;

    for (let i = 0; i < filesLength; i++) {
      let f = files[i];
      let newsize = this.l;
      this.l = this.l + 1;
      // this.filearraynew.splice(index, 0, f);

      this.filearraynew[index] = f;
      this.filearray = this.filearraynew;
      let Size1 = f.size;
      let Size = this.dataconvert(Size1);
      var fileReader = new FileReader();
      //var target:EventTarget;
      fileReader.onload = function (e) {
        //var file = e.target;

        let extension = f.name.substr(f.name.lastIndexOf(".") + 1);

        let ImageName;

        ImageName = (e.target as any).result;

        let typeofimage = f.type;

        let dateofdocument = f.lastModified;
        // var d = new Date(dateofdocument);
        // var date =
        //   d.getDate() + "." + (d.getMonth() + 1) + "." + d.getFullYear();

        var date1 = new Date(dateofdocument).toLocaleDateString("en-IN", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });

        var date = date1.replace(/[/]/g, ".");

        // let Size = Math.round(f.size / 1024);
        $("#logopreview" + index).html(
          '<div class="pip" style="width: 290px;display: inline-block;margin: 8px;" "id=\'pipremove' +
            newsize +
            "'>" +
            '<div class="removepreview" id="removepreviewid' +
            newsize +
            '" style="background: #184297;border-radius: 50%;width:30px;height:30px;font-size: 14px; text-align: center; padding: 6px;color: white;position: absolute;margin-left: 257px;margin-top: -30px;margin-right: 0 !important;cursor: pointer;">X</div>' +
            "" +
            '<video  style="width: 100%;height:210px"  controls><source src="' +
            (e.target as any).result +
            '" type="video/mp4"></video>' +
            "<div> <b>Dokumentenname: " +
            f.name +
            "</b> </div><div> <b>Dateigröße: " +
            Size +
            "</b>  </div><div> <b>Dateityp: " +
            typeofimage +
            "</b> </div><div> <b>Datum des Dokuments: " +
            date +
            "</b> </div></div>"
        );

        // $(`<div> <b>  ${Math.round((f.size / 1024))} </b> KB </div>`).insertAfter(".pip")
        $("#removepreviewid" + newsize).click(function () {
          removeData(newsize);
          // $("#pipremove" + i).remove();
          $(this).parent(".pip").remove();
        });
      };
      fileReader.readAsDataURL(f);
    }
  }


  get personalInfoForm() {
    return this.personalInfoFormGroup.controls;
  }

  getallexerciesequence(id='') {

if(id!='')
{
    let element: HTMLElement = document.getElementById(
      "clicksequenceedit" + id
    ) as HTMLElement;
  let  accordianId = "cardbodyidedit";
  let accordian: HTMLElement = document.getElementById(accordianId);
    let element1new: HTMLElement = document.getElementById(
      "cardbodysequence"
    ) as HTMLElement;
    if (element.innerHTML == "Schließen") {
    $("#cardbodyidedit").css("display", "none");
    element1new.after(accordian);
    element.innerHTML = "Öffnen";
    this.getdivoutside();
    }
  }
    var datanew = { id: "" };
    const data = this.userService.getexercisesequence(datanew).subscribe(
      (success: any) => {
        $("#loaderouterid").css("display", "none");
        // if success and error give response
        if (success.status == "error") {
          Swal.fire("Übungsliste nicht gefunden", "", "error");
        } else {
          this.customerList = success;
          this.setPage(1);
          this.recordCount = success.length;
          console.log(this.customerList);
        }
      },
      (rejected) => {
        console.log(rejected);
      }
    );
    this.filearray = [];
  }

  updateexercise() {
    console.log("dddddddddddddd" + this.lengthfile);
    console.log("dddddddddddddd" + this.uploadlength);
    if (this.lengthfile == this.uploadlength) {
      let product_type_new = [];
      for (var i = 0; i < this.typesOptionsArray.length; i++) {
        product_type_new[i] = this.typesOptionsArray[i];
      }
      if (this.oldtypesOptionsArray) {
        for (var l = 0; l < this.oldtypesOptionsArray.length; l++) {
          product_type_new[i] = this.oldtypesOptionsArray[l];
          i++;
        }
      }
      let data = {
        comment: this.personalInfoForm.comment.value,
        comment1: this.personalInfoForm.comment1.value,
        exercise_name: this.personalInfoForm.exercise_name.value,
        exercise_name1: this.personalInfoForm.exercise_name1.value,
        difficully_level: this.personalInfoForm.difficully_level.value,
        tools: this.personalInfoForm.tools.value,
        exercise_category: this.personalInfoForm.exercise_category.value,
        tags: product_type_new,
        document: this.documentid,
        _id: this.currentopendata.element._id,
      };

      this.userService
        .updateexercise(data)
        .pipe(first())
        .subscribe(
          (data) => {
            console.log("POST Request is successful ", data);
            $("#loaderouterid").css("display", "none");
            Swal.fire("Übung erfolgreich aktualisiert", "", "success");

            setTimeout(() => {
              Swal.close();
              this.getallexerciesequence();
            }, 1000);
          },
          (error) => {
            console.log("Error", error["error"]);
            $("#loaderouterid").css("display", "none");
            Swal.fire("Übung aktualisiert fehlgeschlagen", "", "error");
            // setTimeout(() => {
            Swal.close();
            // window.location.reload();
            // location.href="http://localhost:4200/#/finance/product-partner-list";
            this.getallexerciesequence();
            // }, 1000);
            // $("html, body").animate(
            //   { scrollTop: "0" }, 3000);
          }
        );
    }
  }


  exercise_management() {
    this.router.navigate(["/exercise-sequence-list"], {});
  }
  getdivoutside() {
    let accordianId = "collapse";
    let accordian: HTMLElement = document.getElementById(accordianId);
    let element1new: HTMLElement = document.getElementById(
      "cardbodyid"
    ) as HTMLElement;
    element1new.after(accordian);
    accordian.classList.add("collapse");
    // accordian.classList.add("collapse");
    accordian.classList.remove("collapse-show");
  }







  setPage(page: number) {
    this.getdivoutside();
    // get pager object from service
    this.pager = this.pagerService.getPager(this.customerList.length, page);

    // get current page of items
    this.pagedItems = this.customerList.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );
    if (this.customerList.length > 0) {
      this.startRecord =
        this.pager.currentPage * this.pagerService.getDefaultPageSize() -
        this.pagerService.getDefaultPageSize() +
        1;
      this.endRecord =
        this.pager.currentPage * this.pagerService.getDefaultPageSize() >
        this.customerList.length
          ? this.customerList.length
          : this.pager.currentPage * this.pagerService.getDefaultPageSize();
    } else {
      this.startRecord = 0;
      this.endRecord = 0;
    }
  }
}
