import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
@Component({
  selector: 'app-sessionstrip',
  templateUrl: './sessionstrip.component.html',
  styleUrls: ['./sessionstrip.component.css']
})
export class SessionstripComponent implements OnInit {
  token = this.UserService.getdecode(sessionStorage.getItem("Token"));
  constructor(private UserService:UserService) { }

  ngOnInit() {
  }

}
