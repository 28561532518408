import { Component, OnInit, AfterViewInit } from "@angular/core";
import {
  CdkDragDrop,
  moveItemInArray,
  copyArrayItem,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import Swal from "sweetalert2";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
  CheckboxControlValueAccessor,
} from "@angular/forms";
import { UserService } from "../user.service";

import { concatMapTo, first } from "rxjs/operators";
import { error } from "util";
import { Router } from "@angular/router";
// import { SearchCountryField, CountryISO } from "ngx-intl-tel-input";
import { ReturnStatement } from "@angular/compiler";
import intlTelInput from "intl-tel-input";
import { MatChipInputEvent } from "@angular/material/chips";
import { Observable, Observer } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ActivatedRoute, Params } from "@angular/router";
// import { CompanySelectDialogComponent } from "../company-select-dialog/company-select-dialog.component"
// import { MatDialog } from '@angular/material';
import * as $ from "jquery";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
// import { randomBytes } from "crypto";
export interface Fruit {
  name: string;
}
export interface Fruitnew {
  name: string;
}
@Component({
  selector: 'app-add-exercise-sequence',
  templateUrl: './add-exercise-sequence.component.html',
  styleUrls: ['./add-exercise-sequence.component.css']
})
export class AddExerciseSequenceComponent implements OnInit {
  loginRole = localStorage.getItem("currentActiveRole");
  title = this.userService.getDecodedAccessToken(sessionStorage.getItem("Token"))
    .title;
  loginid = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).id;
  lastname = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).lastname;
  firstname = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).firstname;
  customer_no = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).customerno;
  queryID: any;
  checkeye = "open";
  COMPANYNAME = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).companyname;
  routeParams: any;
  personalInfoFormGroup: FormGroup;
  personalInfoFormGroupadd: FormGroup;
  oldtypesOptionsArray: any[] = [];
  personalInfoFormGroup0: FormGroup;
  secondstep: FormGroup;
  registerDone = false;
  registraionError = false;
  registerbtn = true;
  countryName: any;
  fiorettomediacustomer: any = [];
  uploadedFiles: Array<File> = [];
  Kundeoption: Observable<any>;
  Kundecontrol = new FormControl();
  kunde_id: any = "";
  projectdata: any;
  exercisesequencelist: any = [];
  exercisesequencelistsearch: any = [];
  exerciseoption: Observable<any[]>;
  searchlist = [];
  documentid: any[][] = [];
  constructor(
    private _formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    
    private activatedRoute: ActivatedRoute // private dialog: MatDialog
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.queryID = params["name"];

      // this.statusCustomer();
      // this.userService.statusCustomer({ "id": this.pagedItems[0]._id })
    });
    // this.SparteFormGroup = this.fb.group({
    //   name: '',
    //   quantities: this.fb.array([]) ,
    // });
  }
  ticket_no = "";
  filearray: any[] = [];
  filearraynew: any[] = [];
  l: number = 0;
  items = ["Javascript", "Typescript"];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  objectjsonnew: any;
  fruits: Fruit[] = [];
  lengthfile: number = 0;
  uploadlength: number = 0;
  exercisedata: any = [];
  typesOptionsArray: string[] = [];
  selecteditem: string[] = [];
  exercise_controls = new FormControl();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  itemList: string[] = [
    "Matte",
    "Bett",
    "Spiegel",
    "Strohhalm",
    "Eiswürfel",
    "Hocker",
    "Widerstandsband",
    "Pezziball",
    "Stab",
    "Kettlebell",
    "Faszienrolle",
    "Seil",
    "Hantel (klein)",
    "Handtuch",
    "Tape",
    "Tennisball",
    "Murmeln",
    "Zopfgummi",
    "Tisch",
    "Knete",
  ];
  selecteditemList = this.itemList;
  onKey(value) {
    this.selecteditemList = this.search(value);
  }
  search(value: string) {
    let filter = value.toLowerCase();
    return this.itemList.filter((option) =>
      option.toLowerCase().startsWith(filter)
    );
  }
  exerciselength11(index) {
    let acc = 0;
    for (let i = 0; i < index; i++) {
      acc += this.exercisesequencelistsearch[i].length;
    }
    return acc;
  }
  getdatanew(value) {
    this.selecteditem.push(value);
    console.log("asdsaddsadasdA" + value);
    $("#inputtools").val("");
    this.onKey("");
  }
  addOpt(event: MatChipInputEvent, index: number): void {
    const input = event.input;
    const value = event.value;
    console.log("dsdsad" + index);
    console.log("input" + input);
    console.log("value" + value);
    console.log("valuenew" + value.trim());
    console.log("valuenew" + this.typesOptionsArray);
    // Add our fruit
    if (this.typesOptionsArray.length > 19) {
      Swal.fire("Begrenzen Sie 20 Tags", "", "error");
    } else {
      console.log("ffffffffffff" + JSON.stringify(this.searchlist));
      if ((value || "").trim()) {
        this.typesOptionsArray.push(value.trim());
        this.setsearchlist();
        this.exercise_controls.setValue(" ");
        $("#datanew").trigger("click");
      }
      // Reset the input value
      if (input) {
        input.value = "";
      }
    }
  }
  drop(event: CdkDragDrop<any[]>) {
    if (this.exercisesequencelist.length > 19) {
      Swal.fire(
        "Maximal 20 Übungen zur Übungsabläufe hinzugefügt added",
        "",
        "error"
      );
    } else {
      // console.log("sasdaasd"+JSON.stringify(event.previousContainer)+"    ,     "+JSON.stringify(event.container));
      if (event.previousContainer === event.container) {
        moveItemInArray(
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
      } else {
        copyArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
        // transferArrayItem(event.previousContainer.data,
        //   event.container.data,
        //   event.previousIndex,
        //   event.currentIndex);
      }
      // setTimeout(() => {
      //   $('.openinformationclass').html('<i class="fa fa-eye"></i>');
      // }, 500);
    }
  }
  setsearchlist() {
    this.exercisesequencelistsearch = [];
    if (this.typesOptionsArray.length > 0) {
      for (let i = 0; i < this.searchlist.length; i++) {
        console.log("ffffffffffff" + this.searchlist[i]);
        this.exercisesequencelistsearch.push({
          id: this.searchlist[i].id,
          value: this.searchlist[i].name,
          img: this.searchlist[i].img,
          diff: this.searchlist[i].diff,
        });
      }
    }
  }

  removeOpt(opt: string, index: number): void {
    console.log("removeOpt");
    const optIndex = this.typesOptionsArray.indexOf(opt);
    if (optIndex >= 0) {
      this.typesOptionsArray.splice(optIndex, 1);
    }
    this.exercise_controls.setValue("");
    this.setsearchlist();
  }

  telInputObject(obj) {
    console.log(obj);
    obj.setCountry("de");
  }



  getcurrentUser(openid?, currentData?) {
    const that = this;

    // console.log("call api")
    this.userService.getEditexercise(openid).subscribe(function (data1) {
      setData(data1);
    });

    function setData(data1) {
      console.log("dddddddddddd" + JSON.stringify(data1));
      console.log("dddddddddddd" + data1.element.exercise_category);
      that.choosecategory11(data1.element.exercise_category);

      that.calcount11("commentcount", "comment");
      let commentlength: any = 500 - data1.element.comment.length;
      $("#commentcount").html(commentlength);
      let commentlength1: any = 500 - data1.element.comment1.length;
      $("#comment1count").html(commentlength1);
      let exercise_name: any = 35 - data1.element.exercise_name.length;
      $("#exercise_namecount").html(exercise_name);
      let exercise_name1: any = 35 - data1.element.exercise_name1.length;
      $("#exercise_name1count").html(exercise_name1);
      let tags: any = 20 - data1.element.tags.length;
      $("#manual_tagscount").html(tags);

      that.personalInfoFormGroupadd.patchValue({
        exercise_name: data1.element.exercise_name,
        exercise_name1: data1.element.exercise_name1,
        difficully_level: data1.element.difficully_level,
        tools: data1.element.tools,
        comment: data1.element.comment,
        comment1: data1.element.comment1,
      });
      that.oldtypesOptionsArray = data1.element.tags;
      console.log("dddddddddddd" + that.oldtypesOptionsArray);
      $("#logopreview0").html(
        "<img src='" + data1.image1 + "' style='width: 100%;height: 300px'>"
      );
      $("#logopreview1").html(
        "<video  style='width: 100%;height: 300px' controls><source  src='" +
          data1.image2 +
          "' type='video/mp4'></video>"
      );
      $("#logopreview2").html(
        "<video  style='width: 100%;height: 300px' controls><source  src='" +
          data1.image3 +
          "' type='video/mp4'></video>"
      );
      $("#loaderouterid").css("display", "none");
    }

    // this.choosecategory();
    // $('#loaderouterid').css("display","none");
  }

  openinformation(id, index,tags) {
   
    $("#loaderouterid").css("display", "block");
    this.getcurrentUser(id);
    
  }
  choosecategory11(value) {
    console.log("dddddddddddd" + value);
    this.personalInfoFormGroupadd.patchValue({
      exercise_categoryedit: value,
    });

    $("#exercise_categoryedit").val(value);
    if (value == "Therapieedit") {
      $("#Therapieedit").css("background-color", "gray");
      $("#Präventionedit").css("background-color", "white");
    } else {
      $("#Präventionedit").css("background-color", "gray");
      $("#Therapieedit").css("background-color", "white");
    }
  }

  choosecategory(value) {
    this.personalInfoFormGroup0.patchValue({
      exercise_category: value,
    });

    $("#exercise_category").val(value);
    if (value == "Therapie") {
      $('#Therapie').css('background-image','linear-gradient(#555a5a, #555a5a) ');
      $("#Prävention").css("background-color", "white");
    } else {
      $('#Prävention').css('background-image','linear-gradient(#555a5a, #555a5a) ');
      $("#Therapie").css("background-color", "white");
    }
  }
  ngOnInit() {
    this.loginRole = this.loginRole;
    this.routeParams = this.activatedRoute.snapshot.routeConfig.path;

    this.personalInfoFormGroup = this._formBuilder.group({
      exercisesequence_name: ["", Validators.required],
      
    });

    this.personalInfoFormGroupadd = this._formBuilder.group({
      exercise_name: ["", Validators.required],
      exercise_name1: ["", Validators.required],
      comment1: [""],
      comment: ["", Validators.required],
      manual_tags: ["", Validators.required],
      tools: ["", Validators.required],
      difficully_level: ["", Validators.required],

      upload_file: ["", Validators.required],
      explain: ["", Validators.required],
      implementation: ["", Validators.required],
      exercise_categoryedit: ["", Validators.required],
    });

    this.personalInfoFormGroup0 = this._formBuilder.group({
      exercise_category: ["", Validators.required],
    });

    this.exerciseoption = this.exercise_controls.valueChanges.pipe(
      startWith(""),
      map((value) => (typeof value == "string" ? value : value.name)),
      map((name) => this._filterexercisedata(name))
    );

    this.typesOptionsArray.push();
  }
  getexercisenew() {
    

    if (this.typesOptionsArray.length > 19) {
      Swal.fire("Begrenzen Sie 20 Tags", "", "error");
    } else {
      console.log("ffffffffffff" + JSON.stringify(this.searchlist));
      if ((this.exercise_controls.value.name || "").trim()) {
        this.typesOptionsArray.push(this.exercise_controls.value.name);
        this.setsearchlist();
        this.exercise_controls.setValue(" ");
        $("#datanew").trigger("click");
      }
      // Reset the input value
     
    }


  }
  getexercise(id, name, img, diff) {
    if (this.exercisesequencelist.length > 19) {
      Swal.fire(
        "Maximal 20 Übungen zur Übungsabläufe hinzugefügt added",
        "",
        "error"
      );
    } else {
      let l = "b";
      console.log("exercisesequencelist" + this.exercisesequencelist.length);
      this.exercisesequencelist.push({
        id: id,
        value: name,
        img: img,
        diff: diff,
      });
    }
    // setTimeout(() => {
    //   $('.openinformationclass').html('<i class="fa fa-eye"></i>');
    // }, 500);
  }

  loopingexerciselist(userData: string | any[]) {
    let exerciselist = [];
    console.log("kundenew" + JSON.stringify(userData));
    for (let i = 0; i < userData.length; i++) {
      // console.log(userData[i].roles.includes('b2b') , userData[i].roles)
      console.log(
        "kundenew" + JSON.stringify(userData[i].element.exercise_name1)
      );
      exerciselist.push({
        name: userData[i].element.exercise_name1,
        id: userData[i].element._id,
        tags: userData[i].element.tags,
        img: userData[i].img,
        diff: userData[i].element.difficully_level,
      });
    }

    console.log("broooker!!!!!!!!!!!!!!!!!!!!!" + JSON.stringify(exerciselist));

    return exerciselist;
  }

  getallexerciselist() {
    let exercise_category = this.personalInfoForm0.exercise_category.value;
    console.log("sddd" + exercise_category);
    this.userService
      .getsearchexerciseinsequence(exercise_category)
      .pipe(first())
      .subscribe((data: any) => {
        this.exercisedata = this.loopingexerciselist(data);

        console.log("exercisedata" + this.exercisedata);
      });
  }

  _filterexercisedata(value: string): string[] {
    let searchlist = this.exercisedata;
    let searchlistnewlist = [];
    console.log("kundenew11" + JSON.stringify(searchlist));
    for (let i = 0; i < this.typesOptionsArray.length; i++) {
      const filterValue = this.typesOptionsArray[i].toLowerCase();
      
      searchlist = searchlist.filter(function (itm) {
        return (
          JSON.stringify(itm.name).toLowerCase().indexOf(filterValue) > -1 ||
          JSON.stringify(itm.tags).toLowerCase().indexOf(filterValue) > -1
        );
      });
    }

    const filterValue11 = value.toLowerCase();
    searchlistnewlist = this.exercisedata.filter(function (itm) {
      return (
        JSON.stringify(itm.name).toLowerCase().indexOf(filterValue11) > -1 ||
        JSON.stringify(itm.tags).toLowerCase().indexOf(filterValue11) > -1
      );
    });
    searchlist = searchlist.filter(function (itm) {
      return (
        JSON.stringify(itm.name).toLowerCase().indexOf(filterValue11) > -1 ||
        JSON.stringify(itm.tags).toLowerCase().indexOf(filterValue11) > -1
      );
    });

    console.log("kundenew11" + JSON.stringify(searchlist));
    this.searchlist = searchlist;
    return searchlistnewlist;
  }
  removesequence(i) {
    console.log(i);
    this.exercisesequencelist.splice(i, 1);
  }
  navigateWithexcersicename() {
    this.router.navigate(["/exercise_name"], {
      queryParams: { name: "prevention_course" },
    });
    // this.queryID = this.selectedUser.customerno;
    // this.ngOnInit()
  }
  add_exercise() {
    this.router.navigate(["/add-exercise-sequence"]);
    // this.queryID = this.selectedUser.customerno;
    // this.ngOnInit()
  }
  navigateWithexcersicename1() {
    this.router.navigate(["/exercise_name"], {
      queryParams: { name: "therapeuts_praxen" },
    });
  }
  patchkundeValue() {
    this.kunde_id = this.Kundecontrol.value.id;
    this.Kundecontrol.setValue(this.Kundecontrol.value.value);

    console.log("kundenew" + this.kunde_id);
  }

  public imagePath;
  public pdfPath;
  imgURL: any = [];
  public message: string;
  imgshow = "";

  get personalInfoForm() {
    return this.personalInfoFormGroup.controls;
  }

  get personalInfoForm0() {
    return this.personalInfoFormGroup0.controls;
  }
 

  calcount(id, type) {
    let exercisesequence_namecount: any;
    if (type == "exercisesequence_name") {
      let exercisesequence_name =
        this.personalInfoForm.exercisesequence_name.value.length;

      exercisesequence_namecount = 35 - exercisesequence_name;
    }
    $("#" + id).html(exercisesequence_namecount);
  }

  calcount11(id, type) {
    let commentcount: any;
    if (type == "comment") {
      let comment = this.personalInfoFormGroupadd.controls.comment.value.length;

      commentcount = 500 - comment;
    }
    if (type == "comment1") {
      let comment =
        this.personalInfoFormGroupadd.controls.comment1.value.length;

      commentcount = 500 - comment;
    }
    if (type == "exercise_name") {
      let comment =
        this.personalInfoFormGroupadd.controls.exercise_name.value.length;

      commentcount = 35 - comment;
    }
    if (type == "exercise_name1") {
      let comment =
        this.personalInfoFormGroupadd.controls.exercise_name1.value.length;

      commentcount = 35 - comment;
    }
    $("#" + id).html(commentcount);
  }

  save_exercise_sequence() {
    $("#loaderouterid").css("display", "block");

    let companycodenew = "";

    companycodenew = "60-co-";

    this.userService
      .getLastdocumentnew()
      .pipe(first())
      .subscribe((data1) => {
        console.log("ticket_no" + data1);
        // customerNo.substring(5,customerNo.length);
        this.ticket_no = companycodenew + "" + new Date().valueOf();

        let dataarray = [];

        for (let i = 0; i < this.exercisesequencelist.length; i++) {
          // let datannn={'exercise_id1':this.exercisesequencelist[i].id}
          dataarray.push(this.exercisesequencelist[i].id);
        }

        let datanew = {
          exercisesequence_name:
            this.personalInfoForm.exercisesequence_name.value,
          exercisesequence_category:
            this.personalInfoForm0.exercise_category.value,
          created_by: this.loginid,
          exercise_id: dataarray,
        };

        this.userService
          .registerexercisesequence(datanew)
          .pipe(first())
          .subscribe(
            (data) => {
              Swal.fire("Übungsabläufe erfolgreich hinzugefügt.", "", "success");
              this.ticket_no = "";

              $("#loaderouterid").css("display", "none");
              setTimeout(() => {
                Swal.close();
                this.router.navigate(["./exercise-sequence-list"]);
              }, 1000);
            },
            (error) => {
              Swal.fire("Error" + error["error"] + "", "", "error");

              $("#loaderouterid").css("display", "none");
              setTimeout(() => {
                Swal.close();
                this.add_exercise();
              }, 1000);
              // $("html, body").animate(
              //   { scrollTop: "0" }, 3000);
            }
          );
      });
  }
}

