import { Component, OnInit } from '@angular/core';
import {  ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../user.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  token=this.UserService.getdecode(sessionStorage.getItem('Token'))
  routeParams
  brokerno;
  search;
  currentActiveRole = sessionStorage.getItem('currentActiveRole')
  constructor( private UserService:UserService,private router:Router,private activateRoutes:ActivatedRoute) { 

if(this.currentActiveRole!='customer')
{
  this.gotodashboard();
}



  }
  gotodashboard()
  {
    console.log(this.currentActiveRole);
   if(this.currentActiveRole=='b2b')
   {
    this.router.navigate(['./b2b-dashboard']);
   }
   if(this.currentActiveRole=='customer')
   {
    this.router.navigate(['./dashboard']);
   }
  
  }
  ngOnInit() {
    this.routeParams = this.activateRoutes.snapshot.routeConfig.path;
    if(this.token!=null)
    {
    this.UserService.checkbrokerassign('').subscribe((result:any)=>{
      if(result.length>0)
      {
    this.brokerno=result.brokerno;
      }
    })
    }
    

  }

  Logout()
  {
    console.log("dddd");
    sessionStorage.removeItem("Token");
    this.router.navigate(["./"]);
  }

  onSubmit1()
  {
this.search="Your Therapist"
if(this.search!='')
{
  setTimeout(() => {
    this.router.navigate(["./therapist-list"],{queryParams:{searchvalue:this.search}});
  }, 100);
}
  }

}
