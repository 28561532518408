import { Component, OnInit } from "@angular/core";
import { UserService } from "../user.service";
import jsPDF from "jspdf";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-therapy-plan",
  templateUrl: "./therapy-plan.component.html",
  styleUrls: ["./therapy-plan.component.css"],
})
export class TherapyPlanComponent implements OnInit {
  token = this.UserService.getdecode(sessionStorage.getItem("Token"));
  routeParams;
  therapyplan=[];
  api_url;
  exerciselist=[];
  constructor(
    private UserService: UserService,
    private router: Router,
    private activateRoutes: ActivatedRoute
  ) {}
  newlength:any='';
  GetFormattedDate(date: any) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    var today = new Date(date);
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth()+1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();


    

    return dd + "." + mm + "." + yyyy;
  }
  downloadpdf(id,name,start_date,end_date,length)
  {
console.log("start_date"+start_date)
start_date=this.GetFormattedDate(start_date)
end_date=this.GetFormattedDate(end_date)
    $("#messagesuccess").css("display", "block");
    $("#MyDIv1").css("display", "block");
    $("#mainid").css("overflow", "hidden");
    
    $('#namehead').html(name);
    $('#start_date').html(start_date);
    $('#end_date').html(end_date);
    $('#length').html(length+" Übungen");
    this.newlength=(length-2)%3;
    let time=length*3000;
// this.Showprogressbar(time);


    let data = { starttreatment_id: id };
    this.UserService.getexercisebytherapy(data).subscribe((result: any) => {
      this.exerciselist = result.data;






//       setTimeout(() => {
// this.generatePDF();
// }, 2000);
      try {
setTimeout(() => {
  let data = document.getElementById("MyDIv1");

console.log("data"+data)

// return new Promise(resolve => {
  let pdf = new jsPDF("portrait", "pt", "a4");
  var width = pdf.internal.pageSize.width;
  console.log("dddddddddddddddddddddddwidth"+width)
  pdf.html(data, {
    
    html2canvas: {
      width: width,
      
    },
    callback:  (pdf) => {
      pdf.save("membershipcoursepdf.pdf");
      $("#MyDIv1").css("display", "none");
      $("#mainid").css("overflow", "unset");
      $("#messagesuccess").css("display", "none");
      // setTimeout(()=>{
      //   resolve('resolved');
      //   },8000)
    },
  });

     console.log('wait time for loading image')


    //  pdf.save('table.pdf');
 

  //  });


 
  
}, 1000);

      }
      catch(e) {
        console.log(e); 
      }
  

  });
    
  }
 
  Navigatepage(questiondone, questionindex, id,status) {
    if(status=='0')
    {
    if (questiondone == "0" || questionindex > 0) {
      this.router.navigate(["/questionlist"],  { queryParams: { id: id,questionindex:questionindex } } );
    } else {
      this.router.navigate(["/toolslist"],  { queryParams: { id: id,questionindex:questionindex } } );
    }
  }
  }

  getphasedata(exercisearray) {
    console.log(exercisearray);
    var NewK: any;
    var kInt = 1;
    var kTimeAry: any = [];
    var excerciseCount = -1;
    var countToIncerement = 0;
    exercisearray.forEach((element) => {
      let phase = element.phase;

      excerciseCount += 1;
      countToIncerement += 1;

      if (kInt != phase) {
        let temp = excerciseCount;
        excerciseCount = 0;
        let dates = NewK.dates;
        kInt = phase;
        let start_date = this.GetFormattedDate(dates["start_date"]);
        let end_date = this.GetFormattedDate(dates["end_date"]);

        let toAppendString = {
          start_date: start_date,
          end_date: end_date,
          temp: temp,
        };
        kTimeAry.push(toAppendString);
      }
      if (countToIncerement == exercisearray.length) {

        console.log("hhhhhhhhhhhh"+NewK.phase)
if(NewK.phase==phase)
{
  let temp = excerciseCount + 1;
        excerciseCount = 0;
        let dates = NewK.dates;
        kInt = phase;
        let start_date = this.GetFormattedDate(dates["start_date"]);
        let end_date = this.GetFormattedDate(dates["end_date"]);

        let toAppendString = {
          start_date: start_date,
          end_date: end_date,
          temp: temp,
        };
        kTimeAry.push(toAppendString);

}
else{
  let temp = excerciseCount + 1;
        excerciseCount = 0;
        let dates = element.dates;
        kInt = phase;
        let start_date = this.GetFormattedDate(dates["start_date"]);
        let end_date = this.GetFormattedDate(dates["end_date"]);

        let toAppendString = {
          start_date: start_date,
          end_date: end_date,
          temp: temp,
        };
        kTimeAry.push(toAppendString);
}      



      }
      NewK = element;
    });
    console.log(kTimeAry);
    return kTimeAry;
  }

  ngOnInit() {
    console.log("this.therapyplan");
    this.api_url=environment.API_URL;
    $("#loaderouterid").css("display", "block");
    this.routeParams = this.activateRoutes.snapshot.routeConfig.path;
    if (!sessionStorage.getItem("Token")) {
      this.router.navigate(["./"]);
    }
    // console.log(this.token.title)
    this.UserService.getTherapy("").subscribe((result: any) => {

      console.log("result is here");
      console.log(result);
      
for(let k=0;k<result.data.length;k++)
{
let resultnew=this.getphasedata(result.data[k].exercise_id);
result.data[k].exercise_idnew=resultnew;
}

this.therapyplan = result.data;

console.log("therepy plans ngoninit");
console.log(result.data);

      $("#loaderouterid").css("display", "none");

      // console.log(this.therapyplan);
    });
  }

  Logout() {
    console.log("dddd");
    sessionStorage.removeItem("Token");
    this.router.navigate(["./"]);
  }
}
