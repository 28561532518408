import { Component, OnInit } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";
import { UserService } from "../user.service";
import { FormBuilder } from "@angular/forms";
@Component({
  selector: "app-questionlist",
  templateUrl: "./questionlist.component.html",
  styleUrls: ["./questionlist.component.css"],
})
export class QuestionlistComponent implements OnInit {
  headername;
  id;
  questionindex;
  questionlist;
  optionansarray = [];
  optionansarray11:any = [];
  api_url;
  toolslist;
  fiorettourl;
  firstFormGroup:FormGroup;
  exercise_id = [];
  constructor(
    private UserService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private formbuilder:FormBuilder
  ) {
    this.route.queryParams.subscribe((param) => {
      this.id = param["id"];
      this.questionindex = param["questionindex"];
    });
  }

  selections(ans, index, question) {
    this.optionansarray[index] = { question: question, ans: ans };
this.optionansarray11.push("1")
    if (index == this.questionlist.length - 1) {
      // let jsonarray=
      this.UserService.savequestion({
        questiondetails: JSON.stringify(this.optionansarray),
        starttreatment_id: this.id,
        exercise_id: this.exercise_id,
        questionindex: this.questionindex,
      }).subscribe((result: any) => {
        if (result.status == "200") {
          if (this.questionindex == "0") {
this.firstFormGroup.patchValue({
  companyName:"test"
})

$('#nextbutton').trigger("click");

            // this.router.navigate(["/toolslist"], {
            //   queryParams: { id: this.id, questionindex: this.questionindex },
            // });
          } else {
            this.router.navigate(["/exercise_list"], {
              queryParams: { id: this.id, questionindex: this.questionindex },
            });
          }
        } else if (result.status == "500") {
          Swal.fire(result.msg, "", "error");
          setTimeout(() => {
            this.router.navigate(["/therapy-plan"])
          }, 1000);
        } else {
          Swal.fire(result.msg, "", "error");
          setTimeout(() => {
            this.router.navigate(["/therapy-plan"])
          }, 1000);
        }
      });
    }



  }
  counter(i: number) {
    return new Array(i);
}
  ngOnInit() {
    this.fiorettourl = environment.fiorettourl;
    this.api_url = environment.API_URL;
    let data = { starttreatment_id: this.id };
    this.UserService.gettoolsbytherapy(data).subscribe((result: any) => {
      $("#loaderouterid").css("display", "none");
      if(result.data.length>0)
      {
      this.toolslist = result.data;
      }
      else{
        this.router.navigate(["/therapy-plan"]);
      }
      console.log(result);
      
    });

this.firstFormGroup=this.formbuilder.group({
  companyName:["",[Validators.required]]
})


    this.headername = "Question";
    this.UserService.getquestion({
      questionindex: this.questionindex,
    }).subscribe((result: any) => {
      if (result.status == "200") {
        if (result.data.length > 0) {
          this.questionlist = result.data;

          this.questionlist.forEach((element) => {
            this.optionansarray.push([]);
          });

          console.log(result);
        } else {
        }
      } else {
        Swal.fire(result.msg, "", "error");
        setTimeout(() => {
          Swal.close();
        }, 1000);
      }
    });
  }

  navigateexercise_list() {
    this.router.navigate(["/exercise_list"],  { queryParams: { id: this.id,questionindex:this.questionindex } });
  }

}
