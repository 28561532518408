

import { AfterViewInit, Component, OnInit ,AfterContentInit, Input} from "@angular/core";
import { UserService } from "../user.service";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
import { OwlOptions } from "ngx-owl-carousel-o";
import { DomSanitizer } from '@angular/platform-browser';
import jsPDF from "jspdf";
import * as $ from "jquery"
// import  html2canvas from 'html2canvas';
// import  jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import Swal from "sweetalert2";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { flatMap } from "rxjs/operators";
@Component({
  selector: 'app-prevention-course',
  templateUrl: './prevention-course.component.html',
  styleUrls: ['./prevention-course.component.css']
})
export class PreventionCourseComponent implements OnInit,AfterViewInit {

  @Input() progress: number;
  @Input() total: number;
  color: string;

  customOptions: OwlOptions = {
    items: 5,
    loop: false,
    navText: [
      '<i class="fa fa-chevron-left"></i>',
      '<i class="fa fa-chevron-right"></i>',
    ],
    margin: 10,
  
    // nav:true,
  
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  };

  newlength:any='';
  phasedata=[];
  token = this.UserService.getdecode(sessionStorage.getItem("Token"));
  routeParams;
  therapyplan=[];
  exerciselist=[];
  api_url
  todaydate=new Date();
  constructor(
    private UserService: UserService,
    private router: Router,
    private activateRoutes: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private httpSvc: HttpClient
  ) {}
  quokkaAsyncData;
  GetFormattedDate(date: any) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    var today = new Date(date);
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth()+1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    return dd + "." + mm + "." + yyyy;
  }

 
  Navigatepage(id) {
    this.router.navigate(["/preventioncoursetoolslist"],  { queryParams: { id: id } } );
      // this.router.navigate(["/prevention-course-exercise-list"],  { queryParams: { id: id} } );
  
  }

  calculateamount(pricenew,discount)
  {
    let price=pricenew.replace(",",".");
  let netprice= (
    (price / 100) *
    (100 - discount)
  ).toFixed(2).replace(".",",");;
  let amount=((price/100)
    * (100-discount)).toFixed(2)
    return parseFloat(amount).toFixed(2).replace(".",",");
  }



  checkphasecomplete(exercisearray,prevention_course_id,exercise_idphasewise) {

  let phase=this.getphasedata(exercise_idphasewise);
if(phase.length>=8)
{


let phasecount=this.geteightphasedata(exercise_idphasewise);


   let completecount=phasecount;
if(prevention_course_id.length>0)
{
let exerciseidwatch=prevention_course_id[0].exercise_id
let i=1;
    exercisearray.forEach(exercisearray11 => {

let check= exerciseidwatch.filter(
  function(exerciseidwatch){return exerciseidwatch.exerciseid == exercisearray11,exerciseidwatch.index == i}
);

if(check.length>0)
{
  completecount--;
}
i++;
   });
  }
  return completecount;
}
else{
  return 80;
}
  }



  geteightphasedata(exercisearray) {
    var kInt = 1;
    var kTimeAry: any = 0;
    var excerciseCount = -1;
    var countToIncerement = 0;
    exercisearray.forEach((element) => {
      let phase = element.phase;
if(phase<=8)
{
      excerciseCount += 1;
      countToIncerement += 1;

      if (kInt != phase) {
        let temp:any = excerciseCount;
        excerciseCount = 0;
      
        kTimeAry=parseInt(kTimeAry)+parseInt(temp);
      }
      if (countToIncerement == exercisearray.length) {
        let temp :any = excerciseCount + 1;
        excerciseCount = 0;
        kTimeAry=parseInt(kTimeAry)+parseInt(temp);
      }
    }
    });
    return kTimeAry+1;
  }





  getphasedata(exercisearray) {
    var NewK: any;
    var kInt = 1;
    var kTimeAry: any = [];
    var excerciseCount = -1;
    var countToIncerement = 0;
    exercisearray.forEach((element) => {
      let phase = element.phase;

      excerciseCount += 1;
      countToIncerement += 1;

      if (kInt != phase) {
        let temp = excerciseCount;
        excerciseCount = 0;
        let dates = NewK.dates;
        kInt = phase;
        let start_date = this.GetFormattedDate(dates["start_date"]);
        let end_date = this.GetFormattedDate(dates["end_date"]);

        let toAppendString = {
          start_date: start_date,
          end_date: end_date,
          temp: temp,
        };
        kTimeAry.push(toAppendString);
      }
      if (countToIncerement == exercisearray.length) {
        let temp = excerciseCount + 1;
        excerciseCount = 0;
        let dates = NewK.dates;
        kInt = phase;
        let start_date = this.GetFormattedDate(dates["start_date"]);
        let end_date = this.GetFormattedDate(dates["end_date"]);

        let toAppendString = {
          start_date: start_date,
          end_date: end_date,
          temp: temp,
        };
        kTimeAry.push(toAppendString);
      }
      NewK = element;
    });
    // console.log(kTimeAry);
    return kTimeAry;
  }

  downloadcertificate1()
  {
    Swal.fire("Bitte schließe 8 Phasen ab. Dann kannst du das Zertifikat herunterladen.","","error");
  }

toDataURL = async (url) => {
    console.log("Downloading image...");
    var res = await fetch(url);
    var blob = await res.blob();

    const result = await new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })

    return result
  };

  sanitize(url: string) {  
    return this.sanitizer.bypassSecurityTrustUrl(url);  
  }  
  
  generatePDF() {
    var data = document.getElementById('MyDIv1');
    html2canvas(data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4');
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('newPDF.pdf');
    });
  }
  downloadpdf(id,name,start_date,end_date,length)
  {
    console.log("start_date"+start_date)
    $("#messagesuccess").css("display", "block");
    $("#MyDIv1").css("display", "block");
    $("#mainid").css("overflow", "hidden");
    
    $('#namehead').html(name);
    $('#start_date').html(start_date);
    $('#end_date').html(end_date);
    $('#length').html(length+" Übungen");
    this.newlength=(length-2)%3;
    let time=length*3000;
// this.Showprogressbar(time);


if(!this.progress) {
  this.progress = 0;
}
//if we don't have a total aka no requirement, it's 100%.
if(this.total === 0) {
  this.total = this.progress;
} else if(!this.total) {
  this.total = 100;
}
//if the progress is greater than the total, it's also 100%.
if(this.progress > this.total) {
  this.progress = 100;
  this.total = 100;
}
this.progress = (this.progress / this.total) * 100;
if(this.progress < 55) {
  this.color = 'red';
} else if(this.progress < 75) {
  this.color= 'yellow';
} else {
 this.color = 'green';
}




    let data = { purchasepreventioncourse_id: id };
    this.UserService.getexercisebypreventioncourse(data).subscribe((result: any) => {
      this.exerciselist = result.data;






//       setTimeout(() => {
// this.generatePDF();
// }, 2000);
      try {
setTimeout(() => {
  let data = document.getElementById("MyDIv1");

console.log("data"+data)

return new Promise(resolve => {
  let pdf = new jsPDF("portrait", "pt", "a4");
  var width = pdf.internal.pageSize.width;
  console.log("dddddddddddddddddddddddwidth"+width)
  pdf.html(data, {
    
    html2canvas: {
      width: width,
      
    },
    callback: function (pdf) {
      pdf.save("preventioncoursepdf.pdf");
      $("#MyDIv1").css("display", "none");
      $("#mainid").css("overflow", "unset");
      $("#messagesuccess").css("display", "none");
      setTimeout(()=>{
        resolve('resolved');
        },8000)
    },
  });

     console.log('wait time for loading image')


    //  pdf.save('table.pdf');
 

   });


 
  
}, 8000);

      }
      catch(e) {
        console.log(e); 
      }
  

  });
    
  }


  downloadcertificate(preventioncoursename,i)
  {
    $("#loaderouterid").css("display", "block");
    this.UserService.editUserwithoutlogin(this.token.id).subscribe((resultnew)=>{


$('#patient_name').html(this.token.firstname+" "+this.token.lastname)
$('#patient_name1').html(this.token.firstname+" "+this.token.lastname)
$('#patient_zip').html(resultnew.plz+" "+resultnew.city);
$('#address1').html(resultnew.strassa+" "+resultnew.strno);

$('#preventioncoursename').html(preventioncoursename);
console.log("ddddddddddddddddddddddd"+this.therapyplan[i].exercise_idphasewise)
var NewK: any;
var kInt = 1;
var kTimeAry: any = [];
var excerciseCount = -1;
var countToIncerement = 0;
this.therapyplan[i].exercise_idphasewise.forEach((element) => {
  let phase = element.phase;

  excerciseCount += 1;
  countToIncerement += 1;

  if (kInt != phase) {
    let temp = excerciseCount;
    excerciseCount = 0;
    let dates = NewK.dates;
    kInt = phase;
    let start_date = this.GetFormattedDate(dates["start_date"]);
    let end_date = this.GetFormattedDate(dates["end_date"]);

    let toAppendString = {
      start_date: start_date,
      end_date: end_date,
      temp: temp,
    };
    kTimeAry.push(toAppendString);
  }
  if (countToIncerement == this.therapyplan[i].exercise_idphasewise.length) {
    let temp = excerciseCount + 1;
    excerciseCount = 0;
    let dates = NewK.dates;
    kInt = phase;
    let start_date = this.GetFormattedDate(dates["start_date"]);
    let end_date = this.GetFormattedDate(dates["end_date"]);

    let toAppendString = {
      start_date: start_date,
      end_date: end_date,
      temp: temp,
    };
    kTimeAry.push(toAppendString);
  }
  NewK = element;
});
console.log("ddddddddddddddddddddddd"+kTimeAry)
this.phasedata=kTimeAry;

if(this.phasedata.length>0)
{
  $("#MyDIv").css("display", "block");

  setTimeout(() => {
    let data = document.getElementById("MyDIv");

    let pdf = new jsPDF("portrait", "pt", "a4");
    var width = pdf.internal.pageSize.width;
    console.log("dddddddddddddddddddddddwidth"+width)
    pdf.html(data, {
      html2canvas: {
        width: width,
      },
      callback: function (pdf) {
        pdf.save("certificate.pdf");
        $("#MyDIv").css("display", "none");
        $("#loaderouterid").css("display", "none");

      },
    });
  }, 1000);
   
  }
  });
  }
  downloadDataAsBase64(url: string): Observable<string> {
    return this.httpSvc.get(url, { responseType: 'blob' }).pipe(
      flatMap(blob => {
        return this.blobToBase64(blob);
      })
    );
  }

  blobToBase64(blob: Blob): Observable<any> {
    const fileReader = new FileReader();
    const observable = new Observable(observer => {
      fileReader.onloadend = () => {
        observer.next(fileReader.result);
        observer.complete();
      };
    });
    fileReader.readAsDataURL(blob);
    return observable;
  }

ngAfterViewInit()
{ console.log("data11111111111111111")

  if(this.exerciselist.length>0)
  {
  let data = document.getElementById("MyDIv1");

  console.log("data"+JSON.stringify(data))
  
    let pdf = new jsPDF("portrait", "pt", "a4");
    var width = pdf.internal.pageSize.width;
    console.log("dddddddddddddddddddddddwidth"+width)
    pdf.html(data, {
      html2canvas: {
        width: width,
      },
      callback: function (pdf) {
        pdf.save("preventioncoursepdf.pdf");
        $("#MyDIv1").css("display", "none");
        // $("#mainid").css("overflow", "unset");
        $("#loaderouterid").css("display", "none");
  
      },
    });
  }
}

  ngOnInit() {



    // this.quokkaAsyncData = this.downloadDataAsBase64('https://pbs.twimg.com/media/DR15b9eWAAEn7eo.jpg');


console.log("quokkaAsyncData"+JSON.stringify(this.quokkaAsyncData));


    this.api_url=environment.API_URL;
    $("#loaderouterid").css("display", "block");
    this.routeParams = this.activateRoutes.snapshot.routeConfig.path;
    if (!sessionStorage.getItem("Token")) {
      this.router.navigate(["./"]);
    }
    // console.log(this.token.title)
    this.UserService.getpurchasepreventioncourse("").subscribe((result: any) => {
      
      if(result.data.length>0)
      {
        let m;  
let newarray=result.data;
if(newarray.length>0)
{
  
for(m=0;m<newarray.length;m++)
{
      
          let newdata=this.getphasedata(newarray[m].exercise_idphasewise);
          // console.log("newdata"+m+"ddddddd"+JSON.stringify(newarray[m].exercise_idphasewise));
          // newarray.exercise_idphasewise = newdata;
          newarray[m].exercise_idphasewise11=newdata;
          // console.log("newdata"+m+"ddddddd"+JSON.stringify(newarray[m].exercise_idphasewise));
      
}


if(m==result.data.length)
{
this.therapyplan = newarray;
$("#loaderouterid").css("display", "none");
}
}
else
{
  console.log("ddddddddddd");
  $("#loaderouterid").css("display", "none");
}
      }
      else
{
  console.log("ddddddddddd");
  $("#loaderouterid").css("display", "none");
}
      // this.therapyplan = result.data;
      // console.log("therapyplan"+JSON.stringify(this.therapyplan));
    });
  }


  Showprogressbar(time:number)  
  {  
    this.Showprogress(time); this.timer();  
  }  

 Showprogress(time:number)  
 {  
   console.log(time)
 this.progress = 0;  
   setInterval(() => this.ProgressBar(), 2000)  
   }  
  
 ProgressBar() {  
   
   if (this.progress == 0) {  
     this.progress = this.progress + 1  
   } else {  
     this.progress = this.progress + 1;  
    //  if (this.progress = this.progress + 30) {  
       this.progress == this.progress + 1;  
       if (this.progress >= 100) {  
         this.progress = 100;  
      //  }  
     }  
   }  
 }  
  timer()  
  {  
    var timeleft = 111;  
    var downloadTimer = setInterval(function () {  
      if (timeleft <= 0) {  
        clearInterval(downloadTimer); this.progress = 0;  
  
      } else {  
        document.getElementById("countdown").innerHTML = timeleft + "";  
      }  
      timeleft -= 1;      
    }, 1000);  
    setInterval(() => clearInterval(downloadTimer), 111000)  
      
  }  
 

imageurl(document)
{
  console.log("fffffffff"+document);
  this.UserService.viewdocument(document).subscribe((result: any) => {
  });
  // {{api_url}}document/downloaddocument/{{data1.document[0]}}
}


  Logout() {
    console.log("dddd");
    sessionStorage.removeItem("Token");
    this.router.navigate(["./"]);
  }
}
