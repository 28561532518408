
import { Component, OnInit } from "@angular/core";
import { UserService } from "../user.service";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
import { Color } from "ng2-charts";
import { ChartOptions } from 'chart.js';
// import { ChartsModule } from "ng2-charts";
import * as ChartPiece from 'chart.piecelabel.js'

// import 'chart.piecelabel.js';
@Component({
  selector: 'app-my-progress',
  templateUrl: './my-progress.component.html',
  styleUrls: ['./my-progress.component.css']
})
export class MyProgressComponent implements OnInit {

  token = this.UserService.getdecode(sessionStorage.getItem("Token"));
  routeParams;
  therapyplan=[];
  api_url
  preventioncourse=[];
  constructor(
    private UserService: UserService,
    private router: Router,
    private activateRoutes: ActivatedRoute
  ) {}

 
percentage:number=0;

pieChartLabels:string[] = ['Abgeschlossen','Verbleibend'];
pieChartData:any[] = [];
pieChartData1:any[] = [];
colors: Color[] = [
  {
    backgroundColor: [
      'green',
      '#184397'
    ]
  }
];


pieChartType:string = 'pie';


public chartOptions: any = {
  pieceLabel: {
  render: function (args) {
    const label = args.label,
      value = args.value;
    return label + ': ' + value;
  }
  }
}
pieChartOptions: ChartOptions = {
  responsive: true,
  legend: {
    position: 'bottom',
    labels: {
      fontColor: '#fff'
    },
  },
  
  tooltips: {
    enabled: true,
    mode: 'single',
    callbacks: {
      datalabel: function (tooltipItems, data) {
        console.log(data)
        console.log("dddddddd");
        return ""+data.datasets[0].data[tooltipItems.index] + ' %';
      }
      
    }
  },
   plugins: {
      datalabels: {
          formatter: (value, ctx) => {
              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
            
              dataArr.map(data => {
                  sum += data;
              });
              let percentage = (value*100 / sum).toFixed(2)+" %";
              return percentage;
          },
          color: '#fff',
      },
      labels: {
        fontColor: '#fff'
      },
  }

};

calcompleteexercise(prevention_course_id)
{
  let complete=0;
  prevention_course_id.forEach(prevention_course_id11 => {
    complete+=prevention_course_id11.exercise_id.length;
});
return complete;
}

  GetFormattedDate(date: any) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    var today = new Date(date);
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = months[today.getMonth()]; //January is 0!
    var yyyy = today.getFullYear();

    return dd + " " + mm + " " + yyyy;
  }

 
  Navigatepage(id) {
    this.router.navigate(["/preventioncoursetoolslist"],  { queryParams: { id: id } } );
      // this.router.navigate(["/prevention-course-exercise-list"],  { queryParams: { id: id} } );
  
  }

  calculateamount(pricenew,discount)
  {
    let price=pricenew.replace(",",".");
  let netprice= (
    (price / 100) *
    (100 - discount)
  ).toFixed(2).replace(".",",");;
  let amount=((price/100)
    * (100-discount)).toFixed(2)
    return parseFloat(amount).toFixed(2).replace(".",",");
  }

  ngOnInit() {
  
    this.api_url=environment.API_URL;
    $("#loaderouterid").css("display", "block");
    this.routeParams = this.activateRoutes.snapshot.routeConfig.path;
    if (!sessionStorage.getItem("Token")) {
      this.router.navigate(["./"]);
    }
    // console.log(this.token.title)

    this.UserService.getTherapy("").subscribe((result: any) => {
      this.therapyplan = result.data;

let k=0;
      this.therapyplan.forEach(therapydata => {
        
        let rem=therapydata.exercise_id.length-therapydata.completeexercise;
        this.pieChartData[k] = [therapydata.completeexercise,rem]
        k++;
});


      $("#loaderouterid").css("display", "none");

      console.log(this.therapyplan);
    });


    this.UserService.getpurchasepreventioncourse("").subscribe((result: any) => {
      this.preventioncourse = result.data;
      let k1=0;
      this.preventioncourse.forEach(preventioncoursedata => {
        
        let rem=preventioncoursedata.exercise_idphasewise.length-this.calcompleteexercise(preventioncoursedata.prevention_course_id);
        this.pieChartData1[k1] = [this.calcompleteexercise(preventioncoursedata.prevention_course_id),rem]
        k1++;
});
      // $("#loaderouterid").css("display", "none");

      console.log(this.therapyplan);
    });
  }




imageurl(document)
{
  console.log("fffffffff"+document);
  this.UserService.viewdocument(document).subscribe((result: any) => {
  });
  // {{api_url}}document/downloaddocument/{{data1.document[0]}}
}


  Logout() {
    console.log("dddd");
    sessionStorage.removeItem("Token");
    this.router.navigate(["./"]);
  }
}
