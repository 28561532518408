import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTabChangeEvent } from '@angular/material';
import { UserService } from '../user.service';
import * as $ from "jquery"
@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.css']
})
export class CookiePolicyComponent implements OnInit {
  routeParams:any;
  headername;
  constructor(
    private activatedRoute: ActivatedRoute, 
    private router: Router,
    private userService: UserService,
    ) { }

    activeTab = '0';
    activeTabHeading = ""
    ngOnInit() {
    
      this.routeParams = this.activatedRoute.snapshot.routeConfig.path;
      this.activatedRoute.queryParams

        .subscribe(params => {
        this.activeTab = params.id;
       console.log(params.id);
        switch(params.id){
          case '0': this.activeTabHeading = "Impressum";
          break;
          case '1': this.activeTabHeading = "Datenschutzbestimmungen";
          break;
          case '2': this.activeTabHeading = "Cookies";
          break;
          case '3': this.activeTabHeading = "Nutzungsbedingungen";
          break;
          default: this.activeTabHeading = "Allgemeinen Geschäftsbedingungen";
        }
        console.log(this.activeTabHeading);
      });
      this.headername=this.activeTabHeading;
      console.log("sdsadsada");
  }

 

  handleTabChange(event: MatTabChangeEvent){
    console.log('value', event.index.toString());
    switch( event.index.toString()){
      case '0': this.activeTabHeading = "Impressum";
      break;
      case '1': this.activeTabHeading = "Datenschutzbestimmungen";
      break;
      case '2': this.activeTabHeading = "Cookies";
      break;
      case '3': this.activeTabHeading = "Nutzungsbedingungen";
      break;
      default: this.activeTabHeading = "Allgemeinen Geschäftsbedingungen";
    }
    this.headername=this.activeTabHeading;
  }
  


 
}
