import { Component, OnInit } from '@angular/core';
import {  ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../user.service';
@Component({
  selector: 'app-b2b-dashboard',
  templateUrl: './b2b-dashboard.component.html',
  styleUrls: ['./b2b-dashboard.component.css']
})
export class B2bDashboardComponent implements OnInit {
  token=this.UserService.getdecode(sessionStorage.getItem('Token'))
  routeParams
  brokerno;
  search;
  currentActiveRole = sessionStorage.getItem('currentActiveRole');
  activemembership:any=[];
  id = this.UserService.getDecodedAccessToken(sessionStorage.getItem('Token')).id;
  constructor( private UserService:UserService,private router:Router,private activateRoutes:ActivatedRoute) { 
    if(this.currentActiveRole!='b2b')
  {
    this.gotodashboard();
  }
  
  
  
    }
    gotodashboard()
    {
      console.log(this.currentActiveRole);
     if(this.currentActiveRole=='b2b')
     {
      this.router.navigate(['./b2b-dashboard']);
     }
     if(this.currentActiveRole=='employee')
     {
      this.router.navigate(['./employee-dashboard']);
     }
     if(this.currentActiveRole=='superadmin')
     {
      this.router.navigate(['./superadmin-dashboard']);
     }
     if(this.currentActiveRole=='admin')
     {
      this.router.navigate(['./admin-dashboard']);
     }
     if(this.currentActiveRole=='customer')
     {
      this.router.navigate(['./dashboard']);
     }
    }
    navigatecoursuladashboard()
    {
      this.router.navigate(["/my-company"], {
        queryParams: { id: this.id },
      });
  
    }
  ngOnInit() {
    $("#loaderouterid").css("display", "block");
    this.routeParams = this.activateRoutes.snapshot.routeConfig.path;
    if(this.token!=null)
    {
    this.UserService.checkbrokerassign('').subscribe((result:any)=>{
      if(result.length>0)
      {
    this.brokerno=result.brokerno;
      }
    })
    }
    
    var datanew = { id: "" };
    const data = this.UserService.getpurchasemembershipplan(datanew).subscribe(
      (success: any) => {
        $("#loaderouterid").css("display", "none");
        // if success and error give response
        if (success.status == "error") {
          // Swal.fire("Übungsliste nicht gefunden", "", "error");
        } else {
this.activemembership=success;
console.log(success);

          // this.customerList = success;
          // this.setPage(1);
          // this.recordCount = success.length;
          // console.log(this.customerList);
        }
      },
      (rejected) => {
        console.log(rejected);
      }
    );

  }

  Logout()
  {
    console.log("dddd");
    sessionStorage.removeItem("Token");
    this.router.navigate(["./"]);
  }

  

}
