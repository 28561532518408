import { HttpEvent, HttpEventType } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { first, map, startWith } from "rxjs/operators";
import Swal from "sweetalert2";
import { UserService } from "../user.service";
type unit = 'bytes' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB';
type unitPrecisionMap = {
  [u in unit]: number;
};

const defaultPrecisionMap: unitPrecisionMap = {
  bytes: 0,
  KB: 0,
  MB: 1,
  GB: 1,
  TB: 2,
  PB: 2
};
@Component({
  selector: 'app-add-theraphist-product',
  templateUrl: './add-theraphist-product.component.html',
  styleUrls: ['./add-theraphist-product.component.css']
})
export class AddTheraphistProductComponent implements OnInit {

  loginRole = sessionStorage.getItem("currentActiveRole");
  title = this.userService.getDecodedAccessToken(sessionStorage.getItem("Token"))
    .title;
  loginid = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).id;
  customerno = this.userService.getDecodedAccessToken(sessionStorage.getItem("Token"))
  .customerno;
  COMPANYNAME = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).companyname;
  lastname = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).lastname;
  patient_id:any='';
  minDate:Date;
  minDateforend:Date;
  maxDate11:Date;
  patientlist: any=[];
  patientlistnew:any=[];
  progress =[];
  filearray=[]
  l=0;
  
  filearraynew=[]
  progress1 ;
  documentid1:any='';
  filearray1;
  lengthfile: number = 0;
  uploadlength: number = 0;
  filearraynew1
  documentid:any=[];
  therapyplanlistnew:any=[];
  ticket_no='';
  therapyplanid:any='';
  private readonly units: unit[] = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
  therapyplanlist:any=[];
  personalInfoFormGroup: FormGroup;
  exercise_controls = new FormControl();
  therapyplancontrol =new FormControl();
  constructor(
    private router: Router,
    private userService: UserService,
    private _formBuilder: FormBuilder,
    
  ) {}



  ngOnInit() {



    this.personalInfoFormGroup = this._formBuilder.group({ 
      name:["", Validators.required],
      grossprice:[""],
      description:["",Validators.required],
      discount:["",[Validators.required, Validators.pattern("^[0-9]*$"),Validators.max(100)]],
      price:["",[Validators.required, Validators.pattern("\\d+\\,\\d{2}")]],
      noofpatients:["",[Validators.required, Validators.pattern("^[0-9]*$")]],
      duration:["",[Validators.required, Validators.pattern("^[0-9]*$")]],
      tax:["",[Validators.required, Validators.pattern("^[0-9]*$"),Validators.max(100)]],
      upload_file:["",Validators.required],
      upload_video:["",Validators.required]
    }
      );

      this.personalInfoFormGroup.patchValue({
        price:'0,00'
      })

  }

  gettax()
    {
      let netprice=this.personalInfoFormGroup.controls.price.value.replace(",",".");
      let tax=this.personalInfoFormGroup.controls.tax.value;
let grossprice=(netprice-((netprice/100)*tax)).toFixed(2);
this.personalInfoFormGroup.patchValue({
  grossprice:grossprice.replace(".",",")
});

    }

  get personalInfoForm() {
    return this.personalInfoFormGroup.controls;
  }
  dataconvert(bytes: number = 0, precision: number | unitPrecisionMap = defaultPrecisionMap): string {
  
    if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) return '?';

    let unitIndex = 0;

    while (bytes >= 1024) {
      bytes /= 1024;
      unitIndex++;
    }

    const unit = this.units[unitIndex];

    if (typeof precision === 'number') {
      return `${bytes.toFixed(+precision)} ${unit}`;
    }
    return `${bytes.toFixed(precision[unit])} ${unit}`;
  }

 

  handleImageChange(event: any, index) {
    
    this.progress[index] = 0;
    console.log("asdasdsad"+this.filearray);
    $("#result").html("");
    console.log(this.loginid);
    event.preventDefault();
    const removeData = (j,indexw) => {
console.log("asdaadadasd"+indexw);

      
      console.log("sdddd"+this.filearraynew);
      console.log("sdddd"+j);
      delete this.filearraynew[j];
      console.log("sdddd"+this.filearraynew);
    
      this.filearray[j] = '';
      $('#logopreview'+indexw).html('<p style="text-align: center;padding: 105px 27px 106px 38px;border: 1px solid;"> Vorschau</p>');

      console.log("sdddd"+this.filearray);
    };

    var files = event.target.files; //FileList object
    // var output = document.getElementById("result");

    var filesLength = files.length;

    for (let i = 0; i < filesLength; i++) {
      let f = files[i];
      let newsize = this.l;
      this.l = this.l + 1;
      // this.filearraynew.splice(index, 0, f);

      this.filearraynew[index]=f;
      this.filearray[index] = this.filearraynew[index];
      let Size1 = f.size;
let Size = this.dataconvert(Size1);
      var fileReader = new FileReader();
      //var target:EventTarget;
      fileReader.onload = function (e) {
        //var file = e.target;
   
        let extension = f.name.substr(f.name.lastIndexOf(".") + 1);
   
        let ImageName;
    
          ImageName = (e.target as any).result;

          let typeofimage = f.type;
         
          let dateofdocument = f.lastModified;
          // var d = new Date(dateofdocument);
          // var date =
          //   d.getDate() + "." + (d.getMonth() + 1) + "." + d.getFullYear();


 
            var date1 = new Date(dateofdocument).toLocaleDateString("en-IN", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric"
            });
    
            var date = date1.replace(/[/]/g, ".");
console.log("dddddddddddd"+f.size);
//           let Size1:any = Math.round(f.size / 1024);
// let Size = Size1 > 1024 ? Size1.charAt(0) + "MB" : Size1 + "KB"

          $("#logopreview"+index).html(
            '<div class="pip" style="width: 100%;display: inline-block;margin: 8px;" "id=\'pipremove' +
              index +
              "'>" +
              '<div class="removepreview" id="removepreviewid' +
              index +
              '" style="background: #184297;right:0px;border-radius: 50%;width:30px;height:30px;font-size: 14px; text-align: center; padding: 6px;color: white;position: absolute;margin-left: 257px;margin-top: 2px;margin-right: 0 !important;cursor: pointer;">X</div>' +
              "" +
              '<embed class="imageThumb" style="width: 100%;height:210px;object-fit: contain" src="' +
              ImageName +
              '" title="' +
              f.name +
              '"/></embed>' +
              "<div> <b class='limitword' title='"+f.name+"'>Dokumentenname: " +
              f.name +
              "</b> </div><div> <b > Dateigröße: " +
              Size +
              "</b>  </div><div> <b>Dateityp: " +
              typeofimage +
              "</b> </div><div> <b>Datum des Dokuments: " +
              date +
              "</b> </div></div>"
          )
        

        // $(`<div> <b>  ${Math.round((f.size / 1024))} </b> KB </div>`).insertAfter(".pip")
        $("#removepreviewid" + index).click(function () {
          removeData(index,index);
          // $("#pipremove" + i).remove();
          $(this).parent(".pip").remove();
        });
      };
      fileReader.readAsDataURL(f);
    }

  }

  getpatientdata()
  {
   let data= this.exercise_controls.value.name;
   this.patient_id=this.exercise_controls.value.id;
this.exercise_controls.setValue(data);
  }
  add_therapist_product() {
    this.router.navigate(["/add-therapist-product"]);
  }
save_exercise() {
    
    for (let l = 0; l < this.filearray.length; l++) {
      this.lengthfile++;
    }

    $("#loaderouterid").css("display", "block");
    console.log("filearray" + JSON.stringify(this.filearray));
    let companycodenew = "";

    companycodenew = "60-co-";
    
   
        this.ticket_no = companycodenew;
        let uniquedata:string=new Date().getTime().toString();
        console.log("ticket_no" + this.filearray);

    
        for (let i = 0; i < this.filearray.length; i++) {
          var values = {
            image: "",
            document_type: "",
            document_sub_type: "",
            user_id: "",
            product_partner: "",
            companycode: "",
            brand: "",
            tags: [],
            upload_by: "",
            bill_type: "",
            ticket_no: "",
            document_name:"",
            uniquedata:"",
          };
          let url = this.filearray[i];
          let reader = new FileReader();
          let extension = url.name.substr(url.name.lastIndexOf(".") + 1);

          reader.readAsDataURL(url);
          reader.onload = () => {
            // this.fileName = url.name + " " + url.type;
            let base64ImgString = (reader.result as string).split(",")[1];

            let StringTypeCasting = Math.round(
              this.filearray[i].size / 1024
            );
            let typeofimage = this.filearray[i].type;
            let dateofdocument = this.filearray[i].lastModified;
            console.log("this.StringTypeCasting " + StringTypeCasting);
            values.image = this.filearray[i];
            values.document_type = "Allgemeines Dokument";
            values.document_sub_type = " ";
           
              values.document_name = "Membershipplan Picture";
           
            values.user_id = this.loginid;
            values.product_partner = " ";
            values.companycode = "42160 Jomiretto GmbH";
            values.brand = "Coursula";
            values.upload_by = "exercise";
            values.bill_type = "";
            values.ticket_no = this.ticket_no;
            values.uniquedata = uniquedata;
            values.tags.push(StringTypeCasting.toString());
            values.tags.push(typeofimage);
            values.tags.push(dateofdocument);
            // if(i==1)
            // {
            this.uploadDocument1(values, i);
            // }
            // else{
            //   this.uploadDocument(values, i);
            // }
            // this.documentid='';
            values.tags = [];
          };
        
      }
          
   
  }

  uploadDocument1(values: any,index:number) {
   
    this.progress[index] = 0;
   
    // this.progressInfos[index] = { value: 0, fileName: values.image.name };
    let length = this.filearray.length;
    console.log(length);

    const formData = new FormData();
    formData.append("document_type", values.document_type);
    formData.append("document_sub_type", values.document_sub_type);
    formData.append("product_partner", values.product_partner);
    formData.append("user", values.user_id);
    formData.append("companycode", values.companycode);
    formData.append("brand", values.brand);
    formData.append("tags", values.tags);
    formData.append("upload_by", values.upload_by);
    formData.append("bill_type", "");
    formData.append("ticket_no", values.ticket_no);
    formData.append("document_name", values.document_name);
    formData.append("uniquedata", values.uniquedata);
    // formData.append("index", index);
    if (values.image !== "") {
      formData.append("document", values.image);
    }

    this.userService.callApiMultipartnew11(
      formData
    ).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {

        case HttpEventType.Sent:
          console.log('Request has been made!');
          
          break;
        case HttpEventType.ResponseHeader:
          console.log('Response header has been received!');
          break;
        case HttpEventType.UploadProgress:
          console.log(event.total);
          console.log(event.loaded);
         
          this.progress[index] = Math.round(event.loaded / event.total * 100);
         
          console.log(`Uploaded! ${this.progress}%`);
          break;
        case HttpEventType.Response:
          console.log('User successfully created!', event.body);
          
          setTimeout(() => {
            this.uploadlength++;
            // this.progress[index] = 0;
       
          this.documentid[index]=event.body.document_unique_id;
       
       
          this.ticket_no=event.body.ticket_no;

            this.addmembershipplan();
          }, 1500);

      }
    })
  }



  addmembershipplan()
  {
    
console.log("sssssssssssssssssssssssssssss");
if (this.lengthfile == this.uploadlength) {
let data = {
    name: this.personalInfoForm.name.value,
    description: this.personalInfoForm.description.value,
    price: this.personalInfoForm.price.value,
    discount: this.personalInfoForm.discount.value,
    noofpatients: this.personalInfoForm.noofpatients.value,
    duration:this.personalInfoForm.duration.value,
    tax:this.personalInfoForm.tax.value,
    document: this.documentid,
    created_by: this.loginid,
    membershipplan_id: this.ticket_no,
  };
  console.log(data);

  this.userService
    .addmembershipplan(data)
    .pipe(first())
    .subscribe(
      (data) => {
        Swal.fire(
          "Mitgliedschaftsplan erfolgreich hinzugefügt. Ihre Mitgliedsplan-ID " +
            this.ticket_no +
            "",
          "",
          "success"
        );
        this.ticket_no = "";

        $("#loaderouterid").css("display", "none");
        setTimeout(() => {
          Swal.close();
          this.router.navigate(["./therapist-product-management"]);
        }, 1000);
      },
      (error) => {
        console.log("Error", error["error"]);
        $("#loaderouterid").css("display", "none");
        setTimeout(() => {
          Swal.close();
         
        }, 1000);
        // $("html, body").animate(
        //   { scrollTop: "0" }, 3000);
      }
    );
}

  }


}

