import {
  CdkDragDrop,
  copyArrayItem,
  moveItemInArray,
} from "@angular/cdk/drag-drop";
import { AfterViewInit, Component, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatChipInputEvent } from "@angular/material";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from "@angular/router";
import intlTelInput from "intl-tel-input";
import { Observable } from "rxjs";
import { first, map, startWith } from "rxjs/operators";
import Swal from "sweetalert2";

import { UserService } from "../user.service";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
@Component({
  selector: 'app-start-treatment',
  templateUrl: './start-treatment.component.html',
  styleUrls: ['./start-treatment.component.css']
})
export class StartTreatmentComponent implements AfterViewInit, OnInit {
  loginRole = sessionStorage.getItem("currentActiveRole");
  title = this.userService.getDecodedAccessToken(sessionStorage.getItem("Token"))
    .title;
  loginid = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).id;

  customerno = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).customerno;
  COMPANYNAME = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).companyname;
  lastname = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).lastname;
  patient_id: any = "";
  minDate: Date;
  minDatesecondstep: Date[] = [];
  minDateforend: Date;
  minDateforendsecondstep: Date[] = [];
  maxDate11secondstep: Date[] = [];
  maxDate11: Date;
  patientlist: any = [{"name":"Abhishek Soni (10088-60-co) ","id":"60cc36f9a834394ce38e6e43"}];
  patientlistnew: any = [];
  exercisedata: any = [];
  newpatientname: any = "";
  selectedtherapyplan: any;
  therapyplanlistnew: any = [];
  StpperForm: boolean = false;
  exerciseoption: Observable<any[]>;
  therapyplanid: any = "";
  therapyplanlist: any = [];
  patientformgroup: FormGroup;
  personalInfoFormGroup: FormGroup;
  personalInfoFormGroupadd: FormGroup;
  personalInfoFormGroupmodal: FormGroup;
  exercise_controls = new FormControl();
  exercise_controlspatient = new FormControl();
  therapyplancontrol = new FormControl();
  addressFormGroup: FormGroup;
  moreinfoFormGroup: FormGroup;
  membershipid;
  secondstep: FormGroup;
  customerNo: string;
  realcustomer: boolean;
  registerDone: boolean;
  remnew: any=0;
  registraionError: boolean;
  exercisesequencelist: any[][] = [];
  phase: any = [];
  exercisesequencelistsearch: any = [];
  typesOptionsArray: string[] = [];
  oldtypesOptionsArray: string[] = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  searchby = "";
  autoGeneratedCustomerNumber = this.getLastUser();
  roleCompanies = {
    employee: [],
    admin: [],
    // customer: ["coursula_customer"],
    customer: ["ontrain_customer"],
    b2b: [],
  };
  options: any;
  hide = true;
  countryName: any;
  searchlist = [];
  minDatenew = new Date(1900, 0, 1);
  maxDate = new Date(2020, 0, 1);
  password: string;
  localityName: any;
  documentarray: any = [];
  documentnewarray: any = [];
  optionsValue: string[] = [
    "Afghanisch",
    "Ägyptisch",
    "Albanisch",
    "Algerisch",
    "Andorranisch",
    "Angolanisch",
    "Antiguanisch",
    "Äquatorialguineisch",
    "Argentinisch",
    "Armenisch",
    "Aserbaidschanisch",
    "Äthiopisch",
    "Australisch",
    "Bahamaisch",
    "Bahrainisch",
    "Bangladeschisch",
    "Barbadisch",
    "Belgisch",
    "Belizisch",
    "Beninisch",
    "Bhutanisch",
    "Bolivianisch",
    "Bosnisch herzegowinisch",
    "Botsuanisch",
    "Brasilianisch",
    "Bruneiisch",
    "Bulgarisch",
    "Burkinisch",
    "Burundisch",
    "Chilenisch",
    "Chinesisch",
    "Costa-ricanisch",
    "Dänisch",
    "Kongolesisch",
    "Deutsch",
    "Dominikanisch",
    "Dschibutisch",
    "Ecuadorianisch",
    "Salvadorianisch",
    "Ivorisch",
    "Englisch",
    "Eritreisch",
    "Estnisch",
    "Fidschianisch",
    "Finnisch",
    "Französisch",
    "Gabunisch",
    "Gambisch",
    "Georgisch",
    "Ghanaisch",
    "Grenadisch",
    "Griechisch",
    "Grönländisch",
    "Britisch",
    "Guatemaltekisch",
    "Guineisch",
    "Guinea-bissauisch",
    "Guyanisch",
    "Haitianisch",
    "Honduranisch",
    "Indisch",
    "Indonesisch",
    "Irakisch",
    "Iranisch",
    "Irisch",
    "Isländisch",
    "Israelisch",
    "Italienisch",
    "Jamaikanisch",
    "Japanisch",
    "Jemenitisch",
    "Jordanisch",
    "Kambodschanisch",
    "Kamerunisch",
    "Kanadisch",
    "Kapverdisch",
    "Kasachisch",
    "Katarisch",
    "Kenianisch",
    "Kirgisisch",
    "Kiribatisch",
    "Kolumbianisch",
    "Komorisch",
    "Kongolesisch",
    "Kosovarisch",
    "Kroatisch",
    "Kubanisch",
    "Kuwaitisch",
    "Laotisch",
    "Lesothisch",
    "Lettisch",
    "Libanesisch",
    "Liberianisch",
    "Libysch",
    "Liechtensteinisch",
    "Litauisch",
    "Luxemburgisch",
    "Madagassisch",
    "Malawisch",
    "Malaysisch",
    "Maledivisch",
    "Malisch",
    "Maltesisch",
    "Marokkanisch",
    "Marshallisch",
    "Mauretanisch",
    "Mauritisch",
    "Mazedonisch",
    "Mexikanisch",
    "Mikronesisch",
    "Moldauisch",
    "Monegassisch",
    "Mongolisch",
    "Montenegrinisch",
    "Mosambikanisch",
    "Myanmarisch",
    "Namibisch",
    "Nauruisch",
    "Nepalesisch",
    "Neuseeländisch",
    "Nicaraguanisch",
    "Niederländisch",
    "Nigrisch",
    "Nigerianisch",
    "Nordkoreanisch",
    "Norwegisch",
    "Ömanisch",
    "Österreichisch",
    "Pakistanisch",
    "Palästinensisch",
    "Palauisch",
    "Panamaisch",
    "Papua-neuguineisch",
    "Paraguayisch",
    "Peruanisch",
    "Philippinisch",
    "Polnisch",
    "Portugiesisch",
    "Puerto-ricanisch",
    "Kongolesisch",
    "Ruandisch",
    "Rumänisch",
    "Russisch",
    "Salomonisch",
    "Sambisch",
    "Samoanisch",
    "San-marinesisch",
    "Saudi-arabisch",
    "Schottisch",
    "Schwedisch",
    "Schweizerisch",
    "Senegalesisch",
    "Serbisch",
    "Seychellisch",
    "Sierra-leonisch",
    "Simbabwisch",
    "Singapurisch",
    "Slowenisch",
    "Somalisch",
    "Spanisch",
    "Sri-lankisch",
    "Lucianisch",
    "Vincentisch",
    "Südafrikanisch",
    "Sudanesisch",
    "Südkoreanisch",
    "Surinamisch",
    "Swasiländisch",
    "Syrisch",
    "São-toméisch",
    "Tadschikisch",
    "Taiwanisch",
    "Tansanisch",
    "Thailändisch",
    "Tibetisch",
    "Timoresisch",
    "Togoisch",
    "Tongaisch",
    "Tschadisch",
    "Tschechisch",
    "Tunesisch",
    "Türkisch",
    "Turkmenisch",
    "Tuvaluisch",
    "Ugandisch",
    "Ukrainisch",
    "Ungarisch",
    "Uruguayisch",
    "Amerikanisch",
    "Üsbekisch",
    "Vanuatuisch",
    "Vatikanisch",
    "Venezolanisch",
    "Vietnamesisch",
    "Walisisch",
    "Weißrussisch",
    "Zentralafrikanisch",
  ];
  itemList: string[] = [
    "Matte",
    "Bett",
    "Spiegel",
    "Strohhalm",
    "Eiswürfel",
    "Hocker",
    "Widerstandsband",
    "Pezziball",
    "Stab",
    "Kettlebell",
    "Faszienrolle",
    "Seil",
    "Hantel (klein)",
    "Handtuch",
    "Tape",
    "Tennisball",
    "Murmeln",
    "Zopfgummi",
    "Tisch",
    "Knete",
  ];
  activemembership:any=[];
  selecteditemList = this.itemList;
  filteredOptions: Observable<string[]>;
  companyTitle = false;
  myControl = new FormControl();

  constructor(
    private router: Router,
    private userService: UserService,
    private _formBuilder: FormBuilder,
   
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.patient_id = params["id"];
      this.getpatientdata2();
      // this.ngOnInit();
      // this.statusCustomer();
      // this.userService.statusCustomer({ "id": this.pagedItems[0]._id })
    });
  }

  setnationality() {
    console.log(this.myControl.value);
    console.log("sadsad");
    this.personalInfoFormGroupadd.controls.nationality.setValue(
      this.myControl.value
    );
    // this.personalInfoFormGroupadd.nationality=this.myControl.value
  }
  gettherapyplandata() {
    let data = this.therapyplancontrol.value.name;
    this.therapyplanid = this.therapyplancontrol.value.id;
    this.therapyplancontrol.setValue(data);
  }
  filtercustomer(success, companyName) {
    let newsuccess = [];

    console.log("ddd" + success.length);
    for (let i = 0; i < success.length; i++) {
      let brokerbrandarray = success[i].brokerbrandarray;
      let brokerarray = success[i].brokerarray;
      let a = brokerbrandarray.indexOf(
        companyName.charAt(0).toUpperCase() + companyName.slice(1)
      );
      console.log("ddd" + i);
      console.log("ddd" + a);

      if (a != -1) {
        let brokervaluenew = brokerarray[a];

        if (brokervaluenew == this.customerno) {
        } else {
          newsuccess.push(i);
          // success.splice(i,1);
          console.log("ddd1" + this.customerno);
        }
      } else {
        newsuccess.push(i);
        console.log("ddd2" + this.customerno);
      }
    }

    console.log(newsuccess);
    for (let i1 = 0; i1 < newsuccess.length; i1++) {
      delete success[newsuccess[i1]];
      // success.splice(newsuccess[i1],1);
    }
    success = success.filter(function () {
      return true;
    });
    let patientlist = [];
    for (let j = 0; j < success.length; j++) {
      patientlist.push({
        name:
          success[j].firstname +
          " " +
          success[j].lastname +
          " (" +
          success[j].customerno +
          ") ",
        id: success[j]._id,
      });
    }

    return patientlist;
  }

  getstartdate(event) {
    console.log(
      "start_date111" + this.personalInfoFormGroup.controls.start_date.value
    );
    this.minDateforend = this.personalInfoFormGroup.controls.start_date.value;
    let numWeeks = 12;
    let now = new Date(this.personalInfoFormGroup.controls.start_date.value);
    now.setDate(now.getDate() + numWeeks * 7);
    this.maxDate11 = now;
  }
  getstartdatenew(event, index) {
    this.minDateforendsecondstep[index] = new Date(
      this.personalInfoFormGroup.controls.secondstep.value[index].start_date
    );
  }

  telInputObject(obj) {
    console.log(obj);
    obj.setCountry("de");
  }
  ngAfterViewInit() {
    // const input = document.querySelector("#phone");
    // console.log("querySelector" + input);
    // intlTelInput(input, {
    //   initialCountry: "de",
    //   geoIpLookup: function (callback) {
    //     $.get("http://ipinfo.io", function () {}, "jsonp").always(function (
    //       resp
    //     ) {
    //       var countryCode = resp && resp.country ? resp.country : "de";
    //       callback(countryCode);
    //       console.log("countryCode" + countryCode);
    //     });
    //   },
    // });
    // // $('.firstlast').parent().css('overflow', 'none');
    // // $(".firstlast").find('.mat-form-field-infix').css('width',"100%");
    // $(".firstlast")
    //   .parent("div")
    //   .find(".mat-form-field-infix")
    //   .css("width", "100%");
    // $(".stepnewclass")
    //   .parent("div")
    //   .find(".mat-stepper-horizontal-line")
    //   .css("min-width", "0px");
    // $(".stepnewclass")
    //   .parent("div")
    //   .find(".mat-stepper-horizontal-line")
    //   .css("margin", "0px");
  }
  looptherapyplan(therapyplandata) {
    console.log("sdddddddd" + JSON.stringify(therapyplandata));
    let therapyplanlist = [];
    for (let j = 0; j < therapyplandata.length; j++) {
      therapyplanlist.push({
        name: therapyplandata[j].therapyplan_name,
        id: therapyplandata[j]._id,
      });
    }
    console.log("sdddddddd" + JSON.stringify(therapyplanlist));

    return therapyplanlist;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.optionsValue.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
  ngOnInit() {

    this.documentarray.push("");
    this.documentarray.push("");

    this.documentnewarray.push("");
    this.documentnewarray.push("");

    
    var datanew = { id: "" };
  this.userService.getpurchasemembershipplan(datanew).subscribe(
      (success: any) => {
        $("#loaderouterid").css("display", "none");
        // if success and error give response
        if (success.status == "error") {
          // Swal.fire("Übungsliste nicht gefunden", "", "error");
        } else {
this.activemembership=success;
console.log(success);

          // this.customerList = success;
          // this.setPage(1);
          // this.recordCount = success.length;
          // console.log(this.customerList);
        }
      },
      (rejected) => {
        console.log(rejected);
      }
    );



    console.log("mmmmmmmmmmmmmmmmmmmmm" + this.patient_id);
    this.userService
      // .getCustomers("coursula", true)
      .getCustomers("ontrain", true)
      .subscribe((success: any) => {
        if (success.status == "error") {
          Swal.fire("Kein Patient gefunden", "", "error");
        } else {
          // this.patientlistnew = this.filtercustomer(success, "Coursula");
          this.patientlistnew = this.filtercustomer(success, "Ontrain");
          console.log("searchlistnewlist" + JSON.stringify(this.patientlist));
        }
      });

    this.userService.gettherapyplan({ id: "" }).subscribe((success: any) => {
      if (success.status == "error") {
        Swal.fire("Kein Therapieplan", "", "error");
      } else {
        this.therapyplanlistnew = this.looptherapyplan(success);
        console.log("searchlistnewlist" + JSON.stringify(this.patientlist));
      }
    });

    this.personalInfoFormGroup = this._formBuilder.group({
      start_date: ["", Validators.required],
      end_date: ["", Validators.required],
      Behandlung_name: ["", Validators.required],
      secondstep: this._formBuilder.array([]),
    });

    this.patientformgroup = this._formBuilder.group({
      patientdata: ["", Validators.required],
    });

    this.patientformgroup.patchValue({
      patientdata: this.patient_id,
    });
    this.GoToUploadDocument();

    this.patientlist = this.exercise_controlspatient.valueChanges.pipe(
      startWith(""),
      map((value) => (typeof value == "string" ? value : value.name)),
      map((name) => this._filterpatientdata(name))
    );
  
    this.therapyplanlist = this.therapyplancontrol.valueChanges.pipe(
      startWith(""),
      map((value) => (typeof value == "string" ? value : value.name)),
      map((name) => this._filtertherapyplandata(name))
    );

    this.personalInfoFormGroup.patchValue({
      start_date: new Date(),
    });

    this.autoPassword();
    this.nextCompanies();
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );

    this.minDate = new Date();
    this.minDateforend = new Date();
    console.log("start_date" + this.minDate);
    let numWeeks = 12;
    let now = new Date(this.personalInfoFormGroup.controls.start_date.value);
    now.setDate(now.getDate() + numWeeks * 7);
    this.maxDate11 = now;
    console.log("start_date" + this.maxDate11);

    this.personalInfoFormGroupadd = this._formBuilder.group({
      status: ["", Validators.required],
      title: ["", Validators.required],
      companyName: [" ", Validators.required],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],
      dob: ["", Validators.required],
      nationality: ["", Validators.required],
      birthPlace: ["", Validators.required],
    });

    this.addressFormGroup = this._formBuilder.group({
      street: ["", Validators.required],
      streetNumber: ["", Validators.required],
      postCode: ["", Validators.required],
      city: ["", Validators.required],
      additionalReference: [""],
      countryOfResidence: ["", Validators.required],
    });

    this.moreinfoFormGroup = this._formBuilder.group({
      password: ["", Validators.required],
      phone_number: ["", Validators.required],
      customerno: ["", Validators.required],
    });

    this.personalInfoFormGroupmodal = this._formBuilder.group({
      exercise_name: ["", Validators.required],
      exercise_name1: ["", Validators.required],
      comment1: [""],
      comment: ["", Validators.required],
      manual_tags: ["", Validators.required],
      tools: ["", Validators.required],
      difficully_level: ["", Validators.required],

      upload_file: ["", Validators.required],
      explain: ["", Validators.required],
      implementation: ["", Validators.required],
      exercise_category: ["", Validators.required],
    });

    this.personalInfoFormGroupadd.patchValue({
      status: "Kunde",
    });
    if (
      this.personalInfoFormGroupadd.get("status").value == "Kunde (Interessent)"
    ) {
      console.log("fffff");
      this.realcustomer = false;
    } else {
      this.realcustomer = true;
    }

    this.searchby = "yes";
    this.getallexerciselist();

    this.exerciseoption = this.exercise_controls.valueChanges.pipe(
      startWith(""),
      map((value) => (typeof value == "string" ? value : value.name)),
      map((name) => this._filterexercisedata(name))
    );

    this.typesOptionsArray.push();
    this.exercisesequencelist.push([]);

    var datanew = { id: "" };
    const data = this.userService
      .getpurchasemembershipplanwithstatus(datanew)
      .subscribe(
        (success: any) => {
          $("#loaderouterid").css("display", "none");
          // if success and error give response
          if (success.status == "error") {
            Swal.fire("Übungsliste nicht gefunden", "", "error");
          } else {
            if (success.length > 0) {
              let noofpatients = success[0].noofpatients;
              let usedpatient = success[0].usedpatient;
              let rem = noofpatients - usedpatient;
              this.remnew = rem;
              this.membershipid = success[0]._id;
            } else {
              this.remnew = "0";
              this.membershipid = "0";
            }
          }
        },
        (rejected) => {
          console.log(rejected);
        }
      );
  }

  checkpatient() {
    if (this.remnew > 0) {
    } else {
      Swal.fire("Your Current Plan is already used", "", "error");
    }
  }

  _filterexercisedata(value: string): string[] {
    let searchlist = this.exercisedata;
    let searchlistnewlist = [];

    for (let i = 0; i < this.typesOptionsArray.length; i++) {
      const filterValue = this.typesOptionsArray[i].toLowerCase();
      searchlist = searchlist.filter(function (itm) {
        // console.log("kundenew11dffffffflllllllllll" + JSON.stringify(itm.tags));
        var itmtag = itm.tags.map(v => v.toLowerCase());
        return (
          JSON.stringify(itm.name).toLowerCase().indexOf(filterValue) > -1 ||
          itmtag.includes(filterValue) == true
        );
      });
    }
    console.log(searchlist);

    const filterValue11 = value.toLowerCase();
    searchlistnewlist = this.exercisedata.filter(function (itm) {

console.log(filterValue11);
var itmtag = itm.tags.map(v => v.toLowerCase());
      return (
        JSON.stringify(itm.name).toLowerCase().indexOf(filterValue11) > -1 ||
        itmtag.includes(filterValue11) == true
      );



    });

   


    searchlist = searchlist.filter(function (itm) {
      var itmtag = itm.tags.map(v => v.toLowerCase());
      return (
        JSON.stringify(itm.name).toLowerCase().indexOf(filterValue11) > -1 ||
        itmtag.includes(filterValue11) == true
      );
    });

    // console.log("kundenew11" + JSON.stringify(searchlist));
    this.searchlist = searchlist;


    console.log(searchlistnewlist);
    return searchlistnewlist;
  }

  get personalInfoForm() {
    return this.personalInfoFormGroupadd.controls;
  }

  get addressInfoForm() {
    return this.addressFormGroup.controls;
  }

  get moreinfoForm() {
    return this.moreinfoFormGroup.controls;
  }
  getallexerciselist() {
    let exercise_category = "Therapie";
    if (this.searchby == "yes") {
      console.log("sddd" + exercise_category);
      this.userService
        .getsearchexerciseinsequence(exercise_category)
        .pipe(first())
        .subscribe((data: any) => {
          this.exercisedata = this.loopingexerciselist(data);

          console.log("exercisedata" + this.exercisedata);
        });
    } else if (this.searchby == "both") {
      console.log("sddd" + exercise_category);
      this.userService
        .getsearchtherapyinsequence("")
        .pipe(first())
        .subscribe((data: any) => {
          this.exercisedata = this.loopingexerciselist(data);

          console.log("exercisedata" + this.exercisedata);
        });
    }else if (this.searchby == "doctorbutton") {
      console.log("sddd" + exercise_category);
      this.userService
        .getsearchtreatementinsequence("")
        .pipe(first())
        .subscribe((data: any) => {
          this.exercisedata = this.loopingexerciselist(data);

          console.log("exercisedata" + this.exercisedata);
        });
    } else {
      this.userService
        .getSearchexercisesequence(exercise_category)
        .pipe(first())
        .subscribe((data: any) => {
          this.exercisedata = this.loopingexerciselist(data);

          console.log("exercisedata" + this.exercisedata);
        });
    }
  }

  loopingexerciselist(userData: string | any[]) {
    let exerciselist = [];
    if (this.searchby == "yes") {
      console.log("kundenew" + JSON.stringify(userData));
      for (let i = 0; i < userData.length; i++) {
        // console.log(userData[i].roles.includes('b2b') , userData[i].roles)
        console.log(
          "kundenew" + JSON.stringify(userData[i].element.exercise_name1)
        );
        // exerciselist.push({
        //   name: userData[i].element.exercise_name1,
        //   id: userData[i].element._id,
        //   tags: userData[i].element.tags,
        //   img: userData[i].img,
        //   diff: userData[i].element.difficully_level,
        // });
        exerciselist.push({
          document: [
            userData[i].element.document[1],
            userData[i].element.document[2],
          ],
          documentnew: [userData[i].img1, userData[i].img2],
          name: userData[i].element.exercise_name1,
          id: userData[i].element._id,
          tags: userData[i].element.tags,
          img: userData[i].img,
          diff: userData[i].element.difficully_level,
        });
      }
    } else if (this.searchby == "both") {
      console.log("kundenew" + JSON.stringify(userData));
      for (let i = 0; i < userData.length; i++) {
        // console.log(userData[i].roles.includes('b2b') , userData[i].roles)

        // exerciselist.push({
        //   name: userData[i].therapyplan_name,
        //   id: userData[i]._id,
        //   tags: [],
        //   img: "",
        //   diff: userData[i].exercise_id.length,
        // });
        exerciselist.push({
          document: this.documentarray,
          documentnew: this.documentnewarray,
          name: userData[i].therapyplan_name,
          id: userData[i]._id,
          tags: [],
          img: "",
          diff: userData[i].exercise_id.length,
        });
      }
    } else if (this.searchby == "doctorbutton") {
      console.log("kundenew" + JSON.stringify(userData));
      for (let i = 0; i < userData.length; i++) {
        // console.log(userData[i].roles.includes('b2b') , userData[i].roles)

        // exerciselist.push({
        //   name: userData[i].treatementplan_name,
        //   id: userData[i]._id,
        //   tags: [],
        //   img: "",
        //   diff: userData[i].exercise_id.length,
        // });
        exerciselist.push({
          document: this.documentarray,
          documentnew: this.documentnewarray,
          name: userData[i].treatementplan_name,
          id: userData[i]._id,
          tags: [],
          img: "",
          diff: userData[i].exercise_id.length,
        });
      }
    } else {
      console.log("kundenew" + JSON.stringify(userData));
      for (let i = 0; i < userData.length; i++) {
        // console.log(userData[i].roles.includes('b2b') , userData[i].roles)

        // exerciselist.push({
        //   name: userData[i].exercisesequence_name,
        //   id: userData[i]._id,
        //   tags: [],
        //   img: "",
        //   diff: userData[i].exercise_id.length,
        // });
        exerciselist.push({
          document: this.documentarray,
          documentnew: this.documentnewarray,
          name: userData[i].exercisesequence_name,
          id: userData[i]._id,
          tags: [],
          img: "",
          diff: userData[i].exercise_id.length,
        });
      }
    }
    console.log("broooker!!!!!!!!!!!!!!!!!!!!!" + JSON.stringify(exerciselist));

    return exerciselist;
  }

  choosecategory11(value) {
    console.log("dddddddddddd" + value);
    this.personalInfoFormGroup.patchValue({
      exercise_category: value,
    });

    $("#exercise_category").val(value);
    if (value == "Therapie") {
      $("#Therapie").css("background-color", "gray");
      $("#Prävention").css("background-color", "white");
    } else {
      $("#Prävention").css("background-color", "gray");
      $("#Therapie").css("background-color", "white");
    }
  }

  choosecategory(value) {
    this.exercisesequencelistsearch = [];
    this.typesOptionsArray = [];
    this.searchby = value;
    this.getallexerciselist();
    setTimeout(() => {
      this.exercise_controls.setValue(" ");
    }, 500);
  }
  checkemail1()
  {
    let data={email:this.personalInfoFormGroupadd.controls.email.value}
      this.userService
      .checkemail(data)
      .pipe(first())
      .subscribe((data11) => {
  if(data11['status']=='200')
  {
    $('#checkemailerror').html("")
  }
  else{
    Swal.fire("E-Mail-ID ist nicht verfügbar","","error")
    setTimeout(() => {
      Swal.close();
      $('#backfirst').trigger('click')
    }, 1000);
    $('#checkemailerror').html("E-Mail-ID ist nicht verfügbar")
  }
  });
  
  }



  checkemail()
  {
    let data={email:this.personalInfoFormGroupadd.controls.email.value}
      this.userService
      .checkemail(data)
      .pipe(first())
      .subscribe((data11) => {
  if(data11['status']=='200')
  {
    $('#checkemailerror').html("")
  }
  else{
   
    $('#checkemailerror').html("E-Mail-ID ist nicht verfügbar")
  }
  });
  
  }


  OpenStpper() {
    console.log("ddddddddddd");

    this.StpperForm = true;
    setTimeout(() => {
      const input = document.querySelector("#phone");
      console.log("querySelector" + input);
      intlTelInput(input, {
        initialCountry: "de",
        geoIpLookup: function (callback) {
          $.get("http://ipinfo.io", function () {}, "jsonp").always(function (
            resp
          ) {
            var countryCode = resp && resp.country ? resp.country : "de";
            callback(countryCode);
            console.log("countryCode" + countryCode);
          });
        },
      });
    }, 1000);
  }

  getpatientdata() {
    let data = this.exercise_controlspatient.value.name;
    this.patient_id = this.exercise_controlspatient.value.id;
    


    var datanew = { id: this.patient_id };
    this.userService.getstarttreatmentbypatientid(datanew).subscribe(
      (success: any) => {
        if(success)
        {
         
  Swal.fire({
          
    title: '',
    html: "<span style='font-size: 26px;'>Für diesen Patienten läuft bereits eine Behandlung.  Diese kannst du im Patientenmanagement einsehen." +
        "</span><br><br><br>" +
        '',
    showCancelButton: false,
    showConfirmButton: true,
    allowOutsideClick: false,
    showCloseButton: true,
   icon:"error",
});
this.exercise_controlspatient.setValue("");
}
else
{
  this.exercise_controlspatient.setValue(data);

    this.patientformgroup.patchValue({
      patientdata: this.patient_id,
    });
}

      });


  
  }

  quantities(): FormArray {
    return this.personalInfoFormGroup.get("secondstep") as FormArray;
  }

  newQuantity(): FormGroup {
    return this._formBuilder.group({
      start_date: ["", Validators.required],
      end_date: ["", Validators.required],
    });
  }
  addmorefield() {
    this.quantities().push(this.newQuantity());
    this.exercisesequencelist.push([]);
    console.log("phaselength" + this.phase.length);
    if (this.phase.length == "1") {
      let now = new Date(this.personalInfoFormGroup.controls.end_date.value);
      now.setDate(now.getDate() + 1);
      this.personalInfoFormGroup.patchValue({
        secondstep: [{ start_date: now }],
      });
      this.minDatesecondstep[0] = now;
      this.minDateforendsecondstep[0] = now;
    } else {
      let now = new Date(
        this.personalInfoFormGroup.controls.secondstep.value[
          this.phase.length - 2
        ].end_date
      );
      console.log("now" + now);
      now.setDate(now.getDate() + 1);
      this.quantities()
        .at(this.phase.length - 2 + 1)
        .patchValue({ start_date: now });
      this.minDatesecondstep[this.phase.length - 1] = now;
      this.minDateforendsecondstep[this.phase.length - 1] = now;
    }
  }
  removeQuantity(i: number, index: any) {
    this.quantities().removeAt(i);
    this.phase.splice(index, 1);
    this.exercisesequencelist[index].splice(
      0,
      this.exercisesequencelist[index].length
    );
    // this.uploadedFiles.splice(index, 1);
    // this.imgURL.splice(index, 1);
  }

  checkphase() {
    if (this.phase.length > 0) {
      if (
        this.phase.length - 1 ==
        this.personalInfoFormGroup.controls.secondstep.value.length
      ) {
        return 1;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }
  calcount(id, type) {
    let Behandlung_namecount: any;
    if (type == "Behandlung_name") {
      let Behandlung_name =
        this.personalInfoFormGroup.controls.Behandlung_name.value.length;

      Behandlung_namecount = 35 - Behandlung_name;
    }
    $("#" + id).html(Behandlung_namecount);
  }

  getexercise(id, name, img, diff, tags, data11, index) {
    var datanew = { id: id };

    let add = 0;
    let indexnew = 0;
    if (this.searchby == "both") {
      $("#loaderouterid").css("display", "block");
      $("#loadertext").html("Please wait for extract therapy Plan..");
      $("#loadertext").css("width", "320px");
      $("#loadertext").css("margin-left", "-160px");
      const data = this.userService.gettherapyplannew(datanew).subscribe(
        (success: any) => {
          if (success.status == "error") {
            $("#loadertext").html("");
            $("#loadertext").css("width", "100px");
            $("#loaderouterid").css("display", "none");
            $("#loadertext").css("margin-left", "-50px");
          } else {
            this.selectedtherapyplan = success;
            let totalphase =
              this.selectedtherapyplan[this.selectedtherapyplan.length - 1][2]
                .phase;
            if (
              this.personalInfoFormGroup.controls.secondstep.value.length > 0
            ) {
              let m: number = 0;
              let datearray = [];
              let enddatearray = [];
              let phaselength = this.phase.length;
              let lastenddate = new Date(
                this.personalInfoFormGroup.controls.secondstep.value[
                  phaselength - 2
                ].end_date
              );
              let startdate;
              let enddate;
              for (
                let j = phaselength - 1;
                j < totalphase + (phaselength - 1);
                j++
              ) {
                this.quantities().push(this.newQuantity());
                this.exercisesequencelist.push([]);
                lastenddate.setDate(lastenddate.getDate() + 1);
                startdate = new Date(lastenddate);
                enddate = new Date(startdate);
                enddate.setDate(
                  enddate.getDate() +
                    (parseInt(this.selectedtherapyplan[m][3].days) - 1)
                );
                datearray.push({ start_date: startdate });
                enddatearray.push({ end_date: enddate });
                lastenddate = new Date(enddate);
                this.phase[j + 1] = {
                  start_date: startdate,
                  end_date: enddate,
                };
                this.minDatesecondstep[j] = startdate;
                this.minDateforendsecondstep[j] = startdate;
                for (let k = 0; k < this.selectedtherapyplan.length; k++) {
                  let index: any = j + 1 - (phaselength - 1);
                  if (this.selectedtherapyplan[k][2].phase == parseInt(index)) {
                    this.exercisesequencelist[j + 1].push({
                      id: this.selectedtherapyplan[k][0]._id,
                      value: this.selectedtherapyplan[k][0].exercise_name1,
                      img: this.selectedtherapyplan[k][1].image,
                      diff: this.selectedtherapyplan[k][0].difficully_level,
                      tags: this.selectedtherapyplan[k][0].tags,
                    });
                    m++;
                  }
                }
                this.quantities().at(j).patchValue({ start_date: startdate });
                this.quantities().at(j).patchValue({ end_date: enddate });
              }
            } else {
              let now = new Date(
                this.personalInfoFormGroup.controls.start_date.value
              );
              now.setDate(
                now.getDate() +
                  (parseInt(this.selectedtherapyplan[0][3].days) - 1)
              );
              this.personalInfoFormGroup.patchValue({
                end_date: now,
              });
              this.phase[0] = {
                start_date:
                  this.personalInfoFormGroup.controls.start_date.value,
                end_date: now,
              };
              let m: number = 0;
              for (let i = 0; i < this.selectedtherapyplan.length; i++) {
                if (this.selectedtherapyplan[i][2].phase == 1) {
                  this.exercisesequencelist[0].push({
                    id: this.selectedtherapyplan[i][0]._id,
                    value: this.selectedtherapyplan[i][0].exercise_name1,
                    img: this.selectedtherapyplan[i][1].image,
                    diff: this.selectedtherapyplan[i][0].difficully_level,
                    tags: this.selectedtherapyplan[i][0].tags,
                  });
                  m++;
                }
              }

              let lastenddate = new Date(
                this.personalInfoFormGroup.controls.end_date.value
              );
              let startdate;
              let enddate;
              for (let j = 0; j < totalphase - 1; j++) {
                this.quantities().push(this.newQuantity());
                this.exercisesequencelist.push([]);
                lastenddate.setDate(lastenddate.getDate() + 1);
                startdate = new Date(lastenddate);
                enddate = new Date(startdate);
                enddate.setDate(
                  enddate.getDate() +
                    (parseInt(this.selectedtherapyplan[m][3].days) - 1)
                );

                lastenddate = new Date(enddate);
                this.phase[j + 1] = {
                  start_date: startdate,
                  end_date: enddate,
                };
                this.minDatesecondstep[j] = startdate;
                this.minDateforendsecondstep[j] = startdate;
                for (let k = 0; k < this.selectedtherapyplan.length; k++) {
                  if (this.selectedtherapyplan[k][2].phase == j + 2) {
                    this.exercisesequencelist[j + 1].push({
                      id: this.selectedtherapyplan[k][0]._id,
                      value: this.selectedtherapyplan[k][0].exercise_name1,
                      img: this.selectedtherapyplan[k][1].image,
                      diff: this.selectedtherapyplan[k][0].difficully_level,
                      tags: this.selectedtherapyplan[k][0].tags,
                    });
                    m++;
                  }
                }
                this.quantities().at(j).patchValue({ start_date: startdate });
                this.quantities().at(j).patchValue({ end_date: enddate });
              }
            }

            $("#loadertext").html("");
            $("#loadertext").css("width", "100px");
            $("#loaderouterid").css("display", "none");
            $("#loadertext").css("margin-left", "-50px");
          }
        },
        (rejected) => {
          console.log(rejected);
        }
      );
    }
    else  if (this.searchby == "doctorbutton") {
      $("#loaderouterid").css("display", "block");
      $("#loadertext").html("Please wait for extract therapy Plan..");
      $("#loadertext").css("width", "320px");
      $("#loadertext").css("margin-left", "-160px");
      const data = this.userService.gettreatementplannew(datanew).subscribe(
        (success: any) => {
          if (success.status == "error") {
            $("#loadertext").html("");
            $("#loadertext").css("width", "100px");
            $("#loaderouterid").css("display", "none");
            $("#loadertext").css("margin-left", "-50px");
          } else {
            this.selectedtherapyplan = success;
            let totalphase =
              this.selectedtherapyplan[this.selectedtherapyplan.length - 1][2]
                .phase;
            if (
              this.personalInfoFormGroup.controls.secondstep.value.length > 0
            ) {
              let m: number = 0;
              let datearray = [];
              let enddatearray = [];
              let phaselength = this.phase.length;
              let lastenddate = new Date(
                this.personalInfoFormGroup.controls.secondstep.value[
                  phaselength - 2
                ].end_date
              );
              let startdate;
              let enddate;
              for (
                let j = phaselength - 1;
                j < totalphase + (phaselength - 1);
                j++
              ) {
                this.quantities().push(this.newQuantity());
                this.exercisesequencelist.push([]);
                lastenddate.setDate(lastenddate.getDate() + 1);
                startdate = new Date(lastenddate);
                enddate = new Date(startdate);
                enddate.setDate(
                  enddate.getDate() +
                    (parseInt(this.selectedtherapyplan[m][3].days) - 1)
                );
                datearray.push({ start_date: startdate });
                enddatearray.push({ end_date: enddate });
                lastenddate = new Date(enddate);
                this.phase[j + 1] = {
                  start_date: startdate,
                  end_date: enddate,
                };
                this.minDatesecondstep[j] = startdate;
                this.minDateforendsecondstep[j] = startdate;
                for (let k = 0; k < this.selectedtherapyplan.length; k++) {
                  let index: any = j + 1 - (phaselength - 1);
                  if (this.selectedtherapyplan[k][2].phase == parseInt(index)) {
                    this.exercisesequencelist[j + 1].push({
                      id: this.selectedtherapyplan[k][0]._id,
                      value: this.selectedtherapyplan[k][0].exercise_name1,
                      img: this.selectedtherapyplan[k][1].image,
                      diff: this.selectedtherapyplan[k][0].difficully_level,
                      tags: this.selectedtherapyplan[k][0].tags,
                    });
                    m++;
                  }
                }
                this.quantities().at(j).patchValue({ start_date: startdate });
                this.quantities().at(j).patchValue({ end_date: enddate });
              }
            } else {
              let now = new Date(
                this.personalInfoFormGroup.controls.start_date.value
              );
              now.setDate(
                now.getDate() +
                  (parseInt(this.selectedtherapyplan[0][3].days) - 1)
              );
              this.personalInfoFormGroup.patchValue({
                end_date: now,
              });
              this.phase[0] = {
                start_date:
                  this.personalInfoFormGroup.controls.start_date.value,
                end_date: now,
              };
              let m: number = 0;
              for (let i = 0; i < this.selectedtherapyplan.length; i++) {
                if (this.selectedtherapyplan[i][2].phase == 1) {
                  this.exercisesequencelist[0].push({
                    id: this.selectedtherapyplan[i][0]._id,
                    value: this.selectedtherapyplan[i][0].exercise_name1,
                    img: this.selectedtherapyplan[i][1].image,
                    diff: this.selectedtherapyplan[i][0].difficully_level,
                    tags: this.selectedtherapyplan[i][0].tags,
                  });
                  m++;
                }
              }

              let lastenddate = new Date(
                this.personalInfoFormGroup.controls.end_date.value
              );
              let startdate;
              let enddate;
              for (let j = 0; j < totalphase - 1; j++) {
                this.quantities().push(this.newQuantity());
                this.exercisesequencelist.push([]);
                lastenddate.setDate(lastenddate.getDate() + 1);
                startdate = new Date(lastenddate);
                enddate = new Date(startdate);
                enddate.setDate(
                  enddate.getDate() +
                    (parseInt(this.selectedtherapyplan[m][3].days) - 1)
                );

                lastenddate = new Date(enddate);
                this.phase[j + 1] = {
                  start_date: startdate,
                  end_date: enddate,
                };
                this.minDatesecondstep[j] = startdate;
                this.minDateforendsecondstep[j] = startdate;
                for (let k = 0; k < this.selectedtherapyplan.length; k++) {
                  if (this.selectedtherapyplan[k][2].phase == j + 2) {
                    this.exercisesequencelist[j + 1].push({
                      id: this.selectedtherapyplan[k][0]._id,
                      value: this.selectedtherapyplan[k][0].exercise_name1,
                      img: this.selectedtherapyplan[k][1].image,
                      diff: this.selectedtherapyplan[k][0].difficully_level,
                      tags: this.selectedtherapyplan[k][0].tags,
                    });
                    m++;
                  }
                }
                this.quantities().at(j).patchValue({ start_date: startdate });
                this.quantities().at(j).patchValue({ end_date: enddate });
              }
            }

            $("#loadertext").html("");
            $("#loadertext").css("width", "100px");
            $("#loaderouterid").css("display", "none");
            $("#loadertext").css("margin-left", "-50px");
          }
        },
        (rejected) => {
          console.log(rejected);
        }
      );
    }
    else {
      if (this.personalInfoFormGroup.controls.secondstep.value.length > 0) {
        if (
          this.personalInfoFormGroup.controls.secondstep.value[
            this.personalInfoFormGroup.controls.secondstep.value.length - 1
          ].start_date != "" &&
          this.personalInfoFormGroup.controls.secondstep.value[
            this.personalInfoFormGroup.controls.secondstep.value.length - 1
          ].end_date != ""
        ) {
          add = 1;
          indexnew =
            this.personalInfoFormGroup.controls.secondstep.value.length;
          this.phase[
            this.personalInfoFormGroup.controls.secondstep.value.length
          ] = {
            start_date:
              this.personalInfoFormGroup.controls.secondstep.value[
                this.personalInfoFormGroup.controls.secondstep.value.length - 1
              ].start_date,
            end_date:
              this.personalInfoFormGroup.controls.secondstep.value[
                this.personalInfoFormGroup.controls.secondstep.value.length - 1
              ].end_date,
          };
        } else {
          add = 0;
        }
      } else {
        if (
          this.personalInfoFormGroup.controls.start_date.value != "" &&
          this.personalInfoFormGroup.controls.end_date.value != ""
        ) {
          add = 1;
          indexnew = 0;
          this.phase[0] = {
            start_date: this.personalInfoFormGroup.controls.start_date.value,
            end_date: this.personalInfoFormGroup.controls.end_date.value,
          };
        } else {
          add = 0;
        }
      }

      if (add == 1) {
        if (this.searchby == "yes") {
          let l = "b";
          console.log(
            "exercisesequencelist" + this.exercisesequencelist.length
          );
          this.exercisesequencelist[indexnew].push({
            id: id,
            value: name,
            img: img,
            diff: diff,
            tags: tags,
            data: data11,
          });
          console.log(
            "exercisesequencelist" + this.exercisesequencelist.length
          );
        } else {
          this.exercisesequencelist[indexnew].push({
            id: id,
            value: name,
            img: img,
            diff: diff,
            tags: tags,
            data: data11,
          });

          let lastindex = this.exercisesequencelist[indexnew].length;

          $("#loaderouterid").css("display", "block");
          $("#loadertext").html("Please wait for extract therapy Plan..");
          $("#loadertext").css("width", "320px");
          $("#loadertext").css("margin-left", "-160px");

          let exerciselistdata = [];

          const data = this.userService.getexercisesequence(datanew).subscribe(
            (success: any) => {
              if (success.status == "error") {
              } else {
                exerciselistdata = success;
                console.log(
                  "ddddddddddddddddddd" + JSON.stringify(exerciselistdata)
                );

                for (let i = 0; i < exerciselistdata.length; i++) {
                  console.log(
                    "ddddddddddddddddddd" +
                      JSON.stringify(exerciselistdata[i][0]._id)
                  );
                  console.log(
                    "ddddddddddddddddddd" +
                      JSON.stringify(exerciselistdata[i][1].image)
                  );
                  this.exercisesequencelist[indexnew].push({
                    id: exerciselistdata[i][0]._id,
                    value: exerciselistdata[i][0].exercise_name1,
                    img: exerciselistdata[i][1].image,
                    diff: exerciselistdata[i][0].difficully_level,
                    tags: exerciselistdata[i][0].tags,
                  });
                }

                this.exercisesequencelist[indexnew].splice(lastindex - 1, 1);

                $("#loadertext").html("");
                $("#loadertext").css("width", "100px");
                $("#loaderouterid").css("display", "none");
                $("#loadertext").css("margin-left", "-50px");
              }
            },
            (rejected) => {
              console.log(rejected);
            }
          );
        }
      } else {
        Swal.fire("Bitte Startdatum und Ablaufdatum auswählen", "", "error");
      }
    }

    // }
  }
  removesequence(index, i) {
    console.log(i);
    this.exercisesequencelist[index].splice(i, 1);
    if (this.exercisesequencelist[index].length > 0) {
    } else {
      this.phase.splice(index, 1);
    }
  }

  getpatientdata2() {
    console.log("searchlistnewlist" + JSON.stringify(this.patient_id));
    let data = "";
    this.userService
      // .getCustomers("coursula", true)
      .getCustomers("ontrain", true)
      .subscribe((success: any) => {
        if (success.status == "error") {
          Swal.fire("Kein Patient gefunden", "", "error");
        } else {
          // this.patientlistnew = this.filtercustomer(success, "Coursula");
          this.patientlistnew = this.filtercustomer(success, "Ontrain");
          console.log(
            "searchlistnewlist" + JSON.stringify(this.patientlistnew)
          );

          this.patientlistnew.forEach((element) => {
            console.log("searchlistnewlist" + JSON.stringify(element.id));
            if (element.id == this.patient_id) {
              data = element.name;
              this.exercise_controlspatient.setValue(data);
            }
          });
        }
      });
  }
  getpatientdata1() {
    this.userService
      // .getCustomers("coursula", true)
      .getCustomers("ontrain", true)
      .subscribe((success: any) => {
        if (success.status == "error") {
          Swal.fire("Kein Patient gefunden", "", "error");
        } else {
          // this.patientlistnew = this.filtercustomer(success, "Coursula");
          this.patientlistnew = this.filtercustomer(success, "Ontrain");
          console.log("searchlistnewlist" + JSON.stringify(this.patientlist));
        }
      });
    let data = this.newpatientname;

    this.exercise_controlspatient.setValue(data);
    this.patientformgroup.patchValue({
      patientdata: this.patient_id,
    });
  }

  getexercisenew() {
    // this.getexercise(
    //   this.exercise_controls.value.id,
    //   this.exercise_controls.value.name,
    //   this.exercise_controls.value.img,
    //   this.exercise_controls.value.diff
    // );

    if (this.typesOptionsArray.length > 19) {
      Swal.fire("Begrenzen Sie 20 Tags", "", "error");
    } else {
      console.log("ffffffffffff" + JSON.stringify(this.searchlist));
      if ((this.exercise_controls.value.name || "").trim()) {
        this.typesOptionsArray.push(this.exercise_controls.value.name);
        this.setsearchlist();
        this.exercise_controls.setValue(" ");
        $("#datanew").trigger("click");
      }
      // Reset the input value
    }
  }

  _filterpatientdata(name) {
    if (this.exercise_controlspatient.value == "") {
      this.patient_id = "";
    }
    let searchlistnewlist = this.patientlistnew.filter(function (text) {
      return text.name.toLowerCase().indexOf(name.toLowerCase()) > -1;
    });

    console.log("searchlistnewlist" + JSON.stringify(searchlistnewlist));

    return searchlistnewlist;
  }

  _filtertherapyplandata(name) {
    console.log(this.therapyplancontrol.value);
    if (this.therapyplancontrol.value == "") {
      this.therapyplanid = "";
    }

    let searchlistnewlist = this.therapyplanlistnew.filter(function (text) {
      return text.name.toLowerCase().indexOf(name.toLowerCase()) > -1;
    });
    return searchlistnewlist;
  }

  registernew(EmailSendOrNot) {
    //let statusbrandarraynew = ["Coursula"];
    let statusbrandarraynew = ["Ontrain"];
    let statusarraynew = [this.personalInfoForm.status.value];
    // let brokerbrandarraynew = ["Coursula"];
    let brokerbrandarraynew = ["Ontrain"];
    let brokerarraynew = [
      this.userService.getDecodedAccessToken(localStorage.getItem("token"))
        .customerno,
    ];
    let roles = ["customer"];
    // let companies = ["coursula"];
    // let rolesCompaniesArray = ["coursula_customer"];
    let companies = ["ontrain"];
    let rolesCompaniesArray = ["ontrain_customer"];
    this.newpatientname =
      this.personalInfoForm.firstName.value +
      " " +
      this.personalInfoForm.lastName.value +
      " (" +
      this.moreinfoForm.customerno.value +
      ")";

    let data = {
      // Roles & Companies
      roles: roles,
      companies: companies,
      companies_with_roles: rolesCompaniesArray,
      //companies: this.companyFormGroup.value.companies,

      // Personal Information
      title: this.personalInfoForm.title.value,
      companyname: this.personalInfoForm.companyName.value,
      firstname: this.personalInfoForm.firstName.value,
      lastname: this.personalInfoForm.lastName.value,
      email: this.personalInfoForm.email.value,
      dateofbirth: this.personalInfoForm.dob.value,
      nationality: this.personalInfoForm.nationality.value,
      birth_place: this.personalInfoForm.birthPlace.value,
      customer_status: this.personalInfoForm.status.value,
      // Address Information
      strassa: this.addressInfoForm.street.value,
      strno: this.addressInfoForm.streetNumber.value,
      plz: this.addressInfoForm.postCode.value,
      city: this.addressInfoForm.city.value,
      additionalReference: this.addressInfoForm.additionalReference.value,
      current_country: this.addressInfoForm.countryOfResidence.value,

      //More Information
      password: this.moreinfoForm.password.value,
      contactno: this.moreinfoForm.phone_number.value,
      customerno: this.moreinfoForm.customerno.value,
      emailsendornot: EmailSendOrNot,
      statusbrandarray: statusbrandarraynew,
      statusarray: statusarraynew,
      brokerbrandarray: brokerbrandarraynew,
      brokerarray: brokerarraynew,
      status: "1",
      plantype:this.activemembership[0].plantype,
      companycode: ["c42150_customer"],
      broker: this.userService.getDecodedAccessToken(
        localStorage.getItem("token")
      ).customerno,
    };
    this.userService
      .registerUsercoursula_user(data)
      .pipe(first())
      .subscribe(
        (data) => {
          console.log("POST Request is successful ", data);
          this.registerDone = true;
          // this.patient_id=data._id;
          this.patient_id = data["_id"];
          this.getpatientdata1();
          this.GoToUploadDocument();
        },
        (error) => {
          this.registraionError = true;
          //console.log("Error", error['error']);
        }
      );
  }

  addOpt(event: MatChipInputEvent, index: number): void {
    const input = event.input;
    const value = event.value;
    console.log("dsdsad" + index);
    console.log("input" + input);
    console.log("value" + value);
    console.log("valuenew" + value.trim());
    console.log("valuenew" + this.typesOptionsArray);
    // Add our fruit
    if (this.typesOptionsArray.length > 19) {
      Swal.fire("Begrenzen Sie 20 Tags", "", "error");
    } else {
      console.log("ffffffffffff" + JSON.stringify(this.searchlist));
      if ((value || "").trim()) {
        this.typesOptionsArray.push(value.trim());
        this.setsearchlist();
        this.exercise_controls.setValue(" ");
        $("#datanew").trigger("click");
      }
      // Reset the input value
      if (input) {
        input.value = "";
      }
    }
  }

  //   getstart_date(event)
  //   {
  //  console.log("start_date111"+this.personalInfoFormGroup.controls.start_date.value);
  //  this.minDateforend=this.personalInfoFormGroup.controls.start_date.value;
  //     let numWeeks = 12;
  // let now = new Date(this.personalInfoFormGroup.controls.start_date.value);
  // now.setDate(now.getDate() + numWeeks * 7);
  // this.maxDate11=now;

  //   }

  drop(event: CdkDragDrop<any[]>) {
    let lengthtotal = this.exerciselength(this.exercisesequencelist.length) - 1;
    let indexnewcurrent: any = lengthtotal - event.currentIndex;
    let indexnewprevious: any = lengthtotal - event.previousIndex;
    if (event.previousContainer === event.container) {
      let key1;
      let count = 0;
      $.each(this.exercisesequencelist, function (key, element) {
        let coutexercise = element.length;
        count = count + coutexercise;
        if (indexnewcurrent < count) {
          key1 = key;
          return false;
        }
      });

      let key1previous;
      let countprevious = 0;
      $.each(
        this.exercisesequencelist,
        function (keyprevious, elementprevious) {
          let coutexerciseprevious = elementprevious.length;
          countprevious = countprevious + coutexerciseprevious;
          if (indexnewprevious < countprevious) {
            key1previous = keyprevious;
            return false;
          }
        }
      );

      if (this.exercisesequencelist[key1previous].length > 1) {
        console.log("key1previous" + key1previous);
        console.log("key1previous" + key1);
        if (key1previous == key1) {
          let totalpreviouscount = 0;
          if (key1 >= 0) {
            for (let k = 0; k < key1; k++) {
              totalpreviouscount =
                totalpreviouscount + this.exercisesequencelist[k].length;
            }
          }

      
          let movedata = {
            id: event.previousContainer.data[key1][
              indexnewprevious - totalpreviouscount
            ].id,
            value:
              event.previousContainer.data[key1][
                indexnewprevious - totalpreviouscount
              ].value,
            img: event.previousContainer.data[key1][
              indexnewprevious - totalpreviouscount
            ].img,
            diff: event.previousContainer.data[key1][
              indexnewprevious - totalpreviouscount
            ].diff,
            tags: event.previousContainer.data[key1][
              indexnewprevious - totalpreviouscount
            ].tags,
          };

          if (
            indexnewcurrent - totalpreviouscount >
            indexnewprevious - totalpreviouscount
          ) {
            for (
              let i = (indexnewprevious - totalpreviouscount) + 1;
              i <= indexnewcurrent - totalpreviouscount;
              i++
            ) {
              let movedata1 = {
                id: event.previousContainer.data[key1][i].id,
                value: event.previousContainer.data[key1][i].value,
                img: event.previousContainer.data[key1][i].img,
                diff: event.previousContainer.data[key1][i].diff,
                tags: event.previousContainer.data[key1][i].tags,
              };

              this.exercisesequencelist[key1][i - 1] = movedata1;
            }

            this.exercisesequencelist[key1][
              (indexnewcurrent - totalpreviouscount)
            ] = movedata;
          } else {
            for (
              let i = (indexnewprevious - totalpreviouscount) - 1;
              i >= indexnewcurrent - totalpreviouscount;
              i--
            ) {
              let movedata1 = {
                id: event.previousContainer.data[key1][i].id,
                value: event.previousContainer.data[key1][i].value,
                img: event.previousContainer.data[key1][i].img,
                diff: event.previousContainer.data[key1][i].diff,
                tags: event.previousContainer.data[key1][i].tags,
              };

              this.exercisesequencelist[key1][i + 1] = movedata1;
            }

            this.exercisesequencelist[key1][
              (indexnewcurrent - totalpreviouscount)
            ] = movedata;
          }
        } else {
          let totalpreviouscountnewprevious = 0;
          if (key1previous > 0) {
            for (let k = 0; k < key1previous; k++) {
              totalpreviouscountnewprevious =
                totalpreviouscountnewprevious +
                this.exercisesequencelist[k].length;
            }
          }
          console.log(
            "totalpreviouscountnewprevious" +
            totalpreviouscountnewprevious +
              "previousIndex" +
              event.previousIndex +
              "indexnewprevious" +
              indexnewprevious +
              "currentIndex" +
              event.currentIndex +
              "indexnewcurrent" +
              indexnewcurrent
          );

          let movedata = {
            id: event.previousContainer.data[key1previous][
              indexnewprevious - totalpreviouscountnewprevious
            ].id,
            value:
              event.previousContainer.data[key1previous][
                indexnewprevious - totalpreviouscountnewprevious
              ].value,
            img: event.previousContainer.data[key1previous][
              indexnewprevious - totalpreviouscountnewprevious
            ].img,
            diff: event.previousContainer.data[key1previous][
              indexnewprevious - totalpreviouscountnewprevious
            ].diff,
            tags: event.previousContainer.data[key1previous][
              (indexnewprevious - totalpreviouscountnewprevious)
            ].tags,
          };

          if (
            (indexnewprevious - totalpreviouscountnewprevious) + 1 ==
            this.exercisesequencelist[key1previous].length
          ) {
            this.exercisesequencelist[key1previous].splice(
              indexnewprevious - totalpreviouscountnewprevious,
              1
            );
          } else {
            for (
              let i = indexnewprevious - totalpreviouscountnewprevious + 1;
              i < this.exercisesequencelist[key1previous].length;
              i++
            ) {
              let movedata1 = {
                id: event.previousContainer.data[key1previous][i].id,
                value: event.previousContainer.data[key1previous][i].value,
                img: event.previousContainer.data[key1previous][i].img,
                diff: event.previousContainer.data[key1previous][i].diff,
                tags: event.previousContainer.data[key1previous][i].tags,
              };

              this.exercisesequencelist[key1previous][i - 1] = movedata1;
            }
            this.exercisesequencelist[key1previous].splice(
              this.exercisesequencelist[key1previous].length - 1,
              1
            );
          }

          let totalpreviouscount = 0;
          if (key1 > 0) {
            for (let k = 0; k < key1; k++) {
              totalpreviouscount =
                totalpreviouscount + this.exercisesequencelist[k].length;
            }
          }
          console.log(
            "totalpreviouscount" +
            totalpreviouscount 
              
          );
          let lastarraystartindex;
          if (indexnewcurrent > (totalpreviouscount)) {
            lastarraystartindex = indexnewcurrent - (totalpreviouscount);
          } else {
            lastarraystartindex = totalpreviouscount - indexnewcurrent;
          }
       
          let currentarraylength = this.exercisesequencelist[key1].length;

          if (lastarraystartindex <= currentarraylength - 1) {
           
            for (
              let m = currentarraylength - 1;
              m >= lastarraystartindex;
              m--
            ) {
              let movedata1 = {
                id: event.previousContainer.data[key1][m].id,
                value: event.previousContainer.data[key1][m].value,
                img: event.previousContainer.data[key1][m].img,
                diff: event.previousContainer.data[key1][m].diff,
                tags: event.previousContainer.data[key1][m].tags,
              };
              if (m == currentarraylength) {
                this.exercisesequencelist[key1].push(movedata1);
              } else {
                this.exercisesequencelist[key1][m + 1] = movedata1;
              }
            }
          }
          this.exercisesequencelist[key1][lastarraystartindex] = movedata;
        }
      } else {
        Swal.fire(
          "In dieser Phase ist nur noch 1 Übung übrig. Also nicht bewegen",
          "",
          "error"
        );
      }

      // moveItemInArray(
      //   event.container.data,
      //   event.previousIndex,
      //   event.currentIndex
      // );
    } else {
  

      var datanew = {
        id: event.previousContainer.data[event.previousIndex].id,
      };
      let key1;
      let count = 0;
      $.each(this.exercisesequencelist, function (key, element) {
        let coutexercise = element.length;
        count = count + coutexercise;
        if (indexnewcurrent < count) {
          key1 = key;
          return false;
        }
      });

  
      let add = 0;
      let indexnew = 0;
      if (this.searchby == "both") {
        $("#loaderouterid").css("display", "block");
        $("#loadertext").html("Please wait for extract therapy Plan..");
        $("#loadertext").css("width", "320px");
        $("#loadertext").css("margin-left", "-160px");
        const data = this.userService.gettherapyplannew(datanew).subscribe(
          (success: any) => {
            if (success.status == "error") {
              $("#loadertext").html("");
              $("#loadertext").css("width", "100px");
              $("#loaderouterid").css("display", "none");
              $("#loadertext").css("margin-left", "-50px");
            } else {
              this.selectedtherapyplan = success;
              let totalphase =
                this.selectedtherapyplan[this.selectedtherapyplan.length - 1][2]
                  .phase;
              if (
                this.personalInfoFormGroup.controls.secondstep.value.length > 0
              ) {
                let m: number = 0;
                let datearray = [];
                let enddatearray = [];
                let phaselength = this.phase.length;
                let lastenddate = new Date(
                  this.personalInfoFormGroup.controls.secondstep.value[
                    phaselength - 2
                  ].end_date
                );
                let startdate;
                let enddate;
                for (
                  let j = phaselength - 1;
                  j < totalphase + (phaselength - 1);
                  j++
                ) {
                  this.quantities().push(this.newQuantity());
                  this.exercisesequencelist.push([]);
                  lastenddate.setDate(lastenddate.getDate() + 1);
                  startdate = new Date(lastenddate);
                  enddate = new Date(startdate);
                  enddate.setDate(
                    enddate.getDate() +
                      (parseInt(this.selectedtherapyplan[m][3].days) - 1)
                  );
                  datearray.push({ start_date: startdate });
                  enddatearray.push({ end_date: enddate });
                  lastenddate = new Date(enddate);
                  this.phase[j + 1] = {
                    start_date: startdate,
                    end_date: enddate,
                  };
                  this.minDatesecondstep[j] = startdate;
                  this.minDateforendsecondstep[j] = startdate;
                  for (let k = 0; k < this.selectedtherapyplan.length; k++) {
                    let index: any = j + 1 - (phaselength - 1);
                    if (
                      this.selectedtherapyplan[k][2].phase == parseInt(index)
                    ) {
                      this.exercisesequencelist[j + 1].push({
                        id: this.selectedtherapyplan[k][0]._id,
                        value: this.selectedtherapyplan[k][0].exercise_name1,
                        img: this.selectedtherapyplan[k][1].image,
                        diff: this.selectedtherapyplan[k][0].difficully_level,
                        tags: this.selectedtherapyplan[k][0].tags,
                      });
                      m++;
                    }
                  }
                  this.quantities().at(j).patchValue({ start_date: startdate });
                  this.quantities().at(j).patchValue({ end_date: enddate });
                }
              } else {
                let now = new Date(
                  this.personalInfoFormGroup.controls.start_date.value
                );
                now.setDate(
                  now.getDate() +
                    (parseInt(this.selectedtherapyplan[0][3].days) - 1)
                );
                this.personalInfoFormGroup.patchValue({
                  end_date: now,
                });
                this.phase[0] = {
                  start_date:
                    this.personalInfoFormGroup.controls.start_date.value,
                  end_date: now,
                };
                let m: number = 0;
                for (let i = 0; i < this.selectedtherapyplan.length; i++) {
                  if (this.selectedtherapyplan[i][2].phase == 1) {
                    this.exercisesequencelist[0].push({
                      id: this.selectedtherapyplan[i][0]._id,
                      value: this.selectedtherapyplan[i][0].exercise_name1,
                      img: this.selectedtherapyplan[i][1].image,
                      diff: this.selectedtherapyplan[i][0].difficully_level,
                      tags: this.selectedtherapyplan[i][0].tags,
                    });
                    m++;
                  }
                }

                let lastenddate = new Date(
                  this.personalInfoFormGroup.controls.end_date.value
                );
                let startdate;
                let enddate;
                for (let j = 0; j < totalphase - 1; j++) {
                  this.quantities().push(this.newQuantity());
                  this.exercisesequencelist.push([]);
                  lastenddate.setDate(lastenddate.getDate() + 1);
                  startdate = new Date(lastenddate);
                  enddate = new Date(startdate);
                  enddate.setDate(
                    enddate.getDate() +
                      (parseInt(this.selectedtherapyplan[m][3].days) - 1)
                  );

                  lastenddate = new Date(enddate);
                  this.phase[j + 1] = {
                    start_date: startdate,
                    end_date: enddate,
                  };
                  this.minDatesecondstep[j] = startdate;
                  this.minDateforendsecondstep[j] = startdate;
                  for (let k = 0; k < this.selectedtherapyplan.length; k++) {
                    if (this.selectedtherapyplan[k][2].phase == j + 2) {
                      this.exercisesequencelist[j + 1].push({
                        id: this.selectedtherapyplan[k][0]._id,
                        value: this.selectedtherapyplan[k][0].exercise_name1,
                        img: this.selectedtherapyplan[k][1].image,
                        diff: this.selectedtherapyplan[k][0].difficully_level,
                        tags: this.selectedtherapyplan[k][0].tags,
                      });
                      m++;
                    }
                  }
                  this.quantities().at(j).patchValue({ start_date: startdate });
                  this.quantities().at(j).patchValue({ end_date: enddate });
                }
              }

              $("#loadertext").html("");
              $("#loadertext").css("width", "100px");
              $("#loaderouterid").css("display", "none");
              $("#loadertext").css("margin-left", "-50px");
            }
          },
          (rejected) => {
            console.log(rejected);
          }
        );
      } 
      else  if (this.searchby == "doctorbutton") {
        $("#loaderouterid").css("display", "block");
        $("#loadertext").html("Please wait for extract therapy Plan..");
        $("#loadertext").css("width", "320px");
        $("#loadertext").css("margin-left", "-160px");
        const data = this.userService.gettreatementplannew(datanew).subscribe(
          (success: any) => {
            if (success.status == "error") {
              $("#loadertext").html("");
              $("#loadertext").css("width", "100px");
              $("#loaderouterid").css("display", "none");
              $("#loadertext").css("margin-left", "-50px");
            } else {
              this.selectedtherapyplan = success;
              let totalphase =
                this.selectedtherapyplan[this.selectedtherapyplan.length - 1][2]
                  .phase;
              if (
                this.personalInfoFormGroup.controls.secondstep.value.length > 0
              ) {
                let m: number = 0;
                let datearray = [];
                let enddatearray = [];
                let phaselength = this.phase.length;
                let lastenddate = new Date(
                  this.personalInfoFormGroup.controls.secondstep.value[
                    phaselength - 2
                  ].end_date
                );
                let startdate;
                let enddate;
                for (
                  let j = phaselength - 1;
                  j < totalphase + (phaselength - 1);
                  j++
                ) {
                  this.quantities().push(this.newQuantity());
                  this.exercisesequencelist.push([]);
                  lastenddate.setDate(lastenddate.getDate() + 1);
                  startdate = new Date(lastenddate);
                  enddate = new Date(startdate);
                  enddate.setDate(
                    enddate.getDate() +
                      (parseInt(this.selectedtherapyplan[m][3].days) - 1)
                  );
                  datearray.push({ start_date: startdate });
                  enddatearray.push({ end_date: enddate });
                  lastenddate = new Date(enddate);
                  this.phase[j + 1] = {
                    start_date: startdate,
                    end_date: enddate,
                  };
                  this.minDatesecondstep[j] = startdate;
                  this.minDateforendsecondstep[j] = startdate;
                  for (let k = 0; k < this.selectedtherapyplan.length; k++) {
                    let index: any = j + 1 - (phaselength - 1);
                    if (
                      this.selectedtherapyplan[k][2].phase == parseInt(index)
                    ) {
                      this.exercisesequencelist[j + 1].push({
                        id: this.selectedtherapyplan[k][0]._id,
                        value: this.selectedtherapyplan[k][0].exercise_name1,
                        img: this.selectedtherapyplan[k][1].image,
                        diff: this.selectedtherapyplan[k][0].difficully_level,
                        tags: this.selectedtherapyplan[k][0].tags,
                      });
                      m++;
                    }
                  }
                  this.quantities().at(j).patchValue({ start_date: startdate });
                  this.quantities().at(j).patchValue({ end_date: enddate });
                }
              } else {
                let now = new Date(
                  this.personalInfoFormGroup.controls.start_date.value
                );
                now.setDate(
                  now.getDate() +
                    (parseInt(this.selectedtherapyplan[0][3].days) - 1)
                );
                this.personalInfoFormGroup.patchValue({
                  end_date: now,
                });
                this.phase[0] = {
                  start_date:
                    this.personalInfoFormGroup.controls.start_date.value,
                  end_date: now,
                };
                let m: number = 0;
                for (let i = 0; i < this.selectedtherapyplan.length; i++) {
                  if (this.selectedtherapyplan[i][2].phase == 1) {
                    this.exercisesequencelist[0].push({
                      id: this.selectedtherapyplan[i][0]._id,
                      value: this.selectedtherapyplan[i][0].exercise_name1,
                      img: this.selectedtherapyplan[i][1].image,
                      diff: this.selectedtherapyplan[i][0].difficully_level,
                      tags: this.selectedtherapyplan[i][0].tags,
                    });
                    m++;
                  }
                }

                let lastenddate = new Date(
                  this.personalInfoFormGroup.controls.end_date.value
                );
                let startdate;
                let enddate;
                for (let j = 0; j < totalphase - 1; j++) {
                  this.quantities().push(this.newQuantity());
                  this.exercisesequencelist.push([]);
                  lastenddate.setDate(lastenddate.getDate() + 1);
                  startdate = new Date(lastenddate);
                  enddate = new Date(startdate);
                  enddate.setDate(
                    enddate.getDate() +
                      (parseInt(this.selectedtherapyplan[m][3].days) - 1)
                  );

                  lastenddate = new Date(enddate);
                  this.phase[j + 1] = {
                    start_date: startdate,
                    end_date: enddate,
                  };
                  this.minDatesecondstep[j] = startdate;
                  this.minDateforendsecondstep[j] = startdate;
                  for (let k = 0; k < this.selectedtherapyplan.length; k++) {
                    if (this.selectedtherapyplan[k][2].phase == j + 2) {
                      this.exercisesequencelist[j + 1].push({
                        id: this.selectedtherapyplan[k][0]._id,
                        value: this.selectedtherapyplan[k][0].exercise_name1,
                        img: this.selectedtherapyplan[k][1].image,
                        diff: this.selectedtherapyplan[k][0].difficully_level,
                        tags: this.selectedtherapyplan[k][0].tags,
                      });
                      m++;
                    }
                  }
                  this.quantities().at(j).patchValue({ start_date: startdate });
                  this.quantities().at(j).patchValue({ end_date: enddate });
                }
              }

              $("#loadertext").html("");
              $("#loadertext").css("width", "100px");
              $("#loaderouterid").css("display", "none");
              $("#loadertext").css("margin-left", "-50px");
            }
          },
          (rejected) => {
            console.log(rejected);
          }
        );
      } 
      
      else {
        if (this.personalInfoFormGroup.controls.secondstep.value.length > 0) {
          if (
            this.personalInfoFormGroup.controls.secondstep.value[
              this.personalInfoFormGroup.controls.secondstep.value.length - 1
            ].start_date != "" &&
            this.personalInfoFormGroup.controls.secondstep.value[
              this.personalInfoFormGroup.controls.secondstep.value.length - 1
            ].end_date != ""
          ) {
            add = 1;
            indexnew =
              this.personalInfoFormGroup.controls.secondstep.value.length;
            this.phase[
              this.personalInfoFormGroup.controls.secondstep.value.length
            ] = {
              start_date:
                this.personalInfoFormGroup.controls.secondstep.value[
                  this.personalInfoFormGroup.controls.secondstep.value.length -
                    1
                ].start_date,
              end_date:
                this.personalInfoFormGroup.controls.secondstep.value[
                  this.personalInfoFormGroup.controls.secondstep.value.length -
                    1
                ].end_date,
            };
          } else {
            add = 0;
          }
        } else {
          if (
            this.personalInfoFormGroup.controls.start_date.value != "" &&
            this.personalInfoFormGroup.controls.end_date.value != ""
          ) {
            add = 1;
            indexnew = 0;
            this.phase[0] = {
              start_date: this.personalInfoFormGroup.controls.start_date.value,
              end_date: this.personalInfoFormGroup.controls.end_date.value,
            };
          } else {
            add = 0;
          }
        }

        let totalpreviouscountnewprevious11111111 = 0;
        if (key1 > 0) {
          for (let k = 0; k < key1; k++) {
            totalpreviouscountnewprevious11111111 =
              totalpreviouscountnewprevious11111111 +
              this.exercisesequencelist[k].length;
          }
        }

        if (add == 1) {
          if (this.searchby == "yes") {
            if (this.exercisesequencelist[indexnew].length > 0) {
              let currentindex = indexnewcurrent + 1 - totalpreviouscountnewprevious11111111;
            
              console.log(
                "currentindex" +
                  currentindex +
                  "currentindex" +
                  event.currentIndex +
                  "indexnewcurrent" +
                  indexnewcurrent +
                  "totalpreviouscountnewprevious11111111" +
                  totalpreviouscountnewprevious11111111
              );

              if (key1 == undefined) {
                this.exercisesequencelist[indexnew].push({
                  id: event.previousContainer.data[event.previousIndex].id,
                  value:
                    event.previousContainer.data[event.previousIndex].value,
                  img: event.previousContainer.data[event.previousIndex].img,
                  diff: event.previousContainer.data[event.previousIndex].diff,
                  tags: event.previousContainer.data[event.previousIndex].tags,
                });
              } else {
                if (this.exercisesequencelist[key1].length > currentindex) {
                  let currentmovedata = {
                    id: this.exercisesequencelist[key1][currentindex].id,
                    value: this.exercisesequencelist[key1][currentindex].value,
                    img: this.exercisesequencelist[key1][currentindex].img,
                    diff: this.exercisesequencelist[key1][currentindex].diff,
                    tags: this.exercisesequencelist[key1][currentindex].tags,
                  };
                  this.exercisesequencelist[key1][currentindex] = {
                    id: event.previousContainer.data[event.previousIndex].id,
                    value:
                      event.previousContainer.data[event.previousIndex].value,
                    img: event.previousContainer.data[event.previousIndex].img,
                    diff: event.previousContainer.data[event.previousIndex]
                      .diff,
                    tags: event.previousContainer.data[event.previousIndex]
                      .tags,
                  };

                  // this.exercisesequencelist[key1].push([]);

                  for (
                    var i = this.exercisesequencelist[key1].length - 1;
                    i > currentindex;
                    i--
                  ) {
                    this.exercisesequencelist[key1][i + 1] = {
                      id: this.exercisesequencelist[key1][i].id,
                      value: this.exercisesequencelist[key1][i].value,
                      img: this.exercisesequencelist[key1][i].img,
                      diff: this.exercisesequencelist[key1][i].diff,
                      tags: this.exercisesequencelist[key1][i].tags,
                    };
                  }

                  this.exercisesequencelist[key1][currentindex + 1] =
                    currentmovedata;
                } else {
                  this.exercisesequencelist[key1].push({
                    id: event.previousContainer.data[event.previousIndex].id,
                    value:
                      event.previousContainer.data[event.previousIndex].value,
                    img: event.previousContainer.data[event.previousIndex].img,
                    diff: event.previousContainer.data[event.previousIndex]
                      .diff,
                    tags: event.previousContainer.data[event.previousIndex]
                      .tags,
                  });
                }
              }
            } else {
              this.exercisesequencelist[indexnew].push({
                id: event.previousContainer.data[event.previousIndex].id,
                value: event.previousContainer.data[event.previousIndex].value,
                img: event.previousContainer.data[event.previousIndex].img,
                diff: event.previousContainer.data[event.previousIndex].diff,
                tags: event.previousContainer.data[event.previousIndex].tags,
              });
            }
          } else {
            $("#loaderouterid").css("display", "block");
            $("#loadertext").html("Please wait for extract therapy Plan..");
            $("#loadertext").css("width", "320px");
            $("#loadertext").css("margin-left", "-160px");
            let exerciselistdata = [];
            const data = this.userService
              .getexercisesequence(datanew)
              .subscribe(
                (success: any) => {
                  if (success.status == "error") {
                  } else {
                    exerciselistdata = success;
                    for (let i = 0; i < exerciselistdata.length; i++) {
                      if (this.exercisesequencelist[indexnew].length > 0) {
                        let currentindex =
                          indexnewcurrent +
                          1 -
                          totalpreviouscountnewprevious11111111;
                        if (key1 == undefined) {
                          this.exercisesequencelist[indexnew].push({
                            id: event.previousContainer.data[
                              event.previousIndex
                            ].id,
                            value:
                              event.previousContainer.data[event.previousIndex]
                                .value,
                            img: event.previousContainer.data[
                              event.previousIndex
                            ].img,
                            diff: event.previousContainer.data[
                              event.previousIndex
                            ].diff,
                            tags: event.previousContainer.data[
                              event.previousIndex
                            ].tags,
                          });
                        } else {
                          if (
                            this.exercisesequencelist[key1].length >
                            currentindex
                          ) {
                            let currentmovedata = {
                              id: this.exercisesequencelist[key1][currentindex]
                                .id,
                              value:
                                this.exercisesequencelist[key1][currentindex]
                                  .value,
                              img: this.exercisesequencelist[key1][currentindex]
                                .img,
                              diff: this.exercisesequencelist[key1][
                                currentindex
                              ].diff,
                              tags: this.exercisesequencelist[key1][
                                currentindex
                              ].tags,
                            };
                            this.exercisesequencelist[key1][currentindex] = {
                              id: event.previousContainer.data[
                                event.previousIndex
                              ].id,
                              value:
                                event.previousContainer.data[
                                  event.previousIndex
                                ].value,
                              img: event.previousContainer.data[
                                event.previousIndex
                              ].img,
                              diff: event.previousContainer.data[
                                event.previousIndex
                              ].diff,
                              tags: event.previousContainer.data[
                                event.previousIndex
                              ].tags,
                            };

                            // this.exercisesequencelist[key1].push([]);

                            for (
                              var j =
                                this.exercisesequencelist[key1].length - 1;
                              j > currentindex;
                              j--
                            ) {
                              this.exercisesequencelist[key1][j + 1] = {
                                id: this.exercisesequencelist[key1][j].id,
                                value: this.exercisesequencelist[key1][j].value,
                                img: this.exercisesequencelist[key1][j].img,
                                diff: this.exercisesequencelist[key1][j].diff,
                                tags: this.exercisesequencelist[key1][j].tags,
                              };
                            }

                            this.exercisesequencelist[key1][currentindex + 1] =
                              currentmovedata;
                          } else {
                            this.exercisesequencelist[key1].push({
                              id: event.previousContainer.data[
                                event.previousIndex
                              ].id,
                              value:
                                event.previousContainer.data[
                                  event.previousIndex
                                ].value,
                              img: event.previousContainer.data[
                                event.previousIndex
                              ].img,
                              diff: event.previousContainer.data[
                                event.previousIndex
                              ].diff,
                              tags: event.previousContainer.data[
                                event.previousIndex
                              ].tags,
                            });
                          }
                        }
                      } else {
                        this.exercisesequencelist[indexnew].push({
                          id: exerciselistdata[i][event.previousIndex]._id,
                          value:
                            exerciselistdata[i][event.previousIndex]
                              .exercise_name1,
                          img: exerciselistdata[i][1].image,
                          diff: exerciselistdata[i][event.previousIndex]
                            .difficully_level,
                          tags: exerciselistdata[i][event.previousIndex].tags,
                        });
                      }
                    }
                    $("#loadertext").html("");
                    $("#loadertext").css("width", "100px");
                    $("#loaderouterid").css("display", "none");
                    $("#loadertext").css("margin-left", "-50px");
                  }
                },
                (rejected) => {
                  console.log(rejected);
                }
              );
          }
        } else {
          Swal.fire(
            "Bitte geben Sie die Dauer als numerischen Wert ein",
            "",
            "error"
          );
        }
      }
    }
  }

  openinformation(id, index, tags) {
    $("#loaderouterid").css("display", "block");
    this.getcurrentUser(id);
  }
  getcurrentUser(openid) {
    const that = this;

    // console.log("call api")
    this.userService.getEditexercise(openid).subscribe(function (data1) {
      setData(data1);
    });

    function setData(data1) {
      console.log("dddddddddddd" + JSON.stringify(data1));
      console.log("dddddddddddd" + data1.element.exercise_category);
      that.choosecategory11(data1.element.exercise_category);

      // that.calcount("commentcount", "comment");
      let commentlength: any = 500 - data1.element.comment.length;
      $("#commentcount").html(commentlength);
      let commentlength1: any = 500 - data1.element.comment1.length;
      $("#comment1count").html(commentlength1);
      let exercise_name: any = 35 - data1.element.exercise_name.length;
      $("#exercise_namecount").html(exercise_name);
      let exercise_name1: any = 35 - data1.element.exercise_name1.length;
      $("#exercise_name1count").html(exercise_name1);
      let tags: any = 20 - data1.element.tags.length;
      $("#manual_tagscount").html(tags);

      that.personalInfoFormGroupmodal.patchValue({
        exercise_name: data1.element.exercise_name,
        exercise_name1: data1.element.exercise_name1,
        difficully_level: data1.element.difficully_level,
        tools: data1.element.tools,
        comment: data1.element.comment,
        comment1: data1.element.comment1,
      });
      that.oldtypesOptionsArray = data1.element.tags;
      console.log("dddddddddddd" + that.oldtypesOptionsArray);
      $("#logopreview0").html(
        "<img src='" + data1.image1 + "' style='width: 100%;height: 300px'>"
      );
      $("#logopreview1").html(
        "<video  style='width: 100%;height: 300px' controls><source  src='" +
          data1.image2 +
          "' type='video/mp4'></video>"
      );
      $("#logopreview2").html(
        "<video  style='width: 100%;height: 300px' controls><source  src='" +
          data1.image3 +
          "' type='video/mp4'></video>"
      );
      $("#loaderouterid").css("display", "none");
    }

    // this.choosecategory();
    // $('#loaderouterid').css("display","none");
  }

  setsearchlist() {
    if (this.searchby == "yes") {
      this.exercisesequencelistsearch = [];
      if (this.typesOptionsArray.length > 0) {
        for (let i = 0; i < this.searchlist.length; i++) {
          console.log("ffffffffffff" + this.searchlist[i]);
          this.exercisesequencelistsearch.push({
            id: this.searchlist[i].id,
            value: this.searchlist[i].name,
            img: this.searchlist[i].img,
            diff: this.searchlist[i].diff,
            tags: this.searchlist[i].tags,
          });
        }
      }
    } else if (this.searchby == "both") {
      this.exercisesequencelistsearch = [];
      if (this.typesOptionsArray.length > 0) {
        for (let i = 0; i < this.searchlist.length; i++) {
          console.log("ffffffffffff" + this.searchlist[i]);
          this.exercisesequencelistsearch.push({
            id: this.searchlist[i].id,
            value: this.searchlist[i].name,
            img: this.searchlist[i].img,
            diff: this.searchlist[i].diff,
            tags: "",
          });
        }
      }
    }else if (this.searchby == "doctorbutton") {
      this.exercisesequencelistsearch = [];
      if (this.typesOptionsArray.length > 0) {
        for (let i = 0; i < this.searchlist.length; i++) {
          console.log("ffffffffffff" + this.searchlist[i]);
          this.exercisesequencelistsearch.push({
            id: this.searchlist[i].id,
            value: this.searchlist[i].name,
            img: this.searchlist[i].img,
            diff: this.searchlist[i].diff,
            tags: "",
          });
        }
      }
    } else {
      this.exercisesequencelistsearch = [];
      if (this.typesOptionsArray.length > 0) {
        for (let i = 0; i < this.searchlist.length; i++) {
          console.log("ffffffffffff" + this.searchlist[i]);
          this.exercisesequencelistsearch.push({
            id: this.searchlist[i].id,
            value: this.searchlist[i].name,
            img: this.searchlist[i].img,
            diff: this.searchlist[i].diff,
            tags: "",
          });
        }
      }
    }
  }

  removeOpt(opt: string, index: number): void {
    console.log("removeOpt");
    const optIndex = this.typesOptionsArray.indexOf(opt);
    if (optIndex >= 0) {
      this.typesOptionsArray.splice(optIndex, 1);
    }
    this.exercise_controls.setValue("");
    this.setsearchlist();
  }

  GoToUploadDocument() {
    setTimeout(() => {
      $("#weiterbutton").trigger("click");
      this.StpperForm = false;
    }, 1000);
  }

  logout() {
    localStorage.removeItem("token");
    this.router.navigate(["./"]);
  }

  add_exercise() {
    this.router.navigate(["/start-treatment"]);
  }

  getLastUser() {
    this.userService
      .getLastUser()
      .pipe(first())
      .subscribe((data) => {
        this.autoGeneratedCustomerNumber = data.toString();
        //return this.autoGeneratedCustomerNumber;
      });
  }
  autoPassword() {
    //console.log('in autopassword function');
    this.userService
      .getPassword()
      .pipe(first())
      .subscribe(
        (data) => {
          this.password = data.toString();
          this.moreinfoFormGroup.patchValue({ password: this.password });
          //console.log('password ====', data);
          return data;
        },
        (error) => {
          //console.error(error.message);
          //console.log("Error", error['error']);
        }
      );
  }

  handleAddressChange(data) {
    const splitArr = data.address_components;
    this.getCountry(splitArr);
  }
  handleAddressChangeland(data) {
    const splitArr = data.address_components;
    this.getCountryland(splitArr);
  }

  getCountryland(data): any {
    let that = this;
    const splitArr = data;
    // console.log(splitArr);
    splitArr.forEach(function (i, k) {
      let content: any = i.types;
      //// console.log(content);
      if (content.length > 1) {
        const countryArr = content;
        for (let index = 0; index < content.length; index++) {
          if (countryArr[index] === "country") {
            that.countryName = i.long_name;
          }

          let localityCountry = that.countryName;
          that.addressFormGroup.patchValue({
            countryOfResidence: localityCountry,
          });
        }
      }
    });
  }

  handleAllFields(data) {
    //console.log("original data = ", data);
    const splitArr = data.address_components;
    this.getAllFields(splitArr);
  }

  getCountry(data): any {
    let that = this;
    const splitArr = data;
    //console.log(splitArr);
    splitArr.forEach(function (i, k) {
      let content: any = i.types;
      ////console.log(content);
      if (content.length > 1) {
        const countryArr = content;
        for (let index = 0; index < content.length; index++) {
          if (countryArr[index] === "country") {
            that.countryName = i.long_name;
            //that.countryName = i.short_name;
            ////console.log("country",i.short_name);
            //that.personalInfoFormGroupadd.patchValue({birthPlace: i.short_name});
          }
          if (countryArr[index] === "locality") {
            that.localityName = i.short_name;
            ////console.log("locality",i.short_name);
          }
          let localityCountry = that.localityName + "," + that.countryName;
          that.personalInfoFormGroupadd.patchValue({
            birthPlace: localityCountry,
          });
          //that.personalInfoFormGroupadd.patchValue({nationality: that.countryName});
          /*if(countryArr[index] === 'country'){
            that.personalInfoFormGroupadd.patchValue({nationality: i.short_name});
          }*/
        }
      }
    });
  }

  getAllFields(data): any {
    let that = this;
    const splitArr = data;
    let str = "";
    //console.log("data", splitArr);
    splitArr.forEach(function (i, k) {
      let content: any = i.types;
      const found = content.find((element) => (element = "street_number"));
      if (found === "street_number") {
        that.addressFormGroup.patchValue({ streetNumber: i.short_name });
      } else if (found === "postal_code") {
        that.addressFormGroup.patchValue({ postCode: i.short_name });
      } else if (found === "route") {
        str = i.short_name;
      }
      if (content.length > 1) {
        const countryArr = content;
        ////console.log(countryArr);
        for (let index = 0; index < content.length; index++) {
          if (countryArr[index] === "country") {
            //console.log("country", i);
            that.addressFormGroup.patchValue({
              countryOfResidence: i.long_name,
            });
          }
          if (countryArr[index] === "locality") {
            that.addressFormGroup.patchValue({ city: i.short_name });
          }
        }
      }
    });
    if (str.length > 0) {
      that.addressFormGroup.patchValue({ street: str });
    } else {
      that.addressFormGroup.patchValue({ street: "" });
    }
  }
  titleChange($event) {
    this.companyTitle = false;
    if (this.personalInfoFormGroupadd.get("title").value == "Firma") {
      this.companyTitle = true;
      this.personalInfoFormGroupadd.patchValue({ companyName: "" });
    } else {
      this.personalInfoFormGroupadd.patchValue({ companyName: " " });
    }
  }

  nextCompanies() {
    let company_array = [];
    for (let i in this.roleCompanies) {
      company_array = company_array.concat(this.roleCompanies[i]);
    }
    let temp_set = new Set(company_array);
    company_array = Array.from(temp_set);
    this.userService
      .getLastUser()
      .pipe(first())
      .subscribe((data) => {
        this.autoGeneratedCustomerNumber = data.toString();
        var nums = [this.autoGeneratedCustomerNumber];
        var tmp = company_array; //this.companyArr;
        // if (tmp) {
        //   tmp.forEach(function (i, k) {
        //     var cusNo = i.substring(0, 2);
        //     nums.push(cusNo);
        //   });
        //   var cusNo = nums.join("-");
        //   this.customerNo = cusNo;
        //   this.moreinfoFormGroup.patchValue({ customerno: this.customerNo });
        //   return this.customerNo;
        // }


        // this.customerNo = this.autoGeneratedCustomerNumber + "-60-co";
        this.customerNo = this.autoGeneratedCustomerNumber + "-30-ot";
        this.moreinfoFormGroup.patchValue({ customerno: this.customerNo });
        return this.customerNo;
      });
  }
 
  calculatelength() {
    let count = 0;
    for (let i = 0; i < this.phase.length; i++) {
      for (let j = 0; j < this.exercisesequencelist[i].length; j++) {
        count++;
      }
    }
    return count;
  }
  exerciselength(index) {
    let acc = 0;
    for (let i = 0; i < index; i++) {
      acc += this.exercisesequencelist[i].length;
    }
    return acc;
  }

  exerciselength11(index) {
    let acc = 0;
    for (let i = 0; i < index; i++) {
      acc += this.exercisesequencelistsearch[i].length;
    }
    return acc;
  }

  save_exercise_sequence() {
    $("#loaderouterid").css("display", "block");
    let dataarray = [];

    // let dataarray=[];

    console.log("data array in sequence");
    console.log(this.exercisesequencelist);

    for (let j = 0; j < this.phase.length; j++) {
      for (let i = 0; i < this.exercisesequencelist[j].length; i++) {
        // let datannn={'exercise_id1':this.exercisesequencelist[i].id}

        let documentarray = [];

        //  if (this.exercisesequencelist[j][i].exerciseuniqueid[0] == "") {
        //    documentarray.push("");
        //  } else {
        //    documentarray.push(this.exercisesequencelist[j][i].document[0]);
        //  }
        // if (this.exercisesequencelist[j][i].exerciseuniqueid[1] == "") {
        //   documentarray.push("");
        // } else {
        //   documentarray.push(this.exercisesequencelist[j][i].document[1]);
        // }

        dataarray.push({
          phase: j + 1,
          dates: this.phase[j],
          document: documentarray,
          exercise: this.exercisesequencelist[j][i].id,
        });
      }
    }

    // console.log("data array in sequence");
    // console.log(dataarray);

    let datanew = {
      Behandlung_name:
        this.personalInfoFormGroup.controls.Behandlung_name.value,
      created_by: this.loginid,
      patient_id: this.patient_id,
      exercise_id: dataarray,
      membershipid: this.membershipid,
    };

    this.userService
      .start_treatment(datanew)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data[0].status == "a") {
            Swal.fire(
              "Ausgewählter Patient, der bereits einen Therapieplan durchführt. Bitte wählen Sie einen anderen Patienten",
              "",
              "error"
            );

            $("#loaderouterid").css("display", "none");
            setTimeout(() => {
              Swal.close();
              this.add_exercise();
            }, 2000);
          } else {
            Swal.fire("Behandlung erfolgreich hinzugefügt.", "", "success");
            $("#loaderouterid").css("display", "none");
            setTimeout(() => {
              Swal.close();
              // this.add_exercise();
              // $("#buttonreset").trigger("click");
              this.router.navigate(["./patient-management"]);
            }, 1000);
          }
        },
        (error) => {
          Swal.fire("Error" + error["error"] + "", "", "error");

          $("#loaderouterid").css("display", "none");
          setTimeout(() => {
            Swal.close();
            this.add_exercise();
          }, 2000);
          // $("html, body").animate(
          //   { scrollTop: "0" }, 3000);
        }
      );
  }
}
