import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { UserService } from "../user.service";
import * as $ from "jquery";
import Swal from 'sweetalert2';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  constructor(
    private router: Router,
    private userservice: UserService,
    private formbuilder: FormBuilder
  ) {
    

  }
  brokerno;
  captchaError: boolean;
  search='';
  length;
  loginForm: FormGroup;
  forgotForm: FormGroup;
  showPassword: boolean = false;
  captcha = "";
  userRolesAndRoutes = []
  currentActiveRole = sessionStorage.getItem('currentActiveRole')
  selectedRoute = ""
  token= this.userservice.getdecode(sessionStorage.getItem('Token'));
year;
  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.captcha = captchaResponse;
  }

  grecaptcha: any;
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
  };

  slideConfig1 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    dots: false,
    infinite: true,
    arrows: false,
  };

  
  gotodashboard()
  {
    console.log(this.currentActiveRole);
   if(this.currentActiveRole=='b2b')
   {
    this.router.navigate(['./b2b-dashboard']);
   }
   if(this.currentActiveRole=='employee')
   {
    this.router.navigate(['./employee-dashboard']);
   }
   if(this.currentActiveRole=='superadmin')
   {
    this.router.navigate(['./superadmin-dashboard']);
   }
   if(this.currentActiveRole=='admin')
   {
    this.router.navigate(['./admin-dashboard']);
   }
   if(this.currentActiveRole=='customer')
   {
    this.router.navigate(['./dashboard']);
   }
  }


  navigateTo(route) {
    for (let i = 0; i < this.userRolesAndRoutes.length; i++) {
      if (this.userRolesAndRoutes[i].route == route) {
        sessionStorage.setItem('currentActiveRole', this.userRolesAndRoutes[i].english_name);
        this.selectedRoute = route
      }
    }
    this.selectedRoute = route
    console.log("route"+route)
    this.router.navigate([route])
  }

  onSubmit1()
  {
this.search="Your Therapist"
if(this.search!='')
{
  setTimeout(() => {
    this.router.navigate(["./therapist-list"],{queryParams:{searchvalue:this.search}});
  }, 100);
}
  }
  onSubmit(event)
  {
    console.log("ddddddd");
this.search=event.search
if(this.search!='')
{
  console.log("dddddddjjjj"+this.search);
  setTimeout(() => {
    $('#serarchclosemodal').trigger('click');
    this.router.navigate(["./therapist-list"],{queryParams:{searchvalue:this.search}});
  }, 100);


}

  }
  maindaten()
  {
    this.router.navigate(["./maindaten"]);
  }

  slickInit(e) {
    // console.log("slick initialized");
  }

  breakpoint(e) {
    // console.log("breakpoint");
  }

  afterChange(e) {
    // console.log("afterChange");
  }

  beforeChange(e) {
    // console.log("beforeChange");
  }
  HideAndShow() {
    console.log("ravi");
    if (this.showPassword == false) {
      this.showPassword = true;
    } else {
      this.showPassword = false;
    }
  }

  gototab(type)
  {
    if(type=='doctor')
    {
      $('#doctortab').addClass('is-active');
      $('#b2btab').removeClass('is-active');
          $('#patienttab').removeClass('is-active');
          $('#doctordata').addClass('is-active');
          $('#b2bdata').removeClass('is-active');
              $('#patientdata').removeClass('is-active');
    }
  else  if(type=='b2b')
  {
    $('#doctortab').removeClass('is-active');
    $('#b2btab').addClass('is-active');
        $('#patienttab').removeClass('is-active');
    $('#doctordata').removeClass('is-active');
    $('#b2bdata').addClass('is-active');
        $('#patientdata').removeClass('is-active');
  }
  else{
    $('#doctortab').removeClass('is-active');
    $('#b2btab').removeClass('is-active');
        $('#patienttab').addClass('is-active');
    $('#doctordata').removeClass('is-active');
    $('#b2bdata').removeClass('is-active');
        $('#patientdata').addClass('is-active');
  }
  }

  ngOnInit() {

    if(this.token!=null)
    {
    this.userservice.checkbrokerassign('').subscribe((result:any)=>{
    this.brokerno=result.brokerno;
    })
    }
    this.userRolesAndRoutes = this.userservice.getUserRolesAndRouteArray()
   
for (let i = 0; i < this.userRolesAndRoutes.length; i++) {
  if (this.userRolesAndRoutes[i].english_name == this.currentActiveRole) {
    this.selectedRoute = this.userRolesAndRoutes[i].route;
  }
}

    // $(document).ready(function() {
    //   //variable for the 'stroke-dashoffset' unit
    //   var $dashOffset = $(".path").css("stroke-dashoffset");
    //   //on a scroll event - execute function
    //   $(window).scroll(function() {
    //     //calculate how far down the page the user is 
    //     var $percentageComplete = (($(window).scrollTop() / ($("html").height() - $(window).height())) * 100);
    //     //convert dashoffset pixel value to interger
    //     var $newUnit = parseInt($dashOffset, 10);
    //     //get the value to be subtracted from the 'stroke-dashoffset'
    //     var $offsetUnit = $percentageComplete * ($newUnit / 100);
    //     //set the new value of the dashoffset to create the drawing effect
    //     $(".path").css("stroke-dashoffset", $newUnit - $offsetUnit);
    //   });
    // });

    var items = document.querySelectorAll(".timeline li");

    function isElementInViewport(el) {
      var rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }
    
    function callbackFunc() {
      for (var i = 0; i < items.length; i++) {
        if (isElementInViewport(items[i])) {
          if(!items[i].classList.contains("in-view")){
            items[i].classList.add("in-view");
          }
        } else if(items[i].classList.contains("in-view")) {
            items[i].classList.remove("in-view");
        }
      }
    }
     
    window.addEventListener("load", callbackFunc);
    window.addEventListener("scroll", callbackFunc);
    

// const svg = document.getElementById("svgPath");
// this.length=5000;
// let lengthold=this.length;
// svg.style.strokeDasharray = this.length;
// svg.style.strokeDashoffset = lengthold;
// var height=$(window).scrollTop();
// $(window).scroll(function() {
//   var percentageComplete = (($(window).scrollTop() / (document.body.scrollHeight - $(window).height())) * 100);
//   var newUnit = parseInt(lengthold, 10);
//   var offsetUnit = percentageComplete * (newUnit / 100);
//   let data:any=newUnit - offsetUnit;
//   console.log(offsetUnit)
//   console.log(newUnit)
//   svg.style.strokeDashoffset=data

// });

// const svg1 = document.getElementById("svgPath1");
// this.length=3500;
// let lengthold1=this.length;
// svg1.style.strokeDasharray = this.length;
// svg1.style.strokeDashoffset = lengthold1;
// var height=$(window).scrollTop();
// $(window).scroll(function() {
//   var percentageComplete = (($(window).scrollTop() / (document.body.scrollHeight - $(window).height())) * 100);
//   var newUnit = parseInt(lengthold1, 10);
//   var offsetUnit = percentageComplete * (newUnit / 100);
//   let data:any=newUnit - offsetUnit;
//   console.log(offsetUnit)
//   console.log(newUnit)
//   svg1.style.strokeDashoffset=data

// });


this.year=new Date().getFullYear();

    this.loginForm = this.formbuilder.group({
      username: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });


    this.forgotForm = this.formbuilder.group({
      username: ["", [Validators.required, Validators.email]],
    });

    

    const tabItems = document.querySelectorAll(".tab-item");
    const contentItems = document.querySelectorAll(".content-item");

    const clearActiveClass = (element, className = "is-active") => {
      element.forEach((item) => item.classList.remove(`${className}`));
    };

    const setActiveClass = (element, index, className = "is-active") => {
      element[index].classList.add(`${className}`);
    };

    const checkoutTabs = (item, index) => {
      item.addEventListener("click", () => {
        if (item.classList.contains("is-active")) return;

        const currentItem = index;

        clearActiveClass(tabItems);
        clearActiveClass(contentItems);

        setActiveClass(tabItems, currentItem);
        setActiveClass(contentItems, currentItem);
      });
    };

    tabItems.forEach(checkoutTabs);

    setTimeout(() => {
      $("#howit").trigger("click");
    }, 100);


  }


  forgot()
  {
    
    $("#loaderouterid").css("display", "block");
    if (this.forgotForm.controls.username.value) {
          this.userservice.sendLinkForgotPassword({"email":this.forgotForm.controls.username.value})
            .subscribe(data => {
              if (data['status'] == 200) {
                Swal.fire(data['msg'],"","success")
                setTimeout(() => {
                  $('#closemodal').trigger('click');
                  $("#loaderouterid").css("display", "none");
                  this.router.navigate(["./dashboard"]);
                }, 100);
            
              }
              else{
                Swal.fire(data['msg'],"","error")
                $("#loaderouterid").css("display", "none");
              }
            }, error => {
            
              console.log("Error", error['error']);
              this.router.navigate(['/']);
              $("#loaderouterid").css("display", "none");
            });
        
  
    }
  }


  login() {
    const response = grecaptcha.getResponse();
    console.log("ddddddddddd" + this.captcha);
    if (this.captcha == "" || this.captcha == "null" ) {
      console.log("ddddddddddd" + this.captcha);
      this.captchaError = true;
      return;
    }
    
    if (this.loginForm.invalid) {
      return false;
    }
    this.userservice
      .login(
        this.loginForm.controls.username.value,
        this.loginForm.controls.password.value
      )
      .subscribe(
        (dataUSER: any) => {
          console.log("dd"+dataUSER.token);
          sessionStorage.setItem("Token",dataUSER.token);
          sessionStorage.setItem("currentUser", JSON.stringify(dataUSER.user));
          setTimeout(() => {
            $('#closemodal').trigger('click');
            
            
            
            let decodedData = this.userservice.getDecodedAccessToken(sessionStorage.getItem('Token'))
            // if (decodedData.roles && decodedData.roles.includes('Superadmin')) {
            //   sessionStorage.setItem('currentActiveRole', "superadmin");
            //   this.router.navigate(['./superadmin-home']);
            // }
            // else if (decodedData.roles && decodedData.roles.includes('admin')) {
            //   sessionStorage.setItem('currentActiveRole', "admin");
            //   this.router.navigate(['./admin-home']);
            // }
            // else if (decodedData.roles && decodedData.roles.includes('employee')) {
            //   sessionStorage.setItem('currentActiveRole', "employee");
            //   this.router.navigate(['./mitarbeiter-home']);
            // } else 
            // if (decodedData.roles && decodedData.roles.includes('admin')) {
            //   sessionStorage.setItem('currentActiveRole', "admin");
            //   this.router.navigate(['./admin-dashboard']);
            // }
            // else
            
            if (decodedData.roles && decodedData.roles.includes('b2b')) {
              sessionStorage.setItem('currentActiveRole', "b2b");
              this.router.navigate(['./b2b-dashboard']);
            }
            else {
              sessionStorage.setItem('currentActiveRole', "customer");
              this.router.navigate(['./dashboard']);
            }
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            // this.router.navigate(["./dashboard"]);
          }, 100);
            //  document.body.classList.remove("modal-open");
       
       
        },

        (error) => {
          $("#forget-btn").css("display","block");
          Swal.fire(error,"","error");
          this.router.navigate(["./"]);
        }
      );
  }

  forgetpassword() {
    $("#passwordwindow").css("display","block");
    $("#loginwindow").css("display","none");
  }
  
  back() {
    $("#passwordwindow").css("display","none");
    $("#loginwindow").css("display","block");
  }
  
  
  dashboard()
  {
    this.gotodashboard();
  }


  Logout()
  {
    console.log("ddddlogout");
    sessionStorage.removeItem("Token");
    sessionStorage.removeItem("preventionid");
      sessionStorage.removeItem("b2bid");
      sessionStorage.removeItem("userid");
    window.location.reload();
  }
  mousehover(id) {
    $(".choice").removeClass("expand");
    // $(".choice").addClass("small");

    // $('#'+id).removeClass("small");
    $("#" + id).addClass("expand");
    console.log("test");
  }
  scroll(el: HTMLElement) {
  
    console.log("dd"+$('#myVideo').height())
    window.scrollTo(0,$('#myVideo').height());
    // el.scrollIntoView();
  }
  mousehovermobile(id) {
    $(".choicemobile").removeClass("expand");
    // $(".choice").addClass("small");

    // $('#'+id).removeClass("small");
    $("#" + id).addClass("expand");
    console.log("test");
  }
  

  navigate() {
    console.log("dd");
    // this.router.navigate(["/plan"]);
    this.router.navigate(["/plan"], {});
  }
}
