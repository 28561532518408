// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
   //API_URL:'http://localhost:3000/',
  fiorettourl:'https://fiorettosystems.com/',


  API_URL: 'https://fiorettosystems.com/api/',
  //API_URL: 'http://localhost:3000/',


   //API_URL: 'http://ec2-54-160-193-146.compute-1.amazonaws.com/api/',
  // API_URL: 'http://theadvisorpoint.com/api/',
  VIPPRICE:"100.00",
  paypal: {
    mode: 'sandbox',
    sandboxClientId: 'AVbQKIcfB7EJuZ7rDNrmJss9kihnKich8xFP7MvpOnrQQVXLoS4W0MmNGyYOd70oD5AoebWbXGkT-NLo',
    productionClientId: '',
    currency: 'EUR'
  },
  PAYPAL_CLIENTID:'AVbQKIcfB7EJuZ7rDNrmJss9kihnKich8xFP7MvpOnrQQVXLoS4W0MmNGyYOd70oD5AoebWbXGkT-NLo' //fioretto
  // PAYPAL_CLIENTID:'AVbQKIcfB7EJuZ7rDNrmJss9kihnKich8xFP7MvpOnrQQVXLoS4W0MmNGyYOd70oD5AoebWbXGkT-NLo' //synram
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
