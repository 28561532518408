import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  brokerno='';
  search='';
  id;
  questionindex;
  index;
  exercise_id;
  type;
  userRolesAndRoutes = []
  currentActiveRole = sessionStorage.getItem('currentActiveRole')
  selectedRoute = ""
  constructor(private Userservice:UserService,private router:Router,private activateRoutes:ActivatedRoute ) { 

    this.activateRoutes.queryParams.subscribe((param) => {
      this.id = param["id"];
      this.questionindex = param["questionindex"];
      this.index = param["index"];
      this.exercise_id = param["exercise_id"];
      this.type = param["type"];
    });

  }


  
token = this.Userservice.getdecode(sessionStorage.getItem('Token'));
routeParams
gotodashboard()
{
  console.log(this.currentActiveRole);
 if(this.currentActiveRole=='b2b')
 {
  this.router.navigate(['./b2b-dashboard']);
 }
 if(this.currentActiveRole=='employee')
 {
  this.router.navigate(['./employee-dashboard']);
 }
 if(this.currentActiveRole=='superadmin')
 {
  this.router.navigate(['./superadmin-dashboard']);
 }
 if(this.currentActiveRole=='admin')
 {
  this.router.navigate(['./admin-dashboard']);
 }
 if(this.currentActiveRole=='customer')
 {
  this.router.navigate(['./dashboard']);
 }
}
  ngOnInit() {
console.log("ddddddddddd"+JSON.stringify(this.token));
this.routeParams = this.activateRoutes.snapshot.routeConfig.path;
if(this.token!=null)
{
this.Userservice.checkbrokerassign('').subscribe((result:any)=>{
  if(result.length>0)
  {
this.brokerno=result.brokerno;
  }
})

this.userRolesAndRoutes = this.Userservice.getUserRolesAndRouteArray()
   
for (let i = 0; i < this.userRolesAndRoutes.length; i++) {
  if (this.userRolesAndRoutes[i].english_name == this.currentActiveRole) {
    this.selectedRoute = this.userRolesAndRoutes[i].route;
  }
}

}

  }

  navigateTo(route) {
    for (let i = 0; i < this.userRolesAndRoutes.length; i++) {
      if (this.userRolesAndRoutes[i].route == route) {
        sessionStorage.setItem('currentActiveRole', this.userRolesAndRoutes[i].english_name);
        this.selectedRoute = route
      }
    }
    this.selectedRoute = route
    console.log("route"+route)
    this.router.navigate([route])
  }


  onSubmit1()
  {
this.search="Your Therapist"
if(this.search!='')
{
  setTimeout(() => {
    this.router.navigate(["./therapist-list"],{queryParams:{searchvalue:this.search}});
  }, 100);
}
  }
  onSubmit()
  {
this.search="Your Therapist"
if(this.search!='')
{
  setTimeout(() => {
    this.router.navigate(["./therapist-list"],{queryParams:{searchvalue:this.search}});
  }, 100);


}

  }





  preventionNavigatetoollist() {
    
    this.router.navigate(["/preventioncoursetoolslist"],  { queryParams: { id: this.id } } );
 
}

preventionNavigateubungsliste() {
    
  this.router.navigate(["/prevention-course-exercise-list"],  { queryParams: { id: this.id } } );

}

preventionNavigateexercisevideo() {
    
    this.router.navigate(["/preventionexercisevideo"],  { queryParams: { id: this.id,index:this.index,exercise_id:this.exercise_id,type:this.type } } );
 
}




  Navigatetoollist() {
    
    this.router.navigate(["/toolslist"],  { queryParams: { id: this.id,questionindex:this.questionindex } } );
 
}

Navigateubungsliste() {
    
  this.router.navigate(["/exercise_list"],  { queryParams: { id: this.id,questionindex:this.questionindex } } );

}


  Navigatequestionlist() {
    
      this.router.navigate(["/questionlist"],  { queryParams: { id: this.id,questionindex:this.questionindex } } );
   
  }
  Navigateexercisevideo() {
    
    this.router.navigate(["/exercisevideo"],  { queryParams: { id: this.id,index:this.index,exercise_id:this.exercise_id,type:this.type } } );
 
}
  

  Logout()
  {
    console.log("dddd");
    sessionStorage.removeItem("Token");
    sessionStorage.removeItem("preventionid");
    sessionStorage.removeItem("b2bid");
    sessionStorage.removeItem("userid");
    this.router.navigate(["./"]);
  }

  dashboard()
  {
this.gotodashboard();
  }


  maindaten()
  {
    this.router.navigate(["./maindaten"]);
  }

}
