export class PagerService {
  getDefaultPageSize(){
      return 30;
  }
  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 30) {
     // calculate total pages
     if(totalItems>0){

     
      let totalPages = Math.ceil(totalItems / this.getDefaultPageSize());

      // ensure current page isn't out of range
      if (currentPage < 1) { 
          currentPage = 1; 
      } else if (currentPage > totalPages) { 
          currentPage = totalPages; 
      }
      
      let startPage: number, endPage: number;
      if (totalPages <= 5) {
          // less than 10 total pages so show all
          startPage = 1;
          endPage = totalPages;
      } else {
          // more than 10 total pages so calculate start and end pages
          if (currentPage <= 5) {
              startPage = 1;
              endPage = 5;
          } else if (currentPage + 1 >= totalPages) {
              startPage = totalPages - 4;
              endPage = totalPages;
          } else {
              startPage = currentPage - 1;
              endPage = currentPage + 3;
          }
      }

      // calculate start and end item indexes
      let startIndex = (currentPage - 1) * this.getDefaultPageSize();
      let endIndex = Math.min(startIndex + this.getDefaultPageSize() - 1, totalItems - 1);

      // create an array of pages to ng-repeat in the pager control
      let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

      // return object with all pager properties required by the view
      return {
          totalItems: totalItems,
          currentPage: currentPage,
          pageSize: this.getDefaultPageSize(),
          totalPages: totalPages,
          startPage: startPage,
          endPage: endPage,
          startIndex: startIndex,
          endIndex: endIndex,
          pages: pages
      };
     }
     else{
      return {
          totalItems: 0,
          currentPage: 0,
          pageSize: 0,
          totalPages: 0,
          startPage: 0,
          endPage: 0,
          startIndex: 0,
          endIndex: 0,
          pages: 0
      };
     }


  }
}
