import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from "@angular/core";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatStepperModule } from "@angular/material/stepper";
import { MatChipsModule } from "@angular/material";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { AppRoutingModule } from "./app-routing.module";
import { MatMenuModule } from "@angular/material/menu";

import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { PlansComponent } from "./plans/plans.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { PlansB2bComponent } from "./plans-b2b/plans-b2b.component";
import { PatientProductComponent } from "./patient-product/patient-product.component";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { MatNativeDateModule, MatGridListModule } from "@angular/material";
import { AgmCoreModule } from "@agm/core";
import { UserService } from "./user.service";
import { PagerService } from "./pager.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { JwtInterceptorService } from "./jwt-interceptor.service";
import { OnlineShopComponent } from "./online-shop/online-shop.component";
import { RecaptchaModule } from "ng-recaptcha";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { RegisterUserComponent } from "./register-user/register-user.component";
import { PasswordStrengthMeterModule } from "angular-password-strength-meter";
import {MatPasswordStrengthModule} from "@angular-material-extensions/password-strength";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
// import { GooglePlacesDirective } from "./google-places.directive";
// Material imports
import {MatTableModule} from '@angular/material';
import { MatSliderModule } from "@angular/material/slider";
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import {MatCardModule} from "@angular/material/card"
import {  MatSelectModule, MatTooltipModule } from "@angular/material";
import { MatTabsModule } from "@angular/material/tabs";
import { AlreadyRegisteredComponent } from './already-registered/already-registered.component';
import { SubheaderComponent } from './subheader/subheader.component';
import { PurchaseB2bPlanComponent } from './purchase-b2b-plan/purchase-b2b-plan.component';
import { TherapistListComponent } from './therapist-list/therapist-list.component';
import { PurchaseDoctorPlanComponent } from './purchase-doctor-plan/purchase-doctor-plan.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { TherapyPlanComponent } from './therapy-plan/therapy-plan.component';
import { JwtModule } from "@auth0/angular-jwt";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { NgxPayPalModule } from "ngx-paypal";
import { QuestionlistComponent } from './questionlist/questionlist.component';
import { SessionstripComponent } from './sessionstrip/sessionstrip.component';
import { ToolslistComponent } from './toolslist/toolslist.component';
import { ExerciseListComponent } from './exercise-list/exercise-list.component';
import { ExercisevideoComponent } from './exercisevideo/exercisevideo.component';
import { CarouselModule } from "ngx-owl-carousel-o";
import { PurchasePatientPreventionCourseComponent } from './purchase-patient-prevention-course/purchase-patient-prevention-course.component';
import { PreventionCourseComponent } from './prevention-course/prevention-course.component';
import { PreventionCourseExerciseListComponent } from './prevention-course-exercise-list/prevention-course-exercise-list.component';
import { MaindatenComponent } from './maindaten/maindaten.component';
import { PreventionexercisevideoComponent } from './preventionexercisevideo/preventionexercisevideo.component';
import { PreventioncoursetoolslistComponent } from './preventioncoursetoolslist/preventioncoursetoolslist.component';
import { MyProgressComponent } from './my-progress/my-progress.component';
import { ChartsModule } from 'ng2-charts';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { HowitworkComponent } from './howitwork/howitwork.component';
import { B2bDashboardComponent } from './b2b-dashboard/b2b-dashboard.component';
import { PatientManagementComponent } from './patient-management/patient-management.component';
// import { MyCompanyComponent } from './my-company/my-company.component';
import { MyCompanyComponent } from './my-company/my-company.component';
import { StartTreatmentComponent } from './start-treatment/start-treatment.component';
import { AddPatientComponent } from './add-patient/add-patient.component';
import { TherapyplanComponent } from './therapyplan/therapyplan.component';
import { AddTherapyPlanComponent } from './add-therapy-plan/add-therapy-plan.component';
import { TherapyPlanListComponent } from './therapy-plan-list/therapy-plan-list.component';
import { PatientDashboardComponent } from './patient-dashboard/patient-dashboard.component'
import { SortPipe } from "./sort.pipe";
 import { SortDirective } from "./directive/sort.directive";
export const MY_FORMATS = {
  parse: {
    dateInput: "DD.MM.YYYY",
  },
  display: {
    dateInput: "DD.MM.YYYY",
    monthYearLabel: "MM YYYY",
    dateA11yLabel: "DD.MM.YYYY",
    monthYearA11yLabel: "MM YYYY",
  },
};
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  
} from "@angular/material";
import {
  MomentDateModule,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import { TreatementplanComponent } from './treatementplan/treatementplan.component';
import { AddTreatmentPlanComponent } from './add-treatment-plan/add-treatment-plan.component';
import { TreatmentPlanListComponent } from './treatment-plan-list/treatment-plan-list.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { ExerciseComponent } from './exercise/exercise.component';
import { ExerciseSequenceComponent } from './exercise-sequence/exercise-sequence.component';
import { PreventionCoursesComponent } from './prevention-courses/prevention-courses.component';
import { OnlineShopAdminComponent } from './online-shop-admin/online-shop-admin.component';
import { AddExerciseComponent } from './add-exercise/add-exercise.component';
import { ExerciseListsComponent } from './exercise-lists/exercise-lists.component';
import { AddExerciseSequenceComponent } from './add-exercise-sequence/add-exercise-sequence.component';
import { ExerciseSequenceListComponent } from './exercise-sequence-list/exercise-sequence-list.component';
import { AddPreventionCourseComponent } from './add-prevention-course/add-prevention-course.component';
import { PreventionCourseListComponent } from './prevention-course-list/prevention-course-list.component';
import { DoctorMembershipPlanComponent } from './doctor-membership-plan/doctor-membership-plan.component';
import { TherapistProductComponent } from './therapist-product/therapist-product.component';
import { PatientProductPlanComponent } from './patient-product-plan/patient-product-plan.component';
import { AddDoctorMembershipplanComponent } from './add-doctor-membershipplan/add-doctor-membershipplan.component';
import { DoctorMembershipPlanManagementComponent } from './doctor-membership-plan-management/doctor-membership-plan-management.component';
import { AddTheraphistProductComponent } from './add-theraphist-product/add-theraphist-product.component';
import { TheraphistProductManagementComponent } from './theraphist-product-management/theraphist-product-management.component';
import { AddPatientProductComponent } from './add-patient-product/add-patient-product.component';
import { PatientProductManagementComponent } from './patient-product-management/patient-product-management.component';

export function tokenGetter(){
  return sessionStorage.getItem('Token');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PlansComponent,
    HeaderComponent,
    FooterComponent,
    PlansB2bComponent,
    PatientProductComponent,

    OnlineShopComponent,
    DashboardComponent,
    RegisterUserComponent,
    AlreadyRegisteredComponent,
    SubheaderComponent,
    PurchaseB2bPlanComponent,
    TherapistListComponent,
    PurchaseDoctorPlanComponent,
    CookiePolicyComponent,
    TherapyPlanComponent,
    QuestionlistComponent,
    SessionstripComponent,
    ToolslistComponent,
    ExerciseListComponent,
    ExercisevideoComponent,
    PurchasePatientPreventionCourseComponent,
    PreventionCourseComponent,
    PreventionCourseExerciseListComponent,
    MaindatenComponent,
    PreventionexercisevideoComponent,
    PreventioncoursetoolslistComponent,
    MyProgressComponent,
    ResetPasswordComponent,
    PrivacyPolicyComponent,
    HowitworkComponent,
    B2bDashboardComponent,
    PatientManagementComponent,
    // MyCompanyComponent,
    StartTreatmentComponent,
    AddPatientComponent,
    TherapyplanComponent,
    MyCompanyComponent,
    AddTherapyPlanComponent,
    TherapyPlanListComponent,
    SortPipe,
    PatientDashboardComponent,
    SortDirective,
    TreatementplanComponent,
    AddTreatmentPlanComponent,
    TreatmentPlanListComponent,
    AdminDashboardComponent,
    ExerciseComponent,
    ExerciseSequenceComponent,
    PreventionCoursesComponent,
    OnlineShopAdminComponent,
    AddExerciseComponent,
    ExerciseListsComponent,
    AddExerciseSequenceComponent,
    ExerciseSequenceListComponent,
    AddPreventionCourseComponent,
    PreventionCourseListComponent,
    DoctorMembershipPlanComponent,
    TherapistProductComponent,
    PatientProductPlanComponent,
    AddDoctorMembershipplanComponent,
  
    
    DoctorMembershipPlanManagementComponent,
  
    
    AddTheraphistProductComponent,
  
    
    TheraphistProductManagementComponent,
  
    
    AddPatientProductComponent,
  
    
    PatientProductManagementComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatPasswordStrengthModule,
    PasswordStrengthMeterModule,
    MatTooltipModule,
    RecaptchaModule,
    MatIconModule,
    MatStepperModule,
    MatNativeDateModule,
    MatCardModule,
    ReactiveFormsModule,
    MatToolbarModule,
    AppRoutingModule,
    SlickCarouselModule,
    HttpClientModule,
    MatSliderModule,
    MatMenuModule,
    MatInputModule,
    MatSelectModule,
    GooglePlaceModule,
    FormsModule,
    MatTabsModule,
    NgxSliderModule,
    NgxPayPalModule,
    MatProgressBarModule,
    CarouselModule,
    ChartsModule,
    MatTableModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatDatepickerModule,
  
     DragDropModule,
     
    JwtModule.forRoot({
      config:{
        tokenGetter: tokenGetter,
      }
    }),
    // GooglePlacesDirective,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDl9jr7IxnWUfeO-G99R1AHtMH2pfrJg5I",
    }),
  ],
  providers: [
    UserService,
    PagerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true
      , useValue: {appearance: 'fill'},
    },
    { provide: MAT_DATE_LOCALE, useValue: "de" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
