import { Component, OnInit, AfterViewInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
  CheckboxControlValueAccessor
} from "@angular/forms";
import { UserService } from "../user.service";
import { first } from "rxjs/operators";
import { error } from "util";
import { Router } from "@angular/router";
import { PagerService } from "../pager.service";
import { MatChipInputEvent } from "@angular/material/chips";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ActivatedRoute, Params } from "@angular/router";
import * as $ from "jquery";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { DomSanitizer } from '@angular/platform-browser';
import Swal from "sweetalert2";
import { HttpEvent, HttpEventType } from "@angular/common/http";
type unit = 'bytes' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB';
type unitPrecisionMap = {
  [u in unit]: number;
};

const defaultPrecisionMap: unitPrecisionMap = {
  bytes: 0,
  KB: 0,
  MB: 1,
  GB: 1,
  TB: 2,
  PB: 2
};

// import * as AWS from 'aws-sdk';
export interface Fruit {
  name: string;
}
export interface Fruitnew {
  name: string;
}
@Component({
  selector: 'app-exercise-lists',
  templateUrl: './exercise-lists.component.html',
  styleUrls: ['./exercise-lists.component.css']
})
export class ExerciseListsComponent implements OnInit {
  routeParams: string;
  personalInfoFormGroup: FormGroup;
  typesOptionsArray: any[]=[];
  oldtypesOptionsArray: any[]=[];
  customerList: any;
  recordCount: any;
  pagedItems: any;
  startRecord: number;
  progress = [];
  endRecord: any;
  l=0;
  popupData: any;
editid:any='';
  currentUserData: any;
  filearray: any[]= [];
  currentopendata:any;
  ticket_no:any;
  filearraynew: any[] = [];
  lengthfile: any=0;
  uploadlength: any=0;
  documentid: any=[];
  values: any;
  tagscount;
  constructor(private _formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
     
    private pagerService: PagerService,
    private activatedRoute: ActivatedRoute
    ,private sanitizer: DomSanitizer ) {   
    
      }
  loginRole = sessionStorage.getItem("currentActiveRole");
  title = this.userService.getDecodedAccessToken(sessionStorage.getItem("Token"))
    .title;
    loginid = this.userService.getDecodedAccessToken(sessionStorage.getItem("Token"))
    .id;
  lastname = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).lastname;
  firstname = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).firstname;
  COMPANYNAME = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).companyname;
  private readonly units: unit[] = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
  pager: any = {};
  selecteditem:string[]=[];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  itemList: string[]=[
    'Matte', 
    'Bett', 
    'Spiegel', 
    'Strohhalm', 
    'Eiswürfel', 
    'Hocker',  
    'Widerstandsband', 
    'Pezziball', 
    'Stab', 
'Kettlebell',  
'Faszienrolle', 
'Seil', 
'Hantel (klein)', 
'Handtuch', 
'Tape', 
'Tennisball', 
'Murmeln', 
'Zopfgummi', 
'Tisch', 
'Knete',
  ]
  selecteditemList = this.itemList;


  
  onKey1(value) { 
    this.selecteditemList = this.search(value);










    }
    search(value: string) { 
      let filter = value.toLowerCase();
      return this.itemList.filter(option => option.toLowerCase().startsWith(filter));
    }

    getdatanew(value)
    {
      this.selecteditem.push(value);
console.log("asdsaddsadasdA"+value);
$('#inputtools').val('');
this.onKey('');
    }



  ngOnInit() {
 
    this.loginRole = this.loginRole;
    this.routeParams = this.activatedRoute.snapshot.routeConfig.path;

    this.personalInfoFormGroup = this._formBuilder.group({
      exercise_name: ["", Validators.required],
      exercise_name1: ["", Validators.required],
      comment1: [""],
      comment: ["", Validators.required],
      manual_tags: ["", Validators.required],
      tools:[""],
      difficully_level:["", Validators.required],
      
  upload_file: ["", Validators.required],
  explain: ["", Validators.required],
  implementation: ["", Validators.required],
  exercise_category:["", Validators.required]
    });


    $("#loaderouterid").css("display", "block");
this.getallexercie();


this.userService
      .getLastdocumentnew()
      .pipe(first())
      .subscribe((data1) => {
      });

    this.typesOptionsArray.push();

  }

  choosecategory(value)
  {
    console.log("dddddddddddd"+value);
    this.personalInfoFormGroup.patchValue({
      'exercise_category':value
    });

$('#exercise_category').val(value);
if(value=='Therapie')
{
$('#Therapie').css('background-color','gray');
$('#Prävention').css('background-color','white');
}
else{
  $('#Prävention').css('background-color','gray');
  $('#Therapie').css('background-color','white');
}
  }

  onKey(event)
  {
    console.log();
    // this.values = event.target.value;
    var value = event.keyCode;
    this.values=event.target.value;
    console.log(value);
    if (value == "13") {
      // without type info
      // this.customerList = [];
      const that = this;
      console.log(that.customerList);

      // this.values = event.target.value;
      if (event.target.value != "") {
        console.log(event.target.value);
        // var datanew={currentrole:this.loginRole,id:this.loginid}
        this.userService
          .getSearchexercise(event.target.value)
          .subscribe(function (data1) {
            that.customerList = data1;

            that.setPage(1);

            that.recordCount = that.customerList.length;
            //this.customerList.push(data1);
            //return this.customerList.push(data1);
          });
      } else {
      this.getallexercie();
      }
    }




  }
  onSubmit()
  {
    const that = this;
    console.log();

      // this.values = event.target.value;
      if (this.values != "") {
 
        // var datanew={currentrole:this.loginRole,id:this.loginid}
        this.userService
          .getSearchexercise(this.values)
          .subscribe(function (data1) {
            that.customerList = data1;

            that.setPage(1);

            that.recordCount = that.customerList.length;
            //this.customerList.push(data1);
            //return this.customerList.push(data1);
          });
      } else {
      this.getallexercie();
      }
    



  }

  editRecord(id, accordianId, cl)
  {
    this.progress[0] = 0;
    this.progress[1] = 0;
    this.progress[2] = 0;
    this.filearray[0]='';
    this.filearray[1]='';
    this.filearray[2]='';

    accordianId = "collapse";
    $('#loaderouterid').css("display","block");
    this.popupData = cl;
    let element1: HTMLElement = document.getElementById(
      "ul" + id
    ) as HTMLElement;
    let element1new: HTMLElement = document.getElementById(
      "cardbodyid"
    ) as HTMLElement;
    let element: HTMLElement = document.getElementById(
      "click" + id
    ) as HTMLElement;
    let accordian: HTMLElement = document.getElementById(accordianId);
console.log("accordian"+accordian);
    if (element.innerHTML == "Schließen") {
      element1new.after(accordian);
      accordian.classList.add("collapse");
      accordian.classList.remove("collapse-show");
      element.innerHTML = "Öffnen";
      $('#loaderouterid').css("display","none");
    }
    else{
      if (this.editid != "") {
        let elementnew1: HTMLElement = document.getElementById(
          "click" + this.editid
        ) as HTMLElement;
        if (elementnew1) {
          elementnew1.innerHTML = "Öffnen";
        }
      }


      element1.after(accordian);
      accordian.classList.remove("collapse");
      accordian.classList.add("collapse-show");
      element.innerHTML = "Schließen";
      this.editid = id;
      this.getcurrentUser(this.editid);

    }


  }


  addOpt(event: MatChipInputEvent, index: number): void {
    const input = event.input;
    const value = event.value;
    console.log("dsdsad" + index);
    console.log("input" + input);
    console.log("value" + value);
    console.log("valuenew" + value.trim());
    console.log("valuenew" + this.typesOptionsArray);
    // Add our fruit
    if(this.typesOptionsArray.length>19)
    {
      Swal.fire(
        "Begrenzen Sie 20 Tags",
        "",
        "error"
      );
    }
    else{
      if ((value || "").trim()) {
        this.typesOptionsArray.push(value.trim());
      }
      // Reset the input value
      if (input) {
        input.value = "";
      }
      this.tagscount=20-(this.typesOptionsArray.length+this.oldtypesOptionsArray.length);
$('#manual_tagscount').html(this.tagscount)
    }
   
  }

  removeOpt(opt: string, index: number): void {
    const optIndex = this.typesOptionsArray.indexOf(opt);
    if (optIndex >= 0) {
      this.typesOptionsArray.splice(optIndex, 1);
    }
    this.tagscount=20-(this.typesOptionsArray.length+this.oldtypesOptionsArray.length);
    $('#manual_tagscount').html(this.tagscount)
  }
  removeOpt1(opt: string, index: number): void {
    const optIndex = this.oldtypesOptionsArray.indexOf(opt);
    if (optIndex >= 0) {
      this.oldtypesOptionsArray.splice(optIndex, 1);
    }
    this.tagscount=20-(this.typesOptionsArray.length+this.oldtypesOptionsArray.length);
    $('#manual_tagscount').html(this.tagscount)
  }



  getcurrentUser(openid?, currentData?)
  {
    const that = this;
    if (currentData && !openid) {
      setData(currentData);
    } else {
      // console.log("call api")
      this.userService.getEditexercise(openid).subscribe(function (data1) {
        that.currentUserData = data1;
        console.log(data1);

        setData(data1);
      });
    }






    function setData(data1)
    {
      that.currentopendata=data1;


      console.log("dddddddddddd"+JSON.stringify(data1));
      console.log("dddddddddddd"+data1.element.exercise_category);
that.choosecategory(data1.element.exercise_category);

that.calcount('commentcount','comment');
let commentlength:any=500-data1.element.comment.length;
$('#commentcount').html(commentlength);
let commentlength1:any=500-data1.element.comment1.length;
$('#comment1count').html(commentlength1);
let exercise_name:any=35-data1.element.exercise_name.length;
$('#exercise_namecount').html(exercise_name);
let exercise_name1:any=65-data1.element.exercise_name1.length;
$('#exercise_name1count').html(exercise_name1);
let tags:any=20-data1.element.tags.length;
$('#manual_tagscount').html(tags);







that.personalInfoFormGroup.patchValue({
  exercise_name: data1.element.exercise_name,
  exercise_name1: data1.element.exercise_name1,
  difficully_level: data1.element.difficully_level,
  tools: data1.element.tools,
  comment: data1.element.comment,
  comment1: data1.element.comment1,
});
that.oldtypesOptionsArray=data1.element.tags;

console.log("dddddddddddd"+that.oldtypesOptionsArray);
$('#logopreview0').html("<img src='"+data1.image1+"' style='width: 100%;height: 300px'>");
$('#logopreview1').html("<video  style='width: 100%;height: 300px' controls><source  src='"+data1.image2+"' type='video/mp4'></video>");
$('#logopreview2').html("<video  style='width: 100%;height: 300px' controls><source  src='"+data1.image3+"' type='video/mp4'></video>");
$('#loaderouterid').css("display","none");
    }




// this.choosecategory();
    // $('#loaderouterid').css("display","none");
  }

  calcount(id,type)
  {
    let commentcount:any;
    if(type=='comment')
    {
  let comment=  this.personalInfoForm.comment.value.length;

 commentcount=500-comment;
    }
    if(type=='comment1')
    {
  let comment=  this.personalInfoForm.comment1.value.length;

 commentcount=500-comment;
    }
    if(type=='exercise_name')
    {
  let comment=  this.personalInfoForm.exercise_name.value.length;

 commentcount=35-comment;
    }
    if(type=='exercise_name1')
    {
  let comment=  this.personalInfoForm.exercise_name1.value.length;

 commentcount=35-comment;
    }
$('#'+id).html(commentcount);
  }

  dataconvert(bytes: number = 0, precision: number | unitPrecisionMap = defaultPrecisionMap): string {
  
    if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) return '?';

    let unitIndex = 0;

    while (bytes >= 1024) {
      bytes /= 1024;
      unitIndex++;
    }

    const unit = this.units[unitIndex];

    if (typeof precision === 'number') {
      return `${bytes.toFixed(+precision)} ${unit}`;
    }
    return `${bytes.toFixed(precision[unit])} ${unit}`;
  }


  handleImageChange(event: any, index) {
    $("#result").html("");
    console.log(this.loginid);
    event.preventDefault();
    const removeData = (j,indexw) => {
      console.log("sdddd"+this.filearraynew);
      console.log("sdddd"+j);
      delete this.filearraynew[j];
      console.log("sdddd"+this.filearraynew);
      let newfilearray = this.filearraynew.filter(function () {
        return true;
      });
      if (newfilearray.length > 0) {
      } else {
        // this.showButton = false;
      }

      this.filearray = newfilearray;
      console.log("sdddd"+this.filearray);
      $('#logopreview'+indexw).html('<p style="text-align: center;padding: 105px 27px 106px 38px;border: 1px solid;"> Vorschau</p>');

    };

    var files = event.target.files; //FileList object
    // var output = document.getElementById("result");

    var filesLength = files.length;

    for (let i = 0; i < filesLength; i++) {
      let f = files[i];
      let newsize = this.l;
      this.l = this.l + 1;
      // this.filearraynew.splice(index, 0, f);

      this.filearraynew[index]=f;
      this.filearray = this.filearraynew;
      let Size1 = f.size;
      let Size = this.dataconvert(Size1);
      var fileReader = new FileReader();
      //var target:EventTarget;
      fileReader.onload = function (e) {
        //var file = e.target;
   
        let extension = f.name.substr(f.name.lastIndexOf(".") + 1);
   
        let ImageName;
    
          ImageName = (e.target as any).result;

          let typeofimage = f.type;
         
          let dateofdocument = f.lastModified;
          // var d = new Date(dateofdocument);
          // var date =
          //   d.getDate() + "." + (d.getMonth() + 1) + "." + d.getFullYear();

          var date1 = new Date(dateofdocument).toLocaleDateString("en-IN", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric"
          });
  
          var date = date1.replace(/[/]/g, ".");

          // let Size = Math.round(f.size / 1024);
          $("#logopreview"+index).html(
            '<div class="pip" style="width: 290px;display: inline-block;margin: 8px;" "id=\'pipremove' +
              newsize +
              "'>" +
              '<div class="removepreview" id="removepreviewid' +
              newsize +
              '" style="background: #184297;border-radius: 50%;width:30px;height:30px;font-size: 14px; text-align: center; padding: 6px;color: white;position: absolute;margin-left: 257px;margin-top: 2px;margin-right: 0 !important;cursor: pointer;">X</div>' +
              "" +
              '<img class="imageThumb" style="width: 100%;height:210px" src="' +
              ImageName +
              '" title="' +
              f.name +
              '"/>' +
              "<div> <b>Dokumentenname: " +
              f.name +
              "</b> </div><div> <b>Dateigröße: " +
              Size +
              "</b>  </div><div> <b>Dateityp: " +
              typeofimage +
              "</b> </div><div> <b>Datum des Dokuments: " +
              date +
              "</b> </div></div>"
          )
        

        // $(`<div> <b>  ${Math.round((f.size / 1024))} </b> KB </div>`).insertAfter(".pip")
        $("#removepreviewid" + newsize).click(function () {
          removeData(newsize,index);
          // $("#pipremove" + i).remove();
          $(this).parent(".pip").remove();
        });
      };
      fileReader.readAsDataURL(f);
    }

  }





  handleImageChangeexplain(event: any, index) {
    $("#result").html("");
    console.log(this.loginid);
    event.preventDefault();
    const removeData = (j) => {
      console.log("sdddd"+this.filearraynew);
      console.log("sdddd"+j);
      delete this.filearraynew[j];
      console.log("sdddd"+this.filearraynew);
      let newfilearray = this.filearraynew.filter(function () {
        return true;
      });
      if (newfilearray.length > 0) {
      } else {
        // this.showButton = false;
      }

      this.filearray = newfilearray;
      console.log("sdddd"+this.filearray);
    };

    var files = event.target.files; //FileList object
    // var output = document.getElementById("result");

    var filesLength = files.length;

    for (let i = 0; i < filesLength; i++) {
      let f = files[i];
      let newsize = this.l;
      this.l = this.l + 1;
      // this.filearraynew.splice(index, 0, f);

      this.filearraynew[index]=f;
      this.filearray = this.filearraynew;
      let Size1 = f.size;
      let Size = this.dataconvert(Size1);
      var fileReader = new FileReader();
      //var target:EventTarget;
      fileReader.onload = function (e) {
        //var file = e.target;
   
        let extension = f.name.substr(f.name.lastIndexOf(".") + 1);
   
        let ImageName;
    
          ImageName = (e.target as any).result;

          let typeofimage = f.type;
         
          let dateofdocument = f.lastModified;
          // var d = new Date(dateofdocument);
          // var date =
          //   d.getDate() + "." + (d.getMonth() + 1) + "." + d.getFullYear();


            var date1 = new Date(dateofdocument).toLocaleDateString("en-IN", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric"
            });
    
            var date = date1.replace(/[/]/g, ".");

          // let Size = Math.round(f.size / 1024);
          $("#logopreview"+index).html(
            '<div class="pip" style="width: 290px;display: inline-block;margin: 8px;" "id=\'pipremove' +
              newsize +
              "'>" +
              '<div class="removepreview" id="removepreviewid' +
              newsize +
              '" style="background: #184297;border-radius: 50%;width:30px;height:30px;font-size: 14px; text-align: center; padding: 6px;color: white;position: absolute;margin-left: 257px;margin-top: -30px;margin-right: 0 !important;cursor: pointer;">X</div>' +
              "" +
              '<video  style="width: 100%;height:210px"  controls><source src="'+(e.target as any).result+'" type="video/mp4"></video>' +
              "<div> <b>Dokumentenname: " +
              f.name +
              "</b> </div><div> <b>Dateigröße: " +
              Size +
              "</b>  </div><div> <b>Dateityp: " +
              typeofimage +
              "</b> </div><div> <b>Datum des Dokuments: " +
              date +
              "</b> </div></div>"
          );
        

        // $(`<div> <b>  ${Math.round((f.size / 1024))} </b> KB </div>`).insertAfter(".pip")
        $("#removepreviewid" + newsize).click(function () {
          removeData(newsize);
          // $("#pipremove" + i).remove();
          $(this).parent(".pip").remove();
        });
      };
      fileReader.readAsDataURL(f);
    }

  }

  handleImageChangeimplementation(event: any, index) {
    $("#result").html("");
    console.log(this.loginid);
    event.preventDefault();
    const removeData = (j) => {
      console.log("sdddd"+this.filearraynew);
      console.log("sdddd"+j);
      delete this.filearraynew[j];
      console.log("sdddd"+this.filearraynew);
      let newfilearray = this.filearraynew.filter(function () {
        return true;
      });
      if (newfilearray.length > 0) {
      } else {
        // this.showButton = false;
      }

      this.filearray = newfilearray;
      console.log("sdddd"+this.filearray);
    };

    var files = event.target.files; //FileList object
    // var output = document.getElementById("result");

    var filesLength = files.length;

    for (let i = 0; i < filesLength; i++) {
      let f = files[i];
      let newsize = this.l;
      this.l = this.l + 1;
      // this.filearraynew.splice(index, 0, f);

      this.filearraynew[index]=f;
      this.filearray = this.filearraynew;
      let Size1 = f.size;
      let Size = this.dataconvert(Size1);
      var fileReader = new FileReader();
      //var target:EventTarget;
      fileReader.onload = function (e) {
        //var file = e.target;
   
        let extension = f.name.substr(f.name.lastIndexOf(".") + 1);
   
        let ImageName;
    
          ImageName = (e.target as any).result;

          let typeofimage = f.type;
         
          let dateofdocument = f.lastModified;
          // var d = new Date(dateofdocument);
          // var date =
          //   d.getDate() + "." + (d.getMonth() + 1) + "." + d.getFullYear();


            var date1 = new Date(dateofdocument).toLocaleDateString("en-IN", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric"
            });
    
            var date = date1.replace(/[/]/g, ".");

          // let Size = Math.round(f.size / 1024);
          $("#logopreview"+index).html(
            '<div class="pip" style="width: 290px;display: inline-block;margin: 8px;" "id=\'pipremove' +
              newsize +
              "'>" +
              '<div class="removepreview" id="removepreviewid' +
              newsize +
              '" style="background: #184297;border-radius: 50%;width:30px;height:30px;font-size: 14px; text-align: center; padding: 6px;color: white;position: absolute;margin-left: 257px;margin-top: -30px;margin-right: 0 !important;cursor: pointer;">X</div>' +
              "" +
              '<video  style="width: 100%;height:210px"  controls><source src="'+(e.target as any).result+'" type="video/mp4"></video>' +
              "<div> <b>Dokumentenname: " +
              f.name +
              "</b> </div><div> <b>Dateigröße: " +
              Size +
              "</b>  </div><div> <b>Dateityp: " +
              typeofimage +
              "</b> </div><div> <b>Datum des Dokuments: " +
              date +
              "</b> </div></div>"
          );
        

        // $(`<div> <b>  ${Math.round((f.size / 1024))} </b> KB </div>`).insertAfter(".pip")
        $("#removepreviewid" + newsize).click(function () {
          removeData(newsize);
          // $("#pipremove" + i).remove();
          $(this).parent(".pip").remove();
        });
      };
      fileReader.readAsDataURL(f);
    }

  }
  

  update_exercise()
  {
    $("#loaderouterid").css("display", "block");


if(this.filearray.length>0)
{

    for (let l = 0; l < 3; l++) {
    
      if(this.filearray[l])
      {
        console.log("dfffffffffffffffff"+this.filearray[l]);
        this.lengthfile++;
      }
      else{
        this.documentid[l]=this.currentopendata.element.document[l];
      }
      }



        // customerNo.substring(5,customerNo.length);
        console.log("TICKETNO"+this.currentopendata.element)
        console.log("TICKETNO"+this.currentopendata.element.exercise_id)
        this.ticket_no = this.currentopendata.element.exercise_id;



      
        if(this.lengthfile>0)
        {
          // console.log("ticket_no" + this.filearray.length);
        for (let i = 0; i < this.filearray.length; i++) {
        
if(this.filearray[i]!='' && this.filearray[i]!=undefined)
{
  console.log("ticket_no" + this.filearray[i]);
            var values = {
              image: "",
              document_type: "",
              document_sub_type: "",
              user_id: "",
              product_partner: "",
              companycode: "",
              brand: "",
              tags: [],
              upload_by: "",
              bill_type: "",
              ticket_no: "",
              document_name:"",
            };
            let url = this.filearray[i];
            let reader = new FileReader();
            let extension = url.name.substr(url.name.lastIndexOf(".") + 1);

            reader.readAsDataURL(url);
            reader.onload = () => {
              // this.fileName = url.name + " " + url.type;
              let base64ImgString = (reader.result as string).split(",")[1];

              let StringTypeCasting = Math.round(
                this.filearray[i].size / 1024
              );
              let typeofimage = this.filearray[i].type;
              let dateofdocument = this.filearray[i].lastModified;
              console.log("this.StringTypeCasting " + StringTypeCasting);
              values.image = this.filearray[i];
              values.document_type = "Allgemeines Dokument";
              values.document_sub_type = " ";
              if(i==0)
              {
                values.document_name = "Übungsbild";
              }
         else if(i==1)
         {
           values.document_name = "Erklärvideo";
         }
         else{
          values.document_name = "Umsetzungsvideo";
         }
              values.user_id = this.loginid;
              values.product_partner = " ";
              values.companycode = "42160 Jomiretto GmbH";
              values.brand = "Coursula";
              values.upload_by = "exercise";
              values.bill_type = "";
              values.ticket_no = this.ticket_no;
              values.tags.push(StringTypeCasting.toString());
              values.tags.push(typeofimage);
              values.tags.push(dateofdocument);
              this.uploadDocument1(values, i);

              // this.documentid='';
              values.tags = [];
            };
}
        }
      }
      else{
        this.documentid[0]=this.currentopendata.element.document[0];
        this.documentid[1]=this.currentopendata.element.document[1];
        this.documentid[2]=this.currentopendata.element.document[2];
        this.updateexercise();
      }

      }
      else{
        this.documentid[0]=this.currentopendata.element.document[0];
        this.documentid[1]=this.currentopendata.element.document[1];
        this.documentid[2]=this.currentopendata.element.document[2];
        this.updateexercise();
      }
 
  }



  uploadDocument1(values: any, index: any) {
    console.log("index"+index);
    this.progress[index] = 0;
    // this.progressInfos[index] = { value: 0, fileName: values.image.name };
    let length = this.filearray.length;
    console.log(length);

    const formData = new FormData();
    formData.append("document_type", values.document_type);
    formData.append("document_sub_type", values.document_sub_type);
    formData.append("product_partner", values.product_partner);
    formData.append("user", values.user_id);
    formData.append("companycode", values.companycode);
    formData.append("brand", values.brand);
    formData.append("tags", values.tags);
    formData.append("upload_by", values.upload_by);
    formData.append("bill_type", "");
    formData.append("ticket_no", values.ticket_no);
    formData.append("document_name", values.document_name);
    // formData.append("index", index);
    if (values.image !== "") {
      formData.append("document", values.image);
    }

    this.userService.callApiMultipartnew11(
      formData
    ).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {

        case HttpEventType.Sent:
          console.log('Request has been made!');
          
          break;
        case HttpEventType.ResponseHeader:
          console.log('Response header has been received!');
          break;
        case HttpEventType.UploadProgress:
          console.log(event.total);
          console.log(event.loaded);
          let percentage=Math.round(event.loaded / event.total * 100);
          if(percentage>0)
          {
          this.progress[index] = percentage;
          }
          console.log(`Uploaded! ${this.progress}%`);
          break;
        case HttpEventType.Response:
          console.log('User successfully created!', event.body);
          
          setTimeout(() => {
            this.uploadlength++;
            // this.progress[index] = 0;
            console.log(index);
            console.log(event.body.document_unique_id);
             this.documentid[index]=event.body.document_unique_id;
            this.updateexercise();
          }, 1500);

      }
    })
  }

  uploadDocument(values: any, index: any)
  {

    const formData = new FormData();
    formData.append("document_type", values.document_type);
    formData.append("document_sub_type", values.document_sub_type);
    formData.append("product_partner", values.product_partner);
    formData.append("user", values.user_id);
    formData.append("companycode", values.companycode);
    formData.append("brand", values.brand);
    formData.append("tags", values.tags);
    formData.append("upload_by", values.upload_by);
    formData.append("bill_type", "");
    formData.append("ticket_no", values.ticket_no);
    formData.append("document_name", values.document_name);
    // formData.append("index", index);
    if (values.image !== "") {
      formData.append("document", values.image);
    }

    this.userService
      .callApiMultipartnew(formData)
      .pipe(first())
      .subscribe(
        (data) => {
          this.uploadlength++;
          this.documentid[index]=data.document_unique_id;
          console.log(index);
        },
        (error) => {
          $("#loaderouterid").css("display", "none");

          console.log("Error", error["error"]);
        },
        () => {
          this.updateexercise();
        }
      );
  }

  get personalInfoForm() {
    return this.personalInfoFormGroup.controls;
  }
  getallexercie()
  {
    var datanew={exercise_category:''}
    const data = this.userService.getexercise(datanew).subscribe(
      (success: any) => {
        $("#loaderouterid").css("display", "none");
        // if success and error give response
        if (success.status == "error") {
        
          Swal.fire(
            "Übungsliste nicht gefunden",
            "",
            "error"
          );
          
        } else {
          this.customerList = success;
          this.setPage(1);
          this.recordCount = success.length;
          console.log(this.customerList);
        }
      },
      (rejected) => {
        console.log(rejected);
      }
    );
    this.filearray=[];
  }
  updateexercise()
  {
    console.log("dddddddddddddd"+this.lengthfile);
    console.log("dddddddddddddd"+this.uploadlength);
    if (this.lengthfile == this.uploadlength) {
      let product_type_new = [];
      for (var i = 0; i < this.typesOptionsArray.length; i++) {
        product_type_new[i] = this.typesOptionsArray[i];
      }
      if (this.oldtypesOptionsArray) {
        for (var l = 0; l < this.oldtypesOptionsArray.length; l++) {
          product_type_new[i] = this.oldtypesOptionsArray[l];
          i++;
        }
      }
      let data = {
        comment: this.personalInfoForm.comment.value,
        comment1: this.personalInfoForm.comment1.value,
        exercise_name: this.personalInfoForm.exercise_name.value,
        exercise_name1: this.personalInfoForm.exercise_name1.value,
        difficully_level: this.personalInfoForm.difficully_level.value,
        tools:this.personalInfoForm.tools.value,
        exercise_category:this.personalInfoForm.exercise_category.value,
        tags: product_type_new,
        document: this.documentid,
        _id: this.currentopendata.element._id,
      };




      this.userService
      .updateexercise(data)
      .pipe(first())
      .subscribe(
        (data) => {
          console.log("POST Request is successful ", data);
          $("#loaderouterid").css("display", "none");
          Swal.fire("Übung erfolgreich aktualisiert", "", "success");

          setTimeout(() => {
            Swal.close();
            // this.getallexercie();
            window.location.reload();
          }, 1000);
        },
        (error) => {
          console.log("Error", error["error"]);
          $("#loaderouterid").css("display", "none");
          Swal.fire(
            "Übung aktualisiert fehlgeschlagen",
            "",
            "error"
          );
          // setTimeout(() => {
          Swal.close();
          // window.location.reload();
          // location.href="http://localhost:4200/#/finance/product-partner-list";
          this.getallexercie();
          // }, 1000);
          // $("html, body").animate(
          //   { scrollTop: "0" }, 3000);
        }
      );


    }
  }


 
  logout() {
    localStorage.removeItem("token");
    this.router.navigate(["./"]);
  }
  exercise_management() {
    this.router.navigate(["/exercise-list"], {
    });
  }
  getdivoutside() {
    let accordianId = "collapse";
    let accordian: HTMLElement = document.getElementById(accordianId);
    let element1new: HTMLElement = document.getElementById(
      "cardbodyid"
    ) as HTMLElement;
    element1new.after(accordian);
    accordian.classList.add("collapse");
    // accordian.classList.add("collapse");
    accordian.classList.remove("collapse-show");
  }
  setPage(page: number)
  {
    this.getdivoutside();
        // get pager object from service
        this.pager = this.pagerService.getPager(this.customerList.length, page);

        // get current page of items
        this.pagedItems = this.customerList.slice(
          this.pager.startIndex,
          this.pager.endIndex + 1
        );
        if (this.customerList.length > 0) {
          this.startRecord =
            this.pager.currentPage * this.pagerService.getDefaultPageSize() -
            this.pagerService.getDefaultPageSize() +
            1;
          this.endRecord =
            this.pager.currentPage * this.pagerService.getDefaultPageSize() >
            this.customerList.length
              ? this.customerList.length
              : this.pager.currentPage * this.pagerService.getDefaultPageSize();
        } else {
          this.startRecord = 0;
          this.endRecord = 0;
        }
  }









}

