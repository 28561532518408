import { Component, OnInit, AfterViewInit } from "@angular/core";
import {CdkDragDrop, moveItemInArray,copyArrayItem,transferArrayItem} from '@angular/cdk/drag-drop';
import Swal from "sweetalert2";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
  CheckboxControlValueAccessor,
} from "@angular/forms";
import { UserService } from "../user.service";

import { concatMapTo, first } from "rxjs/operators";
import { error } from "util";
import { Router } from "@angular/router";
// import { SearchCountryField, CountryISO } from "ngx-intl-tel-input";
import { ReturnStatement } from "@angular/compiler";
import intlTelInput from "intl-tel-input";
import { MatChipInputEvent } from "@angular/material/chips";
import { Observable, Observer } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ActivatedRoute, Params } from "@angular/router";
// import { CompanySelectDialogComponent } from "../company-select-dialog/company-select-dialog.component"
// import { MatDialog } from '@angular/material';
import * as $ from "jquery";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
// import { randomBytes } from "crypto";
export interface Fruit {
  name: string;
}
export interface Fruitnew {
  name: string;
}
@Component({
  selector: 'app-add-prevention-course',
  templateUrl: './add-prevention-course.component.html',
  styleUrls: ['./add-prevention-course.component.css']
})
export class AddPreventionCourseComponent implements OnInit {
  loginRole = sessionStorage.getItem("currentActiveRole");
  title = this.userService.getDecodedAccessToken(sessionStorage.getItem("Token"))
    .title;
    
  loginid = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).id;
  lastname = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).lastname;
  firstname = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).firstname;
  customer_no = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).customerno;
  queryID: any;
  COMPANYNAME = this.userService.getDecodedAccessToken(
    sessionStorage.getItem("Token")
  ).companyname;
  routeParams: any;
  personalInfoFormGroup: FormGroup;
  currentUserData: any;
  currentopendata: any;
  exercisesequencelistview=[];
  oldtypesOptionsArray: any[] = [];
  secondstep: FormGroup;
  registerDone = false;
  registraionError = false;
  personalInfoFormGroupadd: FormGroup;
  registerbtn = true;
  countryName: any;
  fiorettomediacustomer: any = [];
  uploadedFiles: Array<File> = [];
  Kundeoption: Observable<any>;
  Kundecontrol = new FormControl();
  kunde_id: any = "";
  projectdata: any;
  exercisesequencelist: any = [];
  exercisesequencelistsearch:any =[];
  exerciseoption: Observable<any[]>
  searchlist=[];
  documentid: any[][] = [];
  constructor(
    private _formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
   
    private activatedRoute: ActivatedRoute // private dialog: MatDialog
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.queryID = params["name"];

      // this.statusCustomer();
      // this.userService.statusCustomer({ "id": this.pagedItems[0]._id })
    });
    // this.SparteFormGroup = this.fb.group({
    //   name: '',
    //   quantities: this.fb.array([]) ,
    // });
  }
  ticket_no = "";
  filearray: any[] = [];
  filearraynew: any[] = [];
  l: number = 0;
  items = ["Javascript", "Typescript"];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  objectjsonnew: any;
  fruits: Fruit[] = [];
  lengthfile: number = 0;
  uploadlength: number = 0;
  exercisedata: any = [];
  typesOptionsArray: string[] = [];
  selecteditem: string[] = [];
  exercise_controls = new FormControl();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  itemList: string[] = [
    "Matte",
    "Bett",
    "Spiegel",
    "Strohhalm",
    "Eiswürfel",
    "Hocker",
    "Widerstandsband",
    "Pezziball",
    "Stab",
    "Kettlebell",
    "Faszienrolle",
    "Seil",
    "Hantel (klein)",
    "Handtuch",
    "Tape",
    "Tennisball",
    "Murmeln",
    "Zopfgummi",
    "Tisch",
    "Knete",
  ];
  selecteditemList = this.itemList;
  onKey(value) {
    this.selecteditemList = this.search(value);
  }
  search(value: string) {
    let filter = value.toLowerCase();
    return this.itemList.filter((option) =>
      option.toLowerCase().startsWith(filter)
    );
  }

  getdatanew(value) {
    this.selecteditem.push(value);
    console.log("asdsaddsadasdA" + value);
    $("#inputtools").val("");
    this.onKey("");
  }
  addOpt(event: MatChipInputEvent, index: number): void {
    const input = event.input;
    const value = event.value;
    console.log("dsdsad" + index);
    console.log("input" + input);
    console.log("value" + value);
    console.log("valuenew" + value.trim());
    console.log("valuenew" + this.typesOptionsArray);
    // Add our fruit
    if (this.typesOptionsArray.length > 19) {
      Swal.fire("Begrenzen Sie 20 Tags", "", "error");
    } else {
      console.log("ffffffffffff"+JSON.stringify(this.searchlist) );
      if ((value || "").trim()) {
        this.typesOptionsArray.push(value.trim());
        this.setsearchlist();
       this.exercise_controls.setValue(' ');
       $('#datanew').trigger('click');
      }
      // Reset the input value
      if (input) {
        input.value = "";
      }


    }

    
  }
  drop(event: CdkDragDrop<any[]>) {
    // console.log("sasdaasd"+JSON.stringify(event.previousContainer)+"    ,     "+JSON.stringify(event.container));
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      copyArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
                        // transferArrayItem(event.previousContainer.data,
                        //   event.container.data,
                        //   event.previousIndex,
                        //   event.currentIndex);
    }
  }
setsearchlist()
{
  this.exercisesequencelistsearch=[];
  if(this.typesOptionsArray.length>0)
  {
  for(let i=0;i<this.searchlist.length;i++)
      {
console.log("ffffffffffff"+this.searchlist[i]);
this.exercisesequencelistsearch.push({
  id: this.searchlist[i].id,
  value: this.searchlist[i].name,
  img: this.searchlist[i].img,
  diff: this.searchlist[i].diff,
});
      }
    }
}


  removeOpt(opt: string, index: number): void {
    console.log("removeOpt");
    const optIndex = this.typesOptionsArray.indexOf(opt);
    if (optIndex >= 0) {
      this.typesOptionsArray.splice(optIndex, 1);
    }
    this.exercise_controls.setValue('');
    this.setsearchlist();
  }

  telInputObject(obj) {
    console.log(obj);
    obj.setCountry("de");
  }

  logout() {
    console.log("in logout function");

    localStorage.removeItem("token");
    this.router.navigate(["./"]);
  }


  checknoofdays()
  {
    let check=true;
    for(let i=0;i<this.exercisesequencelist.length;i++)
    {
      let noofdays:any=$('#noofdays'+i).val();
      if(noofdays=='')
      {
        check=false;
      }
    }
    return check;
  }
 ngOnInit() {
this.getallexerciselist();

    this.loginRole = this.loginRole;
    this.routeParams = this.activatedRoute.snapshot.routeConfig.path;

    this.personalInfoFormGroup = this._formBuilder.group({
      preventioncourse_name: ["", Validators.required],
      fundedby:["",Validators.required],
    });

    this.personalInfoFormGroupadd = this._formBuilder.group({
      exercise_name: ["", Validators.required],
      exercise_name1: ["", Validators.required],
      comment1: [""],
      comment: ["", Validators.required],
      manual_tags: ["", Validators.required],
      tools: ["", Validators.required],
      difficully_level: ["", Validators.required],

      upload_file: ["", Validators.required],
      explain: ["", Validators.required],
      implementation: ["", Validators.required],
      exercise_category: ["", Validators.required],
    });

    this.exerciseoption = this.exercise_controls.valueChanges.pipe(
      startWith(""),
      map((value) => (typeof value == "string" ? value : value.name)),
      map((name) =>this._filterexercisedata(name)
      )
    );

    this.typesOptionsArray.push();
  }
  onKey1(value) {
    this.selecteditemList = this.search(value);
  }
  getexercise() {
    if( this.exercisesequencelist.length>19)
    {
      Swal.fire("Maximal 20 Übungen zur Übungsabläufe hinzugefügt added","","error");
    }
    else{

    
    let l = "b";
   
let length=this.exercisesequencelist.length-1;
let noofdays=$('#noofdays'+length).val();
console.log("exercisesequencelist" + noofdays);
console.log("exercisesequencelist" + length);
if(noofdays!='')
{
    this.exercisesequencelist.push({
      id: this.exercise_controls.value.id,
      value: this.exercise_controls.value.name,
      length: this.exercise_controls.value.length,
      noofdays:''
    });

    this.exercise_controls.setValue(this.exercise_controls.value.name);
  }
  else
  {
    Swal.fire("Bitte geben Sie die Anzahl der Tage ein","","error")
    this.exercise_controls.setValue('');
  }
  }
  }



  loopingexerciselist(userData: string | any[]) {
    let exerciselist = [];
    console.log("kundenew" + JSON.stringify(userData));
    for (let i = 0; i < userData.length; i++) {
     
      exerciselist.push({
        name: userData[i].exercisesequence_name,
        id: userData[i]._id,
        length: userData[i].exercise_id.length,
      });
    }

  
    return exerciselist;
  }

  getallexerciselist() {


    this.userService
      .getSearchexercisesequence('Prävention')
      .pipe(first())
      .subscribe((data: any) => {
        this.exercisedata = this.loopingexerciselist(data);

        console.log("exercisedata" + this.exercisedata);
      });
  }

  _filterexercisedata(value: string): string[] {


    const filterValue11 = value.toLowerCase();
    let searchlistnewlist = this.exercisedata.filter(function(itm){
      return JSON.stringify(itm.name).toLowerCase().indexOf(filterValue11) > -1;
    });

    console.log("searchlistnewlist"+searchlistnewlist);
    return searchlistnewlist;
  }
  removesequence(i) {
    console.log(i);
    this.exercisesequencelist.splice(i, 1);
  }

  add_exercise() {
    this.router.navigate(["/add-prevention-course"]);
    // this.queryID = this.selectedUser.customerno;
    // this.ngOnInit()
  }


  public imagePath;
  public pdfPath;
  imgURL: any = [];
  public message: string;
  imgshow = "";

  get personalInfoForm() {
    return this.personalInfoFormGroup.controls;
  }

 

  calcount(id, type) {
    let exercisesequence_namecount: any;
    if (type == "preventioncourse_name") {
      let preventioncourse_name = this.personalInfoForm.preventioncourse_name
        .value.length;

      exercisesequence_namecount = 35 - preventioncourse_name;
    }
    $("#" + id).html(exercisesequence_namecount);
  }
  choosecategory(value) {
   

    this.personalInfoFormGroup.patchValue({
      fundedby: value,
    });

    $("#fundedby").val(value);
    if (value == "yes") {
      $("#yes").css("background-color", "#808080b5");
      $("#no").css("background-color", "white");
    } else {
      $("#no").css("background-color", "#808080b5");
      $("#yes").css("background-color", "white");
    }
  }
  getKeyByDinner(obj, searchid) {
    let returnKey:any = -1;
      
    console.log("kundenew1111111111111111111obj" + JSON.stringify(obj));
    $.each(obj, function(key, info) {

      console.log("kundenew1111111111111111111info" + JSON.stringify(info));

        if (info.element._id == searchid) {
           returnKey = key;
            return false; 
        };   
    });
    
    return returnKey;       
           
}

choosecategorymodal(value) {
  console.log("dddddddddddd" + value);
  this.personalInfoFormGroup.patchValue({
    exercise_category: value,
  });

  $("#exercise_category").val(value);
  if (value == "Therapie") {
    $("#Therapie").css("background-color", "gray");
    $("#Prävention").css("background-color", "white");
  } else {
    $("#Prävention").css("background-color", "gray");
    $("#Therapie").css("background-color", "white");
  }



}

getcurrentUser11(openid?, currentData?) {
  const that = this;
  if (currentData && !openid) {
    setData(currentData);
  } else {
    // console.log("call api")
    this.userService.getEditexercise(openid).subscribe(function (data1) {
      that.currentUserData = data1;
      console.log(data1);

      setData(data1);
    });
  }

  function setData(data1) {
    that.currentopendata = data1;

    console.log("dddddddddddd" + JSON.stringify(data1));
    console.log("dddddddddddd" + data1.element.exercise_category);
    that.choosecategorymodal(data1.element.exercise_category);



    that.personalInfoFormGroupadd.patchValue({
      exercise_name: data1.element.exercise_name,
      exercise_name1: data1.element.exercise_name1,
      difficully_level: data1.element.difficully_level,
      tools: data1.element.tools,
      comment: data1.element.comment,
      comment1: data1.element.comment1,
    });




    that.oldtypesOptionsArray = data1.element.tags;
    console.log("dddddddddddd" + that.oldtypesOptionsArray);
    $("#logopreview0").html(
      "<img src='" + data1.image1 + "' style='width: 100%;height: 300px'>"
    );
    $("#logopreview1").html(
      "<video  style='width: 100%;height: 300px' controls><source  src='" +
        data1.image2 +
        "' type='video/mp4'></video>"
    );
    $("#logopreview2").html(
      "<video  style='width: 100%;height: 300px' controls><source  src='" +
        data1.image3 +
        "' type='video/mp4'></video>"
    );

    $("#logopreview01").html(
      "<img src='" + data1.image1 + "' style='width: 100%;height: 300px'>"
    );
    $("#logopreview11").html(
      "<video  style='width: 100%;height: 300px' controls><source  src='" +
        data1.image2 +
        "' type='video/mp4'></video>"
    );
    $("#logopreview21").html(
      "<video  style='width: 100%;height: 300px' controls><source  src='" +
        data1.image3 +
        "' type='video/mp4'></video>"
    );


    $("#loaderouterid").css("display", "none");
  }

  // this.choosecategory();
  // $('#loaderouterid').css("display","none");
}

openinformation(id)
{
  $("#loaderouterid").css("display", "block");
  this.getcurrentUser11(id);
}
  loopingexerciselistprevention(userData,exerciselistdata) {
    let exerciselist = [];
    console.log("kundenew" + JSON.stringify(userData));
  
    for(let i=0;i<exerciselistdata.length;i++)
    {
      
      console.log("kundenew1111111111111111111exerciselistdata" +exerciselistdata[i]);
    let exercise_new_id=exerciselistdata[i];
    console.log("kundenew1111111111111111111exercise_new_id" + JSON.stringify(exercise_new_id));
  let key=  this.getKeyByDinner(userData, exercise_new_id);
  console.log("kundenew1111111111111111111key" + key);
    if(key!='-1')
    {
      console.log("asfasdasdasd" + key);
      this.exercisesequencelistview.push({
        id: userData[key].element._id,
        value: userData[key].element.exercise_name1,
       
        img: userData[key].img,
        diff: userData[key].element.difficully_level,
      });
    }
    
   
    }
   
  $('#buttonsavedate').trigger('click');
    console.log("broooker!!!!!!!!!!!!!!!!!!!!!" + this.exercisesequencelistview);
  
    // return exerciselist;
  }



  openexercisesequence(id)
  {

    let that=this;
    this.userService.getexercisesequencebyid({'id':id}).subscribe(function (data1) {
      // that.currentUserData = data1;\
   
      let exercise_category=data1[0].exercisesequence_category;
    console.log("data1"+exercise_category)
    // that.getdata(data1,exercise_category);
    that.userService.getsearchexerciseinsequence(exercise_category).subscribe(function (data:any)
    { 
      that.loopingexerciselistprevention(data,data1[0].exercise_id);

    });
   
    });
  

  
  }



  save_exercise_sequence() {
    $("#loaderouterid").css("display", "block");
let dataarray=[];
let noofdays=[];
        for (let i = 0; i < this.exercisesequencelist.length; i++) {
          dataarray.push(this.exercisesequencelist[i].id);
          let noofdays1:any=$('#noofdays'+i).val();
          console.log("noofdays"+noofdays);
          noofdays.push(noofdays1);
        }

        
        let datanew = {
          preventioncourse_name: this.personalInfoForm.preventioncourse_name
            .value,
            fundedby: this.personalInfoForm.fundedby
            .value,
          created_by: this.loginid,
          noofdays: noofdays,
          exercise_sequence_id: dataarray,
        };

        this.userService
        .registerpreventioncourse(datanew)
        .pipe(first())
        .subscribe(
          (data) => {
      
            Swal.fire(
              "Präventionskurse erfolgreich hinzugefügt.",
              "",
              "success"
            );
            this.ticket_no = "";

            $("#loaderouterid").css("display", "none");
            setTimeout(() => {
              Swal.close();
              this.router.navigate(["./prevention-course-list"]);
            }, 1000);
          },
          (error) => {
            Swal.fire("Error" + error["error"] + "", "", "error");

            $("#loaderouterid").css("display", "none");
            setTimeout(() => {
              Swal.close();
              this.add_exercise();
            }, 1000);
            // $("html, body").animate(
            //   { scrollTop: "0" }, 3000);
          }
        );


  }
}
