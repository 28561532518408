import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { UserService } from "../user.service";
@Component({
  selector: "app-toolslist",
  templateUrl: "./toolslist.component.html",
  styleUrls: ["./toolslist.component.css"],
})
export class ToolslistComponent implements OnInit {
  id: any;
  api_url;
  questionindex: any;
  toolslist;
  fiorettourl;
  constructor(private route: ActivatedRoute, private UserService: UserService,private router:Router) {
    this.route.queryParams.subscribe((param) => {
      this.id = param["id"];
      this.questionindex = param["questionindex"];
    });
  }

  ngOnInit() {
    $("#loaderouterid").css("display", "block");
    this.fiorettourl = environment.fiorettourl;
    this.api_url = environment.API_URL;
    let data = { starttreatment_id: this.id };
    this.UserService.gettoolsbytherapy(data).subscribe((result: any) => {
      $("#loaderouterid").css("display", "none");
      if(result.data.length>0)
      {
      this.toolslist = result.data;
      }
      else{
        this.router.navigate(["/therapy-plan"]);
      }
      console.log(result);
      
    });
  }

  navigateexercise_list() {
    this.router.navigate(["/exercise_list"],  { queryParams: { id: this.id,questionindex:this.questionindex } });
  }
}
