import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-howitwork',
  templateUrl: './howitwork.component.html',
  styleUrls: ['./howitwork.component.css']
})
export class HowitworkComponent implements OnInit {

  constructor() { }
  length:any;
  headername:any;
  ngOnInit() {



    const svg = document.getElementById("svgPath");
    this.length=5000;
    let lengthold=this.length;
    svg.style.strokeDasharray = this.length;
    svg.style.strokeDashoffset = lengthold;
    var height=$(window).scrollTop();
    $(window).scroll(function() {
      var percentageComplete = (($(window).scrollTop() / (document.body.scrollHeight - $(window).height())) * 100);
      var newUnit = parseInt(lengthold, 10);
      var offsetUnit = percentageComplete * (newUnit / 100);
      let data:any=newUnit - offsetUnit;
      console.log(offsetUnit)
      console.log(newUnit)
      svg.style.strokeDashoffset=data
    
    });
this.headername="How Ites Work"
;  }

}
